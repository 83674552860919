// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewSr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Molimo vas da prihvatite i pratite sledeća pravila za korišćenje veštačke inteligencije u uredničkom radu.
          Osnova za sledeće tačke su{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            Smernice za AI Ringiera.
          </ExternalLink>
          <ol>
            <li>
              Aktivno promovišemo razumevanje i upotrebu alata veštačke inteligencije među našim zaposlenima radi
              optimizacije radnih procesa.
            </li>
            <li>Naša upotreba AI uvek se zasniva na odgovornosti i etičkim principima.</li>
            <li>
              Transparentnost nam je važna: Jasno obznanjujemo upotrebu AI u našim izveštajima, bilo u tekstovima ili
              opisima slika.
            </li>
            <li>Osiguravamo da sadržaj generisan AI-jem nikada nije objavljen bez konačne provere od strane čoveka.</li>
            <li>
              Strogo se pridržavamo pravila da ne unosimo poverljive informacije, poslovne tajne ili lične podatke u
              javne AI alate.
            </li>
            <li>
              Za transkripciju intervjua koristimo naš interni audio alat (Blisper) u AI Forge-u, a ne usluge AI trećih
              strana.
            </li>
            <li>
              Ne koristimo AI bazirane alate za obradu slika kao što su DALL·E 3 i Midjourney za kreiranje vesti.
              Njihova upotreba je dozvoljena za ilustracije, ali samo uz konsultaciju sa supervizorom, odeljenjem za
              obradu slika ili AI vođom redakcije.
            </li>
            <li>
              Za osetljive teme gde bi AI mogao dovesti do dezinformacija ili netačnog tumačenja, suzdržavamo se od
              njegove upotrebe.
            </li>
            <li>Nudimo interno obučavanje o AI za naše zaposlene.</li>
            <li>
              Imajući u vidu brzi razvoj u oblasti AI, uvek ostajemo ažurirani i ohrabrujemo naše zaposlene da prijave
              potencijalno korisne AI alate odgovornom kancelariji za AI.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          Kako od Februara24/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
