export const localStorageSetItem = (key: string, value: string) => {
  if (localStorage) {
    localStorage.setItem(key, value)
  }
}

export const localStorageGetItem = (key: string): string | null => {
  if (localStorage) {
    return localStorage.getItem(key)
  }
  return null
}

export const localStorageRemoveItem = (key: string) => {
  if (localStorage) {
    localStorage.removeItem(key)
  }
}
