'use client'
// material-ui
import { Avatar, Box, Button, Card, CardMedia, Grid, Link, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import styled from '@mui/system/styled'
import { IconSparkles } from '@tabler/icons-react'
import { routes } from 'constants/routes'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { Spacer } from 'components/ui-component/Spacer'
import { useAuth } from 'hooks/useAuth'
import hero from 'assets/images/hero.png'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { FormattedMessage } from 'components/FormattedMessage'
import { isPathRestricted } from 'utils/url'
import { config } from 'config'

// ==============================|| DASHBOARD PAGE ||============================== //

export const DashboardView = () => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { session } = useAuth()

  const firstName = session?.name.split(' ')[0]
  const userInitials =
    session?.name
      ?.split(' ')
      .map((n) => n[0])
      .join('') || ''

  const cardIconStyle = {
    borderRadius: '9px',
    padding: '10px',
    backgroundColor: theme.palette.blue[800],
  }

  const smallCardIconStyle = {
    borderRadius: '9px',
    padding: '10px',
    backgroundColor: theme.palette.blue[500],
    color: theme.palette.background.paper,
  }

  const getPluginCircle = () => {
    if (config.chromeExtentionUrl) {
      return (
        <PluginCircle>
          <Typography variant="bodysm">
            <div>
              <b>
                <FormattedMessage id="dashboard.newChromePlugin" components={{ 1: <br /> }} />
              </b>
            </div>{' '}
            <FormattedMessage id="dashboard.newChromePluginDescription" components={{ 1: <br /> }} />
          </Typography>
        </PluginCircle>
      )
    }
  }

  return (
    <Grid container rowSpacing="24px" columnSpacing="24px">
      <Grid item md={12} xs={12}>
        <MainCard contentSX={{ padding: 0 }}>
          <CardMedia
            image={hero}
            title="hero"
            sx={{
              height: {
                xs: '130px',
                sm: '130px',
                md: '250px',
              },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: { md: 'center' },
              marginBottom: '-24px',
            }}
          >
            <Spacer v size={matchDownMd ? 16 : 40} />
            <Avatar
              sx={{
                bgcolor: theme.palette.blue[800],
                height: {
                  md: '72px',
                },
                width: {
                  md: '72px',
                },
                fontSize: {
                  md: '28px',
                },
                color: theme.palette.background.paper,
                ':hover': {
                  bgcolor: theme.palette.blue[800],
                  color: theme.palette.background.paper,
                },
              }}
              alt={session?.name || 'avatar'}
            >
              {userInitials}
            </Avatar>
            <Spacer v size={matchDownMd ? 8 : 16} />
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '20px', sm: '20px', md: '34px' },
                lineHeight: { xs: '28px', sm: '28px', md: '42px' },
                margin: 0,
              }}
            >
              <FormattedMessage id="common.welcome" />, {firstName}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '16px', sm: '16px', md: '20px' },
                lineHeight: { xs: '22px', sm: '22px', md: '28px' },
                color: theme.palette.grey[500],
              }}
            >
              {session?.email}
            </Typography>
          </CardMedia>
        </MainCard>
      </Grid>
      {!isPathRestricted(routes.speechToText.url, session?.restriction) && (
        <Grid item lg={4} md={6} xs={12}>
          <BigCard>
            <Circle />
            <Circle2 />
            <CardInnerWrapper>
              <MaterialIcon sx={cardIconStyle} name="volume_up" />
              <Spacer v size={16} />
              <Typography variant={matchDownMd ? 'h2' : 'h1'} sx={{ color: theme.palette.background.paper }}>
                <FormattedMessage id="pages.speechToText" />
              </Typography>
              {!matchDownMd && (
                <>
                  <Spacer v size={8} />
                  <Typography variant="h3" sx={{ color: theme.palette.background.paper, maxWidth: '480px' }}>
                    <FormattedMessage id="dashboard.speechToText.description" />
                  </Typography>
                </>
              )}

              <Spacer v size={16} />
              <ButtonHolder>
                <StyledButton variant="outlined" startIcon={<IconSparkles />} href={routes.speechToText.url}>
                  <FormattedMessage id="dashboard.speechToText.button" />
                </StyledButton>
              </ButtonHolder>
            </CardInnerWrapper>
          </BigCard>
        </Grid>
      )}
      {!isPathRestricted(routes.titleGenerator.url, session?.restriction) && (
        <Grid item lg={4} md={6} xs={12}>
          <BigCard sx={{ paddingBottom: '48px' }}>
            <Circle />
            <Circle2 />
            {getPluginCircle()}
            <CardInnerWrapper>
              <MaterialIcon sx={cardIconStyle} name="notes" />
              <Spacer v size={16} />
              <Typography variant={matchDownMd ? 'h2' : 'h1'} sx={{ color: theme.palette.background.paper }}>
                <FormattedMessage id="pages.titleGenerator" />
              </Typography>
              {!matchDownMd && (
                <>
                  <Spacer v size={8} />
                  <Typography variant="h3" sx={{ color: theme.palette.background.paper, maxWidth: '480px' }}>
                    <FormattedMessage id="dashboard.titleGenerator.description" />
                  </Typography>
                </>
              )}
              <Spacer v size={16} />
              <ButtonHolder>
                <StyledButton variant="outlined" startIcon={<IconSparkles />} href={routes.titleGenerator.url}>
                  <FormattedMessage id="dashboard.titleGenerator.button" />
                </StyledButton>
                {config.chromeExtentionUrl && (
                  <>
                    <Spacer v size={8} />
                    <StyledButton
                      variant="outlined"
                      startIcon={<MaterialIcon name="add" fontSize="24px" />}
                      href={config.chromeExtentionUrl}
                    >
                      <FormattedMessage id="common.addChromePlugin" />
                    </StyledButton>
                  </>
                )}
              </ButtonHolder>
            </CardInnerWrapper>
          </BigCard>
        </Grid>
      )}
      {!isPathRestricted(routes.articleGenerator.url, session?.restriction) && (
        <Grid item lg={4} md={6} xs={12}>
          <BigCard sx={{ paddingBottom: '48px' }}>
            <Circle />
            <Circle2 />
            {getPluginCircle()}
            <CardInnerWrapper>
              <MaterialIcon sx={cardIconStyle} name="text_fields" />
              <Spacer v size={16} />
              <Typography variant={matchDownMd ? 'h2' : 'h1'} sx={{ color: theme.palette.background.paper }}>
                <FormattedMessage id="pages.articleGenerator" />
              </Typography>
              {!matchDownMd && (
                <>
                  <Spacer v size={8} />
                  <Typography variant="h3" sx={{ color: theme.palette.background.paper, maxWidth: '480px' }}>
                    <FormattedMessage id="dashboard.articleGenerator.description" />
                  </Typography>
                </>
              )}
              <Spacer v size={16} />
              <ButtonHolder>
                <StyledButton variant="outlined" startIcon={<IconSparkles />} href={routes.articleGenerator.url}>
                  <FormattedMessage id="dashboard.articleGenerator.button" />
                </StyledButton>
                {config.chromeExtentionUrl && (
                  <>
                    <Spacer v size={8} />
                    <StyledButton
                      variant="outlined"
                      startIcon={<MaterialIcon name="add" fontSize="24px" />}
                      href={config.chromeExtentionUrl}
                    >
                      <FormattedMessage id="common.addChromePlugin" />
                    </StyledButton>
                  </>
                )}
              </ButtonHolder>
            </CardInnerWrapper>
          </BigCard>
        </Grid>
      )}
      {!isPathRestricted(routes.articleSummaryGenerator.url, session?.restriction) && (
        <Grid item lg={4} md={6} xs={12}>
          <BigCard>
            <Circle />
            <Circle2 />
            <CardInnerWrapper>
              <MaterialIcon sx={cardIconStyle} name="format_list_bulleted" />
              <Spacer v size={16} />
              <Typography variant={matchDownMd ? 'h2' : 'h1'} sx={{ color: theme.palette.background.paper }}>
                <FormattedMessage id="dashboard.articleSumamry.title" />
              </Typography>
              {!matchDownMd && (
                <>
                  <Spacer v size={8} />
                  <Typography variant="h3" sx={{ color: theme.palette.background.paper, maxWidth: '480px' }}>
                    <FormattedMessage id="dashboard.articleSumamry.description" />
                  </Typography>
                </>
              )}
              <Spacer v size={16} />
              <ButtonHolder>
                <StyledButton variant="outlined" startIcon={<IconSparkles />} href={routes.articleSummaryGenerator.url}>
                  <FormattedMessage id="dashboard.articleSumamry.button" />
                </StyledButton>
              </ButtonHolder>
            </CardInnerWrapper>
          </BigCard>
        </Grid>
      )}
      {!isPathRestricted(routes.translator.url, session?.restriction) && (
        <Grid item lg={4} md={6} xs={12}>
          <BigCard>
            <Circle />
            <Circle2 />
            <CardInnerWrapper>
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <MaterialIcon sx={cardIconStyle} name="translate" />
              </Box>
              <Spacer v size={16} />
              <Typography variant={matchDownMd ? 'h2' : 'h1'} sx={{ color: theme.palette.background.paper }}>
                <FormattedMessage id="pages.translator" />
              </Typography>
              {!matchDownMd && (
                <>
                  <Spacer v size={8} />
                  <Typography variant="h3" sx={{ color: theme.palette.background.paper, maxWidth: '480px' }}>
                    <FormattedMessage id="dashboard.translator.description" />
                  </Typography>
                </>
              )}
              <Spacer h size={16} />
              <ButtonHolder>
                <StyledButton variant="outlined" startIcon={<IconSparkles />} href={routes.translator.url}>
                  <FormattedMessage id="dashboard.translator.button" />
                </StyledButton>
              </ButtonHolder>
            </CardInnerWrapper>
          </BigCard>
        </Grid>
      )}
      <Grid item lg={4} md={6} xs={12}>
        {!isPathRestricted(routes.about.url, session?.restriction) && (
          <>
            <Link href={routes.about.url} sx={{ textDecoration: 'none' }}>
              <SmallCard>
                <MaterialIcon sx={smallCardIconStyle} name="info" />
                <Typography variant="h3" sx={{ color: theme.palette.blue[500] }}>
                  <FormattedMessage id="pages.about" />
                </Typography>
              </SmallCard>
            </Link>
            <Spacer v size={24} />
          </>
        )}
        {!isPathRestricted(routes.manifesto.url, session?.restriction) && (
          <Link href={routes.manifesto.url} sx={{ textDecoration: 'none' }}>
            <SmallCard>
              <MaterialIcon sx={smallCardIconStyle} name="local_police" />
              <Typography
                variant="h3"
                sx={{ color: theme.palette.blue[500], display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <FormattedMessage id="pages.aiManifesto" />
              </Typography>
            </SmallCard>
          </Link>
        )}
      </Grid>
    </Grid>
  )
}

const Circle = styled('div')`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.blue[600]};
  position: absolute;
  right: -80px;
  top: -80px;
`

const Circle2 = styled(Circle)`
  opacity: 0.5;
  right: 40px;
  top: -120px;
`

const BigCard = styled(Card)`
  padding: 16px 16px 0px 16px;
  background-color: ${({ theme }) => theme.palette.blue[500]};
  color: ${({ theme }) => theme.palette.background.paper};
  position: relative;
  height: 100%;
`

const SmallCard = styled(Card)`
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.blue[500]};
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: 'pointer';

  &:hover {
    .material-symbols-outlined {
      background-color: ${({ theme }) => theme.palette.blue[800]};
    }

    .MuiTypography-root {
      color: ${({ theme }) => theme.palette.blue[800]};
    }
  }
`

const CardInnerWrapper = styled('div')`
  height: 100%;
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const ButtonHolder = styled('div')`
  position: absolute;
  top: calc(100% - 66px);
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.background.paper};
  border-color: ${({ theme }) => theme.palette.background.paper};
  text-transform: uppercase;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: ${({ theme }) => theme.palette.background.paper};
    border-color: ${({ theme }) => theme.palette.background.paper};
  }
`

const PluginCircle = styled('div')`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  text-align: center;
  transform: rotate(-15deg);
  background: #ffde14;
  position: absolute;
  top: 20px;
  right: 20px;
  padding-top: 14px;
  z-index: 2;
`
