import { styled } from '@mui/material'
import logo from 'views/TextToSpeech/images/logo.svg'
import menu from 'views/TextToSpeech/images/menu.png'

export const Header = () => {
  return (
    <Wrap>
      <Top>
        <img alt="logo" src={logo} />
        <img alt="menu" src={menu} />
      </Top>
      <Bottom />
    </Wrap>
  )
}

const Wrap = styled('div')`
  box-sizing: border-box;
  width: 100%;
`

const Top = styled('div')`
  height: 48px;
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(226, 0, 0);
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`

const Bottom = styled('div')`
  height: 48px;
  box-shadow: rgb(224, 224, 224) 0px 1px 2px;
`
