import { useTranslationContext } from 'hooks/useTranslationContext'
import { AppLanguages } from 'types/translation'
import { LearnMoreViewDe } from './de'
import { LearnMoreViewEn } from './en'
import { LearnMoreViewBg } from './bg'
import { LearnMoreViewFr } from './fr'
import { LearnMoreViewHu } from './hu'
import { LearnMoreViewRo } from './ro'
import { LearnMoreViewPl } from './pl'
import { LearnMoreViewSk } from './sk'
import { LearnMoreViewSr } from './sr'

export const LearnMoreView = () => {
  const { currentLanguage } = useTranslationContext()

  switch (currentLanguage) {
    case AppLanguages.BULGARIAN: {
      return <LearnMoreViewBg />
    }
    case AppLanguages.FRENCH: {
      return <LearnMoreViewFr />
    }
    case AppLanguages.GERMAN: {
      return <LearnMoreViewDe />
    }
    case AppLanguages.HUNGARIAN: {
      return <LearnMoreViewHu />
    }
    case AppLanguages.POLISH: {
      return <LearnMoreViewPl />
    }
    case AppLanguages.ROMANIAN: {
      return <LearnMoreViewRo />
    }
    case AppLanguages.SERBIAN: {
      return <LearnMoreViewSr />
    }
    case AppLanguages.SLOVAKIAN: {
      return <LearnMoreViewSk />
    }

    default: {
      return <LearnMoreViewEn />
    }
  }
}
