// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewBg = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Добре дошли в нашата подбрана колекция от безплатно обучение по Изкуствен Интелект (ИИ)! Тук ще намерите
          вълнуващ набор от видеа, покриващи различни аспекти на ИИ, включително Генеративен ИИ. Независимо дали сте на
          първите стъпки или вече имате напреднали познания, тук има нещо за всекиго.
          <br />
          <br />
          Тази колекция е идеална за вас, ако искате да разширите познанията си в областта на ИИ, независимо дали за
          професионални цели или за личен интерес. Можете да се запознаете със съдържанието в удобен за вас темп и да се
          образувате удобно у дома.
          <br />
          <br />
          Загребете във фасиниращия свят на Изкуствения Интелект и се възползвайте от възможността да се запознаете с
          най-новите тенденции и технологии. Надявам се, че ще намерите тези ресурси ценни и обогатяващи. Насладете се
          на своето учебно пътуване!
        </Typography>
        <Typography variant="h3">ИИ в общи черти:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            ИИ срещу Машинно обучение срещу Дълбоко обучение срещу Невронни мрежи (IBM; Статия)
          </ExternalLink>{' '}
          - Обясняваща статия за разликите между тези концепции и полезно определение на Изкуствения Интелект.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Въведение в Големите Езикови Модели (YouTube)
          </ExternalLink>{' '}
          - Това е 1-часово общо представяне за големите езикови модели: основните технически компоненти зад системи
          като ChatGPT, Claude и Google Bard. Какво са те, къде са насочени, сравнения и аналогии с операционните
          системи от наши дни и някои от предизвикателствата свързани със сигурността на този нов компютърен парадигма.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Въведение в Генеративния ИИ (Google)
          </ExternalLink>{' '}
          - Това е курс на начално ниво за микрообучение, насочен към обясняване какво е Генеративния ИИ, как се
          използва и как се различава от традиционните методи за машинно обучение. Освен това обхваща Google
          инструменти, които ви помагат да развиете собствени приложения за Ген ИИ.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Въведение в Големите Езикови Модели (ГЕМ) (Google)
          </ExternalLink>{' '}
          - Това е курс на начално ниво за микрообучение, който изследва какво са големите езикови модели (ГЕМ),
          употребата им и къде могат да се използват, както и как можете да използвате настройката на команди, за да
          подобрите производителността на ГЕМ. Освен това обхваща Google инструменти, които ви помагат да развиете
          собствени приложения за Ген ИИ.
        </Typography>
        <Typography variant="h3">Насочване:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Основи на Инженерството на Наставките (AWS)
          </ExternalLink>{' '}
          - В този курс ще научите принципите, техниките и най-добрите практики за създаване на ефективни наставки.
          Курсът въвежда основите на инженерството на наставки и продължава с напреднали техники за наставки. Ще научите
          също как да предотвратите злоупотребата с наставки и как да се справите с предразсъдъците при взаимодействие
          със структури на основни модели.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Инженерство на Наставки (Coursera)
          </ExternalLink>{' '}
          - В този курс ще научите принципите, техниките и най-добрите практики за създаване на ефективни наставки.
          Курсът въвежда основите на инженерството на наставки и продължава с напреднали техники за наставки. Ще научите
          също как да предотвратите злоупотребата с наставки и как да се справите с предразсъдъците при взаимодействие
          със структури на основни модели.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            Инженерство на Наставки за ChatGPT
          </ExternalLink>{' '}
          - Научете най-добрите практики за инженерство на наставки за разработка на приложения. Открийте нови начини за
          използване на ГЕМ-ове, включително как да създадете собствен чатбот. Получете практически опит в писането и
          изпълнението на наставки сами с помощта на OpenAI API
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Ефективно насочване за ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - Това видео обяснява как да създадете ефективни наставки за ChatGPT 4, за да получите най-доброто от вашия ИИ
          асистент. Без значение дали сте разработчик или просто някой, който иска да подобри производителността на своя
          ИИ асистент, това видео е за вас.
        </Typography>
        <Typography variant="h3">Създаване на Изображения:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Курс Midjourney за Начинаещи (YouTube)
          </ExternalLink>{' '}
          - В този курс ще научите основите на генерирането на изкуство с ИИ, използвайки платформата Midjourney. Ще
          откриете основите на създаването и управлението на наставки, работата с различни версии на моделите и
          изследването на напреднали техники за създаване на впечатляващи изображения.
        </Typography>
      </MainCard>
    </Text>
  )
}
