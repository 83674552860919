import { Button, Chip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { RotatingIcon } from './RotatingIcon'
import { Spacer } from 'components/ui-component/Spacer'
import { Dispatch, SetStateAction } from 'react'
import { Rating } from 'components/Rating'
import { FormattedMessage } from 'components/FormattedMessage'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  content: string
  onRatingClick: Dispatch<
    SetStateAction<{
      title: string
      defaultValue: string
    } | null>
  >
  onRegenerateClick?: () => void
  isLoading: boolean
  feedbackSent: boolean
}

export const Result = ({ title, content, onRegenerateClick, isLoading, onRatingClick, feedbackSent }: Props) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <Wrapper $isMobile={matchDownMd}>
      <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}
      </Typography>
      <Spacer v size={20} />
      <Option>
        <Typography
          variant="h6"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {content}
        </Typography>
        <Chip
          sx={{
            fontSize: '10px',
            lineHeight: '16px',
            textTransform: 'none',
            fontWeight: 700,
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
            padding: '4px 0px',
            marginLeft: '8px',
            height: 'unset',
          }}
          label={content.length}
        />
      </Option>
      <Spacer v size={8} />
      {onRegenerateClick && (
        <ActionsContainer>
          <Button
            variant="text"
            sx={{
              textTransform: 'uppercase',
              color: theme.palette.blue[500],
              fontSize: '12px !important',
              lineHeight: '20px',
            }}
            startIcon={
              <RotatingIcon $enabled={isLoading} className="material-symbols-outlined">
                autorenew
              </RotatingIcon>
            }
            disabled={isLoading}
            onClick={onRegenerateClick}
          >
            <FormattedMessage id="common.regenerate" />
          </Button>
          {!isLoading && (
            <>
              <Rating
                title={matchDownMd ? t('rating.rate') : t('rating.rateResult')}
                onRatingClick={onRatingClick}
                feedbackSent={feedbackSent}
                feedbackKey="Haupttext"
              />
            </>
          )}
        </ActionsContainer>
      )}
    </Wrapper>
  )
}

const ActionsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  padding: 16px 20px;
  border-radius: 8px;
`

const Option = styled('div')`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blue[50]};
  }
`

const Wrapper = styled('div')<{ $isMobile: boolean }>`
  max-width: ${({ $isMobile }) => ($isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border-radius: 8px;
`
