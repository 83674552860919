// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewPl = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Witaj na naszej wyselekcjonowanej stronie, na której polecamy różnorodne zewnętrzne narzędzia AI! Tutaj
          znajdziesz wybór narzędzi, które mogą zwiększyć Twoją pracę i zapoczątkować innowacje. Jednak ważne jest, aby
          pamiętać, że te narzędzia powinny być używane z ostrożnością i odpowiedzialnością.
          <br />
          <br />
          Zanim się zanurzysz, zalecamy zapoznanie się z naszymi wytycznymi dotyczącymi AI. Te wytyczne mają na celu
          zapewnienie etycznego, odpowiedzialnego i skutecznego wykorzystania technologii AI. Pomogą Ci nawigować w
          potencjalnych złożonościach i wyzwaniach związanych z korzystaniem z zewnętrznych narzędzi AI.
          <br />
          <br />
          Należy pamiętać, że chociaż polecamy te narzędzia ze względu na ich potencjalne korzyści, nie zapewniamy
          bezpośredniego wsparcia dla nich. Jeśli napotkasz problemy lub masz pytania, będziesz musiał skontaktować się
          z odpowiednimi kanałami wsparcia dla każdego narzędzia.
          <br />
          <br />
          Korzystaj z tych narzędzi mądrze, aby badać możliwości AI, zawsze pamiętając o naszych wytycznych i potrzebie
          ostrożnego i świadomego zastosowania. Udanych eksploracji!
        </Typography>
        <Typography variant="h3">Asystenci AI:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : Obecnie najlepszy asystent AI na rynku. ChatGPT firmy OpenAI to model AI konwersacyjny zdolny do generowania
          tekstów ludzkich. Jest idealny do pisania artykułów, burz mózgów, zbierania opinii na temat pomysłów,
          podsumowywania treści itp. Silnik tworzenia obrazów OpenAI, Dall-E, dostępny za pośrednictwem ChatGPT, może
          generować unikalne obrazy do artykułów.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai to asystent AI dostarczający odpowiedzi multimediów i agregujący wiele podstawowych modeli AI,
          takich jak ChatGPT firmy OpenAI lub Claude firmy Anthropic. Może być używany do różnorodnych codziennych
          zadań.
        </Typography>
        <Typography variant="h3">Pisanie:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : Niemieckojęzyczny asystent do pisania AI, który pomaga w szybkim tworzeniu przekonujących treści. Może być
          używany do pisania artykułów, generowania nagłówków i tworzenia kreatywnych tekstów.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : AI do generowania tekstu wspierający język niemiecki, który specjalizuje się w tworzeniu treści
          marketingowych. Jest pomocny w pisaniu atrakcyjnych wstępów do artykułów, kopii reklamowej i postów na mediach
          społecznościowych związanych z artykułami.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Narzędzie do pisania i parafrazowania oparte na AI. Pomaga w przepisywaniu zdań dla klarowności, poprawie
          gramatyki i unikaniu plagiatu w artykułach. Dobrze działa w języku niemieckim.
        </Typography>
        <Typography variant="h3">Tworzenie obrazów:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Program AI specjalizujący się w tworzeniu wysokiej jakości, artystycznych obrazów. Idealny do tworzenia
          unikalnych wizualizacji i ilustracji do historii i funkcji. Obecnie dostępny tylko za pośrednictwem{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          . Możesz znaleźć przewodnik, jak go używać{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            tutaj
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Narzędzie sterowane AI, które generuje realistyczne obrazy i sztukę. Przydatne do opowiadania wizualnych
          historii i tworzenia niestandardowych ilustracji do artykułów.
        </Typography>
        <Typography variant="h3">Edycja zdjęć:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Platforma do edycji zdjęć, która wykorzystuje AI do zaawansowanych zadań edycji. Może poprawić jakość
          fotografii używanych w artykułach, w tym regulowanie oświetlenia, tekstury i kolorów.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Oferuje oprogramowanie do poprawy zdjęć zasilane AI. Przydatne do powiększania obrazów bez utraty jakości,
          redukcji szumów i wyostrzania zdjęć do publikacji cyfrowej.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Narzędzie do edycji obrazów oparte na AI, które może automatycznie edytować zdjęcia, dostosowywać
          oświetlenie i kolory oraz stosować różne efekty, nadające się do poprawy treści wizualnych w artykułach.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Specjalizuje się w usuwaniu tła ze zdjęć za pomocą AI. Jest to korzystne do szybkiego edytowania zdjęć do
          artykułów, zwłaszcza gdy trzeba izolować przedmioty od ich tła do kompozycji graficznych.
        </Typography>
      </MainCard>
    </Text>
  )
}
