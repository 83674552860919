'use client'

import { LinearProgress, Typography, linearProgressClasses, styled, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode, Ref, forwardRef, useCallback, useEffect, useState } from 'react'
import { Accept, FileRejection, useDropzone } from 'react-dropzone'
import { Spacer } from 'components/ui-component/Spacer'
import { ButtonUnderline } from 'components/ui-component/buttons/ButtonUnderline'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'components/FormattedMessage'

type Props = {
  onFileChange?: (file?: File) => void
  accept?: Accept
  progress?: number
  progressText?: ReactNode
  formError?: FieldError
  isLoading?: boolean
  value: File | null
}

const MAX_SIZE = 2500 * 1024 * 1024
const MAX_AUDIO_SIZE = 500 * 1024 * 1024

export const Dropzone = forwardRef(
  (
    { onFileChange, accept, progress, progressText, formError, isLoading, value }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const theme = useTheme()
    const [selectedFile, setSelectedFile] = useState<File | null>()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const [error, setError] = useState(false)
    const [hasAudioSizeError, setHasAudioSizeError] = useState(false)

    const handleOnDrop = useCallback(
      (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
          setError(true)
          return
        }
        const file = acceptedFiles[0]
        if (file) {
          setSelectedFile(file)
          setError(false)
        }
        onFileChange && onFileChange(file)
      },
      [onFileChange],
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleOnDrop,
      accept,
      maxSize: MAX_SIZE,
    })

    useEffect(() => {
      if (value) {
        if (value.size < MAX_AUDIO_SIZE) {
          setHasAudioSizeError(false)
          setSelectedFile(value)
        } else {
          setHasAudioSizeError(true)
          setSelectedFile(null)
          onFileChange && onFileChange()
        }
      }
    }, [value, onFileChange])

    const hasError = Boolean(error || formError || hasAudioSizeError)

    return (
      <>
        <DropzoneContainer {...getRootProps()} $error={hasError}>
          <Icon className="material-symbols-outlined">{selectedFile ? 'volume_up' : 'cloud_upload'}</Icon>
          <input {...getInputProps()} />
          {selectedFile ? (
            <>
              <Typography variant="h3" color={theme.palette.blue[500]}>
                {selectedFile.name}
              </Typography>
              <Spacer v size={8} />
              {progress || isLoading ? (
                <>
                  <Typography variant="h3" color={theme.palette.purple[500]}>
                    {progressText ? (
                      progressText
                    ) : (
                      <>
                        <FormattedMessage id="audioToText.uploadingFile" /> {progress}%
                      </>
                    )}
                  </Typography>
                  <Spacer v size={16} />
                  <BorderLinearProgress variant="determinate" value={progress} aria-label="secondary color progress" />
                </>
              ) : (
                <Typography variant="h3" color={theme.palette.blue[500]}>
                  <ButtonUnderline>
                    <FormattedMessage id="audioToText.replaceFile" />
                  </ButtonUnderline>
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography
                variant="h3"
                color={theme.palette.blue[500]}
                sx={{ display: 'flex', flexDirection: 'column', minHeight: isDragActive ? '84px' : 'unset' }}
              >
                {isDragActive ? (
                  <FormattedMessage id="audioToText.dropFilesHere" />
                ) : (
                  <>
                    {matchDownMd ? (
                      <ButtonUnderline>
                        <FormattedMessage id="audioToText.chooseAFile" />
                      </ButtonUnderline>
                    ) : (
                      <>
                        <span>
                          <FormattedMessage id="audioToText.dragDropFile" />
                        </span>
                        <span>
                          <FormattedMessage id="audioToText.or" />
                        </span>
                        <ButtonUnderline>
                          <FormattedMessage id="audioToText.chooseAFile" />
                        </ButtonUnderline>
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Spacer v size={16} />
              <Typography variant="bodybg" color={theme.palette.grey['500']} sx={{ display: 'block' }}>
                <FormattedMessage id="audioToText.allowedFiles" /> .mp3, .mp4, .mpeg, .mpga, .m4a, .wav, .webm, .mov,
                .mkv
              </Typography>
              <Typography variant="bodybg" color={theme.palette.grey['500']} sx={{ display: 'block' }}>
                Max. 2500 MB
              </Typography>
            </>
          )}
        </DropzoneContainer>
        {hasError &&
          (hasAudioSizeError ? (
            <Typography variant="bodybg" color={theme.palette.error.main} sx={{ marginTop: '8px', display: 'block' }}>
              <FormattedMessage id="audioToText.audioSizeError" />
            </Typography>
          ) : (
            <>
              <Typography variant="bodybg" color={theme.palette.error.main} sx={{ marginTop: '8px', display: 'block' }}>
                Upload failed. Please check the following rules and try again.
              </Typography>
              <Typography variant="bodybg" color={theme.palette.error.main} sx={{ display: 'block' }}>
                Allowed files: .mp3, .mp4, .mpeg, .mpga, .m4a, .wav, .webm, .mov, .mkv
              </Typography>

              <Typography variant="bodybg" color={theme.palette.error.main} sx={{ display: 'block' }}>
                Max. file size: 2500 MB
              </Typography>
            </>
          ))}
      </>
    )
  },
)
Dropzone.displayName = 'Dropzone'

const DropzoneContainer = styled('div')<{ $error: boolean }>(({ theme, $error }) => ({
  width: '100%',
  padding: '20px',
  borderRadius: '12px',
  border: `3px dashed ${$error ? theme.palette.error.main : theme.palette.blue[500]}`,
  textAlign: 'center',
  color: theme.palette.blue[500],
  backgroundColor: theme.palette.grey[50],
}))

const Icon = styled('span')(({ theme }) => ({
  fontSize: '80px !important',
  color: theme.palette.blue[500],
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  background: theme.palette.purple[50],
  [`& .${linearProgressClasses.bar}`]: {
    background: theme.palette.purple[500],
    borderRadius: 5,
  },
}))
