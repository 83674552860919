import { styled } from '@mui/material'
import MarkdownPlugin from 'react-markdown'

type Props = {
  children: string
}

export const Markdown = ({ children }: Props) => {
  return (
    <StyledMarkdownPlugin
      components={{
        a: (element) => {
          const numberRegex = new RegExp('^[0-9]*$')
          if (element.children && numberRegex.test(element.children as string)) {
            return (
              <ReferenceLink href={element.href} target="_blank" rel="noreferrer">
                {element.children}
              </ReferenceLink>
            )
          }
          return (
            <a href={element.href} target="_blank" rel="noreferrer">
              {element.children}
            </a>
          )
        },
      }}
    >
      {children}
    </StyledMarkdownPlugin>
  )
}

const StyledMarkdownPlugin = styled(MarkdownPlugin)`
  p {
    margin: 0;
  }

  ul,
  ol {
    line-height: normal;
    margin: 0;
  }

  ul li {
    marign: 0;
    padding: 0;
  }
`

const ReferenceLink = styled('a')`
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background: ${({ theme }) => theme.palette.blue[500]};
  color: ${({ theme }) => theme.palette.common.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
