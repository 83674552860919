import { useTranslationContext } from 'hooks/useTranslationContext'
import { AppLanguages } from 'types/translation'
import { ManifestoViewDe } from './de'
import { ManifestoViewEn } from './en'
import { ManifestoViewBg } from './bg'
import { ManifestoViewFr } from './fr'
import { ManifestoViewHu } from './hu'
import { ManifestoViewRo } from './ro'
import { ManifestoViewPl } from './pl'
import { ManifestoViewSk } from './sk'
import { ManifestoViewSr } from './sr'

export const ManifestoView = () => {
  const { currentLanguage } = useTranslationContext()

  switch (currentLanguage) {
    case AppLanguages.BULGARIAN: {
      return <ManifestoViewBg />
    }
    case AppLanguages.FRENCH: {
      return <ManifestoViewFr />
    }
    case AppLanguages.GERMAN: {
      return <ManifestoViewDe />
    }
    case AppLanguages.HUNGARIAN: {
      return <ManifestoViewHu />
    }
    case AppLanguages.POLISH: {
      return <ManifestoViewPl />
    }
    case AppLanguages.ROMANIAN: {
      return <ManifestoViewRo />
    }
    case AppLanguages.SERBIAN: {
      return <ManifestoViewSr />
    }
    case AppLanguages.SLOVAKIAN: {
      return <ManifestoViewSk />
    }

    default: {
      return <ManifestoViewEn />
    }
  }
}
