// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewSr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Dobrodošli na našoj kuriranoj stranici gde preporučujemo razne spoljne AI alate! Ovde ćete pronaći izbor alata
          koji mogu unaprediti vaš rad i podstaknuti inovacije. Međutim, važno je zapamtiti da ovi alati treba da se
          koriste sa pažnjom i odgovornošću.
          <br />
          <br />
          Pre nego što zaronite, molimo vas da se upoznate sa našim smernicama za AI. Ove smernice su tu da bi se
          osigurala etička, odgovorna i efikasna upotreba AI tehnologija. Pomoći će vam da se snađete u potencijalnim
          složenostima i izazovima koji dolaze sa korišćenjem spoljnih AI alata.
          <br />
          <br />
          Napomena: Iako preporučujemo ove alate zbog njihovih potencijalnih koristi, mi ne pružamo direktnu podršku za
          njih. Ako naiđete na probleme ili imate pitanja, treba da se obratite odgovarajućim kanalima podrške za svaki
          alat.
          <br />
          <br />
          Koristite ove alate mudro kako biste istražili mogućnosti AI, uvek imajući na umu naše smernice i potrebu za
          opreznom i informisanom primenom. Srećno istraživanje!
        </Typography>
        <Typography variant="h3">AI Asistenti:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : Trenutno najbolji AI asistent na tržištu. ChatGPT od kompanije OpenAI je konverzacijski AI model sposoban da
          generiše tekst sličan ljudskom. Idealan je za kreiranje članaka, brainstorming, dobijanje povratnih
          informacija o idejama, sumiranje sadržaja i slično. Obrtni motor za kreiranje slika kompanije OpenAI, Dall-E,
          dostupan putem ChatGPT-a, može generisati jedinstvene slike za članke.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai je AI asistent koji isporučuje multimedijske odgovore i agregira više osnovnih AI modela poput
          ChatGPT-a kompanije OpenAI ili Claude-a kompanije Anthropic. Može se koristiti za različite svakodnevne
          zadatke.
        </Typography>
        <Typography variant="h3">Pisanje:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : AI pomoćnik za pisanje koji podržava nemački jezik i pomaže u brzom kreiranju uverljivog sadržaja. Može se
          koristiti za pisanje članaka, generisanje naslova i produkciju kreativnih pisanih dela.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : AI za generisanje teksta koji podržava nemački jezik i specijalizovan je za kreiranje marketinškog sadržaja.
          Koristan je za pisanje uzbudljivih uvoda članaka, kopije oglasa i postova na društvenim mrežama koji se odnose
          na članke.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Alat za pisanje i parafraziranje koji pokreće AI. Pomaže u prepravljanju rečenica radi jasnoće, poboljšanja
          gramatike i izbegavanja plagijarizma u člancima. Dobro funkcioniše na nemačkom jeziku.
        </Typography>
        <Typography variant="h3">Kreiranje slika:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Program AI koji se specijalizuje za kreiranje visokokvalitetnih, umetničkih slika. Idealno za kreiranje
          jedinstvenih vizuala i ilustracija za priče i karakteristike. Trenutno dostupan samo putem{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discorda
          </ExternalLink>
          . Vodič o tome kako ga koristiti možete pronaći{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            ovde
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Alat pokretan AI-jem koji generiše realistične slike i umetnost. Koristan za vizualno pripovedanje i
          kreiranje prilagođenih ilustracija za članke.
        </Typography>
        <Typography variant="h3">Uređivanje fotografija:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Platforma za uređivanje fotografija koja koristi AI za napredne zadatke uređivanja. Može poboljšati kvalitet
          fotografija korišćenih u člancima, uključujući podešavanja osvetljenja, teksture i boje.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Nudi softver za poboljšanje fotografija pomoću AI-a. Koristan za povećanje veličine slika bez gubitka
          kvaliteta, smanjenje šuma i oštrinu slika za digitalnu publikaciju.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Alat za uređivanje slika zasnovan na AI-u koji automatski uređuje fotografije, podešava osvetljenje i boje i
          primenjuje različite efekte, pogodan za poboljšavanje vizuelnog sadržaja u člancima.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Specijalizovan za uklanjanje pozadina sa slika pomoću AI-a. Koristan za brzo uređivanje slika za članke,
          posebno kada je potrebno izolovati subjekte od njihovih pozadina za grafičke kompozicije.
        </Typography>
      </MainCard>
    </Text>
  )
}
