// material-ui
import { useTheme } from '@mui/material/styles'
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material'

// project imports
import { AuthWrapper1 } from 'components/authentication/AuthWrapper1'
import { AuthCardWrapper } from 'components/authentication/AuthCardWrapper'
import { useEffect } from 'react'
import { routes } from 'constants/routes'
import { Logo } from 'components/ui-component/Logo'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import robotHi from 'assets/images/robot-hi.svg'
import { AuthLoginButton } from 'components/authentication/auth-forms/AuthLoginButton'
import { IDProvider } from 'shared'

// ================================|| AUTH3 - LOGIN ||================================ //

export const Login = () => {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  const { checkSession } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    checkSession(true, (isLoggedIn) => {
      if (isLoggedIn) {
        navigate(routes.dashboard.url)
      }
    })
  }, [navigate, checkSession])

  return (
    <AuthWrapper1>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: '100vh', background: theme.palette.blue[50] }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ textAlign: 'center' }} xs={12}>
                    <Logo />
                  </Grid>
                  <Grid item sx={{ textAlign: 'center' }} xs={12}>
                    <img src={robotHi} alt="Robot hi" width="130" height="130" />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
                    <Grid
                      container
                      direction={matchDownSM ? 'column-reverse' : 'row'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            color={theme.palette.blue[500]}
                            gutterBottom
                            variant="h3"
                            sx={matchDownSM ? { maxWidth: '150px', textAlign: 'center' } : {}}
                          >
                            Welcome to Ringier AI-Forge
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '20px !important' }}>
                    <AuthLoginButton idp={IDProvider.OKTA} />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '20px !important' }}>
                    <AuthLoginButton idp={IDProvider.GOOGLE} />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  )
}
