import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Segment } from 'shared'

dayjs.extend(duration)

export const getFullText = (segments: Segment[], showSpeakers = true, showTimestamps = true) => {
  return segments
    .reduce(
      (acc, segment) =>
        `${acc}\n${showSpeakers ? `${segment.speaker}:` : ''} ${showTimestamps ? `(${dayjs.duration(segment.start, 'seconds').format('HH:mm:ss')}) ` : ''}${segment.words
          .map((word) => word.text)
          .join(' ')}\n`,
      ``,
    )
    .trim()
}
