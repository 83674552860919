import Lottie from 'lottie-react'
import { styled, Typography, useTheme } from '@mui/material'
import translationLoader from './lotties/translation_loader.json'
import { FormattedMessage } from 'components/FormattedMessage'

export const TranslationLoader = () => {
  const theme = useTheme()
  return (
    <>
      <Typography
        variant="h3"
        sx={{ textAlign: 'center', color: theme.palette.purple[500], marginBottom: '20px', marginTop: '16px' }}
      >
        <FormattedMessage id="translator.translationInProgress" />
      </Typography>
      <StyledLottie animationData={translationLoader} />
    </>
  )
}

const StyledLottie = styled(Lottie)`
  height: 180px;
`
