import dayjs from 'dayjs'

export enum DateFormat {
  DEFAULT,
}

const formats = {
  [DateFormat.DEFAULT]: 'DD.MM.YYYY, HH:mm',
}

export const formatDate = (date: string, dateFormat: DateFormat) => dayjs(date).format(formats[dateFormat])
