// material-ui
import { Box, Button } from '@mui/material'

// project imports
import { useAuth } from 'hooks/useAuth'
import { IDProvider } from 'shared'

export const AuthLoginButton = (
  { loginProp, idp, ...others }: { loginProp?: number; idp: IDProvider } = { idp: IDProvider.OKTA },
) => {
  const { login } = useAuth()

  return (
    <Box>
      <Button fullWidth type="submit" variant="contained" onClick={() => login(idp)}>
        LOGIN WITH {idp.toUpperCase()}
      </Button>
    </Box>
  )
}
