import { Button, styled } from '@mui/material'
import { ChatQuestionItem } from 'types/chatbot'

type Props = {
  questions: ChatQuestionItem[]
  onQuestionClick?: (question: ChatQuestionItem) => void
}

export const Questions = ({ questions, onQuestionClick }: Props) => (
  <QuestionsWrapper className="suggested-questions">
    {questions.map((question, index) => (
      <Question key={`button-${index}`} onClick={() => onQuestionClick && onQuestionClick(question)}>
        {question.text}
      </Question>
    ))}
  </QuestionsWrapper>
)

const QuestionsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 16px;
`

const Question = styled(Button)`
  color: rgb(66, 176, 197);
  border-color: rgb(66, 176, 197);
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  border-width: 0.5px;
  border-style: solid;
  text-transform: none;
`
