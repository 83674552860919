// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewEn = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge is a specialized AI toolbox designed for Ringier Media, offering a suite of selected tools that
          leverage generative AI and other AI technologies. These tools are tailored to address specific editorial use
          cases effectively.
          <br />
          <br />
          This innovative toolbox is a part of the AI Boost initiative.
          <br />
          <br />
          The first tool available is our advanced speech-to-text conversion tool, previously known as Blisper. It
          offers a seamless way to transcribe video and audio files in any language, simplifying content creation.
          <br />
          <br />
          Looking ahead, we plan to introduce a tool that will enable the creation of articles based on various external
          inputs such as police reports, media reports, or foreign news articles. This will greatly enhance content
          diversity and relevancy.
          <br />
          <br />
          We are committed to regularly introducing and testing new functionalities to enhance and support your daily
          work routines.
          <br />
          <br />
          Your feedback is invaluable to us. Please share your thoughts on the quality of the individual tools through
          this survey:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          For any bugs or errors encountered, please report them here:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          For any other queries, suggestions, or inquiries, feel free to contact:
          <ul>
            <li>
              Lauri Kurki, Project Lead AI Boost at{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, AI Innovation Lead Blick Newsroom at{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
