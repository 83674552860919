import { routes } from 'constants/routes'

// project imports
import { Logo } from 'components/ui-component/Logo'
import { Link } from '@mui/material'

// ==============================|| MAIN LOGO ||============================== //

export const LogoSection = () => (
  <Link href={routes.dashboard.url} aria-label="theme logo">
    <Logo />
  </Link>
)
