import { styled } from '@mui/material'
import { CSSProperties } from 'react'

type Props = {
  name: string
  fontSize?: string
  sx?: CSSProperties
  className?: string
  filled?: boolean
  fontWeight?: string
  color?: string
  onClick?: () => void
}

export const MaterialIcon = ({ name, fontSize, sx, className, filled, fontWeight, color, onClick }: Props) => {
  return (
    <Icon
      sx={sx}
      onClick={onClick}
      filled={filled}
      className={`${className} material-symbols-outlined`}
      style={{ fontSize, fontWeight, color }}
    >
      {name}
    </Icon>
  )
}

const Icon = styled('span')(({ filled }: { filled?: boolean }) => ({
  'font-variation-settings': filled ? "'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24" : '',
}))
