'use client'

import {
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Modal,
  SxProps,
  Theme,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  isOpen: boolean
  onClose: () => void
  title?: React.ReactNode | string
  children?: React.ReactNode
  actions?: React.ReactNode
  sx?: SxProps<Theme>
  headerSX?: SxProps<Theme>
}

export const SimpleModal = ({ isOpen = true, onClose, title, children, actions, sx, headerSX }: Props) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div tabIndex={-1}>
        <MainCard
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', lg: 560 },
            ...sx,
          }}
          headerSX={
            matchDownMd
              ? {
                  position: 'sticky',
                  zIndex: 2,
                  top: 0,
                  background: '#FFFFFF',
                  ...headerSX,
                }
              : {
                  ...headerSX,
                }
          }
          title={title}
          noDivider
          content={false}
          secondary={
            <IconButton onClick={onClose} size="large">
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <CardContent sx={{ minHeight: 'calc(100% - 170px)' }}>{children}</CardContent>
          {actions}
        </MainCard>
      </div>
    </Modal>
  )
}

export const SimpleModalActions = ({
  children,
  noDivider = false,
  sx,
}: {
  children: React.ReactNode
  noDivider?: boolean
  sx?: SxProps<Theme>
}) => (
  <SimpleModalActionsWrapper>
    {!noDivider && <Divider />}
    <CardActions sx={{ justifyContent: 'end', ...sx }}>{children}</CardActions>
  </SimpleModalActionsWrapper>
)

const SimpleModalActionsWrapper = styled('div')({
  position: 'sticky',
  bottom: 0,
  background: '#FFFFFF',
})
