// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewPl = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Witaj w naszej wybranej kolekcji darmowych materiałów edukacyjnych na temat sztucznej inteligencji (AI)! Tutaj
          znajdziesz ekscytujący zakres filmów obejmujących różne aspekty AI, w tym Generatywną AI. Bez względu na to,
          czy dopiero zaczynasz czy już masz zaawansowaną wiedzę, tutaj znajdziesz coś dla siebie.
          <br />
          <br />
          Ta kolekcja jest idealna dla Ciebie, jeśli chcesz poszerzyć swoją wiedzę z zakresu AI, zarówno z powodów
          zawodowych, jak i osobistych. Możesz przejrzeć zawartość we własnym tempie i wygodnie się kształcić w domu.
          <br />
          <br />
          Zanurz się w fascynujący świat sztucznej inteligencji i skorzystaj z okazji, aby zapoznać się z najnowszymi
          trendami i technologiami. Mam nadzieję, że te zasoby okażą się dla Ciebie wartościowe i wzbogacające. Ciesz
          się swoją drogą edukacyjną!
        </Typography>
        <Typography variant="h3">AI ogólnie:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            AI vs. Machine Learning vs. Deep Learning vs. Neural Networks (IBM; Artykuł)
          </ExternalLink>{' '}
          - Artykuł wyjaśniający różnice między tymi pojęciami oraz pomocna definicja sztucznej inteligencji.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Wprowadzenie do Dużych Modeli Językowych (YouTube)
          </ExternalLink>{' '}
          - Jest to godzinne wprowadzenie dla ogólnego audytorium do Dużych Modeli Językowych: podstawowe komponenty
          techniczne systemów takich jak ChatGPT, Claude i Google Bard. Co to są, dokąd zmierzają, porównania i analogie
          do dzisiejszych systemów operacyjnych oraz niektóre z wyzwań związanych z bezpieczeństwem tego nowego
          paradygmatu obliczeniowego.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Wprowadzenie do Generatywnej AI (Google)
          </ExternalLink>{' '}
          - Jest to wprowadzenie na poziomie mikronauczenia, które ma na celu wyjaśnienie, czym jest Generatywna AI, jak
          jest używana i w jaki sposób różni się od tradycyjnych metod uczenia maszynowego. Obejmuje także narzędzia
          Google, które pomogą Ci opracować własne aplikacje Gen AI.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Wprowadzenie do Dużych Modeli Językowych (LLM) (Google)
          </ExternalLink>{' '}
          - Jest to wprowadzenie na poziomie mikronauczenia, które bada, czym są duże modele językowe (LLM), przypadki
          użycia, gdzie mogą być wykorzystane, oraz jak można wykorzystać dostrojenie promptów, aby zwiększyć wydajność
          LLM. Obejmuje także narzędzia Google, które pomogą Ci opracować własne aplikacje Gen AI.
        </Typography>
        <Typography variant="h3">Nakłanianie:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Podstawy Inżynierii Nakłaniania (AWS)
          </ExternalLink>{' '}
          - W tym kursie dowiesz się zasad, technik i najlepszych praktyk projektowania skutecznych nakłanian. Kurs ten
          wprowadza podstawy inżynierii nakłaniania i przechodzi do zaawansowanych technik nakłanian. Dowiesz się
          również, jak chronić się przed nadużyciem nakłanian i jak zmniejszyć wpływ uprzedzeń podczas interakcji z
          Modelem Podstawowym.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Inżynieria Nakłaniania (Coursera)
          </ExternalLink>{' '}
          - W tym kursie dowiesz się zasad, technik i najlepszych praktyk projektowania skutecznych nakłanian. Kurs ten
          wprowadza podstawy inżynierii nakłaniania i przechodzi do zaawansowanych technik nakłanian. Dowiesz się
          również, jak chronić się przed nadużyciem nakłanian i jak zmniejszyć wpływ uprzedzeń podczas interakcji z
          Modelem Podstawowym.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            Inżynieria Nakłaniania ChatGPT
          </ExternalLink>{' '}
          - Dowiedz się o najlepszych praktykach inżynierii nakłaniania w rozwoju aplikacji. Odkryj nowe sposoby
          wykorzystania LLM, w tym sposób tworzenia własnego niestandardowego chatbota. Zdobądź praktyczne doświadczenie
          w pisaniu i iterowaniu nakłanian na własną rękę, korzystając z interfejsu API OpenAI
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Skuteczne Nakłanianie dla ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - Ten film wyjaśnia, jak tworzyć skuteczne nakłaniania ChatGPT 4, aby jak najlepiej wykorzystać swojego
          asystenta AI. Czy jesteś programistą, czy po prostu osobą, która chce poprawić wydajność swojego asystenta AI,
          ten film jest dla Ciebie.
        </Typography>
        <Typography variant="h3">Tworzenie Obrazów:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Kurs Midjourney dla Początkujących (YouTube)
          </ExternalLink>{' '}
          - W tym kursie dowiesz się podstaw sztucznej generacji obrazów za pomocą platformy Midjourney. Odkryjesz
          podstawy tworzenia i zarządzania nakłaniami, pracę z różnymi wersjami modeli oraz eksplorację zaawansowanych
          technik tworzenia imponujących obrazów.
        </Typography>
      </MainCard>
    </Text>
  )
}
