import { AiVersion, GoogleAnalyticsContext } from 'shared'

export enum FrontendGoogleAnalyticsEventType {
  VIRTUAL_PAGE_VIEW = 'virtual_page_view',
  BUTTON_CLICK = 'button_click',
  ELEMENT_IMPRESSION = 'element_impression',
  SEND_FEEDBACK = 'send_feedback',
  ASK_QUESTION = 'ask_question',
  ANSWER_SUCCESS = 'answer_success',
  ANSWER_FAILURE = 'answer_failure',
  TRANSLATE = 'translate',
}

export enum FrontendGoogleAnalyticsClickAction {
  FEEDBACK = 'feedback',
  BUG_REPORT = 'bug_report',
  DOWNLOAD = 'download',
  CANCEL_CANCEL = 'cancel_cancel',
  CANCEL = 'cancel',
  CANCEL_DELETE = 'cancel_delete',
  DELETE = 'delete',
  COPY = 'copy',
  PLAY_AUDIO = 'play_audio',
  OPEN_DELETE_MODAL = 'open_delete_modal',
  OPEN_TRANSCRIBE_MODAL = 'open_transcribe_modal',
  TRANSCRIBE = 'transcribe',
  GENERATE_ARTICLE_MEDIA = 'generate_article_media',
  GENERATE_ARTICLE_EXTERNAL = 'generate_article_external',
  GENERATE_ARTICLE_SUMMARY = 'generate_article_summary',
  REGENERATE_ARTICLE_SUMMARY = 'regenerate_article_summary',
  REGENERATE_TEASER_HEADLINE = 'regenerate_teaser_headline',
  REGENERATE_TEASER_TITLE = 'regenerate_teaser_title',
  REGENERATE_META_TITLE = 'regenerate_meta_title',
  REGENERATE_OG_TITLE = 'regenerate_og_title',
  REGENERATE_LEAD = 'regenerate_lead',
  REGENERATE_REWRITE = 'regenerate_rewrite',
  REGENERATE_ALL = 'regenerate_all',
}

export type FrontendGoogleAnalyticsBaseUsageEventData = {
  event: FrontendGoogleAnalyticsEventType
  context?: GoogleAnalyticsContext
  page: string
  value?: string
  click_action?: FrontendGoogleAnalyticsClickAction
  element_label?: string
  user_id: string
  env: string
}

export type FrontendGoogleAnalyticsRatingEventData = FrontendGoogleAnalyticsBaseUsageEventData & {
  feedback_text?: string
  rating: number
}

export type FrontendGoogleAnalyticsAiGenerationEventData = FrontendGoogleAnalyticsBaseUsageEventData & {
  model: AiVersion
}

export type FrontendGoogleAnalyticsNewTranscriptionEventData = FrontendGoogleAnalyticsBaseUsageEventData & {
  file_duration: number
  format_input: string
  session_id?: string
  interaction_id?: string
  cost?: number
  article_id?: string
  job_id?: string
  model?: AiVersion
}

export type FrontendGoogleAnalyticsChatbotActionEventData = FrontendGoogleAnalyticsBaseUsageEventData & {
  value?: string
  session_id: string
  interaction_id: string
  is_suggested_question: boolean
  response_time?: string
  no_answer?: boolean
  cost?: number
  trace_id?: string
}

export type FrontendGoogleAnalyticsTranslateEventData = FrontendGoogleAnalyticsBaseUsageEventData & {
  source_language: string
  target_language: string
}

export type TrackButtonClickParams = {
  page: string
  hashedUserId: string | undefined
  context: GoogleAnalyticsContext
  clickAction: FrontendGoogleAnalyticsClickAction
  elementLabel?: string
}

export type TrackAiGenerationParams = TrackButtonClickParams & {
  model: AiVersion
}

export type TrackNewTranscriptionParams = TrackButtonClickParams & {
  duration: number
  format: string
}

export type TrackChatbotActionParams = {
  event: FrontendGoogleAnalyticsEventType
  page: string
  hashedUserId: string | undefined
  context: GoogleAnalyticsContext
  value?: string
  sessionId: string
  interactionId: string
  isSuggestedQuestion: boolean
  responseTime?: string
  noAnswer?: boolean
  cost?: number
  traceId?: string
}

export type TrackTranslateActionParams = {
  page: string
  hashedUserId: string | undefined
  context: GoogleAnalyticsContext
  sourceLanguage: string
  targetLanguage: string
}

export type TrackRatingParams = {
  page: string
  hashedUserId: string | undefined
  elementLabel: string
  rating: number
  feedbackText?: string
  context?: GoogleAnalyticsContext
  model?: AiVersion
  traceId?: string
  sessionId?: string
  interactionId?: string
  articleId?: string
  jobId?: string
}
