'use client'

// material-ui
import { styled } from '@mui/material/styles'

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

export const AuthWrapper1 = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[100],
  minHeight: '100vh',
}))
