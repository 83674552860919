import { memo, useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Divider, List, Typography, useMediaQuery } from '@mui/material'

// project imports
import { NavItem } from './NavItem'
import { NavGroup } from './NavGroup'
import { useConfig } from 'hooks/useConfig'

import { getMenuItems } from 'menu-items'
import { LAYOUT_CONST } from 'constant'
import { HORIZONTAL_MAX_ITEM } from 'config'

// types
import { NavItemType } from 'types'
import { useApp } from 'hooks/useApp'
import { useAuth } from 'hooks/useAuth'

// ==============================|| SIDEBAR MENU LIST ||============================== //

export const MenuList = memo(() => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { layout } = useConfig()
  const { drawerOpen } = useApp()
  const { session } = useAuth()

  // last menu-item to show in horizontal menu bar
  const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null
  const menuItems = useMemo(() => getMenuItems(session?.restriction), [session?.restriction])

  let lastItemIndex = menuItems.items.length - 1
  let remItems: NavItemType[] = []
  let lastItemId: string

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!
    lastItemIndex = lastItem - 1
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon,
      ...(item.url && {
        url: item.url,
      }),
    }))
  }

  const navItems = menuItems.items.slice(0, lastItemIndex + 1).map((item, index) => {
    switch (item.type) {
      case 'group':
        if (item.url && item.id !== lastItemId) {
          return (
            <List
              key={item.id}
              {...(drawerOpen && layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && { sx: { mt: 1.5 } })}
              disablePadding={!drawerOpen || layout === LAYOUT_CONST.HORIZONTAL_LAYOUT}
            >
              <NavItem item={item} level={1} isParents />
              {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && <Divider sx={{ py: 0.5 }} />}
            </List>
          )
        }

        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            showDivider={lastItemIndex !== index}
          />
        )
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
    <Box
      sx={{
        paddingTop: '3px',
        ...(drawerOpen && { mt: 1.5 }),
      }}
    >
      {navItems}
    </Box>
  ) : (
    <>{navItems}</>
  )
})
MenuList.displayName = 'MenuList'
