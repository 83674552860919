// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewSk = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge je špecializovaný nástroj AI navrhnutý pre Ringier Media, ktorý ponúka súbor vybraných nástrojov
          využívajúcich generatívnu AI a ďalšie technológie AI. Tieto nástroje sú prispôsobené na efektívne riešenie
          konkrétnych editoriálnych použití.
          <br />
          <br />
          Tento inovatívny nástrojový kufor je súčasťou iniciatívy AI Boost.
          <br />
          <br />
          Prvý dostupný nástroj je náš pokročilý nástroj na konverziu reči na text, predtým známy ako Blisper. Ponúka
          bezproblémový spôsob prepisu video a zvukových súborov v akomkoľvek jazyku, čo zjednodušuje tvorbu obsahu.
          <br />
          <br />
          Do budúcnosti plánujeme predstaviť nástroj, ktorý umožní vytváranie článkov na základe rôznych externých
          vstupov, ako sú polície, médiá alebo zahraničné správy. Toto výrazne zvýši diverzitu a relevantnosť obsahu.
          <br />
          <br />
          Zaväzujeme sa pravidelne uvádzať a testovať nové funkcie na zlepšenie a podporu vašich každodenných pracovných
          rutín.
          <br />
          <br />
          Vaša spätná väzba je pre nás neoceniteľná. Prosím, zdieľajte svoje myšlienky o kvalite jednotlivých nástrojov
          prostredníctvom tejto ankety:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          Ak sa vyskytnú akékoľvek chyby alebo problémy, prosím, oznámte ich tu:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          Ak máte akékoľvek ďalšie otázky, návrhy alebo požiadavky, neváhajte nás kontaktovať:
          <ul>
            <li>
              Lauri Kurki, Vedúci projektu AI Boost na{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, Vedúci Inovácie AI v redakcii Blick na{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
