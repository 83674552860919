import { useBlickWidgets } from 'views/BlickWidgets/context/useBlickWidgets'
import { SuggestedQuestion } from './SuggestedQuestion'
import { Spacer } from 'components/ui-component/Spacer'

export const InitialQuestions = () => {
  const { initialQuestions } = useBlickWidgets()

  return (
    <>
      {initialQuestions?.map((question) => (
        <div key={question.text}>
          <SuggestedQuestion question={question} />
          <Spacer v size={12} />
        </div>
      ))}
    </>
  )
}
