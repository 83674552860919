// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewSr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge je specijalizovani AI alat za Ringier Media, koji nudi paket odabranih alata koji koriste generativnu
          AI i druge AI tehnologije. Ovi alati su prilagođeni kako bi efikasno rešavali specifične uredničke slučajeve.
          <br />
          <br />
          Ovaj inovativni alat je deo AI Boost inicijative.
          <br />
          <br />
          Prvi dostupni alat je naš napredni alat za konverziju govora u tekst, ranije poznat kao Blisper. On nudi
          jednostavan način za transkripciju video i audio fajlova na bilo kom jeziku, što olakšava kreiranje sadržaja.
          <br />
          <br />
          Planiramo da uvedemo alat koji će omogućiti kreiranje članaka na osnovu različitih spoljnih ulaznih podataka
          kao što su policijski izveštaji, medijski izveštaji ili strani novinski članci. Ovo će znatno poboljšati
          raznolikost i relevantnost sadržaja.
          <br />
          <br />
          Posvećeni smo redovnom uvođenju i testiranju novih funkcionalnosti kako bismo unapredili i podržali vaše
          dnevne radne rutine.
          <br />
          <br />
          Vaše povratne informacije su neprocenjive za nas. Molimo vas da podelite svoje misli o kvalitetu pojedinačnih
          alata putem ovog ankete:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          Za bilo kakve greške ili probleme koje naiđete, molimo vas da ih prijavite ovde:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          Za bilo kakve druge upite, sugestije ili pitanja, slobodno kontaktirajte:
          <ul>
            <li>
              Lauri Kurki, Vođa Projekta AI Boost na{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, Vođa Inovacija AI u Blick Newsroom-u na{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
