import { createBrowserRouter } from 'react-router-dom'

// routes
import { MainRoutes, MainRoutesNoLayout, PluginRoutes } from './MainRoutes'
import { AuthRoutes } from './AuthRoutes'

// ==============================|| ROUTING RENDER ||============================== //

export const router = createBrowserRouter([MainRoutes, AuthRoutes, MainRoutesNoLayout, PluginRoutes], {
  basename: process.env.REACT_APP_BASE_NAME,
})
