// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewBg = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Добре дошли на нашата подбрана страница, където препоръчваме различни външни AI инструменти! Тук ще откриете
          избрани инструменти, които могат да подобрят вашата работа и да подпалват иновациите. Въпреки това, е от
          съществено значение да се запомни, че тези инструменти трябва да се използват с внимание и отговорност.
          <br />
          <br />
          Преди да се потопите, ви призоваваме да се запознаете с нашите насоки за използване на AI. Тези насоки са в
          сила, за да осигурят етично, отговорно и ефективно използване на AI технологиите. Те ще ви помогнат да се
          справите с потенциалните сложности и предизвикателства, които възникват при използването на външни AI
          инструменти.
          <br />
          <br />
          Моля, имайте предвид, че въпреки че препоръчваме тези инструменти за техните потенциални ползи, ние не
          предоставяме директна поддръжка за тях. Ако се сблъскате с проблеми или имате въпроси, ще трябва да се
          обърнете към съответните канали за поддръжка за всеки инструмент.
          <br />
          <br />
          Използвайте тези инструменти мъдро, за да изследвате възможностите на AI, винаги имайки предвид нашите насоки
          и необходимостта от внимателно и информирано приложение. Щастливо изследване!
        </Typography>
        <Typography variant="h3">AI Помощници:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : В момента най-добрият AI помощник на пазара. ChatGPT от OpenAI е разговорен AI модел, способен да генерира
          текст, приличен на човешкия. Идеален за съставяне на статии, мозъчни штурмове, получаване на обратна връзка за
          идеи, резюмиране на съдържание и т.н. Двигателят за създаване на изображения на OpenAI, Dall-E, достъпен чрез
          ChatGPT, може да създава уникални изображения за статии.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai е AI помощник, който доставя мултимедийни отговори и агрегира множество основни AI модели като
          ChatGPT на OpenAI или Claude на Anthropic. Може да се използва за различни ежедневни задачи.
        </Typography>
        <Typography variant="h3">Писане:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : AI помощник за писане, подкрепящ немския, който помага при създаването на привлекателно съдържание бързо.
          Може да се използва за съставяне на статии, генериране на заглавия и създаване на творчески писма.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : AI, специализиран в генерирането на текст, подкрепящ немския, който се специализира в създаването на
          маркетингово съдържание. Полезен е при създаването на занимателни въведения на статии, обяви и публикации в
          социалните мрежи, свързани със статии.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : AI-захранван инструмент за писане и парафразиране. Помага при пренаписването на изречения за яснота,
          подобряването на граматиката и избягването на плагиатство в статии. Работи добре на немски.
        </Typography>
        <Typography variant="h3">Създаване на изображения:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Програма за изкуствен интелект, специализирана в създаването на висококачествени, художествени изображения.
          Идеален за създаване на уникални визуални елементи и илюстрации за истории и статии. В момента е достъпен само
          чрез{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          . Можете да намерите ръководство как да го използвате{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            тук
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Инструмент, задвижван от AI, който генерира реалистични изображения и изкуство. Полезен за визуално
          разказване и създаване на персонализирани илюстрации за статии.
        </Typography>
        <Typography variant="h3">Редактиране на снимки:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Платформа за редактиране на снимки, която използва AI за напреднали задачи по редактиране. Може да подобри
          качеството на снимките, използвани в статии, включително корекции в осветлението, текстурата и цвета.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Предлага софтуер за подобряване на снимки с използване на AI. Полезен за увеличаване на размера на
          изображенията без загуба на качество, подобряване на редактирането на шум, и заточване на изображения за
          цифрова публикация.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Инструмент за редактиране на изображения, базиран на AI, който може автоматично да редактира снимки, да
          коригира осветлението и цветовете и да приложи различни ефекти, подходящ за подобряване на визуалното
          съдържание в статии.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Специализиран в премахването на фонове от изображения с използване на AI. Полезен е за бързо редактиране на
          изображения за статии, особено когато е необходимо да се изолират обекти от фоновете им за графични
          композиции.
        </Typography>
      </MainCard>
    </Text>
  )
}
