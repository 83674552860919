// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewSk = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Vitajte v našej vybranej kolekcii bezplatného vzdelávacieho obsahu o umelej inteligencii (AI)! Tu nájdete
          vzrušujúcu škálu videí pokrývajúcich rôzne aspekty AI, vrátane Generatívneho AI. Či už začínate alebo už máte
          pokročilé znalosti, tu je niečo pre každého.
          <br />
          <br />
          Táto kolekcia je pre vás ideálna, ak chcete rozšíriť svoje znalosti v oblasti AI, či už z profesionálnych
          dôvodov alebo z osobného záujmu. Obsah môžete prechádzať vo vlastnom tempe a pohodlne sa vzdelávať z domova.
          <br />
          <br />
          Ponorte sa do fascinujúceho sveta umelej inteligencie a využite príležitosť oboznámiť sa s najnovšími trendmi
          a technológiami. Dúfam, že tieto zdroje budú pre vás cenné a obohacujúce. Užite si svoju cestu vzdelávania!
        </Typography>
        <Typography variant="h3">AI vo všeobecnosti:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            AI vs. Strojové učenie vs. Hlboké učenie vs. Neurónové siete (IBM; Článok)
          </ExternalLink>{' '}
          - Vysvetľujúci článok o rozdieloch medzi týmito konceptmi a užitočná definícia umelej inteligencie.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Úvod do Veľkých jazykových modelov (YouTube)
          </ExternalLink>{' '}
          - Ide o 1-hodinovú úvodnú prednášku pre všeobecného poslucháča o Veľkých jazykových modeloch: základné
          technické komponenty za systémami ako ChatGPT, Claude a Google Bard. Čo sú, kam smerujú, porovnania a analógie
          s aktuálnymi operačnými systémami a niektoré z bezpečnostných výziev tohto nového výpočtového paradigmy.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Úvod do Generatívneho AI (Google)
          </ExternalLink>{' '}
          - Ide o úvodný kurz mikroučenia zameraný na vysvetlenie toho, čo je Generatívne AI, ako sa používa a ako sa
          líši od tradičných metód strojového učenia. Taktiež sa zaoberá nástrojmi Google na pomoc pri vytváraní
          vlastných aplikácií Gen AI.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Úvod do Veľkých jazykových modelov (LLMs) (Google)
          </ExternalLink>{' '}
          - Ide o úvodný kurz mikro-učenia, ktorý preskúma, čo sú veľké jazykové modely (LLM), prípady použitia, kde
          môžu byť využité, a ako môžete využiť ladenie promptu na zlepšenie výkonu LLM. Taktiež sa zaoberá nástrojmi
          Google na pomoc pri vytváraní vlastných aplikácií Gen AI.
        </Typography>
        <Typography variant="h3">Vyvolávanie:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Základy Inžinierstva Promptu (AWS)
          </ExternalLink>{' '}
          - V tomto kurze sa dozviete zásady, techniky a najlepšie postupy na návrh efektívnych promptov. Tento kurz
          predstavuje základy inžinierstva promptu a pokračuje k pokročilým technikám promptu. Taktiež sa dozviete, ako
          sa chrániť pred zneužitím promptu a ako minimalizovať predsudky pri interakcii s modelmi základov.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Inžinierstvo Promptu (Coursera)
          </ExternalLink>{' '}
          - V tomto kurze sa dozviete zásady, techniky a najlepšie postupy na návrh efektívnych promptov. Tento kurz
          predstavuje základy inžinierstva promptu a pokračuje k pokročilým technikám promptu. Taktiež sa dozviete, ako
          sa chrániť pred zneužitím promptu a ako minimalizovať predsudky pri interakcii s modelmi základov.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            Inžinierstvo Promptu ChatGPT
          </ExternalLink>{' '}
          - Naučte sa najlepšie postupy inžinierstva promptu pre vývoj aplikácií. Objavte nové spôsoby použitia LLM,
          vrátane toho, ako vytvárať vlastné vlastné chatboty. Získajte praktické skúsenosti pri písaní a iterovaní
          promptov sami pomocou rozhrania API OpenAI.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Efektívne Vyvolávanie pre ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - Tento videozáznam vysvetľuje, ako vytvoriť efektívne prompty ChatGPT 4, aby ste získali maximum z vášho
          asistenta AI. Či už ste vývojár alebo len niekto, kto chce zlepšiť výkon svojho asistenta AI, toto video je
          pre vás.
        </Typography>
        <Typography variant="h3">Vytváranie Obrazov:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Kurz Midjourney pre Začiatočníkov (YouTube)
          </ExternalLink>{' '}
          - V tomto kurze sa dozviete všetko o tvorbe umelej inteligencie pomocou platformy Midjourney. Objavíte základy
          tvorby a správy promptov, prácu s rôznymi verziami modelov a preskúmanie pokročilých techník na vytvorenie
          impozantných obrazov.
        </Typography>
      </MainCard>
    </Text>
  )
}
