// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, useMediaQuery } from '@mui/material'

// project imports
import { LogoSection } from 'layout/MainLayout/LogoSection'
import { ProfileSection } from './ProfileSection'
// import SearchSection from './SearchSection';
// import MobileSection from './MobileSection';
// import FullScreenSection from './FullScreenSection';
import { LocalizationSection } from './LocalizationSection'
// import MegaMenuSection from './MegaMenuSection';
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons-react'
import { useApp } from 'hooks/useApp'
import { Spacer } from 'components/ui-component/Spacer'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export const Header = () => {
  const theme = useTheme()
  const matchMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { drawerOpen, toggleDrawer } = useApp()

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 236,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box component="span" sx={{ flexGrow: 1, marginTop: '8px' }}>
          <LogoSection />
        </Box>
        {(matchMobile || !drawerOpen) && (
          <>
            <Spacer h size={16} />
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
              }}
              onClick={() => toggleDrawer(!drawerOpen)}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="20px" />
            </Avatar>
          </>
        )}
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* mega-menu */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <MegaMenuSection />
      </Box> */}

      {/* localization */}
      <Box sx={{ marginRight: { xs: '16px', md: '24px' } }}>
        <LocalizationSection />
      </Box>

      {/* notification */}
      {/* <NotificationSection /> */}

      {/* full sceen toggler */}
      {/* <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <FullScreenSection />
      </Box> */}

      {/* profile */}
      <ProfileSection />

      {/* mobile header */}
      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box> */}
    </>
  )
}
