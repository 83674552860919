// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewFr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Bienvenue sur notre page sélectionnée où nous recommandons une variété d'outils IA externes ! Ici, vous
          trouverez une sélection d'outils qui peuvent améliorer votre travail et susciter l'innovation. Cependant, il
          est crucial de se rappeler que ces outils doivent être utilisés avec soin et responsabilité.
          <br />
          <br />
          Avant de plonger, nous vous encourageons à vous familiariser avec nos directives en matière d'IA. Ces
          directives sont en place pour garantir une utilisation éthique, responsable et efficace des technologies d'IA.
          Elles vous aideront à naviguer dans les complexités et les défis potentiels liés à l'utilisation d'outils IA
          externes.
          <br />
          <br />
          Veuillez noter que bien que nous recommandions ces outils pour leurs avantages potentiels, nous ne fournissons
          pas de support direct pour eux. Si vous rencontrez des problèmes ou avez des questions, vous devrez contacter
          les canaux de support respectifs pour chaque outil.
          <br />
          <br />
          Utilisez ces outils judicieusement pour explorer les possibilités de l'IA, en gardant toujours à l'esprit nos
          directives et la nécessité d'une application prudente et informée. Bonne exploration !
        </Typography>
        <Typography variant="h3">Assistant IA :</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : Actuellement le meilleur assistant IA sur le marché. ChatGPT d'OpenAI est un modèle d'IA conversationnelle
          capable de générer un texte semblable à celui d'un humain. Il est idéal pour rédiger des articles, faire du
          brainstorming, obtenir des retours sur des idées, résumer du contenu, etc. Le moteur de création d'images de
          OpenAI, Dall-E, accessible via ChatGPT, peut générer des images uniques pour des articles.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai est un assistant IA fournissant des réponses multimédias et agrégeant plusieurs modèles d'IA
          sous-jacents tels que ChatGPT d'OpenAI ou Claude d'Anthropic. Il peut être utilisé pour une variété de tâches
          quotidiennes.
        </Typography>
        <Typography variant="h3">Écriture :</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : Un assistant d'écriture IA prenant en charge l'allemand qui aide à créer rapidement du contenu convaincant.
          Il peut être utilisé pour rédiger des articles, générer des titres et produire des morceaux d'écriture
          créative.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : Une IA de génération de texte prenant en charge l'allemand et spécialisée dans la création de contenu
          marketing. Il est utile pour rédiger des introductions d'articles attrayantes, des copies publicitaires et des
          publications sur les réseaux sociaux liées aux articles.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Un outil d'écriture et de paraphrase alimenté par IA. Aide à reformuler les phrases pour plus de clarté, à
          améliorer la grammaire et à éviter le plagiat dans les articles. Fonctionne bien en allemand.
        </Typography>
        <Typography variant="h3">Création d'images :</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Un programme IA spécialisé dans la création d'images artistiques de haute qualité. Idéal pour créer des
          visuels et des illustrations uniques pour des histoires et des fonctionnalités. Actuellement uniquement
          accessible via{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          . Vous pouvez trouver un guide sur la façon de l'utiliser{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            ici
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Un outil piloté par IA qui génère des images et des œuvres d'art réalistes. Utile pour le storytelling
          visuel et la création d'illustrations personnalisées pour des articles.
        </Typography>
        <Typography variant="h3">Retouche photo :</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Une plateforme de retouche photo qui utilise l'IA pour des tâches de retouche avancées. Il peut améliorer la
          qualité des photographies utilisées dans les articles, y compris les ajustements de luminosité, de texture et
          de couleur.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Offre un logiciel de retouche photo alimenté par l'IA. Utile pour agrandir les images sans perdre en
          qualité, réduire le bruit et accentuer les images pour une publication numérique.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Un outil de retouche d'images basé sur l'IA qui peut éditer automatiquement les photos, ajuster la
          luminosité et les couleurs, et appliquer divers effets, adapté pour améliorer le contenu visuel dans les
          articles.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Spécialisé dans la suppression d'arrière-plans à partir d'images à l'aide de l'IA. Il est utile pour éditer
          rapidement des images pour des articles, notamment lorsqu'il est nécessaire d'isoler des sujets de leurs
          arrière-plans pour des compositions graphiques.
        </Typography>
      </MainCard>
    </Text>
  )
}
