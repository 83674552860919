'use client'

// material-ui
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useMediaQuery,
  Pagination,
  Typography,
  ClickAwayListener,
  Tooltip,
  IconButton,
} from '@mui/material'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { useEffect, useRef, useState } from 'react'
import { deleteJob, fetchJobs } from 'api/speechToText'
import { NewTranscriptionModal } from './componenets/NewTranscriptionModal'
import { routes } from 'constants/routes'
import { useApp } from 'hooks/useApp'
import { DeleteTranscriptionModal } from './componenets/DeleteTranscriptionModal'
import { trackButtonClick, trackElementImpression } from 'tracking/gtm'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { DateFormat, formatDate } from 'utils/date/formatDate'
import { preserveUserId } from 'utils/url'
import { useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { Spacer } from 'components/ui-component/Spacer'
import { FormattedMessage } from 'components/FormattedMessage'
import { Job, JobStatus, GoogleAnalyticsContext } from 'shared'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { JobProgress } from './componenets/JobProgress'
import { CancelTranscriptionModal } from './componenets/CancelTranscriptionModal'
import { FrontendGoogleAnalyticsClickAction } from 'types/analytics'

const FETCH_INTERVAL = 1000 * 60 * 5 // 5 min
export const MAX_DAYS_AUDIO_AVAILABILITY = 91

export const SpeechToTextListView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { session } = useAuth()
  const { openSnackbar } = useApp()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'))
  const [jobs, setJobs] = useState<Job[]>([])
  const [displayedJobs, setDisplayedJobs] = useState<Job[]>([])
  const [pageCount, setPageCount] = useState(1)
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState<string[]>([])
  const [jobIdForDelete, setJobIdForDelete] = useState<string | undefined>()
  const [jobIdForCancel, setJobIdForCancel] = useState<string | undefined>()
  const [isNewTranscriptionModalOpen, setIsNewTranscriptionModalOpen] = useState(false)
  const [isDeleteTranscriptionModalOpen, setIsDeleteTranscriptionModalOpen] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isCancelTranscriptionModalOpen, setIsCancelTranscriptionModalOpen] = useState(false)
  const [isCancelLoading, setIsCancelLoading] = useState(false)
  const [isOpenTranscribeModalTracked, setIsOpenTranscribeModalTracked] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const interval = useRef<NodeJS.Timeout | null>(null)

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>, jobId: string) => {
    const newSelection = selected.slice()
    if (event.target.checked) {
      newSelection.push(jobId)
    } else {
      newSelection.splice(
        newSelection.findIndex((item) => item === jobId),
        1,
      )
    }
    setSelected(newSelection)
  }

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selected.length > 0) {
        setSelected([])
      } else {
        const newSelectedId: string[] = displayedJobs.map((job) => job.jobId)
        setSelected(newSelectedId)
      }
      return
    }
    setSelected([])
  }

  const isSelected = (jobId: string) => {
    return selected.indexOf(jobId) !== -1
  }

  const onDelete = async (jobId: string) => {
    setJobIdForDelete(jobId)
    setIsDeleteTranscriptionModalOpen(true)
    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.OPEN_DELETE_MODAL,
      elementLabel: 'Delete',
    })
  }

  const onCloseDeleteTranscriptionModal = (track?: boolean) => {
    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.CANCEL_DELETE,
      elementLabel: 'No, cancel',
    })
    setJobIdForDelete(undefined)
    setIsDeleteTranscriptionModalOpen(false)
  }

  const onCloseCancelTranscriptionModal = (track?: boolean) => {
    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.CANCEL_CANCEL,
      elementLabel: 'No',
    })
    setJobIdForCancel(undefined)
    setIsCancelTranscriptionModalOpen(false)
  }

  const deleteTranscription = async () => {
    if (jobIdForDelete) {
      setIsDeleteLoading(true)
      const result = await deleteJob(jobIdForDelete)
      if (result?.success) {
        openSnackbar({
          open: true,
          message: t('audioToText.transcriptionDeletedSuccessfully'),
          variant: 'success',
        })
        fetchData()

        trackButtonClick({
          page: pathname,
          hashedUserId: session?.hashedId,
          context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
          clickAction: FrontendGoogleAnalyticsClickAction.DELETE,
          elementLabel: 'Yes, delete',
        })
        setIsDeleteLoading(false)
        onCloseDeleteTranscriptionModal()
      }
    }
  }

  const cancelTranscription = async () => {
    if (jobIdForCancel) {
      setIsCancelLoading(true)
      const result = await deleteJob(jobIdForCancel)
      if (result?.success) {
        openSnackbar({
          open: true,
          message: t('audioToText.transcriptionDeletedSuccessfully'),
          variant: 'success',
        })
        trackButtonClick({
          page: pathname,
          hashedUserId: session?.hashedId,
          context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
          clickAction: FrontendGoogleAnalyticsClickAction.CANCEL,
          elementLabel: 'Yes, cancel',
        })
      } else {
        openSnackbar({
          open: true,
          message: t('audioToText.error'),
          variant: 'error',
        })
      }
      fetchData()
      setIsCancelLoading(false)
      onCloseCancelTranscriptionModal()
    }
  }

  const onNewTranscriptionClick = () => {
    setIsNewTranscriptionModalOpen(true)
    if (!isOpenTranscribeModalTracked) {
      trackButtonClick({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.OPEN_TRANSCRIBE_MODAL,
        elementLabel: 'New Transcription',
      })
      setIsOpenTranscribeModalTracked(true)
    }
  }

  const fetchData = async () => {
    const data = await fetchJobs()
    if (data) {
      setJobs(
        data.sort((a: Job, b: Job) => {
          if (a.createdAt < b.createdAt) {
            return 1
          } else if (a.createdAt > b.createdAt) {
            return -1
          }
          return 0
        }),
      )

      setPageCount(Math.ceil(data.length / 10))
      setDisplayedJobs(data.slice((page - 1) * 10, page * 10))
    }
  }

  const onPageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    setDisplayedJobs(jobs.slice((value - 1) * 10, value * 10))
  }

  const getRemainingDays = (createdAt: string) => {
    const remainingDays = dayjs(createdAt).add(MAX_DAYS_AUDIO_AVAILABILITY, 'day').diff(new Date(), 'days')

    let remainingDaysText = (
      <Box color={theme.palette.grey[400]}>
        <FormattedMessage id="audioToText.audioRemoved" />
      </Box>
    )
    if (remainingDays > 0) {
      remainingDaysText = (
        <>
          {remainingDays} <FormattedMessage id="audioToText.days" />
        </>
      )
    }
    if (remainingDays === 1) {
      remainingDaysText = (
        <>
          1 <FormattedMessage id="audioToText.day" />
        </>
      )
    }
    return remainingDaysText
  }

  const onTableRowClick = (job: Job) => () => {
    if (job.status === JobStatus.COMPLETED) {
      navigate(preserveUserId(`${routes.speechToText.url}/${job.jobId}`))
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pathname && session?.hashedId) {
      trackElementImpression(
        pathname,
        session?.hashedId,
        GoogleAnalyticsContext.AUDIO_TO_TEXT,
        'list of transcriptions',
      )
    }
  }, [pathname, session?.hashedId])

  useEffect(() => {
    const hasProcessingJob = jobs.find((job) => job.status === 'processing')
    if (!hasProcessingJob && interval.current) {
      clearInterval(interval.current)
      interval.current = null
    }
    if (hasProcessingJob && !interval.current) {
      interval.current = setInterval(() => fetchData(), FETCH_INTERVAL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, interval])

  return (
    <MainCard
      title={
        <Title sx={matchDownMd ? { flexDirection: 'column', gap: '10px', fontSize: '20px' } : { alignItems: 'center' }}>
          <div>
            <FormattedMessage id="audioToText.yourTranscriptions" />
          </div>
          <Button
            variant="contained"
            size={matchDownMd ? 'small' : 'medium'}
            startIcon={<MaterialIcon name="add_circle" fontSize="24px" />}
            onClick={onNewTranscriptionClick}
            sx={{
              '.MuiButton-startIcon': { marginLeft: '0px', marginRight: '12px' },
              padding: '8px 14px !important',
              maxWidth: '50%',
            }}
          >
            <Typography
              variant="bodybg"
              sx={{
                textTransform: 'uppercase',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                color: '#FFFFFF',
              }}
            >
              {matchDownMd ? (
                <FormattedMessage id="common.new" />
              ) : (
                <FormattedMessage id="audioToText.newTranscription" />
              )}
            </Typography>
          </Button>
        </Title>
      }
    >
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  pl: 3,
                  display: 'none', // hidden for now (v1)
                }}
              >
                <Checkbox
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < jobs.length}
                  checked={jobs.length > 0 && selected.length === jobs.length}
                  onChange={onSelectAll}
                />
              </TableCell>
              <TableCell sx={{ paddingLeft: 0 }}>
                <FormattedMessage id="audioToText.title" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="audioToText.status" />
              </TableCell>
              {!matchDownLg && (
                <>
                  <TableCell>
                    <FormattedMessage id="audioToText.uploaded" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="audioToText.availability" />
                    <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setIsTooltipOpen(false)}
                        open={isTooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="top"
                        title={
                          <>
                            <FormattedMessage id="audioToText.availabilityDescription" />
                          </>
                        }
                      >
                        <IconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
                          <MaterialIcon name="info" fontSize="16px" />
                        </IconButton>
                      </Tooltip>
                    </ClickAwayListener>
                  </TableCell>
                </>
              )}
              <TableCell sx={{ width: { xs: '50px', md: 'auto' } }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedJobs.map((job, index) => (
              <StyledTableRow
                $completed={job.status === JobStatus.COMPLETED}
                key={`job-${index}`}
                selected={isSelected(job.jobId)}
                onClick={onTableRowClick(job)}
              >
                <TableCell
                  padding="checkbox"
                  sx={{
                    pl: 3,
                    display: 'none', // hidden for now (v1)
                  }}
                >
                  <Checkbox color="primary" checked={isSelected(job.jobId)} onChange={(e) => onSelect(e, job.jobId)} />
                </TableCell>
                <TableCell sx={{ paddingLeft: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {job.description}
                </TableCell>
                <TableCell>
                  <Grid container>
                    {job.status === JobStatus.COMPLETED && (
                      <Chip
                        sx={{
                          ...chipStyle,
                          background: theme.palette.success.light,
                          color: theme.palette.success.dark,
                        }}
                        label={<FormattedMessage id={`audioToText.${job.status}`} />}
                      />
                    )}
                    {job.status === JobStatus.PROCESSING && (
                      <>
                        {job.inputAudioFileDuration && (
                          <JobProgress createdAt={job.createdAt} inputAudioFileDuration={job.inputAudioFileDuration} />
                        )}
                      </>
                    )}
                    {job.status === JobStatus.ERROR && (
                      <Chip
                        sx={{ ...chipStyle, background: theme.palette.error.light, color: theme.palette.error.dark }}
                        label={<FormattedMessage id={`audioToText.${job.status}`} />}
                        color="error"
                      />
                    )}
                    {job.status === JobStatus.STALED && (
                      <Chip
                        sx={{
                          ...chipStyle,
                          background: theme.palette.warning.light,
                          color: theme.palette.warning.dark,
                        }}
                        label={<FormattedMessage id={`audioToText.${job.status}`} />}
                        color="warning"
                      />
                    )}
                    {job.status === JobStatus.CANCELLED && (
                      <Chip
                        sx={{
                          ...chipStyle,
                          background: theme.palette.dark.light,
                          color: theme.palette.dark.dark,
                        }}
                        label={<FormattedMessage id={`audioToText.${job.status}`} />}
                        color="error"
                      />
                    )}
                  </Grid>
                </TableCell>
                {!matchDownLg && (
                  <>
                    <TableCell>{formatDate(job?.createdAt, DateFormat.DEFAULT)}</TableCell>
                    <TableCell>{getRemainingDays(job?.createdAt)}</TableCell>
                  </>
                )}
                <TableCell>
                  {matchDownMd ? (
                    <>
                      {[JobStatus.COMPLETED, JobStatus.STALED, JobStatus.PROCESSING].includes(
                        job.status as JobStatus,
                      ) && (
                        <div onClick={(e) => e.stopPropagation()}>
                          <PopupState disableAutoFocus={true} variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <>
                                <div {...bindTrigger(popupState)}>
                                  <MaterialIcon name="more_vert" />
                                </div>
                                <Menu {...bindMenu(popupState)} sx={{ marginLeft: '-30px' }}>
                                  {job.status === JobStatus.COMPLETED && (
                                    <StyledMenuItem
                                      onClick={() => {
                                        navigate(preserveUserId(`${routes.speechToText.url}/${job.jobId}`))
                                        popupState.close()
                                      }}
                                    >
                                      <MaterialIcon fontSize="24px" name="visibility" />
                                      <Spacer h size={8} />
                                      <Box sx={{ marginTop: '-2px' }}>
                                        <FormattedMessage id="common.show" />
                                      </Box>
                                    </StyledMenuItem>
                                  )}

                                  {(job.status === JobStatus.COMPLETED || job.status === JobStatus.STALED) && (
                                    <StyledMenuItem
                                      onClick={() => {
                                        onDelete(job.jobId)
                                        popupState.close()
                                      }}
                                    >
                                      <MaterialIcon fontSize="24px" name="delete" />
                                      <Spacer h size={8} />
                                      <Box sx={{ marginTop: '-2px' }}>
                                        <FormattedMessage id="common.delete" />
                                      </Box>
                                    </StyledMenuItem>
                                  )}
                                  {job.status === JobStatus.PROCESSING && (
                                    <StyledMenuItem
                                      onClick={() => {
                                        onDelete(job.jobId)
                                        popupState.close()
                                      }}
                                    >
                                      <MaterialIcon fontSize="24px" name="cancel" />
                                      <Spacer h size={8} />
                                      <Box sx={{ marginTop: '-2px' }}>
                                        <FormattedMessage id="common.cancel" />
                                      </Box>
                                    </StyledMenuItem>
                                  )}
                                </Menu>
                              </>
                            )}
                          </PopupState>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {job.status === JobStatus.COMPLETED && (
                        <>
                          <StyledButton
                            onClick={() => navigate(preserveUserId(`${routes.speechToText.url}/${job.jobId}`))}
                            variant="ghost"
                            startIcon={<MaterialIcon fontSize="24px" name="visibility" />}
                          >
                            <FormattedMessage id="common.show" />
                          </StyledButton>
                        </>
                      )}
                      {[JobStatus.COMPLETED, JobStatus.STALED].includes(job.status as JobStatus) && (
                        <StyledButton
                          onClick={(e) => {
                            e.stopPropagation()
                            onDelete(job.jobId)
                          }}
                          variant="ghost"
                          startIcon={<MaterialIcon fontSize="24px" name="delete" />}
                        >
                          <FormattedMessage id="common.delete" />
                        </StyledButton>
                      )}
                      {job.status === JobStatus.PROCESSING && (
                        <StyledButton
                          onClick={(e) => {
                            e.stopPropagation()
                            onDelete(job.jobId)
                          }}
                          variant="ghost"
                          startIcon={<MaterialIcon fontSize="24px" name="cancel" />}
                        >
                          <FormattedMessage id="common.cancel" />
                        </StyledButton>
                      )}
                    </>
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <StyledPagination count={pageCount} page={page} shape="rounded" onChange={onPageChange} />
      </TableContainer>
      {isNewTranscriptionModalOpen && (
        <NewTranscriptionModal
          isOpen={isNewTranscriptionModalOpen}
          onClose={() => setIsNewTranscriptionModalOpen(false)}
          fetchData={fetchData}
        />
      )}
      <DeleteTranscriptionModal
        isOpen={isDeleteTranscriptionModalOpen}
        onClose={() => onCloseDeleteTranscriptionModal(true)}
        onConfirm={() => deleteTranscription()}
        isLoading={isDeleteLoading}
      />
      <CancelTranscriptionModal
        isOpen={isCancelTranscriptionModalOpen}
        onClose={() => onCloseCancelTranscriptionModal(true)}
        onConfirm={() => cancelTranscription()}
        isLoading={isCancelLoading}
      />
    </MainCard>
  )
}

const Title = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const chipStyle = {
  fontSize: '10px',
  fontWeight: '700',

  height: 'auto',
  '& .MuiChip-label': {
    padding: '4px 10px',
  },
}

const StyledButton = styled(Button)({
  minWidth: 'auto',
})

const StyledTableRow = styled(TableRow)<{ $completed: boolean }>`
  cursor: ${({ $completed }) => ($completed ? 'pointer' : 'auto')};

  &:hover {
    background: ${({ theme }) => theme.palette.grey[50]};
  }
`

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  display: flex;
  align-items: center;
`
