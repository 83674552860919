export enum AppLanguages {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  POLISH = 'pl',
  SERBIAN = 'sr',
  SLOVAKIAN = 'sk',
  BULGARIAN = 'bg',
  HUNGARIAN = 'hu',
  ROMANIAN = 'ro',
}

export interface TranslationContextType {
  languages: {
    [key: string]: {
      translation: any
      label: string
      subLabel: string
    }
  }
  visibleLanguages: {
    [key: string]: {
      translation: any
      label: string
      subLabel: string
    }
  }
  onLanguageChange: (language: AppLanguages) => void
  currentLanguage: AppLanguages
}
