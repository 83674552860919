import { AxiosError } from 'axios'
import { config } from 'config'
import {
  BlickWidgetsAnswerResponse,
  BlickWidgetsAvailableAricleIdsResponse,
  BlickWidgetsQuestionsResponse,
  QuestionWidgetId,
} from 'types/blick-widgets'
import { axiosInstance } from 'utils/axios'

// dailyBriefing -> home
// articleQuestion -> aritlceId
export const getAnswer = async (questionId: string, widgetId: QuestionWidgetId, articleId = 'home') => {
  const url = `${config.apiUrl}/chatbot/questions/answer?questionId=${questionId}&publication=blick&widgetId=${widgetId}&context=${articleId}`
  try {
    const result = await axiosInstance.get<BlickWidgetsAnswerResponse>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getQuestions = async (widgetId: QuestionWidgetId, articleId = 'home') => {
  const url = `${config.apiUrl}/chatbot/questions/questions?publication=blick&widgetId=${widgetId}&context=${articleId}`
  try {
    const result = await axiosInstance.get<BlickWidgetsQuestionsResponse>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getArticleIds = async () => {
  const url = `${config.apiUrl}/chatbot/questions/available-article-ids`

  try {
    const result = await axiosInstance.get<BlickWidgetsAvailableAricleIdsResponse>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Basic dXNlcjplNzlTWnZLZ0ZXbnRaVndyMTJGNg==',
      },
    })

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}
