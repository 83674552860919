import { LAYOUT_CONST } from 'constant'
// types
import { ConfigProps } from 'types/config'

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = ''

export const HORIZONTAL_MAX_ITEM = 7

const PREFIX_MAP = {
  dev: 'd.',
  stg: 's.',
  prod: '',
}

const ENV = process.env.REACT_APP_ENV || 'dev'

const getApiUrl = () => {
  return `https://api.${PREFIX_MAP[ENV as keyof typeof PREFIX_MAP]}aiforge.ringier.com/api`
}

export const getSelfUrl = () => {
  return `https://${PREFIX_MAP[ENV as keyof typeof PREFIX_MAP]}aiforge.ringier.com`
}

const getSentryDSN = () => 'https://70ef1475a6ca9e6b4d16f3debdb51bdd@o321856.ingest.us.sentry.io/4506546267750400'

const getMdbUrl = () => `https://mdb${ENV !== 'prod' ? 'stage' : ''}.ringier.ch/dcx_rng/documents#/doc/`

export const config: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;`,
  borderRadius: 12,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
  apiUrl: getApiUrl(),
  selfUrl: getSelfUrl(),
  gtmID: 'GTM-TC76PJSQ',
  env: ENV,
  sentryDSN: getSentryDSN(),
  mdbUrl: getMdbUrl(),
  chromeExtentionUrl: 'https://chromewebstore.google.com/detail/ai-forge-text-sender/cjmplcolmanfcbopjilhigfoljgpencn',
}
