// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewEn = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Welcome to our selected collection of free learning content on Artificial Intelligence (AI)! Here you&apos;ll
          find an exciting range of videos covering various aspects of AI, including Generative AI. Whether you&apos;re
          just starting out or already have advanced knowledge, there&apos;s something for everyone here.
          <br />
          <br />
          This collection is perfect for you if you want to expand your knowledge in the field of AI, whether for
          professional reasons or personal interest. You can go through the content at your own pace and educate
          yourself comfortably from home.
          <br />
          <br />
          Dive into the fascinating world of Artificial Intelligence and seize the opportunity to familiarize yourself
          with the latest trends and technologies. I hope you find these resources valuable and enriching. Enjoy your
          learning journey!
        </Typography>
        <Typography variant="h3">AI in general:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            AI vs. Machine Learning vs. Deep Learning vs. Neural Networks (IBM; Article)
          </ExternalLink>{' '}
          - Explaining article about the differences between these concepts and a helpful definition of Artificial
          Intelligence.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Intro to Large Language Models (YouTube)
          </ExternalLink>{' '}
          - This is a 1 hour general-audience introduction to Large Language Models: the core technical components
          behind systems like ChatGPT, Claude and Google Bard. What they are, where they are headed, comparisons and
          analogies to present-day operating systems and some of the security-related challenges of this new computing
          paradigm.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Introduction to Generative AI (Google)
          </ExternalLink>{' '}
          - This is an introductory level microlearning course aimed at explaining what Generative AI is, how it is
          used, and how it differs from traditional machine learning methods. It also covers Google Tools to help you
          develop your own Gen AI apps.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Introduction to Large Language Models (LLMs) (Google)
          </ExternalLink>{' '}
          - This is an introductory level micro-learning course that explores what large language models (LLM) are, the
          use cases where they can be utilized, and how you can use prompt tuning to enhance LLM performance. It also
          covers Google tools to help you develop your own Gen AI apps.
        </Typography>
        <Typography variant="h3">Prompting:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Foundation of Prompt Engineering (AWS)
          </ExternalLink>{' '}
          - In this course, you will learn the principles, techniques, and the best practices for designing effective
          prompts. This course introduces the basics of prompt engineering, and progresses to advanced prompt
          techniques. You will also learn how to guard against prompt misuse and how to mitigate bias when interacting
          with Foundation Models.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Prompt Engineering (Coursera)
          </ExternalLink>{' '}
          - In this course, you will learn the principles, techniques, and the best practices for designing effective
          prompts. This course introduces the basics of prompt engineering, and progresses to advanced prompt
          techniques. You will also learn how to guard against prompt misuse and how to mitigate bias when interacting
          with Foundation Models.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            ChatGPT Prompt Engineering
          </ExternalLink>{' '}
          - Learn prompt engineering best practices for application development. Discover new ways to use LLMs,
          including how to build your own custom chatbot. Gain hands-on practice writing and iterating on prompts
          yourself using the OpenAI API
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Effective Prompting for ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - This video explains how to create effective ChatGPT 4 prompts to help you get the most out of your AI
          assistant. Whether you’re a developer or just someone who wants to improve their AI assistant’s performance,
          this video is for you.
        </Typography>
        <Typography variant="h3">Image Creation:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Midjourney Course for Beginners (YouTube)
          </ExternalLink>{' '}
          - In this course, you&apos;ll learn the ins and outs of AI art generation using the Midjourney platform.
          You&apos;ll discover the fundamentals of creating and managing prompts, working with various model versions,
          and exploring advanced techniques to create impressive imagery.
        </Typography>
      </MainCard>
    </Text>
  )
}
