import { axiosInstance } from 'utils/axios'
import {
  ApiResponse,
  ArticleLeadResponse,
  ArticleMetaTitleResponse,
  ArticleOgTitleResponse,
  ArticleRequest,
  ArticleRequestConfig,
  ArticleRewriteRequest,
  ArticleRewriteResponse,
  ArticleSendToMDBRequest,
  ArticleSendToMDBResponse,
  ArticleSummaryRequest,
  ArticleSummaryResponse,
  ArticleTeaserResponse,
} from 'shared'

export const generateTeaser = async (
  { body, version, publication, articleId }: ArticleRequest,
  config?: ArticleRequestConfig,
) => {
  try {
    const headers = {} as Record<string, string>

    if (config && config.origin) {
      headers['x-origin-aiforge'] = config.origin
    }

    const result = await axiosInstance.post<ApiResponse<ArticleTeaserResponse>>(
      '/articles/teaser',
      {
        body,
        version,
        publication,
        articleId,
      },
      { headers },
    )

    return result.data.data
  } catch {
    return null
  }
}

export const generateLead = async (
  { body, version, publication, articleId }: ArticleRequest,
  config?: ArticleRequestConfig,
) => {
  try {
    const headers = {} as Record<string, string>

    if (config && config.origin) {
      headers['x-origin-aiforge'] = config.origin
    }

    const result = await axiosInstance.post<ApiResponse<ArticleLeadResponse>>(
      '/articles/lead',
      {
        body,
        version,
        publication,
        articleId,
      },
      { headers },
    )

    return result.data.data
  } catch {
    return null
  }
}

export const generateMetaTitle = async (
  { body, version, publication, articleId }: ArticleRequest,
  config?: ArticleRequestConfig,
) => {
  try {
    const headers = {} as Record<string, string>

    if (config && config.origin) {
      headers['x-origin-aiforge'] = config.origin
    }

    const result = await axiosInstance.post<ApiResponse<ArticleMetaTitleResponse>>(
      '/articles/meta-title',
      {
        body,
        version,
        publication,
        articleId,
      },
      { headers },
    )

    return result.data.data
  } catch {
    return null
  }
}

export const generateOgTitle = async (
  { body, version, publication, articleId }: ArticleRequest,
  config?: ArticleRequestConfig,
) => {
  try {
    const headers = {} as Record<string, string>

    if (config && config.origin) {
      headers['x-origin-aiforge'] = config.origin
    }

    const result = await axiosInstance.post<ApiResponse<ArticleOgTitleResponse>>(
      '/articles/og-title',
      {
        body,
        version,
        publication,
        articleId,
      },
      { headers },
    )

    return result.data.data
  } catch {
    return null
  }
}

export const generateTextRewrite = async (
  { originalText, source, focus, version, sourceUrl, paragraphs, characters, publication }: ArticleRewriteRequest,
  config?: ArticleRequestConfig,
) => {
  try {
    const headers = {} as Record<string, string>

    if (config && config.origin) {
      headers['x-origin-aiforge'] = config.origin
    }

    const result = await axiosInstance.post<ApiResponse<ArticleRewriteResponse>>(
      '/articles/rewrite',
      {
        originalText,
        source,
        focus,
        version,
        sourceUrl,
        paragraphs,
        characters,
        publication,
      },
      { headers },
    )

    return result.data.data
  } catch {
    return null
  }
}

export const generateSummary = async (
  { originalText, version, regenerate, articleId, publication }: ArticleSummaryRequest,
  config?: ArticleRequestConfig,
) => {
  try {
    const headers = {} as Record<string, string>

    if (config && config.origin) {
      headers['x-origin-aiforge'] = config.origin
    }

    const result = await axiosInstance.post<ApiResponse<ArticleSummaryResponse>>(
      '/articles/summary',
      {
        originalText,
        version,
        regenerate,
        articleId,
        publication,
      },
      { headers },
    )
    return result.data.data
  } catch {
    return null
  }
}

export const scrapeUrl = async (url: string) => {
  try {
    const result = await axiosInstance.get<ApiResponse<string>>('/articles/scrape', { params: { url } })

    return result.data.data ?? ''
  } catch {
    return ''
  }
}

export const sendArticleToMDB = async ({
  metaTitle,
  teaserHeadline,
  teaserTitle,
  lead,
  body,
  language,
  ogTitle,
  summary,
}: ArticleSendToMDBRequest) => {
  try {
    const result = await axiosInstance.post<ApiResponse<ArticleSendToMDBResponse>>('/articles/send-to-mdb', {
      metaTitle,
      teaserHeadline,
      teaserTitle,
      lead,
      body,
      language,
      ogTitle,
      summary,
    })
    return result.data.data?.id
  } catch {
    return null
  }
}
