'use client'
// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles'
import { Container, AppBar, Box, CssBaseline, Toolbar } from '@mui/material'

// project imports
import { Header } from './Header'
import { Sidebar } from './Sidebar'
import { Breadcrumbs } from 'components/ui-component/extended/Breadcrumbs'
import { useConfig } from 'hooks/useConfig'
import { drawerWidth } from 'constants/common'
import { useApp } from 'hooks/useApp'
import { Footer } from './Footer'
import { Outlet } from 'react-router-dom'

interface MainStyleProps {
  theme: Theme
  open: boolean
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter + 200,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 72),
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop: 88,
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter + 200,
    }),
    marginLeft: 0,
    marginTop: 88,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up('md')]: {
      marginTop: 88,
    },
  }),
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    padding: '16px',
    marginTop: 88,
    ...(!open && {
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    marginRight: '0px',
    padding: '16px',
    marginTop: 88,
    ...(!open && {
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  },
}))

// ==============================|| MAIN LAYOUT ||============================== //

export const MainLayout = () => {
  const theme = useTheme()
  const { drawerOpen } = useApp()

  const { container } = useConfig()

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{ bgcolor: theme.palette.background.default }}
        >
          <Toolbar sx={{ p: '19px' }}>
            <Header />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar />

        {/* main content */}
        <Main theme={theme} open={drawerOpen} sx={{ bgcolor: theme.palette.blue[50] }}>
          <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
            {/* breadcrumb */}
            <Breadcrumbs />
            <Outlet />
          </Container>
        </Main>
      </Box>
      <Footer drawerOpen={drawerOpen} />
    </>
  )
}
