import { Typography, styled } from '@mui/material'
import blickPlusLogo from 'assets/images/blick-plus-logo.svg'

type Props = {
  isPlus?: any
  title?: string
}

export const TooltipContent = ({ title, isPlus }: Props) => {
  return (
    <Wrapper>
      <Title>
        {isPlus === 'true' && <img src={blickPlusLogo} alt="Blick plus" />} {title}
      </Title>
      <Typography variant="h6">Blick</Typography>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 16px;
`

const Title = styled('div')`
  font-family: Inter, sans-serif;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
`
