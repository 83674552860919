import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Rating,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { getConversation, getConversations } from 'api/chatbot'
import { AxiosError } from 'axios'
import { Markdown } from 'components/Markdown'
import { MainCard } from 'components/ui-component/cards/MainCard'
import { useApp } from 'hooks/useApp'
import { useCallback, useEffect, useState } from 'react'
import { ChatConversationResponse, ChatConversationsResponse } from 'types/chatbot'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { debounce } from 'lodash-es'
import { useSearchParams } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'

const DATE_RANGE_DEFAULT_VALUE = [dayjs('2024-05-01'), dayjs(new Date()).add(1, 'day')]

export const ChatbotAdmin = () => {
  const { drawerOpen, toggleDrawer } = useApp()
  const theme = useTheme()
  const [isInit, setIsInit] = useState(false)
  const [isFetchConversationsLoading, setIsFetchConversationsLoading] = useState(false)
  const [conversations, setConversations] = useState<ChatConversationsResponse>()
  const [filteredConversations, setFilteredConversations] = useState<ChatConversationsResponse>()
  const [isFetchConversationLoading, setIsFetchConversationLoading] = useState(false)
  const [selectedConversation, setSelectedConversation] = useState<ChatConversationResponse>()
  const [searchById, setSearchById] = useState<string>()
  const [costRange, setCostRange] = useState([0, 0.009])
  const [durationRange, setDurationRange] = useState([0, 65])
  const [ratingRange, setRatingRange] = useState([0, 5])
  const [dateRange, setDateRange] = useState<Dayjs[]>(DATE_RANGE_DEFAULT_VALUE)
  const [searchParams, setSearchParams] = useSearchParams()
  const conversationId = searchParams.get('id')

  const fetchConverstaions = async () => {
    setIsFetchConversationsLoading(true)
    const result = await getConversations()
    if (!(result instanceof AxiosError)) {
      setConversations(result.data)
      setFilteredConversations(
        result.data.map((item) => {
          const timestamp = parseInt(item.conversation_id.split('.')[1])
          item.date = timestamp && !isNaN(timestamp) ? dayjs(timestamp) : undefined
          return item
        }),
      )
    }
    setIsFetchConversationsLoading(false)
  }

  const fetchConverstaion = async (id: string) => {
    setSelectedConversation(undefined)
    setIsFetchConversationLoading(true)
    const result = await getConversation(id)
    if (!(result instanceof AxiosError)) {
      setSelectedConversation(result.data)
    }
    setIsFetchConversationLoading(false)
  }

  const onConversationClick = (id: string) => {
    setSearchParams({ id: encodeURIComponent(id) })
  }

  const getName = (id: string) => {
    const name = id.replace('USERID', '').split('.')
    return `${name[0].substring(0, 10)}.${name[1]}`
  }

  // eslint-disable-next-line
  const filterConversations = useCallback(
    debounce(() => {
      setFilteredConversations(
        conversations?.filter((conversation) => {
          return (
            conversation.average_cost >= costRange[0] &&
            conversation.average_cost <= costRange[1] &&
            conversation.average_response_time >= durationRange[0] &&
            conversation.average_response_time <= durationRange[1] &&
            conversation.average_rating >= ratingRange[0] &&
            conversation.average_rating <= ratingRange[1] &&
            (searchById ? conversation.conversation_id.indexOf(searchById) > -1 : true) &&
            (conversation.date && dateRange[0] ? conversation.date?.isAfter(dateRange[0], 'minute') : true) &&
            (conversation.date && dateRange[1] ? conversation.date?.isBefore(dateRange[1], 'minute') : true)
          )
        }),
      )
    }, 200),
    [costRange, durationRange, ratingRange, searchById, dateRange, conversations],
  )

  useEffect(() => {
    if (drawerOpen && !isInit) {
      toggleDrawer(false)
      setIsInit(true)
    }
  }, [drawerOpen, isInit, toggleDrawer])

  useEffect(() => {
    fetchConverstaions()
  }, [])

  useEffect(() => {
    if (conversationId) {
      fetchConverstaion(decodeURIComponent(conversationId))
    }
  }, [conversationId])

  useEffect(() => {
    filterConversations()
  }, [filterConversations])

  return (
    <>
      <Grid container columnSpacing="24px">
        <Grid item md={4}>
          <MainCard
            sx={{ height: '100%' }}
            title={
              <Grid container>
                <Grid item md={6}>
                  Conversations: {filteredConversations?.length}
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    value={searchById}
                    onChange={(event) => setSearchById(event.target.value)}
                    fullWidth
                    id="outlined-basic"
                    label="Search"
                    sx={{ zIndex: '0' }}
                    size="small"
                  />
                </Grid>
              </Grid>
            }
          >
            <Grid container padding="0 12px 12px 12px" columnSpacing="24px">
              <Grid item container md={6} alignItems="end">
                Cost range: CHF {costRange[0]}
                {costRange[0] > 0 && '00'} - CHF {costRange[1]}00
                <Slider
                  valueLabelDisplay="auto"
                  value={costRange}
                  onChange={(event, value) => setCostRange(value as number[])}
                  step={0.001}
                  max={0.009}
                />
              </Grid>
              <Grid item container md={6} alignItems="end">
                Duration range: {durationRange[0]}s - {durationRange[1]}s
                <Slider
                  valueLabelDisplay="auto"
                  value={durationRange}
                  onChange={(event, value) => setDurationRange(value as number[])}
                  max={65}
                />
              </Grid>
              <Grid item container md={6} alignItems="end">
                Rating range: {ratingRange[0]} - {ratingRange[1]}
                <Slider
                  valueLabelDisplay="auto"
                  value={ratingRange}
                  onChange={(event, value) => setRatingRange(value as number[])}
                  max={5}
                />
              </Grid>
              <Grid item container md={6} alignItems="end">
                <div>Date range:</div>
                <Box position="relative" paddingLeft="50px" width="100%" marginBottom="6px">
                  <Box position="absolute" left="0">
                    From
                  </Box>{' '}
                  <StyledTextField
                    type="date"
                    value={dateRange[0].format('YYYY-MM-DD')}
                    onChange={(event) =>
                      setDateRange([
                        event.target.value ? dayjs(event.target.value) : DATE_RANGE_DEFAULT_VALUE[0],
                        dateRange[1],
                      ])
                    }
                  />{' '}
                </Box>
                <Box position="relative" paddingLeft="30px" width="100%">
                  <Box position="absolute" left="0">
                    To
                  </Box>{' '}
                  <StyledTextField
                    type="date"
                    value={dateRange[1].format('YYYY-MM-DD')}
                    onChange={(event) =>
                      setDateRange([
                        dateRange[0],
                        event.target.value ? dayjs(event.target.value) : DATE_RANGE_DEFAULT_VALUE[1],
                      ])
                    }
                  />{' '}
                </Box>
              </Grid>
            </Grid>
            <Wrapper sx={{ height: 'calc(100vh - 443px)' }}>
              <TableContainer>
                {isFetchConversationsLoading ? (
                  <StyledCircularProgress size={50} />
                ) : (
                  <Table sx={{ tableLayout: 'fixed' }}>
                    <TableBody>
                      {filteredConversations &&
                        filteredConversations.map((conversation) => (
                          <StyledTableRow
                            key={conversation.conversation_id}
                            onClick={() => onConversationClick(conversation.conversation_id)}
                          >
                            <TableCell>
                              {getName(conversation.conversation_id)}
                              {conversation.average_rating && (
                                <Grid container>
                                  <Grid item md={6}>
                                    <Typography variant="bodysm" color={theme.palette.grey[500]}>
                                      Avarage rating
                                    </Typography>
                                  </Grid>
                                  <Grid container item md={6} justifyContent="flex-end" alignContent="center">
                                    {conversation.average_rating && (
                                      <Rating size="small" readOnly defaultValue={conversation.average_rating} />
                                    )}
                                  </Grid>
                                </Grid>
                              )}

                              {(conversation.average_response_time || conversation.average_cost) && (
                                <Grid container>
                                  <Grid item md={6}>
                                    <Typography variant="bodysm" color={theme.palette.grey[500]}>
                                      {conversation.average_response_time &&
                                        `${conversation.average_response_time.toFixed(2)}s`}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6} textAlign="right">
                                    <Typography variant="bodysm" color={theme.palette.grey[500]}>
                                      {conversation.average_cost && `CHF${conversation.average_cost.toFixed(5)}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Wrapper>
          </MainCard>
        </Grid>
        <Grid item md={8}>
          <MainCard
            sx={{ height: '100%' }}
            title={
              !selectedConversation && !isFetchConversationLoading
                ? 'Select conversation'
                : selectedConversation && (
                    <>
                      {getName(selectedConversation.conversation_id)}
                      <Typography
                        variant="bodysm"
                        fontSize="10px"
                        color={theme.palette.grey[500]}
                        lineHeight="10px"
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {selectedConversation.conversation_id}
                      </Typography>
                    </>
                  )
            }
          >
            <Wrapper>
              {isFetchConversationLoading ? (
                <StyledCircularProgress size={50} />
              ) : (
                <Grid container>
                  {selectedConversation?.messages.map((message, index) => (
                    <>
                      {message.type === 'question' && (
                        <Grid container justifyContent="end">
                          <QuestionWrapper>
                            <div>{message.text}</div>
                          </QuestionWrapper>
                        </Grid>
                      )}

                      {message.type === 'answer' && (
                        <Grid container justifyContent="start">
                          <div>
                            <AnswerWrapper>
                              {message.is_suggested_question && (
                                <StyledChip
                                  sx={{
                                    background: 'white',
                                    color: theme.palette.info.dark,
                                  }}
                                  label={'Suggested question'}
                                  color="warning"
                                />
                              )}

                              {message.no_answer && (
                                <StyledChip
                                  sx={{ background: theme.palette.error.light, color: theme.palette.error.dark }}
                                  label={'Blocked'}
                                  color="error"
                                />
                              )}

                              <Markdown>{message.text}</Markdown>
                              {message.feedback?.rating && (
                                <Grid container justifyContent="end" marginTop="50px">
                                  <Box textAlign="right">
                                    <Rating size="small" readOnly defaultValue={message.feedback.rating} />
                                    {message.feedback?.text && (
                                      <>
                                        <br />
                                        <Typography color={theme.palette.common.white} variant="bodysm">
                                          {' '}
                                          <b>Feedback</b>: {message.feedback?.text}
                                        </Typography>
                                      </>
                                    )}
                                  </Box>
                                </Grid>
                              )}
                            </AnswerWrapper>
                            <MuiAccordion
                              defaultExpanded={false}
                              sx={{
                                '& .MuiButtonBase-root': {
                                  padding: 0,
                                  marginTop: '10px',
                                  marginLeft: '16px',
                                  minHeight: 'auto !important',
                                },
                                '&.Mui-expanded': { margin: 0 },
                                '&:before': { display: 'none' },
                              }}
                            >
                              <MuiAccordionSummary
                                sx={{
                                  justifyContent: 'start',
                                  '& .MuiAccordionSummary-content': { padding: 0, margin: '0 !important', flexGrow: 0 },
                                }}
                                expandIcon={<MaterialIcon name="expand_more" />}
                              >
                                Usage
                              </MuiAccordionSummary>
                              <MuiAccordionDetails>
                                <StyledList>
                                  {message.usage?.map((item, itemIdex) => (
                                    <li key={`usage-${index}-${itemIdex}`}>
                                      {item.name} (Model: {item.model}, In: {item.in}, Out: {item.out})
                                    </li>
                                  ))}
                                </StyledList>
                                <p>
                                  No Answer: <b>{`${!!message.no_answer}`}</b>
                                </p>
                                <p>
                                  Cost: <b>{message.cost}</b>
                                </p>
                                <p>
                                  Suggested Question: <b>{`${!!message.is_suggested_question}`}</b>
                                </p>
                                <p>
                                  Trace ID: <b>{message.trace_id}</b>
                                </p>
                                <p>
                                  Feedback text: <b>{`${message.feedback?.text}`}</b>
                                </p>
                              </MuiAccordionDetails>
                            </MuiAccordion>
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </Wrapper>
          </MainCard>
        </Grid>
      </Grid>
    </>
  )
}

const Wrapper = styled('div')`
  height: calc(100vh - 285px);
  width: 100%;
  overflow-y: scroll;
  position: relative;
`

const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.palette.grey[50]};
  }
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: ${({ theme }) => theme.palette.blue[500]};
`

const AnswerWrapper = styled('div')`
  margin-left: 16px;
  animation: bot-entry 0.3s ease-in backwards;
  background-color: rgb(73, 29, 141);
  color: rgb(255, 255, 255);
  max-width: 70%;
  white-space: pre-line;
  margin-top: 8px;
  padding: 12px 16px;
  border-radius: 22px;
  min-height: 20px;
  height: fit-content;
  width: fit-content;
  font-size: 15px;
  overflow: hidden;
`

const QuestionWrapper = styled(AnswerWrapper)`
  background-color: rgb(66, 176, 197);
`

const StyledList = styled('ul')`
  margin-top: 0;
`

const StyledChip = styled(Chip)`
  fontSize: 10px;
  fontWeight: 700;
  margin-bottom: 12px;
  margin-right: 6px;
  height: auto;
  
  & .MuiChip-label {
    padding 4px 10px;
  }
`

const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiInputBase-input {
    padding: 1px 14px !important;
  }

  & .MuiFormLabel-root {
    top: -9px;
  }
`
