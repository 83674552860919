import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AI_MODEL_OPTIONS } from 'views/TextToText/useTextGenerator'
import { useTranslation } from 'react-i18next'
import { AiVersion } from 'shared'

type Props = {
  onSubmit: SubmitHandler<ArticleSummaryGeneratorFormValues>
  isLoading: boolean
}

export type ArticleSummaryGeneratorFormValues = {
  text: string
  version: AiVersion
}

export const ArticleSummaryGeneratorForm = ({ onSubmit, isLoading }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { register, formState, control, handleSubmit } = useForm<ArticleSummaryGeneratorFormValues>({
    defaultValues: { version: AiVersion.GPT4o },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing="24px" columnSpacing="24px">
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model-select-label">
              <FormattedMessage id="common.regenerate" />
            </InputLabel>
            <Controller
              name="version"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="model-select-label"
                  value={value}
                  label={<FormattedMessage id="common.chooseAIModel" />}
                  onChange={onChange}
                >
                  {AI_MODEL_OPTIONS.map(({ label, value, id }) => (
                    <MenuItem key={`model_item_${id}`} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            sx={{ '& .MuiInputBase-input': { height: '362px !important' } }}
            fullWidth
            multiline
            label={<FormattedMessage id="common.pasteText" />}
            placeholder={t('common.pleaseProvideMeTextSoICcanDoMyMagic')}
            error={!!formState.errors.text}
            helperText={!!formState.errors.text && <FormattedMessage id="articleSummary.textAreaError" />}
            type="textarea"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              sx: {
                '&::placeholder': {
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: theme.palette.grey[500],
                  paddingTop: '3px',
                  opacity: 1, // otherwise firefox shows a lighter color
                },
                '&:focus::placeholder': {
                  color: 'transparent',
                },
              },
            }}
            {...(register && register('text', { required: true }))}
          />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            sx={{
              textTransform: 'uppercase',
            }}
          >
            {isLoading && <CircularProgress size={16} sx={{ color: theme.palette.grey[400], marginRight: '8px' }} />}
            <FormattedMessage id="articleSummary.generateSummary" />
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
