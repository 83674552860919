import { useTranslationContext } from 'hooks/useTranslationContext'
import { AppLanguages } from 'types/translation'
import { RecommendedViewDe } from './de'
import { RecommendedViewEn } from './en'
import { RecommendedViewBg } from './bg'
import { RecommendedViewFr } from './fr'
import { RecommendedViewHu } from './hu'
import { RecommendedViewRo } from './ro'
import { RecommendedViewPl } from './pl'
import { RecommendedViewSk } from './sk'
import { RecommendedViewSr } from './sr'

export const RecommendedView = () => {
  const { currentLanguage } = useTranslationContext()

  switch (currentLanguage) {
    case AppLanguages.BULGARIAN: {
      return <RecommendedViewBg />
    }
    case AppLanguages.FRENCH: {
      return <RecommendedViewFr />
    }
    case AppLanguages.GERMAN: {
      return <RecommendedViewDe />
    }
    case AppLanguages.HUNGARIAN: {
      return <RecommendedViewHu />
    }
    case AppLanguages.POLISH: {
      return <RecommendedViewPl />
    }
    case AppLanguages.ROMANIAN: {
      return <RecommendedViewRo />
    }
    case AppLanguages.SERBIAN: {
      return <RecommendedViewSr />
    }
    case AppLanguages.SLOVAKIAN: {
      return <RecommendedViewSk />
    }

    default: {
      return <RecommendedViewEn />
    }
  }
}
