import { Button, CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FormattedMessage } from 'components/FormattedMessage'

type Props = {
  onReset: () => void
  onSubmit: () => void
  isLoading: boolean
}

export const ActionFooter = ({ onReset, onSubmit, isLoading }: Props) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Wrapper $isMobile={matchDownMd}>
      <Button variant="outlined" onClick={onReset} sx={{ textTransform: 'uppercase' }}>
        {matchDownMd ? (
          <FormattedMessage id="articleGenerator.resetAll" />
        ) : (
          <FormattedMessage id="articleGenerator.resetAndStartOver" />
        )}
      </Button>
      <Button variant="contained" onClick={onSubmit} sx={{ textTransform: 'uppercase' }} disabled={isLoading}>
        {isLoading && <CircularProgress size={16} sx={{ color: theme.palette.grey[400], marginRight: '8px' }} />}
        {isLoading ? (
          <FormattedMessage id="common.loading" />
        ) : (
          <FormattedMessage id="articleGenerator.sendToMDBButton" />
        )}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled('div')<{ $isMobile: boolean }>`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  zindex: 1099;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  boxshadow: 0px -1px 8px 0px rgba(54, 65, 82, 0.2);
  background: #ffffff;
  gap: 20px;
`
