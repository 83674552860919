export const Loader = () => (
  <>
    <div className="rcb-bot-message">
      <div className="rcb-typing-indicator">
        <span className="rcb-dot" />
        <span className="rcb-dot" />
        <span className="rcb-dot" />
      </div>
    </div>
  </>
)
