'use client'

// project imports
import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { Loader } from 'components/ui-component/Loader'

// types
import { GuardProps } from 'types'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { getLoginRedirectUrl, isPathRestricted } from 'utils/url'
import { routes } from 'constants/routes'

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
export const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, isInitialized, session } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!isLoggedIn && isInitialized && !matchPath(routes.login.url, pathname)) {
      navigate(getLoginRedirectUrl(pathname))
    }
  }, [isLoggedIn, isInitialized, navigate, pathname])

  useEffect(() => {
    if (isLoggedIn && isInitialized && isPathRestricted(pathname, session?.restriction)) {
      navigate(routes.dashboard.url)
    }
  }, [isLoggedIn, isInitialized, navigate, pathname, session?.restriction])

  if (!isLoggedIn) return <Loader />

  return children
}
