import {
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { FormattedMessage } from 'components/FormattedMessage'
import { Transitions } from 'components/ui-component/extended/Transitions'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TRANSLATION_LANGUAGES = (t: any) => {
  return [
    {
      label: t('translator.languages.fr'),
      value: 'FR',
    },
    {
      label: t('translator.languages.en'),
      value: 'EN',
    },
    {
      label: t('translator.languages.de'),
      value: 'DE',
    },
    {
      label: t('translator.languages.it'),
      value: 'IT',
    },
    {
      label: t('translator.languages.pl'),
      value: 'PL',
    },
    {
      label: t('translator.languages.ro'),
      value: 'RO',
    },
    {
      label: t('translator.languages.hu'),
      value: 'HU',
    },
    {
      label: t('translator.languages.sk'),
      value: 'SK',
    },
  ]
}

export const getLangLabel = (lang: string | null, t: any) => {
  if (lang === 'detect') {
    return 'DETECT LANG.'
  }

  const targetLanguage = TRANSLATION_LANGUAGES(t).find((language) => language.value === lang)

  if (targetLanguage) {
    return targetLanguage.label
  }

  return lang
}

const getTranslationLanguages = (isSource: boolean, t: any) => {
  if (isSource) {
    return [
      {
        label: 'Detect language',
        value: 'detect',
      },
      ...TRANSLATION_LANGUAGES(t),
    ]
  }
  return TRANSLATION_LANGUAGES(t)
}

type Props = {
  isSource?: boolean
  onChange: (lang: string) => void
  detectedLang?: string | null
  helperText?: boolean
}

export const LanguageSelector = ({ isSource = false, onChange, detectedLang, helperText = false }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<any>(null)
  const translationLanguages = getTranslationLanguages(isSource, t)
  const [currentLanguage, setCurrentLanguage] = useState(translationLanguages[0].value)

  const [tabList, setTablist] = useState([translationLanguages[0], translationLanguages[1], translationLanguages[2]])

  const onCurrentLanguageChange = (lang: string) => {
    setCurrentLanguage(lang)
    onChange(lang)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const handleListLanguageChange = (language: { label: string; value: string }) => {
    if (!tabList.find((existingLang) => existingLang.value === language.value)) {
      setTablist([tabList[0], language, tabList[1]])
    }
    onCurrentLanguageChange(language.value)
    setOpen(false)
  }

  return (
    <FlexContainer
      sx={{
        marginLeft: '4px',
        borderBottom: matchDownMd && helperText ? `none` : `1px solid ${theme.palette.grey[300]}`,
        justifyContent: matchDownMd && !isSource && !helperText ? 'space-between' : 'flex-start',
        paddingBottom: matchDownMd && !helperText ? '20px' : 0,
        width: '100%',
      }}
    >
      {/* {!isSource && <Icon className="material-symbols-outlined">sync_alt</Icon>} */}
      {!matchDownMd && (
        <>
          {helperText && (
            <Typography variant="bodymd" sx={{ lineHeight: '20px', color: theme.palette.grey[700], fontWeight: 700 }}>
              <FormattedMessage id="translator.translateTo" />
            </Typography>
          )}
          <Tabs
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.blue[500],
              },
              '& .MuiTabs-flexContainer': {
                borderBottom: 'none',
              },
            }}
            value={currentLanguage}
            onChange={(_, newValue) => onCurrentLanguageChange(newValue)}
          >
            {tabList.map((language) => {
              if (language.value === 'detect' && detectedLang && currentLanguage === 'detect') {
                return (
                  <StyledTab
                    key={`language_${language.value}_tab_${isSource ? 'source' : 'result'}`}
                    sx={{ '&.Mui-selected': { color: theme.palette.blue[500] }, padding: '20px 8px' }}
                    disableRipple
                    label={`${getLangLabel(detectedLang, t)} - Detected`}
                    value={language.value}
                  />
                )
              }
              return (
                <StyledTab
                  key={`language_${language.value}_tab_${isSource ? 'source' : 'result'}`}
                  sx={{ '&.Mui-selected': { color: theme.palette.blue[500] }, padding: '20px 8px' }}
                  disableRipple
                  label={language.label}
                  value={language.value}
                />
              )
            })}
          </Tabs>
        </>
      )}
      {matchDownMd && (
        <Typography
          variant="bodysm"
          sx={{ lineHeight: '20px', color: theme.palette.blue[500], textTransform: 'uppercase' }}
        >
          {getLangLabel(currentLanguage, t)}
        </Typography>
      )}
      <MoreIcon $matchDownMd={matchDownMd} className="material-symbols-outlined" onClick={handleToggle} ref={anchorRef}>
        expand_more
      </MoreIcon>
      <Popper
        placement={'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [30, 20],
            },
          },
        ]}
        sx={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions position={'top'} in={open} {...TransitionProps}>
              <Paper elevation={16} sx={{ zIndex: '3' }}>
                {open && (
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      minWidth: 200,
                      maxWidth: 280,
                      bgcolor: theme.palette.background.paper,
                      borderRadius: '12px',
                      padding: '20px 0',
                      [theme.breakpoints.down('md')]: {
                        maxWidth: 250,
                      },
                    }}
                  >
                    {translationLanguages.map((language) => {
                      return (
                        <ListItemButton
                          key={language.value}
                          onClick={() => handleListLanguageChange(language)}
                          sx={{
                            padding: '2px 24px 2px 52px',
                            position: 'relative',

                            '&:hover': {
                              background: theme.palette.blue[50],
                              color: theme.palette.grey[700],
                            },
                          }}
                        >
                          {language.value === currentLanguage && (
                            <MaterialIcon sx={{ position: 'absolute', left: '24px' }} name="done" />
                          )}
                          <ListItemText
                            primary={
                              <Grid container>
                                <Typography variant="bodymd" sx={{ fontWeight: '400' }}>
                                  {language.label}
                                </Typography>
                              </Grid>
                            }
                          />
                          {language.value === 'detect' && <MaterialIcon name="star" sx={{ marginLeft: '4px' }} />}
                        </ListItemButton>
                      )
                    })}
                  </List>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </FlexContainer>
  )
}

export const StyledTab = styled(Tab)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.blue[500]};

  &.mui-selected: {
    color: ${({ theme }) => theme.palette.blue[500]};
  }
`

const FlexContainer = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
`

const MoreIcon = styled('span')<{ $matchDownMd: boolean }>`
  font-size: ${({ $matchDownMd }) => ($matchDownMd ? '16px' : '24px')};
  cursor: pointer;
  color: ${({ $matchDownMd, theme }) => ($matchDownMd ? theme.palette.blue[500] : 'inherit')};
  margin-left: ${({ $matchDownMd }) => ($matchDownMd ? '-12px' : '0px')};
`

// const Icon = styled('span')`
//   font-size: 24px;
//   margin-left: -12px;
// `
