import { Grid, Link, Typography, useMediaQuery } from '@mui/material'
import { styled, useTheme, Theme } from '@mui/material/styles'
import { FormattedMessage } from 'components/FormattedMessage'
import { useAuth } from 'hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { trackButtonClick } from 'tracking/gtm'
import { GoogleAnalyticsContext } from 'shared'
import { FrontendGoogleAnalyticsClickAction } from 'types/analytics'

type Props = {
  drawerOpen: boolean
}

export const Footer = ({ drawerOpen }: Props) => {
  const { pathname } = useLocation()
  const { session } = useAuth()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <FooterWrapper theme={theme} open={drawerOpen} isMobile={matchDownMd}>
      <Grid
        container
        sx={{
          justifyContent: { xs: 'center', md: 'space-between' },
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Grid item>
          <Typography
            variant="bodysm"
            sx={{
              lineHeight: '14px',
              color: theme.palette.grey[500],
            }}
          >
            <Grid container alignItems="center" sx={{ justifyContent: { xs: 'center', md: 'start' } }}>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon className="material-symbols-outlined">maps_ugc</Icon>
                <Link
                  href="https://forms.gle/bCW14hLc1BuhrDbx7"
                  target="_blank"
                  sx={{ color: theme.palette.grey[500], textDecorationColor: theme.palette.grey[500] }}
                  onClick={() =>
                    trackButtonClick({
                      page: pathname,
                      hashedUserId: session?.hashedId,
                      context: GoogleAnalyticsContext.GENERAL,
                      clickAction: FrontendGoogleAnalyticsClickAction.FEEDBACK,
                      elementLabel: 'Share feedback',
                    })
                  }
                >
                  <FormattedMessage id="footer.shareFeedback" />
                </Link>
                &nbsp; or &nbsp;
                <Link
                  href="https://forms.gle/4NvGmA99xCKyLcmf8"
                  target="_blank"
                  sx={{ color: theme.palette.grey[500], textDecorationColor: theme.palette.grey[500] }}
                  onClick={() =>
                    trackButtonClick({
                      page: pathname,
                      hashedUserId: session?.hashedId,
                      context: GoogleAnalyticsContext.GENERAL,
                      clickAction: FrontendGoogleAnalyticsClickAction.BUG_REPORT,
                      elementLabel: 'Report a bug',
                    })
                  }
                >
                  <FormattedMessage id="footer.reportABug" />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.ringier.com/data-privacy/"
                  target="_blank"
                  sx={{
                    color: theme.palette.grey[500],
                    textDecorationColor: theme.palette.grey[500],
                    marginLeft: { xs: '4px', sm: '12px', md: '32px' },
                  }}
                >
                  <FormattedMessage id="footer.dataPrivacy" />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.ringier.com/legal-notice/"
                  target="_blank"
                  sx={{
                    color: theme.palette.grey[500],
                    textDecorationColor: theme.palette.grey[500],
                    marginLeft: { xs: '4px', sm: '12px', md: '32px' },
                  }}
                >
                  <FormattedMessage id="footer.legalNotice" />
                </Link>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'start' },
            marginBottom: { xs: '20px', md: '0px' },
          }}
        >
          <Typography
            variant="bodysm"
            sx={{
              lineHeight: '14px',
              color: theme.palette.grey[500],
            }}
          >
            © Ringier AI-Forge - Version 1.1
          </Typography>
        </Grid>
      </Grid>
    </FooterWrapper>
  )
}

const FooterWrapper = styled('footer', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile' })(
  ({ theme, open, isMobile }: { theme: Theme; open: boolean; isMobile: boolean }) => ({
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(!open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter + 200,
      }),
      marginLeft: isMobile ? '0' : '72px',
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - 72px)`,
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter + 200,
      }),
      marginLeft: isMobile ? '0' : '280px',
      width: `calc(100% - 280px)`,
    }),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
)

const Icon = styled('span')`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-right: 4px;
`
