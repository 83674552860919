import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { InitialQuestions } from './InitialQuestions'
import { Message } from './Message'
import { useBlickWidgets } from 'views/BlickWidgets/context/useBlickWidgets'
import { MainCard } from 'components/ui-component/cards/MainCard'
import { useEffect, useState } from 'react'
import { getArticleIds } from 'api/blick-widgets'
import { AxiosError } from 'axios'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { IconExternalLink } from '@tabler/icons-react'
import { Spacer } from 'components/ui-component/Spacer'

type Props = {
  title?: string
}

export const QuestionWidget = ({ title }: Props) => {
  const { messages, isInitialLoading, isAnswerLoading, getInitialQuestions, widgetId, setMessages } = useBlickWidgets()
  const [articleIds, setArticleIds] = useState<string[] | undefined>()
  const [articlesLoading, setGetArticlesLoading] = useState(false)
  const [selectedArticleId, setSelectedArticleId] = useState<string | null>('')

  const getArticles = async () => {
    setGetArticlesLoading(true)
    const result = await getArticleIds()
    if (!(result instanceof AxiosError)) {
      setArticleIds(result.data.articleIds)
    }
    setGetArticlesLoading(false)
  }

  useEffect(() => {
    if (widgetId === 'articleQuestion') {
      if (selectedArticleId) {
        getInitialQuestions(selectedArticleId)
      }
    } else {
      getInitialQuestions()
    }
  }, [getInitialQuestions, selectedArticleId, widgetId])

  useEffect(() => {
    if (widgetId === 'articleQuestion') {
      getArticles()
    }
  }, [widgetId])

  return (
    <>
      <MainCard
        title={
          <Box display="flex" justifyContent="space-between">
            {title}
            {widgetId === 'articleQuestion' && (
              <>
                {articlesLoading && <CircularProgress size={20} />}
                {articleIds && (
                  <Autocomplete
                    disablePortal
                    options={articleIds}
                    // inputValue={selectedArticleId || ''}
                    onChange={(event, newInputValue) => {
                      setSelectedArticleId(newInputValue)
                      setMessages([])
                    }}
                    size="small"
                    sx={{ width: 200, height: '15px', marginTop: '-8px' }}
                    renderInput={(params) => <TextField {...params} label="Article id" />}
                  />
                )}
              </>
            )}
          </Box>
        }
      >
        {isInitialLoading ? (
          <CircularProgress />
        ) : (
          <>
            <InitialQuestions />
            {isAnswerLoading && <CircularProgress />}
            {messages[0] && <Message widgetId={widgetId} message={messages[0]} index={0} />}
            {messages[1] && <Message widgetId={widgetId} message={messages[1]} index={1} />}
            {selectedArticleId && (
              <ExternalLink
                target="_blank"
                href={`https://www.blick.ch/${selectedArticleId}`}
                sx={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
              >
                Go to article
                <Spacer h size={8} />
                <IconExternalLink />
              </ExternalLink>
            )}
          </>
        )}
      </MainCard>
    </>
  )
}
