import { Typography, styled, useTheme } from '@mui/material'
import { Ref } from 'react'
import Lottie from 'lottie-react'

import { MainCard } from 'components/ui-component/cards/MainCard'
import roboticLoader from './lotties/robotic_loader.json'
import { FormattedMessage } from 'components/FormattedMessage'

type Props = {
  isLoading: boolean
  loaderRef: Ref<HTMLDivElement> | null
  isTitleLoading?: boolean
}

export const RoboticLoader = ({ isLoading, loaderRef, isTitleLoading }: Props) => {
  const theme = useTheme()
  return (
    <MainCard
      contentSX={{
        display: isLoading ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={loaderRef}
    >
      <StyledLottie animationData={roboticLoader} />
      <Typography variant="h3" sx={{ textAlign: 'center', color: theme.palette.purple[500], marginTop: '-50px' }}>
        {isTitleLoading ? (
          <FormattedMessage id="roboticLoader.titleText1" />
        ) : (
          <FormattedMessage id="roboticLoader.text1" />
        )}
      </Typography>
      <Typography variant="h3" sx={{ textAlign: 'center', color: theme.palette.purple[500] }}>
        <FormattedMessage id="roboticLoader.text2" />
      </Typography>
    </MainCard>
  )
}

const StyledLottie = styled(Lottie)`
  height: 340px;
  margin-top: -56px;
`
