// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewBg = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Моля, приемете и следвайте следните правила за използване на изкуствен интелект в редакционната работа.
          Основата за следните точки са{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            ръководствата за AI на Ringier.
          </ExternalLink>
          <ol>
            <li>
              Активно насърчаваме разбирането и използването на инструментите за изкуствен интелект сред нашите
              служители, за да оптимизираме работните процеси.
            </li>
            <li>Нашето използване на AI винаги е базирано на отговорност и етични принципи.</li>
            <li>
              Прозрачността е важна за нас: Ясно обявяваме използването на AI в нашата редакция, независимо дали става
              въпрос за текстове или описания на изображения.
            </li>
            <li>
              Уверяваме се, че съдържанието, генерирано от AI, никога не се публикува без окончателен преглед от човек.
            </li>
            <li>
              Строго се придържаме към забраната за подаване на конфиденциална информация, търговски тайни или лични
              данни в публични AI инструменти.
            </li>
            <li>
              За транскрипциите на интервюта използваме нашия вътрешен аудио инструмент (Blisper) в AI Forge, а не
              услуги на трети страни за изкуствен интелект.
            </li>
            <li>
              Не използваме AI базирани инструменти за изображения като DALL·E 3 и Midjourney за създаване на новини.
              Тяхното използване е разрешено за илюстративни изображения, но само след консултация с надзорника, отдела
              за редактиране на снимки или лидера на AI Newsroom.
            </li>
            <li>
              За чувствителни теми, където AI може да доведе до дезинформация или неправилно тълкуване, се въздържаме от
              използването му.
            </li>
            <li>Предлагаме вътрешно обучение по AI за нашите служители.</li>
            <li>
              С оглед на бързото развитие в областта на изкуствения интелект, винаги сме в крак с времето и насърчаваме
              нашите служители да докладват за потенциално полезни AI инструменти на отговорната офис.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          24 февруари/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
