import { styled, useTheme, Rating as RatingComponent, Typography, useMediaQuery, SxProps } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage } from './FormattedMessage'
import { RateModalConfig } from './RateModal'

type Props = {
  onRatingClick: Dispatch<SetStateAction<RateModalConfig | null>>
  title?: string
  feedbackSent: boolean
  feedbackKey?: string
  sx?: SxProps
  ratingSx?: SxProps
  noReset?: boolean
}

export const Rating = ({
  onRatingClick,
  title = 'Rate result',
  feedbackKey,
  feedbackSent,
  sx,
  ratingSx,
  noReset,
}: Props) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [ratingValue, setRatingValue] = useState(0)
  return feedbackSent ? (
    <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.purple[500] }}>
      {matchDownMd ? (
        <FormattedMessage id="rating.thanksForRating" />
      ) : (
        <FormattedMessage id="rating.thanksForYourValuableFeedback" />
      )}
    </Typography>
  ) : (
    <RatingContainer sx={sx}>
      {title}
      <RatingComponent
        sx={{
          color: theme.palette.purple[500],
          '& .MuiRating-iconEmpty': { color: theme.palette.purple[500] },
          ...ratingSx,
        }}
        size={matchDownMd ? 'small' : 'medium'}
        value={ratingValue}
        onChange={(e, value) => {
          onRatingClick({
            title: feedbackKey || title,
            defaultValue: `${value}`,
          })
          if (value && noReset) {
            setRatingValue(value)
          } else {
            setRatingValue(0)
          }
        }}
      />
    </RatingContainer>
  )
}

const RatingContainer = styled('div')`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.palette.purple[500]};
  font-size: 12px;
  line-height: 20px;
`
