import { Button, CircularProgress, styled, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
import { ReactNode } from 'react'

type Props = {
  disabled?: boolean
  isLoading: boolean
  buttonText: ReactNode
}

export const GenerateArticleButton = ({ disabled, isLoading, buttonText }: Props) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <ActionsContainer $isMobile={matchDownMd}>
      <Button
        variant="contained"
        type="submit"
        sx={{
          textTransform: 'uppercase',
          ...(matchDownMd ? { width: '100%' } : {}),
        }}
        disabled={disabled}
      >
        {isLoading && <CircularProgress size={16} sx={{ color: theme.palette.grey[400], marginRight: '8px' }} />}
        {isLoading ? <FormattedMessage id="common.generating" /> : buttonText}
      </Button>
    </ActionsContainer>
  )
}

const ActionsContainer = styled('div')<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-end')};
`
