export enum GoogleAnalyticsContext {
  AUDIO_TO_TEXT_USAGE = 'audio to text usage',
  AUDIO_TO_TEXT_METRICS = 'audio to text metrics',
  TEXT_TO_AUDIO = 'text to audio',
  TRANSLATE = 'translate',

  DASHBOARD = 'dashboard',
  AUDIO_TO_TEXT = 'audio to text',
  TEXT_TO_TEXT = 'text to text',
  ARTICLE_SUMMARY = 'article summary',
  ABOUT_AI_FORGE = 'about ai forge',
  AI_MANIFESTO = 'ai manifesto',
  LEARN_MORE = 'learn more',
  RECOMMENDED_AI_TOOLS = 'recommended AI Tools',
  GENERAL = 'general',
  CHATBOT = 'chatbot',
  TRANSLATOR = 'translator',
  MDB_PLUGIN = 'mdb plugin'
}
