'use client'

import { SimpleModal, SimpleModalActions } from 'components/ui-component/SimpleModal'
import { Dropzone } from 'components/ui-component/inputs/Dropzone'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, TextField, useMediaQuery, useTheme } from '@mui/material'

import { Spacer } from 'components/ui-component/Spacer'
import { createJob, uploadFileToS3 } from 'api/speechToText'
import { useApp } from 'hooks/useApp'
import { ReactNode, useState } from 'react'
import { JobCreateParams, GoogleAnalyticsContext } from 'shared'
import { trackNewTranscription } from 'tracking/gtm'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { FormattedMessage } from 'components/FormattedMessage'
import { useTranslation } from 'react-i18next'
import { FrontendGoogleAnalyticsClickAction } from 'types/analytics'

type Props = {
  isOpen: boolean
  onClose: () => void
  fetchData: () => void
}

type FormValues = {
  file: File | null
  name: string | null
}

const extractingProgressDefaultText = 'Extracting audio...'

export const NewTranscriptionModal = ({ isOpen = true, onClose, fetchData }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { session } = useAuth()
  const { openSnackbar } = useApp()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [progressText, setProgressText] = useState<ReactNode>()
  const [extracingInProgress, setExtracingInProgress] = useState(false)
  const ffmpeg = new FFmpeg()

  const getDuration = (src: string): Promise<number> => {
    return new Promise((resolve) => {
      const audio = new Audio()
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration)
      })
      audio.src = src
    })
  }

  const extractAudio = async (file: File) => {
    const newFileName = `${file.name.split('.')?.slice(0, -1)?.join('.')}.mp3`
    await ffmpeg.load()
    ffmpeg.on('log', () => {
      setProgressText(extractingProgressDefaultText)
    })
    ffmpeg.on('progress', ({ progress }) => {
      const currentProgress = Math.round(progress * 100)
      if (currentProgress > 1) {
        setProgress(currentProgress)
      }
    })
    await ffmpeg.writeFile(file.name, await fetchFile(file))
    await ffmpeg.exec([
      '-i',
      file.name, // input file
      '-codec:a',
      'libmp3lame', // using LAME MP3 encoder
      '-q:a',
      '2', // decent quality with reduced processing time
      '-map',
      '0:a:0', // correctly map the first audio stream
      newFileName, // output file
    ])

    setProgress(0)
    setProgressText(undefined)
    setExtracingInProgress(false)

    const data = await ffmpeg.readFile(newFileName)
    const newFile = new File([new Blob([data])], newFileName, { type: 'audio/mp3' })

    ffmpeg.terminate()

    return newFile
  }

  const onFileChange = async (file?: File) => {
    if (file) {
      setProgress(1)
      setProgressText(extractingProgressDefaultText)
      setExtracingInProgress(true)
      const audioFile = await extractAudio(file)
      setValue('file', audioFile, { shouldValidate: true })
    } else {
      setValue('file', null, { shouldValidate: true })
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    const { file, name } = formData
    if (file && !isLoading) {
      setIsLoading(true)
      const duration = await getDuration(URL.createObjectURL(file))
      const createJobData: JobCreateParams = {
        description: name || undefined,
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
        duration: duration || undefined,
        speakerRecognition: true,
        wordTimestamp: true,
      }

      const result = await createJob(createJobData)

      if (result?.success && result?.data) {
        setProgress(0)
        const upload = await uploadFileToS3(
          file,
          result.data.upload.url,
          result.data.upload.fields,
          (progressEvent) => {
            if (progressEvent.progress) {
              setProgress(Math.round(progressEvent.progress * 100))
            }
          },
        )

        openSnackbar({
          open: true,
          message: upload ? t('audioToText.successUpload') : t('audioToText.errorUpload'),
          autoHideDuration: 10000,
          variant: upload ? 'info' : 'error',
        })

        fetchData()
      }
      trackNewTranscription({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.TRANSCRIBE,
        elementLabel: 'Upload and Transcribe',
        duration: duration,
        format: file.type,
      })
      setIsLoading(false)
      handleOnClose()
    }
  }

  const handleOnClose = () => {
    ffmpeg.terminate()
    onClose()
  }

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={handleOnClose}
      title="Upload Audio or Video File"
      actions={
        <SimpleModalActions>
          {!isLoading && (
            <Button variant="outlined" onClick={handleOnClose} sx={{ textTransform: 'uppercase' }}>
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{
              textTransform: 'uppercase',
              minHeight: '24px',
              minWidth: '172px',
            }}
            onClick={isLoading ? handleOnClose : handleSubmit(onSubmit)}
            disabled={extracingInProgress}
          >
            {isLoading ? (
              <FormattedMessage id="audioToText.closeAndContinue" />
            ) : !matchDownMd ? (
              <FormattedMessage id="audioToText.uploadAndTranscribe" />
            ) : (
              <FormattedMessage id="audioToText.transcribe" />
            )}
          </Button>
        </SimpleModalActions>
      }
      sx={{
        width: { xs: '100%', lg: 520 },
        ...(!matchDownMd
          ? { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
          : { borderRadius: 0, height: '100%', overflow: 'auto' }),
      }}
      headerSX={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
    >
      <form>
        <Dropzone
          {...register('file', { required: true })}
          accept={{
            'audio/*': ['.mp3', '.mp4', '.mpeg', '.mpga', '.m4a', '.wav', '.webm', '.mov', '.mkv'],
          }}
          onFileChange={onFileChange}
          progress={progress}
          progressText={progressText}
          formError={errors.file}
          isLoading={isLoading}
          value={getValues('file')}
        />
        {!isLoading && (
          <>
            <Spacer v size={40} />
            <TextField
              fullWidth
              id="outlined-basic"
              label={
                <>
                  <FormattedMessage id="audioToText.titleLabel" /> (<FormattedMessage id="common.optional" />)
                </>
              }
              {...register('name')}
              sx={{ zIndex: '0' }}
            />
            <Spacer v size={20} />
          </>
        )}
      </form>
    </SimpleModal>
  )
}
