// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewRo = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Vă rugăm să acceptați și să urmați următoarele reguli pentru utilizarea inteligenței artificiale în
          activitatea editorială. Baza pentru următoarele puncte sunt{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            ghidurile de AI ale Ringier.
          </ExternalLink>
          <ol>
            <li>
              Promovăm activ înțelegerea și utilizarea instrumentelor AI printre angajații noștri pentru a optimiza
              procesele de lucru.
            </li>
            <li>Folosirea noastră a AI se bazează întotdeauna pe responsabilitate și principii etice.</li>
            <li>
              Transparența este importantă pentru noi: Divulgăm clar utilizarea AI în raportările noastre, fie în texte,
              fie în legendele imaginilor.
            </li>
            <li>
              Ne asigurăm că conținutul generat de AI nu este niciodată publicat fără o revizuire finală de către un om.
            </li>
            <li>
              Ne supunem strict să nu alimentăm informații confidențiale, secrete comerciale sau date personale în
              instrumentele AI publice.
            </li>
            <li>
              Pentru transcrierile interviurilor, folosim instrumentul nostru audio intern (Blisper) în AI Forge, nu
              serviciile AI de la terți.
            </li>
            <li>
              Nu folosim instrumente AI bazate pe imagini, cum ar fi DALL·E 3 și Midjourney, pentru crearea
              fotografiilor de știri. Utilizarea lor este permisă pentru imagini de tip ilustrație, dar numai în
              consultare cu supervisorul, departamentul de editare foto sau conducerea redacției AI.
            </li>
            <li>
              Pentru subiecte sensibile în care AI-ul ar putea duce la dezinformare sau interpretare greșită, ne abținem
              să îl folosim.
            </li>
            <li>Oferim training intern despre AI pentru angajații noștri.</li>
            <li>
              Având în vedere dezvoltarea rapidă în domeniul AI, rămânem întotdeauna la curent și încurajăm angajații
              noștri să raporteze instrumente AI potențial utile biroului AI responsabil.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          La data de 24 februarie/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
