import { Box, Button, Rating, Typography, styled, useTheme } from '@mui/material'
import { generateSummary } from 'api/textToText'
import { FormattedMessage } from 'components/FormattedMessage'
import { RateModal, RateModalConfig } from 'components/RateModal'
import { Spacer } from 'components/ui-component/Spacer'
import { useEffect, useState } from 'react'
import {
  ArticleMetaTitleResponse,
  AiVersion,
  TextToTextOrigin,
  GoogleAnalyticsContext,
  ArticlePublications,
} from 'shared'
import { TinyMceEvent } from 'types/tinymce-plugin'
import { RoboticLoader } from 'views/TextToText/components/RoboticLoader'
import starsBluePurple from 'assets/lotties/stars-bluepurple.json'
import Lottie from 'lottie-react'
import { trackRating } from 'tracking/gtm'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

const COMMON_ERROR_TEXT = 'An error occurred. Please close this window and try again.'

export const SummaryPluginView = () => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const { session } = useAuth()
  const [data, setData] = useState<TinyMceEvent>()
  const [documentId, setDocumentId] = useState<string>()
  const [publication, setPublication] = useState<ArticlePublications>(ArticlePublications.BLICK)
  const [result, setResult] = useState<ArticleMetaTitleResponse>()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [rateModalConfig, setRateModalConfig] = useState<RateModalConfig | null>(null)
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)

  const onInsert = () => {
    if (result) {
      const htmlString = `
        <ul>
          ${result.map((item) => `<li>${item}</li>`).join('')}
        </ul>
      `
      window.parent.postMessage({ type: 'data', value: htmlString }, '*')
    }
  }

  const handleMessage = (event: any) => {
    if (event.data.type === 'data') {
      setData(event.data as TinyMceEvent)
    }

    if (event.data.type === 'id') {
      setDocumentId(event.data.value)
    }

    if (event.data.type === 'language') {
      if (event.data.value === 'fr') {
        setPublication(ArticlePublications.BLICK_ROMANDIE)
      } else {
        setPublication(ArticlePublications.BLICK)
      }
    }
  }

  const getSummary = async (text?: string) => {
    if (!text) {
      return
    }
    setIsLoading(true)
    const summaryResult = await generateSummary(
      { originalText: text, version: AiVersion.CLAUDE_35_SONNET, articleId: documentId, publication },
      { origin: TextToTextOrigin.MDB_PLUGIN },
    )
    if (summaryResult) {
      setResult(summaryResult)
    } else {
      setError(COMMON_ERROR_TEXT)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    window.parent.postMessage({ type: 'ready' }, '*')

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    if (data) {
      getSummary(data.value)
      setError(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!data?.value) {
      setError('Bitte Artikelinhalt befüllen.')
    }
  }, [data])

  return (
    <>
      <RoboticLoader isLoading={isLoading} loaderRef={null} />
      {!isLoading && result && (
        <Wrapper>
          <ul>
            {result.map((item, index) => (
              <li key={`summary-${index}`}>{item}</li>
            ))}
          </ul>
          <Spacer v size={24} />
          {isFeedbackSent ? (
            <>
              <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.purple[500] }}>
                <Stars animationData={starsBluePurple} loop={true} />
                <FormattedMessage id="rating.thanksForYourValuableFeedback" />
              </Typography>
            </>
          ) : (
            <RatingContainer>
              <FormattedMessage id="rating.rateResult" />
              <Rating
                sx={{
                  color: theme.palette.purple[500],
                  '& .MuiRating-iconEmpty': { color: theme.palette.purple[500] },
                }}
                onChange={(e, value) => {
                  setRateModalConfig({
                    title: 'article summary',
                    defaultValue: `${value}`,
                  })
                }}
              />
            </RatingContainer>
          )}
          <Spacer v size={24} />
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={onInsert}>
              Insert summary
            </Button>
          </Box>

          {rateModalConfig && (
            <RateModal
              isOpen={Boolean(rateModalConfig)}
              onClose={() => setRateModalConfig(null)}
              config={rateModalConfig}
              onConfirm={() => setIsFeedbackSent(true)}
              onTrack={(rating, feedbackText) =>
                trackRating({
                  page: pathname,
                  hashedUserId: session?.hashedId,
                  elementLabel: rateModalConfig.title,
                  context: GoogleAnalyticsContext.MDB_PLUGIN,
                  rating,
                  feedbackText,
                  model: AiVersion.CLAUDE_35_SONNET,
                  articleId: documentId,
                })
              }
            />
          )}
        </Wrapper>
      )}

      {error && <Wrapper>{error}</Wrapper>}
    </>
  )
}

const Wrapper = styled('div')`
  padding: 24px;
`

const RatingContainer = styled('div')`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.palette.purple[500]};
  font-size: 12px;
  line-height: 20px;
`

const Stars = styled(Lottie)<{ $matchDownMd?: boolean }>`
  position: absolute;
  margin-top: ${({ $matchDownMd }) => ($matchDownMd ? '-30px' : '-50px')};
  margin-left: ${({ $matchDownMd }) => ($matchDownMd ? '0px' : '50px')};
  height: 120px;
  width: 120px;
`
