import { sha512 } from 'js-sha512'
import { ArticlePublications, Prompt, PublicationStyles } from 'shared'

export const hashString = (str: string, salt: string) => {
  // This is a solution for NextJS issue here:
  // https://github.com/vercel/next.js/discussions/47314
  // and
  // https://stackoverflow.com/questions/77073994/webpack-error-in-nodecrypto-module-build-failed-unhandledschemeerror-reading
  return sha512.create().update(str).update(sha512.create().update(salt).hex()).hex()
}

export const isValidUrl = (urlStr: string): boolean => {
  try {
    const url = new URL(urlStr)
    if (url) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const getLengthValueForPublication = (publication: ArticlePublications, prompt: Prompt) => {
  return PublicationStyles[publication]?.length[prompt] ?? 0
}
