// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewFr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Veuillez accepter et suivre les règles suivantes pour l'utilisation de l'intelligence artificielle dans le
          travail éditorial. La base des points suivants sont les{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            directives en matière d'IA de Ringier.
          </ExternalLink>
          <ol>
            <li>
              Nous encourageons activement la compréhension et l'utilisation des outils d'IA parmi nos employés pour
              optimiser les processus de travail.
            </li>
            <li>Notre utilisation de l'IA est toujours basée sur la responsabilité et les principes éthiques.</li>
            <li>
              La transparence est importante pour nous : nous divulguons clairement l'utilisation de l'IA dans nos
              rapports, que ce soit dans des textes ou des légendes d'images.
            </li>
            <li>
              Nous veillons à ce que le contenu généré par l'IA ne soit jamais publié sans une révision finale par un
              humain.
            </li>
            <li>
              Nous respectons strictement le fait de ne pas fournir d'informations confidentielles, de secrets
              commerciaux ou de données personnelles dans des outils d'IA publics.
            </li>
            <li>
              Pour les transcriptions d'interviews, nous utilisons notre outil audio interne (Blisper) dans l'AI Forge,
              et non des services d'IA tiers.
            </li>
            <li>
              Nous n'utilisons pas d'outils d'images basés sur l'IA tels que DALL·E 3 et Midjourney pour la création de
              photos d'actualités. Leur utilisation est autorisée pour des images de type illustration, mais uniquement
              après consultation avec le superviseur, le département de retouche photo ou le responsable de l'IA
              Newsroom.
            </li>
            <li>
              Pour les sujets sensibles où l'IA pourrait conduire à la désinformation ou à une mauvaise interprétation,
              nous nous abstenons de l'utiliser.
            </li>
            <li>Nous offrons une formation interne sur l'IA à nos employés.</li>
            <li>
              Compte tenu du développement rapide dans le domaine de l'IA, nous restons toujours à jour et encourageons
              nos employés à signaler les outils d'IA potentiellement utiles au bureau d'IA responsable.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          Au 24 février/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
