// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewDe = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Willkommen zu unserer ausgewählten Sammlung kostenloser Lerninhalte zum Thema Künstliche Intelligenz (KI)!
          Hier finden Sie eine spannende Auswahl von Videos zu verschiedenen Aspekten der KI, einschließlich Generative
          KI. Egal, ob Sie gerade erst anfangen oder bereits fortgeschrittene Kenntnisse haben, hier ist für jeden etwas
          dabei.
          <br />
          <br />
          Diese Sammlung ist perfekt für Sie, wenn Sie Ihr Wissen auf dem Gebiet der KI erweitern möchten, sei es aus
          beruflichen Gründen oder aus persönlichem Interesse. Sie können die Inhalte in Ihrem eigenen Tempo durchgehen
          und sich bequem von zu Hause aus weiterbilden.
          <br />
          <br />
          Tauchen Sie ein in die faszinierende Welt der Künstlichen Intelligenz und nutzen Sie die Gelegenheit, sich mit
          den neuesten Trends und Technologien vertraut zu machen. Ich hoffe, Sie finden diese Ressourcen wertvoll und
          bereichernd. Genießen Sie Ihre Lernreise!
        </Typography>
        <Typography variant="h3">KI im Allgemeinen:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            KI vs. Maschinelles Lernen vs. Deep Learning vs. Neuronale Netzwerke (IBM; Artikel)
          </ExternalLink>{' '}
          - Erklärender Artikel über die Unterschiede zwischen diesen Konzepten und eine hilfreiche Definition von
          Künstlicher Intelligenz.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Einführung in große Sprachmodelle (YouTube)
          </ExternalLink>{' '}
          - Dies ist eine einstündige allgemeinverständliche Einführung in große Sprachmodelle: die wesentlichen
          technischen Komponenten hinter Systemen wie ChatGPT, Claude und Google Bard. Was sie sind, wohin sie gehen,
          Vergleiche und Analogien zu heutigen Betriebssystemen und einige der sicherheitsrelevanten Herausforderungen
          dieses neuen Berechnungsparadigmas.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Einführung in Generative KI (Google)
          </ExternalLink>{' '}
          - Dies ist ein Einführungskurs auf Mikrolernniveau, der erklärt, was Generative KI ist, wie sie verwendet wird
          und wie sie sich von traditionellen Methoden des maschinellen Lernens unterscheidet. Er behandelt auch
          Google-Tools, die Ihnen helfen, Ihre eigenen Gen-KI-Apps zu entwickeln.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Einführung in große Sprachmodelle (LLMs) (Google)
          </ExternalLink>{' '}
          - Dies ist ein Einführungskurs auf Mikrolernniveau, der untersucht, was große Sprachmodelle (LLM) sind, die
          Anwendungsfälle, in denen sie genutzt werden können, und wie Sie die Leistung von LLM durch Promptabstimmung
          verbessern können. Er behandelt auch Google-Tools, die Ihnen helfen, Ihre eigenen Gen-KI-Apps zu entwickeln.
        </Typography>
        <Typography variant="h3">Aufforderungen:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Grundlagen des Prompt-Engineerings (AWS)
          </ExternalLink>{' '}
          - In diesem Kurs lernen Sie die Prinzipien, Techniken und besten Praktiken für das Entwerfen effektiver
          Aufforderungen. Dieser Kurs führt in die Grundlagen des Prompt-Engineerings ein und geht auf fortgeschrittene
          Prompttechniken ein. Sie lernen auch, wie Sie gegen den Missbrauch von Aufforderungen vorgehen und wie Sie
          Voreingenommenheit bei der Interaktion mit Foundation-Modellen vermeiden können.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Prompt Engineering (Coursera)
          </ExternalLink>{' '}
          - In diesem Kurs lernen Sie die Prinzipien, Techniken und besten Praktiken für das Entwerfen effektiver
          Aufforderungen. Dieser Kurs führt in die Grundlagen des Prompt-Engineerings ein und geht auf fortgeschrittene
          Prompttechniken ein. Sie lernen auch, wie Sie gegen den Missbrauch von Aufforderungen vorgehen und wie Sie
          Voreingenommenheit bei der Interaktion mit Foundation-Modellen vermeiden können.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            ChatGPT-Prompt-Engineering
          </ExternalLink>{' '}
          - Lernen Sie bewährte Verfahren für das Prompt-Engineering in der Anwendungs­entwicklung kennen. Entdecken Sie
          neue Möglichkeiten zur Verwendung von LLMs, einschließlich des Aufbaus Ihres eigenen individuellen Chatbots.
          Sammeln Sie praktische Erfahrungen beim Schreiben und Überarbeiten von Aufforderungen mit Hilfe der
          OpenAI-API.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Effektives Auffordern für ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - In diesem Video wird erklärt, wie Sie effektive ChatGPT 4-Aufforderungen erstellen können, um das Beste aus
          Ihrem KI-Assistenten herauszuholen. Ob Sie Entwickler sind oder einfach nur jemand, der die Leistung seines
          KI-Assistenten verbessern möchte, dieses Video ist für Sie.
        </Typography>
        <Typography variant="h3">Bild-Erstellung:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Midjourney-Kurs für Anfänger (YouTube)
          </ExternalLink>{' '}
          - In diesem Kurs lernen Sie die Grundlagen der KI-Kunsterzeugung mit der Midjourney-Plattform kennen. Sie
          entdecken die Grundlagen für das Erstellen und Verwalten von Aufforderungen, arbeiten mit verschiedenen
          Modellversionen und erkunden fortgeschrittene Techniken zur Erstellung beeindruckender Bilder.
        </Typography>
      </MainCard>
    </Text>
  )
}
