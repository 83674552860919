// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewHu = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Üdvözöljük a válogatott oldalunkon, ahol különféle külső AI eszközöket ajánlunk! Itt megtalálható egy
          válogatás azokról az eszközökről, amelyek javíthatják munkáját és serkenthetik az innovációt. Fontos azonban,
          hogy ezeket az eszközöket óvatosan és felelősségteljesen használja.
          <br />
          <br />
          Mielőtt belevágnánk, arra kérjük, ismerkedjen meg az AI irányelveinkkel. Ezek a irányelvek azért vannak, hogy
          biztosítsák az AI technológiák etikus, felelős és hatékony használatát. Segítenek eligazodni azokban a
          potenciális bonyolultságokban és kihívásokban, amelyekkel külső AI eszközök használata jár.
          <br />
          <br />
          Kérjük, vegye figyelembe, hogy bár ezeket az eszközöket az előnyeik miatt ajánljuk, közvetlen támogatást nem
          nyújtunk számukra. Ha problémába ütközik, vagy kérdése merül fel, keresse fel az egyes eszközökkel kapcsolatos
          támogatási csatornákat.
          <br />
          <br />
          Használja ezeket az eszközöket bölcsen az AI lehetőségeinek felfedezésére, mindig figyelembe véve
          irányelveinket és a körültekintő, tájékozott alkalmazás szükségességét. Kellemes böngészést!
        </Typography>
        <Typography variant="h3">AI asszisztensek:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : Jelenleg a legjobb AI asszisztens a piacon. Az OpenAI ChatGPT egy olyan beszélgetéses AI modell, amely képes
          emberihez hasonló szöveget generálni. Ideális cikkek tervezéséhez, ötletek megbeszéléséhez, visszajelzés
          kérésehez, tartalom összefoglalásához és hasonlókhoz. Az OpenAI képalkotó motora, a Dall-E, amelyhez a
          ChatGPT-n keresztül hozzáférhet, egyedi képeket képes generálni cikkekhez.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : A Perlexity.ai egy olyan AI asszisztens, amely multimédiás válaszokat nyújt és több alapvető AI modellt
          aggregál, mint az OpenAI ChatGPT vagy az Anthropic Claude. Sokféle mindennapi feladatra használható.
        </Typography>
        <Typography variant="h3">Írás:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : Egy német nyelvet támogató AI írási segéd, amely segít gyorsan vonzó tartalmat létrehozni. Használható
          cikkek tervezéséhez, címek generálásához és kreatív írások elkészítéséhez.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : Egy német nyelvet támogató szöveggeneráló AI, amely specializálódott a marketingtartalom létrehozásában.
          Segít vonzó cikkbevezetők, hirdetésszövegek és közösségi média bejegyzések írásában, amelyek az cikkekhez
          kapcsolódnak.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Egy AI által hajtott írás és parafrázis eszköz. Segít mondatok újraírásában az átláthatóság érdekében, a
          nyelvtan javításában és a cikkekben a plágium elkerülésében. Jól működik német nyelven.
        </Typography>
        <Typography variant="h3">Kép létrehozása:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Egy olyan AI program, amely kiváló minőségű, művészi képek létrehozására specializálódott. Ideális egyedi
          vizuális elemek és illusztrációk létrehozásához történetekhez és jellemzőkhöz. Jelenleg csak a{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          -on keresztül érhető el. Itt található egy útmutató, hogyan használhatja{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            itt
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Egy AI-vezérelt eszköz, amely valósághű képeket és művészetet generál. Hasznos vizuális történetmeséléshez
          és egyedi illusztrációk létrehozásához cikkekhez.
        </Typography>
        <Typography variant="h3">Fotószerkesztés:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Egy fotószerkesztő platform, amely AI-t használ haladó szerkesztési feladatokhoz. Javíthatja a cikkekben
          használt fényképek minőségét, beleértve a világítás, textúra és szín beállításokat.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : AI által hajtott fotójavító szoftvert kínál. Hasznos képek nagyításához minőségromlás nélkül,
          zajcsökkentéshez, és képek élesítéséhez digitális kiadványokhoz.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Egy AI-alapú képszerkesztő eszköz, amely automatikusan szerkesztheti a fényképeket, beállíthatja a
          világítást és a színeket, és alkalmazhat különféle effekteket, alkalmas a cikkekben lévő vizuális tartalom
          javítására.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Szakosodott a képek hátterének eltávolítására AI segítségével. Hasznos a cikkek gyors szerkesztéséhez,
          különösen amikor szükség van arra, hogy az alanyokat elkülönítsük hátterüktől a grafikus kompozíciókhoz.
        </Typography>
      </MainCard>
    </Text>
  )
}
