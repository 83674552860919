import { TranscriptionContext } from 'contexts/TranscriptionContext'
import { useContext } from 'react'
import { TranscriptionContextType } from 'types/transcription'

export const useTranscriptionContext = (): TranscriptionContextType => {
  const context = useContext(TranscriptionContext)

  if (context === undefined) {
    console.error('useTranscriptionContext must be used within a TranscriptionContextProvider')
  }

  return context
}
