import { MainCard } from 'components/ui-component/cards/MainCard'
import { Text } from 'components/ui-component/Text'

import 'react-chatbotify/dist/react-chatbotify.css'
import ChatBot, { BotOptionsContext, MessagesContext } from 'react-chatbotify'
import { Box, Button, CircularProgress, TextField, Typography, useTheme } from '@mui/material'
import { Spacer } from 'components/ui-component/Spacer'
import { useChatbot } from './useChatbot'
import { Loader } from 'components/ui-component/Loader'
import styled from '@emotion/styled'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'

export const ChatbotView = () => {
  const theme = useTheme()
  const {
    botOptions,
    setBotOptions,

    flow,

    messages,
    setMessages,

    articleUrl,
    articleUrlField,
    setArticleUrlField,

    isAnswerLoading,
    isChatbotLoading,

    onReloadChatbot,
  } = useChatbot({
    isDev: false,
  })

  return (
    <Wrapper>
      <Text>
        <MainCard title="Chatbot">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <TextField
              id="articleUrl"
              label={'Article url'}
              sx={{ flexGrow: 1 }}
              value={articleUrlField}
              onChange={(e) => setArticleUrlField(e.target.value)}
              helperText="To change the context add an article url and hit Reload. Leave empty for no cotext."
            />
            <Spacer h size={16} />
            <div>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'uppercase',
                  height: '56px',
                }}
                disabled={isChatbotLoading}
                onClick={onReloadChatbot}
              >
                {isChatbotLoading && (
                  <CircularProgress size={16} sx={{ color: theme.palette.grey[400], marginRight: '8px' }} />
                )}
                Reload
              </Button>
            </div>
          </Box>
          <Spacer v size={16} />
          {!isChatbotLoading ? (
            <>
              <Typography variant="bodysm">
                <i>
                  {articleUrl ? (
                    <>
                      You are currently in the <b>context</b> of this article:
                      <br />
                      <ExternalLink href={articleUrl} target="_blank">
                        {articleUrl}
                      </ExternalLink>
                    </>
                  ) : (
                    <>
                      You are currently in a <b>general context</b>.
                    </>
                  )}
                </i>
              </Typography>
              <Spacer v size={16} />
              <MessagesContext.Provider value={{ messages, setMessages }}>
                <BotOptionsContext.Provider value={{ botOptions, setBotOptions }}>
                  <Box
                    sx={{
                      '& .suggested-questions, & .rcb-chat-input': { pointerEvents: isAnswerLoading ? 'none' : 'auto' },
                    }}
                  >
                    <ChatBot flow={flow} options={botOptions} />
                  </Box>
                </BotOptionsContext.Provider>
              </MessagesContext.Provider>
            </>
          ) : (
            <Loader position="static" />
          )}
        </MainCard>
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  & .rcb-options-container {
    max - width: 100%;
  }

  & .rcb-view-history-container {
    display: none;
  }
`
