'use client'

import React, { createContext, useCallback, useEffect, useState } from 'react'
import { Loader } from 'components/ui-component/Loader'

// types
import { AuthContextType, InitialLoginContextProps } from 'types/auth'
import { IDProvider, UserSession } from 'shared'
import { axiosInstance } from 'utils/axios'
import { config } from 'config'
import { preserveRedirectUrl } from 'utils/url'
import { Sentry } from 'logger'

// constant
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  session: null,
}

// ==============================|| AUTH CONTEXT & PROVIDER ||============================== //
export const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [authState, setAuthState] = useState(initialState)

  const checkSession = useCallback((silently = false, callback?: (isLoggedIn: boolean) => void) => {
    const getSession = async () => {
      if (!silently) {
        setAuthState((authState) => ({ ...authState, isInitialized: false }))
      }

      try {
        const { data } = await axiosInstance.get<UserSession>('/auth/session') // maybe it should be moved to /api/ directory
        setAuthState((authState) => ({
          ...authState,
          session: { ...data },
          isInitialized: true,
          isLoggedIn: true,
        }))
        Sentry.setUser({
          id: data.hashedId,
        })
        callback && callback(true)
      } catch {
        setAuthState((authState) => ({ ...authState, isInitialized: true, isLoggedIn: false }))
        Sentry.setUser(null)
        callback && callback(false)
      }
    }
    getSession()
  }, [])

  useEffect(() => {
    checkSession()
  }, [checkSession])

  const login = (idp: IDProvider) =>
    (window.location.href = `${config.apiUrl}/auth/login/idp/${idp}?returnTo=${preserveRedirectUrl(config.selfUrl)}`)

  const logout = () => (window.location.href = `${config.apiUrl}/auth/logout?returnTo=${config.selfUrl}`)

  if (!authState.isInitialized) {
    return <Loader />
  }

  return <AuthContext.Provider value={{ ...authState, login, logout, checkSession }}>{children}</AuthContext.Provider>
}
