import { ReactNode, createContext, useCallback, useState } from 'react'
import { TranscriptionContextType } from 'types/transcription'

type TranscriptionProviderProps = {
  children: ReactNode
}

export const TranscriptionContext = createContext<TranscriptionContextType>({
  audioDuration: 0,
  setAudioDuration: () => {
    return
  },
  playerRef: () => {
    return
  },
  player: null,
  playerWrapperRef: () => {
    return
  },
  playerWrapper: null,
})

export const TranscriptionProvider = ({ children }: TranscriptionProviderProps) => {
  const [audioDuration, setAudioDuration] = useState<number>(0)
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null)
  const [audioWrapperElement, setAudioWrapperElement] = useState<HTMLDivElement | null>(null)
  const audioElementRef = useCallback((node: HTMLAudioElement | null) => setAudioElement(node), [])
  const audioWrapperElementRef = useCallback((node: HTMLDivElement | null) => setAudioWrapperElement(node), [])

  return (
    <TranscriptionContext.Provider
      value={{
        audioDuration,
        setAudioDuration,
        playerRef: audioElementRef,
        player: audioElement,
        playerWrapperRef: audioWrapperElementRef,
        playerWrapper: audioWrapperElement,
      }}
    >
      {children}
    </TranscriptionContext.Provider>
  )
}
