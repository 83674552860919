import { useEffect, useMemo, useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project imports
import { Transitions } from 'components/ui-component/extended/Transitions'

// assets
import { useTranslationContext } from 'hooks/useTranslationContext'
import { AppLanguages } from 'types/translation'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { useSearchParams } from 'react-router-dom'

// ==============================|| LOCALIZATION ||============================== //

export const LocalizationSection = () => {
  const { languages, visibleLanguages, onLanguageChange, currentLanguage } = useTranslationContext()
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<any>(null)

  // used only for testing
  const [searchParams] = useSearchParams()
  const [showAllLanguages, setShowAllLanguages] = useState(false)

  const currentLanguages = useMemo(
    () => (showAllLanguages ? languages : visibleLanguages),
    [showAllLanguages, languages, visibleLanguages],
  )

  useEffect(() => {
    setShowAllLanguages(Boolean(searchParams && searchParams.get('showLanguage')))
  }, [searchParams])

  const handleListItemClick = (language: AppLanguages) => {
    onLanguageChange(language)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <>
      <Box
        sx={{
          ml: 2,
          [theme.breakpoints.down('md')]: {
            ml: 1,
          },
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            width: {
              xs: '34px',
              sm: '34px',
              md: 'auto',
            },
            padding: {
              xs: '0',
              sm: '0',
              md: '0 8px',
            },
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="inherit"
        >
          <MaterialIcon fontSize="20px" name="language" />
          {!matchesXs && (
            <Box marginLeft="8px" textTransform="uppercase">
              {currentLanguage}
            </Box>
          )}
        </Avatar>
      </Box>

      <Popper
        placement={matchesXs ? 'bottom-start' : 'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [matchesXs ? 85 : 0, 20],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
              <Paper elevation={16}>
                {open && (
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      minWidth: 200,
                      maxWidth: 280,
                      bgcolor: theme.palette.background.paper,
                      borderRadius: '12px',
                      padding: '24px 0',
                      [theme.breakpoints.down('md')]: {
                        maxWidth: 250,
                      },
                    }}
                  >
                    {Object.keys(currentLanguages).map((id) => {
                      const language = currentLanguages[id]

                      return (
                        <ListItemButton
                          key={id}
                          onClick={() => handleListItemClick(id as AppLanguages)}
                          sx={{
                            padding: '0 24px 0px 52px',
                            position: 'relative',

                            '&:hover': {
                              background: theme.palette.blue[50],
                              color: theme.palette.grey[700],
                            },
                          }}
                        >
                          {id === currentLanguage && (
                            <MaterialIcon sx={{ position: 'absolute', left: '24px' }} name="done" />
                          )}
                          <ListItemText
                            primary={
                              <Grid container>
                                <Typography
                                  variant="bodymd"
                                  sx={{ fontWeight: id === currentLanguage ? '700' : '400' }}
                                >
                                  {language.label}
                                </Typography>
                                <Typography
                                  variant="bodymd"
                                  color={theme.palette.grey[400]}
                                  sx={{ fontWeight: id === currentLanguage ? '700' : '400', ml: '8px' }}
                                >
                                  {language.subLabel}
                                </Typography>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )
                    })}
                  </List>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
