export interface UserSession {
  email: string;
  name: string;
  id: string;
  hashedId: string;
  country: string;
  businessArea: string;
  restriction: AIForgeFeatures[];
  idProvider?: string;
}

export enum IDProvider {
  GOOGLE = 'google',
  OKTA = 'okta'
}

export enum AIForgeFeatures {
  SPEECH_TO_TEXT = 'speech-to-text',
  TITLE_GENERATOR = 'title-generator',
  ARTICLE_GENERATOR = 'article-generator',
  ARTICLE_SUMMARY_GENERATOR = 'article-summary-generator',
  TRANSLATOR = 'translator',
  CHATBOT = 'chatbot'
}
