import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField, styled, useTheme } from '@mui/material'
import { GenerateArticleButton } from './GenerateArticleButton'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import { Spacer } from 'components/ui-component/Spacer'
import { AI_MODEL_OPTIONS, GENERATE_FOR_OPTIONS, TitleFormValues } from 'views/TextToText/useTextGenerator'
import { FormattedMessage } from 'components/FormattedMessage'
import { useTranslation } from 'react-i18next'
import { StyledTextField, URL_REGEX } from './ArticleGeneratorForm'

type Props = {
  register: UseFormRegister<TitleFormValues>
  onSubmit: any
  isLoading: boolean
  control: Control<TitleFormValues>
  errors: FieldErrors<TitleFormValues>
  originalTextLength: number
}

export const TitleGeneratorForm = ({ register, onSubmit, isLoading, control, errors, originalTextLength }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <form onSubmit={onSubmit}>
      <Grid container rowSpacing="24px" columnSpacing="24px">
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="generate-for-select-label" sx={{ color: theme.palette.grey[700] }}>
              <FormattedMessage id="titleGenerator.generateArticleFor" />
            </InputLabel>
            <Controller
              name="publication"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="generate-for-select-label"
                  value={value || GENERATE_FOR_OPTIONS[0].value}
                  label={<FormattedMessage id="titleGenerator.generateArticleFor" />}
                  onChange={onChange}
                >
                  {GENERATE_FOR_OPTIONS.map(({ label, value, id }) => (
                    <MenuItem key={`generate_for_item_${id}`} value={value} sx={{ color: theme.palette.grey[700] }}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <StyledTextField
            fullWidth
            label={
              <>
                <FormattedMessage id="articleGenerator.sourceUrlLabel" /> (<FormattedMessage id="common.optional" />)
              </>
            }
            {...register('sourceUrl', { required: false, pattern: URL_REGEX })}
            error={!!errors.sourceUrl}
            helperText={
              errors.sourceUrl ? (
                <FormattedMessage id="articleGenerator.sourceUrlError" />
              ) : (
                <>
                  <FormattedMessage id="articleGenerator.sourceUrlDescription1" />
                  <br />
                  <FormattedMessage id="articleGenerator.sourceUrlDescription2" />
                </>
              )
            }
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model-select-label">
              <FormattedMessage id="common.chooseAIModel" />
            </InputLabel>
            <Controller
              name="version"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="model-select-label"
                  value={value}
                  label={<FormattedMessage id="common.chooseAIModel" />}
                  onChange={onChange}
                >
                  {AI_MODEL_OPTIONS.map(({ label, value, id }) => (
                    <MenuItem key={`model_item_${id}`} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextAreaWrapper>
            <Chip
              sx={{
                fontSize: '10px',
                lineHeight: '16px',
                textTransform: 'none',
                fontWeight: 700,
                color: theme.palette.grey[500],
                backgroundColor: theme.palette.grey[200],
                padding: '4px 0px',
                height: 'unset',
                border: `1px solid ${theme.palette.grey[500]}`,
                marginBottom: '-13px',
                marginRight: '8px',
                zIndex: '2',
              }}
              label={
                <>
                  <FormattedMessage id="common.characters" />: {originalTextLength}
                </>
              }
            />
            <TextField
              error={!!errors.originalText}
              helperText={!!errors.originalText && <FormattedMessage id="common.textAreaError" />}
              fullWidth
              multiline
              rows={12}
              label={<FormattedMessage id="common.pasteText" />}
              placeholder={t('common.pleaseProvideMeTextSoICcanDoMyMagic')}
              type="textarea"
              {...register('originalText', { required: false })}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                sx: {
                  '&::placeholder': {
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: theme.palette.grey[500],
                    paddingTop: '3px',
                    opacity: 1, // otherwise firefox shows a lighter color
                  },
                  '&:focus::placeholder': {
                    color: 'transparent',
                  },
                },
              }}
            />
          </TextAreaWrapper>
          <Spacer size={24} v />
          <GenerateArticleButton
            isLoading={isLoading}
            disabled={isLoading}
            buttonText={<FormattedMessage id="titleGenerator.generateTitles" />}
          />
        </Grid>
      </Grid>
    </form>
  )
}

const TextAreaWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
