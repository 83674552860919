// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewSk = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Prosím, prijmite a dodržiavajte nasledujúce pravidlá pre používanie umelej inteligencie v editoriálnych
          prácach. Základom nasledujúcich bodov sú{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            smernice AI Ringieru.
          </ExternalLink>
          <ol>
            <li>
              Aktívne podporujeme porozumenie a používanie nástrojov AI medzi našimi zamestnancami na optimalizáciu
              pracovných procesov.
            </li>
            <li>Používanie AI je vždy založené na zodpovednosti a etických princípoch.</li>
            <li>
              Transparentnosť je pre nás dôležitá: Jasne uvedieme použitie AI vo svojich správach, či už v textoch alebo
              podpisoch k obrázkom.
            </li>
            <li>Uistíme sa, že obsah vygenerovaný AI nikdy nie je publikovaný bez konečného preskúmania človekom.</li>
            <li>
              Dôsledne dodržiavame pravidlo, aby sme do verejných nástrojov AI nevkladali dôverné informácie, obchodné
              tajomstvá ani osobné údaje.
            </li>
            <li>
              Pri prepisovaní rozhovorov používame náš interný audio nástroj (Blisper) v AI Forge, nie služby AI od
              tretích strán.
            </li>
            <li>
              Nepoužívame AI nástroje na tvorbu novinových fotografií, ako je DALL·E 3 a Midjourney. Ich použitie je
              povolené pre ilustračné obrázky, ale len po konzultácii so supervízorom, oddelením úpravy fotografií alebo
              AI vedúcim redakcie.
            </li>
            <li>
              Pri citlivých témach, kde by AI mohla viesť k dezinformáciám alebo nesprávnemu vyhodnoteniu, sa vyhýbame
              jej použitiu.
            </li>
            <li>Ponúkame interné školenie o AI pre našich zamestnancov.</li>
            <li>
              Vzhľadom na rýchly vývoj v oblasti AI sa vždy udržiavame aktuálni a povzbudzujeme našich zamestnancov, aby
              hlásili potenciálne užitočné AI nástroje príslušnému kancelárii AI.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          Aktualizované k 24. februáru/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
