import { Trans, useTranslation } from 'react-i18next'
import { AnyObject } from 'shared'

type Props = {
  id: string
  values?: AnyObject
  components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement }
}

export const FormattedMessage = ({ id, values, components }: Props) => {
  const { t } = useTranslation()

  return components ? <Trans i18nKey={id} components={components} values={values} /> : (t(id, { ...values }) as any)
}
