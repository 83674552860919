'use client'

// material-ui
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  SwitchProps,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import styled from '@mui/system/styled'
import { deleteJob, downloadJobContent, fetchJob } from 'api/speechToText'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Spacer } from 'components/ui-component/Spacer'
import { getTimeFromSeconds } from 'utils/date/utils'
import { AudioPlayer } from 'components/AudioPlayer'
import { useTranscriptionContext } from 'hooks/useTranscriptionContext'
import { DateFormat, formatDate } from 'utils/date/formatDate'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TranscriptionActionsMenu } from './componenets/TranscriptionActionsMenu'
import { DeleteTranscriptionModal } from './componenets/DeleteTranscriptionModal'
import { Breadcrumbs } from 'components/ui-component/extended/Breadcrumbs'
import { CustomBreadcrumps } from 'types/custom-breadcrumps'
import { routes } from 'constants/routes'
import { useApp } from 'hooks/useApp'
import { ToolboxDownloadOptions } from 'types/common'
import { trackButtonClick, trackRating } from 'tracking/gtm'
import { getFullText } from 'utils/transcriptionContent'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { FormattedMessage } from 'components/FormattedMessage'
import { Job, TranscriptionContentSegment, GoogleAnalyticsContext } from 'shared'
import { Rating } from 'components/Rating'
import { RateModal, RateModalConfig } from 'components/RateModal'
import dayjs from 'dayjs'
import { MAX_DAYS_AUDIO_AVAILABILITY } from './SpeechToTextList'
import { CopyDownloadActions } from 'views/Translator/components/CopyDownloadActions'
import { LanguageSelector, StyledTab, getLangLabel } from 'views/Translator/components/LanguageSelector'
import { downloadTextTranslation, translateText } from 'api/translator'
import { debounce } from 'lodash-es'
import { TranslationLoader } from 'views/Translator/components/TranslationLoader'
import { useTranslation } from 'react-i18next'
import { FrontendGoogleAnalyticsClickAction } from 'types/analytics'

const COPY_TO_CLIPBOARD_TIMEOUT = 2000 // 2s

export const SpeechToTextItemView = () => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const params = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { session } = useAuth()
  const { openSnackbar } = useApp()
  const { t } = useTranslation()
  const { player } = useTranscriptionContext()
  const [job, setJob] = useState<(Job & { url: string }) | undefined>()
  const [showSpeakers, setShowSpeakers] = useState(false)
  const [showTimestamps, setShowTimestamps] = useState(false)
  const [highlightWords, setHighlightWords] = useState(false)
  const [copied, setCopied] = useState(false)
  const [showAllOptions, setShowAllOptions] = useState(false)
  const [isDeleteTranscriptionModalOpen, setIsDeleteTranscriptionModalOpen] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [rateModalConfig, setRateModalConfig] = useState<RateModalConfig | null>(null)
  const [feedbackSent, setFeedbackSent] = useState<{ [key: string]: boolean }>({})

  const onRateModalConfirm = (element: string) => {
    setFeedbackSent((prev) => ({ ...prev, [element]: true }))
  }

  const [translateView, setTranslateView] = useState(false)
  const [targetLanguage, setTargetLanguage] = useState('FR')
  const [detectedLang, setDetectedLang] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [resultValue, setResultValue] = useState('')
  const detectedLangLabel = getLangLabel(detectedLang, t) || '-'
  const translationRef = React.useRef<HTMLDivElement | null>(null)

  const intervalRef = React.useRef<NodeJS.Timeout | null>(null)
  const segments: TranscriptionContentSegment[] = useMemo(() => job?.content?.segments || [], [job])

  const customBreadcrumps: CustomBreadcrumps = useMemo(
    () => ({
      title: <>{routes.speechToText.title}</>,
      likns: [
        {
          title: <>{routes.speechToText.title}</>,
          url: routes.speechToText.url,
        },
      ],
      item: {
        title: job?.description,
      },
    }),
    [job],
  )

  const ACTION_BUTTONS_STYLING = {
    color: theme.palette.grey[700],
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'none',
    justifyContent: 'start',
    paddingRight: '0px',
  }

  const roundProbability = (probability: number) => {
    return Math.round(probability * 100)
  }

  const onDownload = (type: string) => {
    if (!job) {
      return
    }

    downloadJobContent(job.jobId, type).then((result) => {
      if (!result) {
        return
      }

      const href = URL.createObjectURL(result)
      const link = document.createElement('a')

      link.href = href
      link.download = `${job.jobId}.${type}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })

    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.DOWNLOAD,
      elementLabel: `Download as ${type.toUpperCase()}`,
    })
  }

  const onReplay = (segment: TranscriptionContentSegment) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    if (player) {
      const playTime = (segment.end - segment.start) * 1000
      player.currentTime = segment.start
      player.play()
      intervalRef.current = setTimeout(() => player.pause(), playTime)
    }
  }

  const onCloseDeleteTranscriptionModal = () => {
    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.CANCEL_DELETE,
      elementLabel: `No, cancel`,
    })
    setIsDeleteTranscriptionModalOpen(false)
  }

  const onDelete = async () => {
    if (job) {
      setIsDeleteLoading(true)
      const result = await deleteJob(job.jobId)
      if (result?.success) {
        openSnackbar({
          open: true,
          message: 'Transcription deleted successfully',
          variant: 'success',
        })
        trackButtonClick({
          page: pathname,
          hashedUserId: session?.hashedId,
          context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
          clickAction: FrontendGoogleAnalyticsClickAction.DELETE,
          elementLabel: `Yes, delete`,
        })
        setIsDeleteLoading(false)
        navigate(routes.speechToText.url)
      }
    }
  }

  const onCopyClick = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), COPY_TO_CLIPBOARD_TIMEOUT)
    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.COPY,
      elementLabel: `Copy complete text`,
    })
  }

  const onPlay = useCallback(() => {
    trackButtonClick({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.PLAY_AUDIO,
    })
  }, [pathname, session?.hashedId])

  const shouldShowPlayer = () => {
    if (!job) {
      return false
    }
    const remainingDays = dayjs(job?.createdAt).add(MAX_DAYS_AUDIO_AVAILABILITY, 'day').diff(new Date(), 'days')
    return remainingDays > 0
  }

  useEffect(() => {
    if (player) {
      player.addEventListener('play', onPlay)
    }

    return () => {
      if (player) {
        player.removeEventListener('play', onPlay)
      }
    }
  }, [player, onPlay])

  useEffect(() => {
    if (params.id && typeof params.id === 'string') {
      const fetchData = async () => {
        const data = await fetchJob(params.id as string)
        if (data) {
          setJob({
            ...data.job,
            content: data.content,
            url: data.url,
          })
        }
      }
      fetchData()
    }
  }, [params.id])

  useEffect(() => {
    if (job) {
      setShowSpeakers(job.config.speakerRecognition)
      setShowTimestamps(job.config.wordTimestamp)
      setHighlightWords(job.config.wordTimestamp)
    }
  }, [job])

  const translate = async ({ sourceValue, targetLanguage }: any) => {
    try {
      setIsLoading(true)
      const translation = await translateText({
        text: sourceValue,
        targetLanguage: targetLanguage === 'EN' ? 'EN-US' : targetLanguage,
      })
      setResultValue(translation.text)
      setDetectedLang(translation['detected_source_language'])
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const debouncedTranslate = useMemo(
    () =>
      debounce((data) => {
        translate(data)
      }, 200),
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    if (translateView) {
      debouncedTranslate({ sourceValue: getFullText(segments, false, false), targetLanguage })
    }
  }, [targetLanguage, debouncedTranslate, translateView, segments])

  const onResultDownload = async (type: ToolboxDownloadOptions) => {
    const result = await downloadTextTranslation(
      {
        text: getFullText(segments, false, false),
        targetLanguage: targetLanguage === 'EN' ? 'EN-US' : targetLanguage,
      },
      type,
    )

    createDownloadableFile(result, type)
  }

  const onSourceDownload = async (type: ToolboxDownloadOptions) => {
    const result = await downloadTextTranslation(
      {
        text: getFullText(segments, false, false),
      },
      type,
    )

    createDownloadableFile(result, type)
  }

  const createDownloadableFile = async (result: Blob | null, type: ToolboxDownloadOptions) => {
    if (!result) {
      return
    }

    const href = URL.createObjectURL(result)
    const link = document.createElement('a')

    link.href = href
    link.download = `translation.${type}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  const onJumpClick = () => {
    matchDownMd && translationRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Breadcrumbs custom={customBreadcrumps} />
      <MainCard
        sx={{
          '& .MuiCardContent-root ': { padding: '0 !important' },
          '& .MuiCardHeader-root': { paddingBottom: '0', border: '0', '& .MuiDivider-root': { display: 'block' } },
          '& .MuiDivider-root': { display: 'none' },
        }}
        title={
          <>
            <HeaderContainer>
              <div>
                <Typography variant="h3" color={theme.palette.grey[700]} sx={{ lineHeight: '28px', fontWeight: '700' }}>
                  {job?.description}
                </Typography>
                {job?.updatedAt && <TitleDate>{formatDate(job?.updatedAt, DateFormat.DEFAULT)}</TitleDate>}
              </div>
              {!matchDownMd && (
                <TranscriptionActionsMenu
                  onDelete={() => setIsDeleteTranscriptionModalOpen(true)}
                  onDownload={onDownload}
                  job={job}
                  translateView={translateView}
                />
              )}
            </HeaderContainer>
            {!matchDownMd && (
              <Divider
                sx={{
                  marginTop: { xs: 2.5, md: 3 },
                  marginBottom: { xs: 2.5, md: 3 },
                }}
              />
            )}
            {matchDownMd && showAllOptions && (
              <div>
                <Divider
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                />
                {!translateView && (
                  <Button
                    variant="text"
                    sx={ACTION_BUTTONS_STYLING}
                    disabled={!job}
                    startIcon={<ButtonIcon className="material-symbols-outlined">download</ButtonIcon>}
                    onClick={() => onDownload(ToolboxDownloadOptions.TXT)}
                  >
                    <FormattedMessage id="audioToText.downloadAs" /> TXT
                  </Button>
                )}
                {!translateView && (
                  <Button
                    variant="text"
                    sx={ACTION_BUTTONS_STYLING}
                    disabled={!job}
                    startIcon={<ButtonIcon className="material-symbols-outlined">download</ButtonIcon>}
                    onClick={() => onDownload(ToolboxDownloadOptions.PDF)}
                  >
                    <FormattedMessage id="audioToText.downloadAs" /> PDF
                  </Button>
                )}
                {!translateView && (
                  <Button
                    variant="text"
                    sx={ACTION_BUTTONS_STYLING}
                    disabled={!job}
                    startIcon={<ButtonIcon className="material-symbols-outlined">download</ButtonIcon>}
                    onClick={() => onDownload(ToolboxDownloadOptions.SRT)}
                  >
                    <FormattedMessage id="audioToText.downloadAs" /> SRT
                  </Button>
                )}
                <Button
                  variant="text"
                  sx={ACTION_BUTTONS_STYLING}
                  disabled={!job}
                  startIcon={<ButtonIcon className="material-symbols-outlined">download</ButtonIcon>}
                  onClick={() => onDownload(ToolboxDownloadOptions.VTT)}
                >
                  <FormattedMessage id="audioToText.downloadAs" /> VTT
                </Button>
                <Divider
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                />
                <Button
                  variant="text"
                  sx={{
                    color: theme.palette.grey[700],
                    fontSize: '14px',
                    fontWeight: '400',
                    textTransform: 'none',
                    justifyContent: 'start',
                    paddingRight: '0px',
                  }}
                  startIcon={<ButtonIcon className="material-symbols-outlined">delete</ButtonIcon>}
                  onClick={() => setIsDeleteTranscriptionModalOpen(true)}
                >
                  <FormattedMessage id="audioToText.deleteTranscription" />
                </Button>
              </div>
            )}
            {matchDownMd && (
              <MobileActionsContainer>
                <Button
                  sx={{ textTransform: 'uppercase', width: '100%', margin: showAllOptions ? '0 0 16px 0' : '16px 0' }}
                  endIcon={
                    <span className="material-symbols-outlined">
                      {showAllOptions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    </span>
                  }
                  onClick={() => setShowAllOptions(!showAllOptions)}
                  variant="contained"
                  size="small"
                >
                  <FormattedMessage id="common.actions" />
                </Button>
              </MobileActionsContainer>
            )}
            {matchDownMd && <Spacer v size={16} />}
            <Controls
              sx={
                matchDownMd
                  ? {
                      display: 'grid',
                      gap: 1,
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      maxWidth: '1000px',
                    }
                  : { display: 'flex', gap: '24px', marginLeft: '12px' }
              }
            >
              <FormControlLabel
                control={<StyledSwitch onChange={() => setShowSpeakers(!showSpeakers)} checked={showSpeakers} />}
                label={
                  matchDownMd ? (
                    <FormattedMessage id="audioToText.speakers" />
                  ) : (
                    <FormattedMessage id="audioToText.showSpeakers" />
                  )
                }
                sx={matchDownMd ? { marginRight: '0', marginLeft: '0' } : {}}
                componentsProps={{ typography: { variant: 'h6' } }}
              />
              <FormControlLabel
                control={<StyledSwitch onChange={() => setShowTimestamps(!showTimestamps)} checked={showTimestamps} />}
                label={
                  matchDownMd ? (
                    <FormattedMessage id="audioToText.timestamps" />
                  ) : (
                    <FormattedMessage id="audioToText.showTimestamps" />
                  )
                }
                sx={matchDownMd ? { marginRight: '0', marginLeft: '0' } : {}}
                componentsProps={{ typography: { variant: 'h6' } }}
              />
              <FormControlLabel
                control={<StyledSwitch onChange={() => setHighlightWords(!highlightWords)} checked={highlightWords} />}
                label={
                  matchDownMd ? (
                    <FormattedMessage id="audioToText.wordsWithLowConfidence" />
                  ) : (
                    <FormattedMessage id="audioToText.highlightWordsWithALowConfidenceScore" />
                  )
                }
                sx={matchDownMd ? { marginRight: '0', marginLeft: '0' } : {}}
                componentsProps={{ typography: { variant: 'h6' } }}
              />
              <FormControlLabel
                control={<StyledSwitch onChange={() => setTranslateView(!translateView)} checked={translateView} />}
                label={
                  matchDownMd ? (
                    <FormattedMessage id="audioToText.translation" />
                  ) : (
                    <FormattedMessage id="audioToText.translationOptions" />
                  )
                }
                sx={matchDownMd ? { marginRight: '0', marginLeft: '0' } : {}}
                componentsProps={{ typography: { variant: 'h6' } }}
              />
              {!matchDownMd && !translateView && (
                <CopyToClipboard text={getFullText(segments, showSpeakers, showTimestamps)}>
                  <Button
                    variant="text"
                    sx={{
                      color: copied ? theme.palette.success.dark : theme.palette.grey[700],
                      fontSize: '14px',
                      fontWeight: '400',
                      textTransform: 'none',
                      justifyContent: 'start',
                      padding: '0px !important',
                      ...(!matchDownMd ? { marginRight: '8px', minWidth: '156px' } : {}),
                      '&:focus': {
                        border: 'none',
                      },
                    }}
                    startIcon={
                      <ButtonIcon className="material-symbols-outlined">
                        {copied ? 'check_circle' : 'content_copy'}
                      </ButtonIcon>
                    }
                    onClick={onCopyClick}
                  >
                    {copied ? (
                      <FormattedMessage id="common.copied" />
                    ) : matchDownMd ? (
                      <FormattedMessage id="audioToText.copyText" />
                    ) : (
                      <FormattedMessage id="audioToText.copyCompleteText" />
                    )}
                  </Button>
                </CopyToClipboard>
              )}
            </Controls>
            <Divider
              sx={{
                margin: '24px -20px 0px -20px',
              }}
            />
            <Grid container sx={{ height: '100%' }}>
              {translateView && (
                <>
                  {!matchDownMd && (
                    <Grid item md={6} xs={6}>
                      <FlexContainer
                        sx={{
                          borderBottom: `1px solid ${theme.palette.grey[300]}`,
                          justifyContent: 'flex-start',
                          paddingBottom: matchDownMd ? '20px' : 0,
                        }}
                      >
                        <Typography
                          variant="bodymd"
                          sx={{ lineHeight: '20px', color: theme.palette.grey[700], fontWeight: 700 }}
                        >
                          <FormattedMessage id="translator.translateFrom" />
                        </Typography>
                        <Tabs
                          sx={{
                            '& .MuiTabs-indicator': {
                              backgroundColor: theme.palette.blue[500],
                            },
                            '& .MuiTabs-flexContainer': {
                              borderBottom: 'none',
                            },
                          }}
                          value={detectedLangLabel}
                        >
                          <StyledTab
                            sx={{
                              '&.Mui-selected': { color: theme.palette.blue[500] },
                              padding: '20px 8px',
                              cursor: 'default',
                            }}
                            disableRipple
                            label={detectedLangLabel}
                            value={detectedLangLabel}
                          />
                        </Tabs>
                      </FlexContainer>
                    </Grid>
                  )}
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={
                      matchDownMd
                        ? {
                            marginTop: '8px',
                            paddingTop: '24px',
                            borderBottom: `1px solid ${theme.palette.grey[300]}`,
                          }
                        : {}
                    }
                  >
                    {matchDownMd && (
                      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: '8px' }}>
                        <FormattedMessage id="translator.translateTranscribed" />
                      </Typography>
                    )}
                    <MobileLanguageSelectorContainer>
                      <LanguageSelector onChange={setTargetLanguage} helperText />
                      {matchDownMd && (
                        <Button
                          variant="text"
                          sx={{ textTransform: 'uppercase', fontSize: '12px !important', padding: '4px !important' }}
                          onClick={onJumpClick}
                        >
                          <FormattedMessage id="translator.jumpToTranslation" />
                        </Button>
                      )}
                    </MobileLanguageSelectorContainer>
                    {matchDownMd && <Spacer v size={8} />}
                  </Grid>
                </>
              )}

              <Grid
                item
                md={translateView ? 6 : 12}
                xs={12}
                sx={{ padding: matchDownMd ? '20px 0' : '20px 40px 20px 0px' }}
              >
                {translateView && (
                  <CopyDownloadActions
                    text={getFullText(segments, false, false)}
                    onDownload={onSourceDownload}
                    isLoading={isLoading}
                    original={true}
                  />
                )}
                {segments.map((segment, topIndex) => (
                  <TranscriptionParagraph key={`p-${topIndex}`}>
                    <Typography variant="bodybg" onClick={() => onReplay(segment)}>
                      {showSpeakers && <Speaker>{`${segment.speaker}: `}</Speaker>}
                      <Typography
                        title={`Play starting at ${getTimeFromSeconds(segment.start)}`}
                        variant="bodybg"
                        sx={{
                          cursor: 'pointer',
                          padding: '2px',
                          ':hover': {
                            background: theme.palette.purple[50],
                            borderRadius: '4px',
                          },
                        }}
                      >
                        {showTimestamps && <Timestamp>{`(${getTimeFromSeconds(segment.start)})`}</Timestamp>}
                        {(segment.words as any[]).map((item, index) => (
                          <>
                            <span> </span>
                            <Word
                              className="word"
                              key={`word-${topIndex}-${index}`}
                              probability={highlightWords ? roundProbability(item.probability) : undefined}
                            >
                              {item.text.trim()}
                            </Word>
                          </>
                        ))}
                      </Typography>
                    </Typography>
                    <Spacer v size={16} />
                  </TranscriptionParagraph>
                ))}
                <Spacer v size={16} />
                <Rating
                  title={t('audioToText.rateTranscription')}
                  feedbackKey="transcription"
                  onRatingClick={setRateModalConfig}
                  feedbackSent={feedbackSent.transcription || false}
                />
              </Grid>
              {translateView && (
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    padding: !matchDownMd ? '20px 20px 20px 40px' : '20px',
                    backgroundColor: theme.palette.grey[50],
                    scrollMarginTop: '60px',
                    margin: matchDownMd ? '0 -20px 0 -20px' : '0',
                    flexBasis: matchDownMd ? 'auto !important' : '100%',
                    maxWidth: matchDownMd ? 'calc(100% + 40px) !important' : '100%',
                    width: matchDownMd ? 'calc(100% + 40px) !important' : '100%',
                  }}
                  ref={translationRef}
                >
                  <CopyDownloadActions text={resultValue} onDownload={onResultDownload} isLoading={isLoading} />
                  {isLoading ? (
                    <TranslationLoader />
                  ) : resultValue ? (
                    <>
                      <Typography variant="bodybg" sx={{ paddingTop: '16px' }}>
                        {resultValue}
                      </Typography>
                      <Spacer v size={20} />
                      <Rating
                        title={t('translator.rate')}
                        feedbackKey="translation"
                        onRatingClick={setRateModalConfig}
                        feedbackSent={feedbackSent.translation || false}
                      />
                    </>
                  ) : null}
                </Grid>
              )}
            </Grid>
          </>
        }
      >
        {shouldShowPlayer() && <AudioPlayer audioSrc={job?.url} />}
        <DeleteTranscriptionModal
          isOpen={isDeleteTranscriptionModalOpen}
          onClose={() => onCloseDeleteTranscriptionModal()}
          onConfirm={() => onDelete()}
          isLoading={isDeleteLoading}
        />
        {rateModalConfig && (
          <RateModal
            isOpen={Boolean(rateModalConfig)}
            onClose={() => setRateModalConfig(null)}
            config={rateModalConfig}
            onConfirm={onRateModalConfirm}
            onTrack={(rating, feedbackText) =>
              trackRating({
                page: pathname,
                hashedUserId: session?.hashedId,
                elementLabel: rateModalConfig.title,
                context: GoogleAnalyticsContext.AUDIO_TO_TEXT,
                rating,
                feedbackText,
                jobId: job?.jobId,
              })
            }
          />
        )}
      </MainCard>
      {!matchDownMd && <Spacer v size={34} />}
    </>
  )
}

// Move to new component when design is ready
const TranscriptionParagraph = styled('div')({
  p: {
    '&:hover': {
      '.word': {
        background: '#d3d3d3', // will change
        cursor: 'pointer',
        '&:first-child': {
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
        },
        '&:last-child': {
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        },
      },
    },
  },
})

const Word = styled('span')(({ theme, probability }: { theme?: Theme; probability?: number }) => ({
  background: probability && probability < 35 ? theme?.palette.orange.main : 'transparent',
  fontSize: '16px',
  lineHeight: '28px',
}))

const TitleDate = styled('div')({
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '28px',
})

const Controls = styled('div')({})

const Speaker = styled('b')({
  color: '#673AB7',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '900',
  cursor: 'default',
})

const Timestamp = styled(Speaker)({
  fontSize: '12px',
  lineHeight: '26px',
})

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonIcon = styled('span')`
  font-size: 24px;
  margin-left: -4px;
`

const MobileActionsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -10px;
`

const FlexContainer = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
`

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginRight: '8px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#DEF3FB',
        border: '1px solid #B9E6F7',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#009FE3',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#EEEEEE',
    opacity: 1,
  },
}))

const MobileLanguageSelectorContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
