import { useTranslationContext } from 'hooks/useTranslationContext'
import { AppLanguages } from 'types/translation'
import { AboutViewDe } from './de'
import { AboutViewEn } from './en'
import { AboutViewBg } from './bg'
import { AboutViewFr } from './fr'
import { AboutViewHu } from './hu'
import { AboutViewRo } from './ro'
import { AboutViewPl } from './pl'
import { AboutViewSk } from './sk'
import { AboutViewSr } from './sr'

export const AboutView = () => {
  const { currentLanguage } = useTranslationContext()

  switch (currentLanguage) {
    case AppLanguages.BULGARIAN: {
      return <AboutViewBg />
    }
    case AppLanguages.FRENCH: {
      return <AboutViewFr />
    }
    case AppLanguages.GERMAN: {
      return <AboutViewDe />
    }
    case AppLanguages.HUNGARIAN: {
      return <AboutViewHu />
    }
    case AppLanguages.POLISH: {
      return <AboutViewPl />
    }
    case AppLanguages.ROMANIAN: {
      return <AboutViewRo />
    }
    case AppLanguages.SERBIAN: {
      return <AboutViewSr />
    }
    case AppLanguages.SLOVAKIAN: {
      return <AboutViewSk />
    }

    default: {
      return <AboutViewEn />
    }
  }
}
