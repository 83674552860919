// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewPl = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Prosimy o przestrzeganie poniższych zasad dotyczących korzystania ze sztucznej inteligencji w pracy
          redakcyjnej. Podstawą dla poniższych punktów są{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            Wytyczne dotyczące sztucznej inteligencji Ringiera.
          </ExternalLink>
          <ol>
            <li>
              Aktywnie promujemy zrozumienie i wykorzystanie narzędzi sztucznej inteligencji wśród naszych pracowników w
              celu optymalizacji procesów pracy.
            </li>
            <li>Nasz sposób korzystania z AI zawsze opiera się na odpowiedzialności i zasadach etycznych.</li>
            <li>
              Transparentność jest dla nas ważna: Wyraźnie informujemy o korzystaniu z AI w naszych raportach, zarówno w
              tekście, jak i podpisach pod obrazami.
            </li>
            <li>
              Upewniamy się, że treści generowane przez AI nigdy nie były publikowane bez ostatecznej weryfikacji przez
              człowieka.
            </li>
            <li>
              Ściśle przestrzegamy zasady niepodawania poufnych informacji, tajemnic handlowych ani danych osobowych do
              publicznych narzędzi AI.
            </li>
            <li>
              Do transkrypcji wywiadów używamy naszego wewnętrznego narzędzia audio (Blisper) w AI Forge, a nie usług AI
              firm trzecich.
            </li>
            <li>
              Nie korzystamy z narzędzi AI do obróbki zdjęć, takich jak DALL·E 3 i Midjourney, do tworzenia zdjęć
              wiadomości. Ich użycie jest dozwolone tylko dla ilustracji, ale wyłącznie po konsultacji z przełożonym,
              działem obróbki zdjęć lub kierownictwem redakcyjnym AI.
            </li>
            <li>
              W przypadku tematów wrażliwych, gdzie użycie AI mogłoby prowadzić do dezinformacji lub błędnej
              interpretacji, powstrzymujemy się od jego używania.
            </li>
            <li>Oferujemy wewnętrzne szkolenia z zakresu AI dla naszych pracowników.</li>
            <li>
              Biorąc pod uwagę szybki rozwój w dziedzinie AI, zawsze pozostajemy na bieżąco i zachęcamy naszych
              pracowników do zgłaszania potencjalnie przydatnych narzędzi AI do odpowiedniego biura ds. AI.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          Na dzień 24 lutego/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
