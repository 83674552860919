import { Button, Chip, Radio, Rating, Typography, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { RotatingIcon } from './RotatingIcon'
import { Spacer } from 'components/ui-component/Spacer'
import { Dispatch, ReactNode, useState } from 'react'
import Lottie from 'lottie-react'
import starsBluePurple from 'assets/lotties/stars-bluepurple.json'
import { FormattedMessage } from 'components/FormattedMessage'
import { RateModalConfig } from 'components/RateModal'

type Props = {
  title: string | ReactNode
  content: string[]
  onRatingClick?: Dispatch<RateModalConfig>
  onRegenerateClick?: () => void
  onSelect?: (selectedIndex: number) => void
  selectedIndex?: number
  isLoading: boolean
  feedbackSent?: boolean
  feedbackKey?: string
  chipLabel?: string
  showActions?: boolean
  plugin?: boolean
}

export const MultiResult = ({
  title,
  content,
  onRatingClick,
  onRegenerateClick,
  onSelect,
  selectedIndex,
  isLoading,
  feedbackSent,
  feedbackKey,
  chipLabel,
  showActions = true,
  plugin = false,
}: Props) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [ratingValue, setRatingValue] = useState(0)
  return (
    <Wrapper $isMobile={matchDownMd}>
      <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}{' '}
        <Chip
          sx={{
            fontSize: '10px',
            lineHeight: '16px',
            textTransform: 'none',
            fontWeight: 700,
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
            padding: '4px 0px',
            marginLeft: '8px',
            height: 'unset',
          }}
          label={chipLabel}
        />
      </Typography>
      <Spacer v size={4} />
      {content.map((option, idx) => (
        <OptionWrapper
          key={`${title}_${option}_${idx}`}
          onClick={() => onSelect && onSelect(idx)}
          selected={selectedIndex === idx && plugin}
        >
          <Option>
            <Radio size="medium" checked={selectedIndex === idx} />
            <Typography variant="h6">{option}</Typography>
          </Option>
          <Chip
            sx={{
              fontSize: '10px',
              lineHeight: '16px',
              textTransform: 'none',
              fontWeight: 700,
              color: theme.palette.grey[500],
              backgroundColor: theme.palette.grey[200],
              padding: '4px 0px',
              marginLeft: '8px',
              height: 'unset',
            }}
            label={option.length}
          />
        </OptionWrapper>
      ))}
      {showActions && (
        <ActionsContainer>
          <Button
            variant="text"
            sx={{
              textTransform: 'uppercase',
              color: theme.palette.blue[500],
              fontSize: '12px !important',
              lineHeight: '20px',
            }}
            startIcon={
              <RotatingIcon className="material-symbols-outlined" $enabled={isLoading}>
                autorenew
              </RotatingIcon>
            }
            onClick={onRegenerateClick}
            disabled={isLoading}
          >
            <FormattedMessage id="common.regenerate" />
          </Button>
          {feedbackSent ? (
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, color: theme.palette.purple[500], textAlign: matchDownMd ? 'center' : 'left' }}
            >
              {feedbackSent && <Stars $matchDownMd={matchDownMd} animationData={starsBluePurple} loop={true} />}
              {matchDownMd ? (
                <FormattedMessage id="rating.thanksForRating" />
              ) : (
                <FormattedMessage id="rating.thanksForYourValuableFeedback" />
              )}
            </Typography>
          ) : !isLoading ? (
            <RatingContainer>
              {matchDownMd ? (
                <FormattedMessage id="rating.rate" />
              ) : (
                <FormattedMessage id="textToTextResult.rateText" />
              )}{' '}
              <Rating
                sx={{
                  color: theme.palette.purple[500],
                  '& .MuiRating-iconEmpty': { color: theme.palette.purple[500] },
                }}
                size={matchDownMd ? 'small' : 'medium'}
                value={ratingValue}
                onChange={(e, value) => {
                  onRatingClick &&
                    feedbackKey &&
                    onRatingClick({
                      title: feedbackKey,
                      defaultValue: `${value}`,
                    })
                  setRatingValue(0)
                }}
              />
            </RatingContainer>
          ) : null}
        </ActionsContainer>
      )}
    </Wrapper>
  )
}

const RatingContainer = styled('div')`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.palette.purple[500]};
  font-size: 12px;
  line-height: 20px;
`

const ActionsContainer = styled('div')`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
`

const Wrapper = styled('div')<{ $isMobile: boolean }>`
  max-width: ${({ $isMobile }) => ($isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`

const OptionWrapper = styled('div')<{ selected: boolean }>`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 8px;
  background-color: ${({ theme, selected }) => (!selected ? theme.palette.grey[50] : theme.palette.blue[200])};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blue[50]};
  }
`

const Option = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const Stars = styled(Lottie)<{ $matchDownMd: boolean }>`
  position: absolute;
  margin-top: ${({ $matchDownMd }) => ($matchDownMd ? '-30px' : '-50px')};
  margin-left: ${({ $matchDownMd }) => ($matchDownMd ? '0px' : '50px')};
  height: 120px;
  width: 120px;
`
