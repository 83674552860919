import { Grid } from '@mui/material'
import { QuestionWidget } from './components/QuestionWidget'
import { BlickWidgetsProvider } from './context'

export const BlickWidgets = () => {
  return (
    <Grid container columnSpacing="24px">
      <Grid item md={6} sm={12} xs={12}>
        <BlickWidgetsProvider widgetId="dailyBriefing">
          <QuestionWidget title="Daily Briefing" />
        </BlickWidgetsProvider>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <BlickWidgetsProvider widgetId="articleQuestion">
          <QuestionWidget title="Article" />
        </BlickWidgetsProvider>
      </Grid>
    </Grid>
  )
}
