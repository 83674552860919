// project imports
import { Theme } from '@mui/material/styles'
import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { LinkProps } from '@mui/material/Link'
import { preserveUserId } from 'utils/url'
import { radioClasses } from '@mui/material'

export function componentStyleOverrides(theme: Theme, borderRadius: number, outlinedFilled: boolean) {
  const mode = theme.palette.mode
  const bgColor = mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50]
  const menuSelectedBack = mode === 'dark' ? theme.palette.blue[500] + 15 : theme.palette.blue[200]
  const menuSelected = mode === 'dark' ? theme.palette.blue[500] : theme.palette.blue[600]

  const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
  >((props, ref) => {
    const { href, ...other } = props
    const hrefWithUserId = preserveUserId(href as string)
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={hrefWithUserId} {...other} />
  })
  LinkBehavior.displayName = 'LinkBehavior'

  return {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
          lineHeight: '20px',
          '&.MuiButton-sizeMedium': {
            padding: '8px 12px',
            fontSize: '14px',
            letterSpacing: '0.28px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '0px 4px',
            fontSize: '12px',
            letterSpacing: '0.24px',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          boxShadow: 'none',
          '&:has(.MuiCircularProgress-root)': {
            pointerEvents: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.blue[500],
          border: `2px solid ${theme.palette.blue[500]}`,
          color: '#FFFFFF',
          '&.MuiButton-sizeMedium': {
            padding: '10px 14px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '2px 6px',
          },
          '&:hover': {
            backgroundColor: theme.palette.blue[800],
            border: `2px solid ${theme.palette.blue[800]}`,
          },
          '&:active': {
            backgroundColor: theme.palette.blue[500],
            border: `2px solid ${theme.palette.blue[500]}`,
          },
          '&:focus': {
            backgroundColor: theme.palette.blue[500],
            border: `2px solid ${theme.palette.grey[700]}`,
          },
          '&.Mui-disabled': {
            border: `2px solid ${theme.palette.grey[300]}`,
            background: theme.palette.grey[300],
          },
        },
        outlinedPrimary: {
          '&.MuiButton-sizeMedium': {
            padding: '10px 14px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '2px 6px',
          },
          border: `1px solid ${theme.palette.blue[500]}`,
          color: theme.palette.blue[500],
          '&:hover': {
            border: `1px solid ${theme.palette.blue[800]}`,
            color: theme.palette.blue[800],
            backgroundColor: 'transparent',
          },
          '&:active': {
            border: `1px solid ${theme.palette.blue[500]}`,
            color: theme.palette.blue[500],
          },
          '&:focus': {
            border: `2px solid ${theme.palette.blue[500]}`,
            color: theme.palette.blue[500],
            '&.MuiButton-sizeMedium': {
              padding: '9px 13px',
            },
            '&.MuiButton-sizeSmall': {
              padding: '1px 5px',
            },
          },
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.grey[400]}`,
            color: theme.palette.grey[400],
          },
        },
        textPrimary: {
          border: `2px solid transparent`,

          '&.MuiButton-sizeMedium': {
            padding: '12px 8px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '4px',
          },
          color: theme.palette.blue[500],
          '&:hover': {
            color: theme.palette.blue[800],
            backgroundColor: 'transparent',
          },
          '&:active': {
            color: theme.palette.blue[500],
          },
          '&:focus': {
            border: `2px solid ${theme.palette.blue[500]}`,
            '&.MuiButton-sizeMedium': {
              padding: '12px 8px',
            },
            '&.MuiButton-sizeSmall': {
              padding: '4px',
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlined: {
          border: '1px dashed',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '14px',
          paddingRight: '14px',
          '&.Mui-selected': {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            '&:hover': {
              backgroundColor: menuSelectedBack,
            },
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
          '&:hover': {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.palette.text.dark,
          '&::placeholder': {
            color: theme.palette.text.secondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: outlinedFilled ? bgColor : 'transparent',
          borderRadius: `${borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'dark' ? theme.palette.text.primary + 28 : theme.palette.grey[400],
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.blue[50],
          },
          '&.MuiInputBase-multiline': {
            padding: '12px 16px',
          },
        },
        input: {
          fontWeight: 500,
          background: outlinedFilled ? bgColor : 'transparent',
          borderRadius: `${borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: mode === 'dark' ? theme.palette.text.primary + 50 : theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: '4px',
        },
        valueLabel: {
          color: mode === 'dark' ? theme.palette.blue[500] : theme.palette.blue[50],
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            background: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.purple[200],
            borderRadius: 4,
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color: mode === 'dark' ? theme.palette.text.primary + 80 : theme.palette.purple[200],
            },
          },
        },
        popper: {
          borderRadius: `${borderRadius}px`,
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[300],
          opacity: mode === 'dark' ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: mode === 'dark' ? theme.palette.purple[500] : theme.palette.blue[500],
          background: mode === 'dark' ? theme.palette.dark.main : theme.palette.blue[50],
          '&:hover': {
            background: theme.palette.blue[200],
            color: theme.palette.blue[800],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: '16px',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: mode === 'dark' ? theme.palette.dark[900] : theme.palette.blue[50],
          '& .MuiTabs-flexContainer': {
            borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.blue[50],
          },
          '& .MuiTab-root': {
            color: mode === 'dark' ? theme.palette.text.secondary : theme.palette.grey[900],
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.blue[600],
          },
          '& .Mui-selected': {
            color: theme.palette.blue[600],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.grey[200],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: mode === 'dark' ? theme.palette.text.primary + 15 : theme.palette.grey[200],
          '&.MuiTableCell-head': {
            fontSize: '0.875rem',
            color: mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[900],
            fontWeight: 500,
          },
        },
      },
    },
    MuiDateTimePickerToolbar: {
      styleOverrides: {
        timeDigitsContainer: {
          alignItems: 'center',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary,
          padding: '8px',
          lineHeight: '20px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: '3px',
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        rowHeight: 54,
      },
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiFormControl-root>.MuiInputBase-root': {
            backgroundColor: theme.palette.background.default + ' !important',
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.divider + 20 + ' !important'
                : theme.palette.divider + ' !important',
          },
        },
        row: {
          borderBottom: `1px solid ${mode === 'dark' ? theme.palette.divider + 20 : theme.palette.divider}`,
        },
        columnHeader: {
          paddingLeft: 24,
          paddingRight: 24,
          borderBottom: `1px solid ${mode === 'dark' ? theme.palette.divider + 20 : theme.palette.divider}`,
        },
        columnHeaderCheckbox: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        cellCheckbox: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        cell: {
          border: 'none',
          paddingLeft: 24,
          paddingRight: 24,
          '&.MuiDataGrid-cell--withRenderer > div ': {
            ...(theme.palette.mode === 'dark' && {
              color: theme.palette.grey[50],
            }),
            ' > .high': {
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.success.dark : theme.palette.success.light,
            },
            '& > .medium': {
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.light,
            },
            '& > .low': {
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light,
            },
          },
        },
        columnsContainer: {
          borderColor: mode === 'dark' ? theme.palette.divider + 20 : theme.palette.divider,
        },
        columnSeparator: {
          borderColor: mode === 'dark' ? theme.palette.divider + 20 : theme.palette.divider,
        },
        withBorderColor: {
          borderColor: mode === 'dark' ? theme.palette.divider + 20 : theme.palette.divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.13)',
            '& .MuiList-root': {
              padding: '0',
              '& .MuiButtonBase-root': {
                padding: '16px',
              },
            },
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            backgroundColor: theme.palette.grey[50],
            borderRadius: '25px',
          },
          '& .MuiPaginationItem-root': {
            color: theme.palette.blue[500],
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: theme.palette.blue[500],
              '&:hover': {
                backgroundColor: theme.palette.blue[600],
              },
            },
          },
          '& .MuiPaginationItem-previousNext': {
            borderRadius: '100%',
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.grey[800],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          [`&.${radioClasses.checked}`]: {
            color: theme.palette.blue[500],
          },
        },
      },
    },
  }
}
