import { Box, CircularProgress, Typography, styled, useTheme } from '@mui/material'
import { ThemeCustomization } from 'themes'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { Rating } from 'components/Rating'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { RateModal, RateModalConfig } from 'components/RateModal'
import { GoogleAnalyticsContext } from 'shared'
import { Questions } from './Questions'
import { useApp } from 'hooks/useApp'
import { trackRating } from 'tracking/gtm'
import { getQuestions } from 'api/chatbot'
import { AxiosError } from 'axios'
import { Spacer } from 'components/ui-component/Spacer'
import { ChatbotAnswerContentItem, ChatbotLinks, ChatQuestionItem } from 'types/chatbot'
import { ReferenceLink } from 'views/BlickWidgets/components/ReferenceLink'

type ErrorMessageProps = {
  status?: number
  message: string
}

export const ErrorMessage = ({ status, message }: ErrorMessageProps) => {
  const theme = useTheme()

  return (
    <ThemeCustomization>
      <MessageWrapper sx={{ background: theme.palette.error.main }}>
        Error! Please try again or contact the adminstrator!
      </MessageWrapper>
      <MessageWrapper sx={{ background: theme.palette.error.main }}>
        {status} {message}
      </MessageWrapper>
    </ThemeCustomization>
  )
}

type HistoryMessageProps = {
  text: string
  onRating: Dispatch<SetStateAction<RateModalConfig | null>>
}

export const HistoryMessage = ({ text, onRating }: HistoryMessageProps) => {
  return (
    <>
      <MessageWrapper>{text}</MessageWrapper>
      <RatingContainer>
        <Rating
          onRatingClick={onRating}
          feedbackSent={false}
          title="Rate answer"
          sx={{ fontSize: '10px' }}
          ratingSx={{ fontSize: '18px' }}
          noReset
        />
      </RatingContainer>
    </>
  )
}

type AnswerMessageProps = {
  content?: ChatbotAnswerContentItem[]
  links?: ChatbotLinks
  rawJson: string
  isDev?: boolean
  traceId: string
  interactionId: string
  sessionId: string
  onQuestionClick?: (question: string, context?: string) => void
  page: string
  articleUrl?: string
  noAnswer?: boolean
  hashedUserId: string
  isSuggestedQuestion: boolean
  questionContext?: string
}

export const AnswerMessage = ({
  content,
  links,
  rawJson,
  isDev = false,
  traceId,
  interactionId,
  sessionId,
  page,
  onQuestionClick,
  articleUrl,
  noAnswer,
  hashedUserId,
  isSuggestedQuestion,
  questionContext,
}: AnswerMessageProps) => {
  const theme = useTheme()
  const { openSnackbar } = useApp()
  const [rateModalConfig, setRateModalConfig] = useState<RateModalConfig | null>(null)
  const [questions, setQuestions] = useState<ChatQuestionItem[]>([])
  const [isQuestionsLoading, setIsQustionsLoading] = useState(false)
  const linksKeys = useMemo(() => (links ? Object.keys(links) : []), [links])
  const text = useMemo(() => content?.map((contentItem) => contentItem.text).join(' '), [content])

  const getLink = (key: string) => {
    if (links) {
      return links[key]
    }
  }

  const onRateModalConfirm = () => {
    openSnackbar({ open: true, variant: 'success', message: 'Thank you for your feedback!' })
  }

  const getAricleId = (articleUrl?: string) => (articleUrl ? articleUrl.replace(/.*id(\d+)\.html.*/, '$1') : undefined)

  const getSuggestedQuestions = useCallback(async () => {
    if (!noAnswer) {
      setIsQustionsLoading(true)
      const articleId = getAricleId(articleUrl)
      const context =
        typeof questionContext !== 'undefined' ? questionContext : isSuggestedQuestion ? articleId : 'home'
      const questionsResult = await getQuestions({
        context,
        text,
      })

      if (!(questionsResult instanceof AxiosError) && questionsResult.data) {
        setIsQustionsLoading(false)
        setQuestions(
          questionsResult.data.questions.map((question) => {
            question.context = context
            return question
          }),
        )
      }
    }
  }, [articleUrl, noAnswer, text, isSuggestedQuestion, questionContext])

  useEffect(() => {
    getSuggestedQuestions()
  }, [getSuggestedQuestions])

  return (
    <ThemeCustomization>
      <MessageWrapper>
        {content?.map((contentItem) => {
          const linkItem = links && links[contentItem.link]
          return (
            <p key={contentItem.text}>
              {contentItem.text}
              {linkItem && (
                <ReferenceLink href={linkItem?.url} isPlus={linkItem.isPlus} title={linkItem.title}>
                  {contentItem.link}
                </ReferenceLink>
              )}
            </p>
          )
        })}
      </MessageWrapper>
      <RatingContainer>
        <Rating
          onRatingClick={setRateModalConfig}
          feedbackSent={false}
          title="Rate answer"
          sx={{ fontSize: '10px' }}
          ratingSx={{ fontSize: '18px' }}
          noReset
        />
      </RatingContainer>
      <Box sx={{ padding: '0 32px', fontSize: '12px', color: theme.palette.grey[500], lineBreak: 'anywhere' }}>
        {Boolean(linksKeys.length) && 'Sources:'}&nbsp;
        <ol style={{ margin: 0 }}>
          {linksKeys?.slice(0, 3)?.map((linksKey) => {
            const link = getLink(linksKey)
            return (
              link && (
                <li key={link.url}>
                  <ExternalLink href={link.url} target="_blank" rel="noreferrer">
                    {link.title}
                  </ExternalLink>
                </li>
              )
            )
          })}

          {linksKeys?.length > 3 && (
            <MuiAccordion
              defaultExpanded={false}
              sx={{
                '& .MuiButtonBase-root': { padding: 0, margin: 0, marginLeft: '-22px', minHeight: 'auto !important' },
              }}
            >
              <MuiAccordionSummary
                sx={{
                  justifyContent: 'start',
                  '& .MuiAccordionSummary-content': {
                    padding: 0,
                    margin: 0,
                    flexGrow: 0,
                    '&.Mui-expanded': { padding: 0, margin: 0, flexGrow: 0 },
                  },
                }}
                expandIcon={<MaterialIcon name="expand_more" />}
              >
                Show more sources
              </MuiAccordionSummary>
              <MuiAccordionDetails sx={{ padding: 0, margin: 0 }}>
                {linksKeys?.slice(3)?.map((linksKey) => {
                  const link = getLink(linksKey)
                  return (
                    link && (
                      <li key={link.url}>
                        <ExternalLink href={link.url} target="_blank" rel="noreferrer">
                          {link.title}
                        </ExternalLink>
                      </li>
                    )
                  )
                })}
              </MuiAccordionDetails>
            </MuiAccordion>
          )}
        </ol>
        {isDev && (
          <MuiAccordion
            defaultExpanded={false}
            sx={{ '& .MuiButtonBase-root': { padding: 0, margin: 0, minHeight: 'auto !important' } }}
          >
            <MuiAccordionSummary
              sx={{
                justifyContent: 'start',
                '& .MuiAccordionSummary-content': { padding: 0, margin: 0, flexGrow: 0 },
              }}
              expandIcon={<MaterialIcon name="expand_more" />}
            >
              Show raw json
            </MuiAccordionSummary>
            <MuiAccordionDetails>{rawJson}</MuiAccordionDetails>
          </MuiAccordion>
        )}
      </Box>
      {isQuestionsLoading && (
        <Box display="flex" alignItems="center">
          <CircularProgress size={16} sx={{ color: theme.palette.grey[400], marginLeft: '24px' }} />
          <Spacer h size={8} />
          <Typography variant="bodysm" color={theme.palette.grey[400]}>
            Generating questions
          </Typography>
        </Box>
      )}
      {questions && questions.length > 0 && (
        <Questions
          questions={questions}
          onQuestionClick={(question) => onQuestionClick && onQuestionClick(question.text, question.context)}
        />
      )}
      {rateModalConfig && (
        <RateModal
          isOpen={Boolean(rateModalConfig)}
          onClose={() => setRateModalConfig(null)}
          config={rateModalConfig}
          onConfirm={onRateModalConfirm}
          titleOverride
          onTrack={(rating, feedbackText) =>
            trackRating({
              page,
              hashedUserId,
              elementLabel: rateModalConfig.title,
              context: GoogleAnalyticsContext.CHATBOT,
              rating,
              feedbackText,
              traceId,
              sessionId,
              interactionId,
            })
          }
        />
      )}
    </ThemeCustomization>
  )
}

export const MessageWrapper = styled('div')`
  margin-left: 16px;
  animation: bot-entry 0.3s ease-in backwards;
  background-color: rgb(73, 29, 141);
  color: rgb(255, 255, 255);
  max-width: 70%;
  white-space: pre-line;
  margin-top: 8px;
  padding: 12px 16px;
  border-radius: 22px;
  min-height: 20px;
  height: fit-content;
  width: fit-content;
  font-size: 15px;
  overflow: hidden;
`

const RatingContainer = styled('div')`
  margin-top: 8px;
  margin-left: 32px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.palette.purple[500]};
  font-size: 12px;
  line-height: 20px;
  font-size: 10px;
`
