import { AxiosProgressEvent } from 'axios'
import {
  JobCreateParams,
  ApiResponse,
  JobCreateResponse,
  JobsGetAllResponse,
  JobGetResponse,
  JobDeleteResponse,
} from 'shared'
import { axiosInstance } from 'utils/axios'

export const fetchJobs = async () => {
  try {
    const result = await axiosInstance.get<ApiResponse<JobsGetAllResponse>>('/jobs/transcripts', {
      params: { sortBy: 'DESC', limit: '500' },
    })

    return result.data.data?.jobs
  } catch {
    return null
  }
}

export const fetchJob = async (id: string) => {
  try {
    const result = await axiosInstance.get<ApiResponse<JobGetResponse>>(`/jobs/transcripts/${id}`)

    return result.data.data
  } catch {
    return null
  }
}

export const createJob = async (body: JobCreateParams) => {
  try {
    const result = await axiosInstance.post<ApiResponse<JobCreateResponse>>(`/jobs/transcripts`, body)

    return result.data
  } catch {
    return null
  }
}

export const deleteJob = async (id: string) => {
  try {
    const result = await axiosInstance.delete<ApiResponse<JobDeleteResponse>>(`/jobs/transcripts/${id}`)
    return result.data
  } catch {
    return null
  }
}

export const downloadJobContent = async (id: string, type: string) => {
  try {
    const result = await axiosInstance.get<Blob>(`/jobs/transcripts/${id}/download/${type}`, { responseType: 'blob' })

    return result.data
  } catch {
    return null
  }
}

export const uploadFileToS3 = async (
  file: File,
  url: string,
  fields: Record<string, string>,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  const formData = new FormData()

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })

  formData.append('file', file)
  try {
    const result = await axiosInstance.post(url, formData, { onUploadProgress })

    return result.status === 204
  } catch {
    return null
  }
}
