// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewBg = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge е специализиран инструмент за изкуствен интелект, проектиран за Ringier Media, който предлага
          комплект от избрани инструменти, които използват генеративен изкуствен интелект и други технологии на
          изкуствения интелект. Тези инструменти са насочени към решаването на конкретни редакторски случаи по ефективен
          начин.
          <br />
          <br />
          Този иновативен инструмент е част от инициативата AI Boost.
          <br />
          <br />
          Първият наличен инструмент е нашият напреднал инструмент за превръщане на реч в текст, предишно известен като
          Blisper. Той предлага безпроблемен начин за превеждане на видео и аудио файлове на всяки език, опростявайки
          създаването на съдържание.
          <br />
          <br />
          В бъдеще планираме да въведем инструмент, който ще позволи създаването на статии на база различни външни данни
          като полицейски доклади, медийни доклади или чуждестранни новини. Това ще увеличи значително разнообразието и
          актуалността на съдържанието.
          <br />
          <br />
          Ние сме ангажирани с редовното въвеждане и тестване на нови функционалности, за да подобрим и подкрепим вашите
          ежедневни работни рутини.
          <br />
          <br />
          Вашите отзиви са от съществено значение за нас. Моля, споделете мислите си за качеството на отделните
          инструменти чрез тази анкета:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          За всички бъгове или грешки, с които се сблъскате, моля, докладвайте тук:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          За всички други въпроси, предложения или запитвания, не се колебайте да се свържете с:
          <ul>
            <li>
              Lauri Kurki, Project Lead AI Boost на{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, AI Innovation Lead в Blick Newsroom на{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
