import { ReactNode, createContext, useEffect, useRef, useState } from 'react'
import { AppLanguages, TranslationContextType } from 'types/translation'
import i18n from 'i18next'
import { I18nextProvider } from 'react-i18next'
import enTranslations from 'utils/locales/en.json'
import deTranslations from 'utils/locales/de.json'
import frTranslations from 'utils/locales/fr.json'
import plTranslations from 'utils/locales/pl.json'
import srTranslations from 'utils/locales/sr.json'
import skTranslations from 'utils/locales/sk.json'
import bgTranslations from 'utils/locales/bg.json'
import huTranslations from 'utils/locales/hu.json'
import roTranslations from 'utils/locales/ro.json'

import { localStorageGetItem, localStorageSetItem } from 'utils/localStorage'

type TranslationProviderProps = {
  children: ReactNode
}

const STORAGE_KEY = 'user_lang'

const visibleLanguages = {
  [AppLanguages.ENGLISH]: {
    translation: enTranslations,
    label: 'English',
    subLabel: 'English',
  },
  [AppLanguages.GERMAN]: {
    translation: deTranslations,
    label: 'Deutsch',
    subLabel: '(German)',
  },
  [AppLanguages.FRENCH]: {
    translation: frTranslations,
    label: 'Français',
    subLabel: '(French)',
  },
}

const languages = {
  [AppLanguages.ENGLISH]: {
    translation: enTranslations,
    label: 'English',
    subLabel: 'English',
  },
  [AppLanguages.GERMAN]: {
    translation: deTranslations,
    label: 'Deutsch',
    subLabel: '(German)',
  },
  [AppLanguages.FRENCH]: {
    translation: frTranslations,
    label: 'Français',
    subLabel: '(French)',
  },
  [AppLanguages.POLISH]: {
    translation: plTranslations,
    label: 'Polski',
    subLabel: '(Polish)',
  },
  [AppLanguages.SERBIAN]: {
    translation: srTranslations,
    label: 'Српски',
    subLabel: '(Serbian)',
  },
  [AppLanguages.SLOVAKIAN]: {
    translation: skTranslations,
    label: 'Slovenský',
    subLabel: '(Slovakian)',
  },
  [AppLanguages.BULGARIAN]: {
    translation: bgTranslations,
    label: 'Български',
    subLabel: '(Bulgarian)',
  },
  [AppLanguages.HUNGARIAN]: {
    translation: huTranslations,
    label: 'Magyar',
    subLabel: '(Hungarian)',
  },
  [AppLanguages.ROMANIAN]: {
    translation: roTranslations,
    label: 'Românesc',
    subLabel: '(Romanian)',
  },
}

const intitialState: TranslationContextType = {
  languages,
  visibleLanguages,
  onLanguageChange: () => {
    return
  },
  currentLanguage: AppLanguages.ENGLISH,
}

export const TranslationContext = createContext<TranslationContextType>(intitialState)

export const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const instance = useRef(i18n.createInstance())
  const [currentLanguage, setCurrentLanguage] = useState(intitialState.currentLanguage)

  const onLanguageChange = (language: AppLanguages) => {
    localStorageSetItem(STORAGE_KEY, language)
    setCurrentLanguage(language)
    instance.current.changeLanguage(language)
  }

  useEffect(() => {
    const language = localStorageGetItem(STORAGE_KEY) || AppLanguages.ENGLISH
    setCurrentLanguage(language as AppLanguages)

    instance.current.init({
      resources: languages,
      lng: language,
      keySeparator: '.',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })
  }, [instance])

  return (
    <TranslationContext.Provider
      value={{
        languages,
        visibleLanguages,
        onLanguageChange,
        currentLanguage,
      }}
    >
      <I18nextProvider i18n={instance.current}>{children}</I18nextProvider>
    </TranslationContext.Provider>
  )
}
