import { MainCard } from 'components/ui-component/cards/MainCard'
import Grid from '@mui/material/Grid'
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  ArticleSummaryGeneratorForm,
  ArticleSummaryGeneratorFormValues,
} from './components/ArticleSummaryGeneratorForm'
import { Spacer } from 'components/ui-component/Spacer'
import { ArticleSummaryPreview } from './components/ArticleSummaryPreview'
import { SubmitHandler } from 'react-hook-form'
import { useState, useRef } from 'react'
import { generateSummary } from 'api/textToText'
import { trackAiGeneration, trackRating } from 'tracking/gtm'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { GoogleAnalyticsContext } from 'shared'
import { useApp } from 'hooks/useApp'
import { FormattedMessage } from 'components/FormattedMessage'
import { useTranslation } from 'react-i18next'
import { FrontendGoogleAnalyticsClickAction } from 'types/analytics'

export const ArticleSummaryGeneratorView = () => {
  const { pathname } = useLocation()
  const { session } = useAuth()
  const { openSnackbar } = useApp()
  const { t } = useTranslation()
  const theme = useTheme()
  const previewRef = useRef<HTMLDivElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentFormData, setCurrentFormData] = useState<ArticleSummaryGeneratorFormValues>()
  const [summary, setSummary] = useState<string[]>()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const submitGenerateSummary = async (formData: ArticleSummaryGeneratorFormValues, regenerate?: boolean) => {
    const { text, version } = formData
    setIsLoading(true)
    const generatedSummary = await generateSummary({ originalText: text, version, regenerate })
    if (generatedSummary) {
      setSummary(generatedSummary)
    } else {
      onError(t('articleSummary.generateError'))
    }
    setIsLoading(false)
  }

  const onSubmit: SubmitHandler<ArticleSummaryGeneratorFormValues> = async (formData) => {
    setCurrentFormData(formData)
    matchDownMd && previewRef?.current?.scrollIntoView({ behavior: 'smooth' })
    await submitGenerateSummary(formData)
    trackAiGeneration({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.TEXT_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.GENERATE_ARTICLE_SUMMARY,
      elementLabel: 'Generate Summary',
      model: formData.version,
    })
  }

  const onRegenerate = async () => {
    if (currentFormData) {
      await submitGenerateSummary(currentFormData, true)
      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_ARTICLE_SUMMARY,
        elementLabel: 'Regenerate',
        model: currentFormData.version,
      })
    }
  }

  const onError = (message: string) => {
    openSnackbar({
      open: true,
      message,
      autoHideDuration: 10000,
      variant: 'error',
    })
  }

  return (
    <Grid container columnSpacing="24px" rowSpacing="24px">
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <MainCard>
          <Typography variant="h3">
            <FormattedMessage id="articleSummary.headline" />
          </Typography>
          {matchDownMd && <Divider sx={{ margin: '18px -16px 0px -16px' }} />}
          <Spacer size={24} v />
          <ArticleSummaryGeneratorForm onSubmit={onSubmit} isLoading={isLoading} />
        </MainCard>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <MainCard ref={previewRef}>
          <ArticleSummaryPreview
            isLoading={isLoading}
            summary={summary}
            onRegenerate={onRegenerate}
            onTrack={(rating, feedbackText) =>
              trackRating({
                page: pathname,
                hashedUserId: session?.hashedId,
                elementLabel: 'article summary',
                context: GoogleAnalyticsContext.ARTICLE_SUMMARY,
                rating,
                feedbackText,
                model: currentFormData?.version,
              })
            }
          />
        </MainCard>
      </Grid>
    </Grid>
  )
}
