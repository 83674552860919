import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { trackPageView } from 'tracking/gtm'

type Props = {
  children: React.ReactNode
}

export const AppWrapper = ({ children }: Props) => {
  const { pathname } = useLocation()
  const { session } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get('redirectUrl')

  useEffect(() => {
    if (redirectUrl) {
      navigate(decodeURIComponent(redirectUrl))
    }
  }, [redirectUrl, navigate])

  useEffect(() => {
    if (pathname && session) {
      trackPageView(pathname, session.hashedId)
    }
  }, [pathname, session])

  return <>{children}</>
}
