// ==============================|| TEXT TO SPEECH DEMO PAGE ||============================== //
import breadcrump from './images/article2/breadcrump.png'
import social from './images/article2/social.png'
import slider from './images/article2/slider.png'
import table from './images/article2/table.png'
import video from './images/article2/video.png'
import footer from './images/footer.png'
import { Spacer } from 'components/ui-component/Spacer'
import { Wrapper } from './components/Wrapper'
import { Header } from './components/Header'
import { SubTitle, Summary, TextWrapper, Title, Text, TextTitle } from './components/Typography'
import './fonts/fonts.css'
import { Player } from './components/Player'
import { Box } from '@mui/material'

export const Article2View = () => {
  return (
    <Wrapper>
      <Header />
      <Spacer v size={8} />
      <TextWrapper>
        <img alt="breadcrump" src={breadcrump} />
        <Spacer v size={16} />
        <SubTitle
          sx={{
            fontSize: {
              sm: '5vw',
              md: '45px',
            },
          }}
        >
          Rote Lebensmittel sind gesund
        </SubTitle>
        <Spacer v size={8} />
        <Title
          sx={{
            fontSize: {
              sm: '9vw',
              md: '80px',
            },
          }}
        >
          Darum solltest du mehr Tomaten essen
        </Title>
        <Spacer v size={24} />
        <Summary>
          Obst und Gemüse sollten täglich auf dem Speiseplan stehen. Dabei gilt: Je bunter die Mischung, desto gesünder
          die Mahlzeit. Blick erklärt, warum Sie aber besonders auf mehr rote Lebensmittel setzen sollten.
        </Summary>
        <Spacer v size={16} />
        <img alt="social" src={social} />
        <Spacer v size={16} />
        <Player projectId="39342" sourceId="15518435" playerStyle="small" />
        <Spacer v size={32} />
        <Box sx={{ margin: '0 -16px' }}>
          <img alt="slider" src={slider} width="100%" />
        </Box>
        <Spacer v size={48} />
        <Text>
          Die Farben in Obst und Gemüse werden von sekundären Pflanzenstoffen hervorgerufen, die gut für unseren Körper
          sind. Jeder Ton bringt dabei andere gesundheitsfördernde Effekte mit sich.
        </Text>
        <Spacer v size={24} />
        <Text>
          Was aber alle gemeinsam haben: Studien zeigen, dass die sekundären Pflanzenstoffe antioxidativ wirken. Das
          bedeutet, dass sie unseren Körper vor schädlichen Umwelteinflüssen schützen.
        </Text>
        <Spacer v size={48} />
        <TextTitle>Warum mehr von der Farbe Rot?</TextTitle>
        <Spacer v size={16} />
        <Text>
          Lycopin heisst der Stoff, der Tomaten ihre rote Farbe verleiht. Dem Pflanzenfarbstoff, der zu den Carotinoiden
          gehört, wird nachgesagt, dass er vor Herzinfarkt, Schlaganfall und Gefässveränderungen schützt. Ausserdem soll
          Lycopin Krebs vorbeugen und bei regelmässigem Konsum auch Herz-Kreislauf-Erkrankungen verhindern.
        </Text>
        <Spacer v size={48} />
        <TextTitle>So gesund sind Tomaten</TextTitle>
        <Spacer v size={16} />
        <img alt="table" src={table} width="100%" />
        <Spacer v size={24} />
        <Text>
          Da Lycopin durch Kochen konzentriert wird, ist Tomatensauce besonders reich an dem Farbstoff. Es ist zudem in
          Gemüse wie Chili oder roten Peperoni, aber auch in Obst wie Erdbeeren, Kirschen, roten Johannisbeeren,
          Hagebutten, Granatäpfeln oder Preiselbeeren enthalten.
        </Text>
        <Spacer v size={32} />
        <Box sx={{ margin: '0 -16px' }}>
          <img alt="slider" src={video} width="100%" />
        </Box>
        <Spacer v size={32} />
        <TextTitle>7 Gründe, um mehr Tomaten zu essen</TextTitle>
        <Text>
          <ol>
            <li>
              <strong>Reich an Nährstoffen:</strong> Tomaten sind eine hervorragende Quelle für wichtige Nährstoffe wie
              Vitamin C, Vitamin A, Vitamin K, Folsäure und Kalium. Diese Nährstoffe tragen zur allgemeinen Gesundheit
              und Funktion deines Körpers bei.
            </li>
            <li>
              <strong>Antioxidantien: </strong>Tomaten enthalten Antioxidantien wie Vitamin C, Vitamin E, Beta-Carotin
              und Lycopin. Diese Verbindungen schützen deine Zellen vor Schäden durch freie Radikale und können das
              Risiko von chronischen Krankheiten wie Herzkrankheiten und bestimmten Krebsarten verringern.
            </li>
            <li>
              <strong>Gesunde Haut: </strong>Die enthaltenen Vitamine und Antioxidantien können dazu beitragen, die
              Gesundheit deiner Haut zu fördern, indem sie vor schädlichen UV-Strahlen schützen und zur
              Kollagenproduktion beitragen.
            </li>
            <li>
              <strong>Verdauungsfördernd:</strong> Die Ballaststoffe in Tomaten können die Verdauung fördern und zur
              Regulierung des Stuhlgangs beitragen.
            </li>
            <li>
              <strong>Entzündungshemmend:</strong> Bestimmte Verbindungen in Tomaten, wie etwa Quercetin und Kaempferol,
              haben entzündungshemmende Eigenschaften und könnten dazu beitragen, Entzündungen im Körper zu reduzieren.
            </li>
            <li>
              <strong>V</strong>
              <strong>erbesserung der Sehkraft:</strong> Das enthaltene Vitamin A und Lutein in Tomaten können zur
              Erhaltung der Sehkraft beitragen und das Risiko von altersbedingten Augenerkrankungen verringern.
            </li>
            <li>
              <strong>Unterstützung des Immunsystems:</strong> Die enthaltenen Vitamine und Antioxidantien können dazu
              beitragen, dein Immunsystem zu stärken und dich vor Krankheiten zu schützen. (CM)
            </li>
          </ol>
        </Text>
        <Spacer v size={48} />
      </TextWrapper>
      <img alt="footer" src={footer} width="100%" />
    </Wrapper>
  )
}
