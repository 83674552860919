// material-ui
import { createTheme } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'

// assets
import defaultColor from 'scss/_themes-vars.module.scss'

// types
import { ColorProps } from 'types'

// ==============================|| DEFAULT THEME - PALETTE ||============================== //

export const Palette = (navType: PaletteMode, presetColor: string) => {
  let colors: ColorProps
  switch (presetColor) {
    case 'default':
    default:
      colors = defaultColor
  }

  return createTheme({
    palette: {
      mode: navType,
      common: {
        black: colors.darkPaper,
      },
      blue: {
        50: colors.blue50,
        200: colors.blue200,
        500: colors.blue500,
        600: colors.blue600,
        800: colors.blue800,
      },
      purple: {
        50: colors.purple50,
        200: colors.purple200,
        500: colors.purple500,
        600: colors.purple600,
        800: colors.purple800,
      },
      error: {
        light: colors.errorLight,
        main: colors.errorMain,
        dark: colors.errorDark,
      },
      orange: {
        light: colors.orangeLight,
        main: colors.orangeMain,
        dark: colors.orangeDark,
      },
      warning: {
        light: colors.warningLight,
        main: colors.warningMain,
        dark: colors.warningDark,
      },
      success: {
        light: colors.successLight,
        200: colors.success200,
        main: colors.successMain,
        dark: colors.successDark,
      },
      grey: {
        50: colors.grey50,
        100: colors.grey100,
        200: colors.grey200,
        300: colors.grey300,
        400: colors.grey400,
        500: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
        600: navType === 'dark' ? colors.darkTextTitle : colors.grey600,
        700: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
        //@ts-expect-error
        760: colors.grey760,
        800: colors.grey800,
        900: navType === 'dark' ? colors.darkTextPrimary : colors.grey900,
      },
      dark: {
        light: colors.darkTextPrimary,
        main: colors.darkLevel1,
        dark: colors.darkLevel2,
        800: colors.darkBackground,
        900: colors.darkPaper,
      },
      text: {
        primary: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
        secondary: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
        dark: navType === 'dark' ? colors.darkTextPrimary : colors.grey900,
        hint: colors.grey100,
      },
      divider: navType === 'dark' ? colors.darkTextPrimary : colors.grey200,
      background: {
        paper: navType === 'dark' ? colors.darkLevel2 : colors.paper,
        default: navType === 'dark' ? colors.darkPaper : colors.paper,
      },
    },
  })
}
