import { Button, Card, IconButton, Modal, Rating, TextField, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { Controller, useForm } from 'react-hook-form'
import { Spacer } from 'components/ui-component/Spacer'
import { FormattedMessage } from './FormattedMessage'
import { useState } from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: (element: string) => void
  isLoading?: boolean
  config: RateModalConfig
  titleOverride?: boolean
  onTrack: onTrackRatingFunction
}

export type onTrackRatingFunction = (rating: number, feedbackText: string) => void

type FormValues = {
  rating: string
  feedbackText: string
}

export type RateModalConfig = {
  title: string
  defaultValue: string
}

export const RateModal = ({ isOpen = true, onClose, onConfirm, config, titleOverride, onTrack }: Props) => {
  const theme = useTheme()
  const { register, handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      rating: config?.defaultValue,
    },
  })

  const [textAreaError, setTextAreaError] = useState(false)

  const validateByteSize = (value: string) => {
    const size = new Blob([value]).size
    if (size > 100) {
      setTextAreaError(true)
      return false
    } else {
      setTextAreaError(false)
    }
    return true
  }

  const onSubmit = (formData: FormValues) => {
    onTrack(Number(formData.rating), formData.feedbackText)
    onConfirm && onConfirm(config.title)
    reset()
    onClose()
  }
  const onModalClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onModalClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div tabIndex={-1}>
          <Card
            sx={{
              position: 'absolute',
              width: { xs: '90%', lg: 400 },
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
            }}
          >
            <IconButton onClick={onModalClose} size="large" sx={{ position: 'absolute', right: '8px', top: '8px' }}>
              <CloseIcon fontSize="medium" />
            </IconButton>
            <Centered>
              <Typography variant="h3" sx={{ maxWidth: '160px', textAlign: 'center' }}>
                {titleOverride ? (
                  config.title
                ) : (
                  <>
                    <FormattedMessage id="rating.title" /> {config.title}
                  </>
                )}
              </Typography>
            </Centered>
            <Spacer v size={16} />
            <Centered>
              <Controller
                name="rating"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Rating
                    sx={{
                      color: theme.palette.purple[500],
                      '& .MuiRating-iconEmpty': { color: theme.palette.purple[500] },
                      fontSize: '40px',
                    }}
                    onChange={onChange}
                    value={Number(value)}
                  />
                )}
              />
            </Centered>
            <RatingText>
              <Typography variant="h6" sx={{ color: theme.palette.purple[500] }}>
                <FormattedMessage id="rating.bad" />
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.purple[500] }}>
                <FormattedMessage id="rating.great" />
              </Typography>
            </RatingText>

            <Spacer v size={16} />
            <TextField
              fullWidth
              multiline
              rows={4}
              label={<FormattedMessage id="rating.textareaLabel" />}
              type="textarea"
              error={!!textAreaError}
              helperText={!!textAreaError && <FormattedMessage id="rating.textAreaError" />}
              {...register('feedbackText', { validate: validateByteSize })}
            />
            <Spacer v size={20} />
            <ModalActions>
              <Button
                variant="outlined"
                onClick={onModalClose}
                sx={{
                  textTransform: 'uppercase',
                  padding: '12px 16px',
                  width: '50%',
                  marginRight: '20px',
                  border: `1px solid ${theme.palette.purple[500]}`,
                  color: theme.palette.purple[500],
                  ':hover': {
                    border: `1px solid ${theme.palette.purple[500]}`,
                    color: theme.palette.purple[500],
                  },
                  ':focus': {
                    border: `2px solid ${theme.palette.purple[500]}`,
                    color: theme.palette.purple[500],
                  },
                }}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  textTransform: 'uppercase',
                  padding: '12px 16px',
                  width: '50%',
                  backgroundColor: theme.palette.purple[500],
                  border: `2px solid ${theme.palette.purple[500]}`,
                  ':hover': {
                    backgroundColor: theme.palette.purple[500],
                    border: `2px solid ${theme.palette.purple[500]}`,
                  },
                  ':focus': {
                    backgroundColor: theme.palette.purple[500],
                    border: `2px solid ${theme.palette.purple[500]}`,
                  },
                }}
                disabled={!formState.isValid}
              >
                <FormattedMessage id="rating.sendFeedback" />
              </Button>
            </ModalActions>
          </Card>
        </div>
      </form>
    </Modal>
  )
}

const Centered = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalActions = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RatingText = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  margin: 0 auto;
`
