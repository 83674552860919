import { useMediaQuery, useTheme } from '@mui/material'
import { getCachedAnswer, getCachedQuestions, postPrompt } from 'api/chatbot'
import { AxiosError } from 'axios'
import { useAuth } from 'hooks/useAuth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Flow, getDefaultBotOptions, Message, Options, Params } from 'react-chatbotify'
import { useLocation } from 'react-router-dom'
import { trackChatbotAction } from 'tracking/gtm'
import { GoogleAnalyticsContext } from 'shared'
import { AnswerMessage, ErrorMessage, MessageWrapper } from './components/Messages'
import { Loader } from './components/Loader'
import { Questions } from './components/Questions'
import { ulid } from 'ulid'
import { FrontendGoogleAnalyticsEventType } from 'types/analytics'
import { sessionStorageGetItem, sessionStorageSetItem } from 'utils/sessionStorage'

type ChatbotProps = {
  isDev: boolean
}

const generateSessionId = (userId: string) => {
  return `${userId}.${new Date().getTime()}`
}

export const useChatbot = ({ isDev = false }: ChatbotProps) => {
  const { pathname } = useLocation()
  const defaultBotOptions = getDefaultBotOptions()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { session } = useAuth()

  const [messages, setMessages] = useState<Message[]>([])
  const [lastAnswerIndex, setLastAnswerIndex] = useState(-1)
  const [articleUrlField, setArticleUrlField] = useState<string>()
  const [articleUrl, setArticleUrl] = useState<string>()
  const [isAnswerLoading, setIsAnswerLoading] = useState(false)
  const [isChatbotLoading, setIsChatbotLoading] = useState(true)
  const hashedUserId = useMemo(() => (session ? session.hashedId : 'no-user-id'), [session])
  const [sessionId, setSessionId] = useState<string>(generateSessionId(hashedUserId))
  const [flow, setFlow] = useState<Flow>()
  const [botOptions, setBotOptions] = useState<Options>({
    ...defaultBotOptions,
    theme: {
      ...defaultBotOptions.theme,
      embedded: true,
    },
    chatHistoryButtonStyle: {
      display: 'none',
    },
    chatHistory: {
      maxEntries: 0,
      viewChatHistoryButtonText: '',
    },
    chatWindowStyle: {
      width: '100%',
      transition: 'none',
      animation: 'none',
      boxShadow: 'none',
      maxWidth: '100%',
      height: 'calc(100vh - 345px)',
      minHeight: '300px',
      border: '1px solid #aeb4bb',
      paddingTop: '10px',
    },
    botOptionStyle: {
      display: 'flex',
    },
    botBubbleStyle: {
      whiteSpace: 'pre-line',
    },
    header: {
      showAvatar: false,
      title: '',
      closeChatIcon: '',
      avatar: '',
    },
    headerStyle: { display: 'none' },
    footer: {
      text: '',
    },
    advance: {
      useCustomBotOptions: true,
      useCustomMessages: true,
    },
  })

  const setConversationId = (id: string, sessionId: string) => {
    sessionStorageSetItem('coversationId', JSON.stringify({ [sessionId]: id }))
  }

  const getConversationId = (sessionId: string) => {
    const storedId = sessionStorageGetItem('coversationId')
    if (storedId) {
      const idObject = JSON.parse(storedId)
      return idObject[sessionId]
    }
  }

  const onQuestionClick = useMemo(
    () =>
      async (
        params: Params,
        question: string,
        renderFunction: (
          params: Params,
          sessionId: string,
          articleUrl?: string,
          isSuggestedQuestion?: boolean,
          cachedQuestions?: boolean,
          questionId?: string,
          questionContext?: string,
        ) => Promise<JSX.Element | undefined>,
        sessionId: string,
        articleUrl?: string,
        cachedQuestions?: boolean,
        questionId?: string,
        questionContext?: string,
      ) => {
        setMessages((prev) => [
          ...prev,
          {
            content: question,
            isUser: true,
            type: 'string',
            timestamp: new Date(),
          },
          {
            content: <Loader />,
            isUser: false,
            type: 'loader',
            timestamp: new Date(),
          },
        ])

        const message = await renderFunction(
          { ...params, userInput: question },
          sessionId,
          articleUrl,
          true,
          cachedQuestions,
          questionId,
          questionContext,
        )
        if (message) {
          setMessages((prev) => {
            if (prev) {
              prev.pop()
            }

            return [
              ...prev,
              {
                content: message,
                isUser: false,
                type: 'object',
                timestamp: new Date(),
              },
            ]
          })
        }
      },
    [],
  )

  const renderMessage = useCallback(
    async (
      params: Params,
      sessionId: string,
      articleUrl?: string,
      isSuggestedQuestion = false,
      cachedQuestions = false,
      questionId?: string,
      questionContext?: string,
    ) => {
      const { userInput } = params
      const interactionId = ulid()
      // const articleUrl = (document.querySelector('#articleUrl') as HTMLInputElement)?.value
      trackChatbotAction({
        event: FrontendGoogleAnalyticsEventType.ASK_QUESTION,
        page: articleUrl ? articleUrl : pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.CHATBOT,
        value: userInput,
        sessionId,
        interactionId,
        isSuggestedQuestion,
      })
      setIsAnswerLoading(true)
      const articleId = getAricleId(articleUrl)
      const conversationId = getConversationId(sessionId)
      const result =
        cachedQuestions && questionId
          ? await getCachedAnswer(questionId, 'questionWidget', articleId)
          : await postPrompt(userInput, isSuggestedQuestion, articleId, conversationId)

      if (result instanceof AxiosError) {
        trackChatbotAction({
          event: FrontendGoogleAnalyticsEventType.ANSWER_FAILURE,
          page: articleUrl ? articleUrl : pathname,
          hashedUserId: session?.hashedId,
          context: GoogleAnalyticsContext.CHATBOT,
          value: userInput,
          sessionId,
          interactionId,
          isSuggestedQuestion,
          responseTime: (parseFloat(result?.config?.headers['Response-Time']) / 1000).toFixed(2),
        })

        return <ErrorMessage status={result.status} message={result.message} />
      }

      if (result?.data) {
        const currentAnswerIndex = lastAnswerIndex + 1
        setLastAnswerIndex(currentAnswerIndex)

        const { content, links, traceId, conversationId } = result.data
        setConversationId(conversationId, sessionId)
        trackChatbotAction({
          event: FrontendGoogleAnalyticsEventType.ANSWER_SUCCESS,
          page: articleUrl ? articleUrl : pathname,
          hashedUserId: session?.hashedId,
          context: GoogleAnalyticsContext.CHATBOT,
          sessionId,
          interactionId,
          isSuggestedQuestion,
          responseTime: (parseFloat(result?.config?.headers['Response-Time']) / 1000).toFixed(2),
          traceId,
        })

        setIsAnswerLoading(false)
        return (
          <AnswerMessage
            content={content}
            links={links}
            rawJson={JSON.stringify(result)}
            isDev={isDev}
            traceId={traceId}
            sessionId={sessionId}
            interactionId={interactionId}
            page={articleUrl ? articleUrl : pathname}
            onQuestionClick={(question, context) =>
              onQuestionClick(params, question, renderMessage, sessionId, articleUrl, undefined, undefined, context)
            }
            articleUrl={articleUrl}
            hashedUserId={hashedUserId}
            isSuggestedQuestion={isSuggestedQuestion}
            questionContext={questionContext}
          />
        )
      }
    },
    [isDev, lastAnswerIndex, pathname, session?.hashedId, onQuestionClick, hashedUserId],
  )

  const initialFlow = useMemo(
    () => ({
      start: {
        message:
          'Hallo, mein Name ist Blix (Arbeitstitel), dein News-Chatbot von Blick. Du kannst mir Fragen zu aktuellen oder vergangenen News sowie anderen Blick-Inhalten stellen. Was möchtest du wissen? Bitte beachte, dass ich mich noch in einer Testphase befinde. Solltest du keine zufriedenstellende Antwort bekommen, bitte wiederhole deine Frage oder probiere sie anders zu formulieren.',
        path: 'loop',
      },
      loop: {
        render: (params: Params) => renderMessage(params, sessionId, articleUrl),
        path: 'loop',
      },
    }),
    [renderMessage, sessionId, articleUrl],
  )

  const onReloadChatbot = async () => {
    const newSessionId = generateSessionId(hashedUserId)

    setArticleUrl(articleUrlField)
    setSessionId(newSessionId)
    await loadChatbot(newSessionId, articleUrlField)
    setArticleUrlField('')
  }

  const getAricleId = (articleUrl?: string) => (articleUrl ? articleUrl.replace(/.*id(\d+)\.html.*/, '$1') : undefined)

  const loadChatbot = useCallback(
    async (sessionId: string, articleUrl?: string) => {
      setIsChatbotLoading(true)
      const articleId = getAricleId(articleUrl)
      const cachedQuestionsResult = await getCachedQuestions('questionWidget', articleId)

      if (!(cachedQuestionsResult instanceof AxiosError) && cachedQuestionsResult.data) {
        setFlow({
          start: {
            path: 'loop',
            render: (params) => (
              <>
                <MessageWrapper>
                  Hallo, mein Name ist Blix (Arbeitstitel), dein News-Chatbot von Blick. Du kannst mir Fragen zu
                  aktuellen oder vergangenen News sowie anderen Blick-Inhalten stellen. Was möchtest du wissen? Bitte
                  beachte, dass ich mich noch in einer Testphase befinde. Solltest du keine zufriedenstellende Antwort
                  bekommen, bitte wiederhole deine Frage oder probiere sie anders zu formulieren.
                </MessageWrapper>
                <Questions
                  questions={!(cachedQuestionsResult instanceof AxiosError) ? cachedQuestionsResult.data.questions : []}
                  onQuestionClick={(question) =>
                    onQuestionClick(params, question.text, renderMessage, sessionId, articleUrl, true, question.id)
                  }
                />
              </>
            ),
          },
          loop: {
            render: (params: Params) => renderMessage(params, sessionId, articleUrl),
            path: 'loop',
          },
        })
      } else {
        setFlow(initialFlow)
      }
      setIsChatbotLoading(false)
    },
    [onQuestionClick, renderMessage, initialFlow],
  )

  const onFocus = useCallback(() => {
    if (!botOptions.isOpen) {
      setBotOptions({ ...botOptions, isOpen: true, theme: { ...botOptions.theme, embedded: false } })
    }
  }, [botOptions])

  const onBlur = useCallback(() => {
    if (botOptions.isOpen) {
      setBotOptions({ ...botOptions, isOpen: false, theme: { ...botOptions.theme, embedded: true } })
    }
  }, [botOptions])

  useEffect(() => {
    if (matchDownMd) {
      const initialHeight = window.innerHeight
      const textarea = document.querySelector('.rcb-chat-input-textarea') as HTMLElement

      const onViewportResize = (e: any) => {
        if (e.target.height === initialHeight) {
          textarea?.blur()
        }
      }

      textarea?.addEventListener('click', onFocus)
      textarea?.addEventListener('blur', onBlur)
      window.visualViewport?.addEventListener('resize', onViewportResize)

      return () => {
        textarea?.removeEventListener('click', onFocus)
        textarea?.removeEventListener('blur', onBlur)
        window.visualViewport?.removeEventListener('resize', onViewportResize)
      }
    }
  }, [onBlur, onFocus, matchDownMd])

  const getInitialQuestions = useCallback(async () => {
    if (sessionId) {
      loadChatbot(sessionId)
    } else {
      setFlow(initialFlow)
    }
    // disabled because it should be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getInitialQuestions()
  }, [getInitialQuestions])

  return {
    botOptions,
    setBotOptions,

    onFocus,
    onBlur,

    flow,

    messages,
    setMessages,

    articleUrl,
    articleUrlField,
    setArticleUrlField,

    isAnswerLoading,
    isChatbotLoading,

    onReloadChatbot,
  }
}
