// types
import { NavItemType } from 'types'

import { main } from './main'
import { help } from './help'
import { isPathRestricted } from 'utils/url'
import { AIForgeFeatures } from 'shared'

// ==============================|| MENU ITEMS ||============================== //

export const getMenuItems = (restriction?: AIForgeFeatures[]): { items: NavItemType[] } => {
  const restrict = (menuItems: NavItemType) => {
    if (!restriction) {
      return menuItems
    }

    if (menuItems.url && isPathRestricted(menuItems.url, restriction)) {
      return null
    }

    if (menuItems.children) {
      const children: NavItemType[] = []
      menuItems.children.forEach((item) => {
        const restrictedItem = restrict(item)
        restrictedItem && children.push(item)
      })
      menuItems.children = children
    }

    return menuItems
  }

  const items = []
  const restrictedMain = restrict(main)
  restrictedMain && items.push(restrictedMain)
  const restrictedHelp = restrict(help)
  restrictedHelp && items.push(restrictedHelp)

  return { items }
}
