// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewEn = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Welcome to our curated page where we recommend a variety of external AI tools! Here, you&apos;ll find a
          selection of tools that can enhance your work and spark innovation. However, it&apos;s crucial to remember
          that these tools should be used with care and responsibility.
          <br />
          <br />
          Before diving in, we urge you to familiarize yourself with our AI guidelines. These guidelines are in place to
          ensure ethical, responsible, and effective use of AI technologies. They will help you navigate the potential
          complexities and challenges that come with using external AI tools.
          <br />
          <br />
          Please note that while we recommend these tools for their potential benefits, we do not provide direct support
          for them. If you encounter issues or have questions, you&apos;ll need to reach out to the respective support
          channels for each tool.
          <br />
          <br />
          Use these tools wisely to explore the possibilities of AI, always keeping in mind our guidelines and the need
          for cautious and informed application. Happy exploring!
        </Typography>
        <Typography variant="h3">AI Assistants:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : Currently the best AI assistant in the market. ChatGPT by OpenAI is a conversational AI model capable of
          generating human-like text. It’s ideal for drafting articles, brainstorming, getting feedback on ideas,
          summarising content and such. OpenAI’s image creation engine, Dall-E, accessible via ChatGPT, can generate
          unique images for articles.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai is an AI assistant delivering multi-media responses and aggregating multiple underlying AI
          models like OpenAI’s ChatGPT or Anthropic’s Claude. It can be used for a variety of day-to-day tasks.
        </Typography>
        <Typography variant="h3">Writing:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : An AI writing assistant supporting German that helps in creating compelling content quickly. It can be used
          for drafting articles, generating headlines, and producing creative writing pieces.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : A text-generation AI supporting German that specializes in creating marketing content. It&apos;s helpful in
          writing engaging article intros, ad copy, and social media posts related to articles.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : An AI-powered writing and paraphrasing tool. Helps in rewriting sentences for clarity, improving grammar,
          and avoiding plagiarism in articles. Works well in German.
        </Typography>
        <Typography variant="h3">Image Creation:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : An AI program that specialises in creating high-quality, artistic images. Ideal for creating unique visuals
          and illustrations for stories and features. Currently only accessible via{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          . You can find a guide how to use it{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            here
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : An AI-driven tool that generates realistic images and art. Useful for visual storytelling and creating
          custom illustrations for articles.
        </Typography>
        <Typography variant="h3">Photo Editing:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : A photo editing platform that uses AI for advanced editing tasks. It can enhance the quality of photographs
          used in articles, including adjustments in lighting, texture, and color.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Offers AI-powered photo enhancement software. Useful for upsizing images without losing quality, denoising,
          and sharpening images for digital publication.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : An AI-based image editing tool that can automatically edit photos, adjust lighting and colors, and apply
          various effects, suitable for enhancing visual content in articles.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Specializes in removing backgrounds from images using AI. It&apos;s beneficial for quickly editing images
          for articles, particularly when needing to isolate subjects from their backgrounds for graphic compositions.
        </Typography>
      </MainCard>
    </Text>
  )
}
