import { useLocation } from 'react-router-dom'

import { useApp } from 'hooks/useApp'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useRef, useState } from 'react'
import {
  generateLead,
  generateMetaTitle,
  generateOgTitle,
  generateSummary,
  generateTeaser,
  generateTextRewrite,
  scrapeUrl,
  sendArticleToMDB,
} from 'api/textToText'
import { trackAiGeneration } from 'tracking/gtm'
import { ArticlePublications, AiVersion, MDBArticleLanguage, GoogleAnalyticsContext } from 'shared'
import { Trans, useTranslation } from 'react-i18next'
import { RateModalConfig } from 'components/RateModal'
import { FrontendGoogleAnalyticsClickAction } from 'types/analytics'
import { config } from 'config'
import { useBrowserExtension } from 'hooks/useBrowserExtension'
import { localStorageGetItem, localStorageSetItem } from 'utils/localStorage'

export type TitleFormValues = {
  originalText: string
  version: AiVersion
  publication: ArticlePublications
  sourceUrl?: string
}

export type ArticleFormValues = TitleFormValues & {
  source: string
  focus: string
  paragraphs: string
  publication: string
  characters: string
}

export const FEEDBACK_ELEMENTS = {
  teaserHeadline: 'Teaser-Überzeile',
  teaserTitle: 'Teaser-Titel',
  metaTitle: 'Meta-Titel',
  ogTitle: 'Og-Title',
  articleLead: 'Artikel-Lead',
  text: 'Haupttext',
  summary: 'Zusammenfassung',
}

export const AI_MODEL_OPTIONS = [
  { value: AiVersion.CLAUDE_35_SONNET, label: 'Claude Sonnet v3.5', id: 1 },
  { value: AiVersion.GPT4o, label: 'GPT 4o', id: 2 },
]

export const GENERATE_FOR_OPTIONS = [
  { value: ArticlePublications.BLICK, label: 'Blick (GER)', id: 1 },
  { value: ArticlePublications.BLICK_ROMANDIE, label: 'Blick Romandie (FR)', id: 2 },
  { value: ArticlePublications.LIBERTATEA, label: 'Libertatea (RO)', id: 3 },
  { value: ArticlePublications.LIBERTATEAPENTRUFEMEI, label: 'Libertatea pentru femei (RO)', id: 4 },
  { value: ArticlePublications.ELLE, label: 'Elle (RO)', id: 5 },
  { value: ArticlePublications.AVANTAJE, label: 'Avantaje (RO)', id: 6 },
  { value: ArticlePublications.TVMANIA, label: 'TV Mania (RO)', id: 7 },
  { value: ArticlePublications.UNICA, label: 'Unica (RO)', id: 8 },
  { value: ArticlePublications.VIVA, label: 'Viva (RO)', id: 9 },
]

const SUGGESTED = 'suggested'

export const NUMBER_OF_PARAGRAPHS_OPTIONS = [
  { value: SUGGESTED, label: 'common.suggestedByAI', id: 1 },
  ...Array.from({ length: 7 }).map((_, idx) => ({ value: `${idx + 2}`, label: `${idx + 2}`, id: idx + 2 })),
]

const convertParagraphsValue = (paragraphs: string | undefined) => {
  if (paragraphs === SUGGESTED || !paragraphs) {
    return undefined
  }

  return Number(paragraphs)
}

export const useTextGenerator = (type: string, setFormValue?: any) => {
  const { t } = useTranslation()
  const { session } = useAuth()
  const { openSnackbar } = useApp()
  const { prefilledArticleSource, prefilledArticleText, prefilledArticleUrl } = useBrowserExtension()
  const { pathname } = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const [teaserHeadline, setTeaserHeadline] = useState<any>(null)
  const [isTeaserHeadlineLoading, setTeaserHeadlineLoading] = useState(false)
  const [selectedTeaserHeadline, setSelectedTeaserHeadline] = useState(0)

  const [teaserTitle, setTeaserTitle] = useState<any>(null)
  const [isTeaserTitleLoading, setTeaserTitleLoading] = useState(false)
  const [selectedTeaserTitle, setSelectedTeaserTitle] = useState(0)

  const [metaTitle, setMetaTitle] = useState<any>(null)
  const [isMetaTitleLoading, setMetaTitleLoading] = useState(false)
  const [selectedMetaTitle, setSelectedMetaTitle] = useState(0)

  const [ogTitle, setOgTitle] = useState<any>(null)
  const [isOgTitleLoading, setOgTitleLoading] = useState(false)
  const [selectedOgTitle, setSelectedOgTitle] = useState(0)

  const [lead, setLead] = useState<any>(null)
  const [isLeadLoading, setLeadLoading] = useState(false)
  const [selectedLead, setSelectedLead] = useState(0)

  const [rewrittenText, setRewrittenText] = useState<any>(null)
  const [isRewriteTextLoading, setRewriteTextLoading] = useState(false)

  const [summary, setSummary] = useState<any>(null)
  const [isSummaryLoading, setSummaryLoading] = useState(false)

  const [isRegenerateAllLoading, setRegenerateAllLoading] = useState(false)

  const [rateModalConfig, setRateModalConfig] = useState<RateModalConfig | null>(null)
  const [feedbackSent, setFeedbackSent] = useState<{ [key: string]: boolean }>({})

  const loaderRef = useRef<null | HTMLDivElement>(null)
  const allTitleDataReceived = teaserTitle && teaserHeadline && lead && metaTitle && ogTitle
  const allArticleDataReceived = allTitleDataReceived && rewrittenText && summary

  const [sendToMDBLoading, setSendToMDBLoading] = useState(false)
  const [showChromePluginInfo, setShowChromePluginInfo] = useState(
    !localStorageGetItem('hideChromePluginInfo') && config.chromeExtentionUrl,
  )

  const onError = (message: string) => {
    openSnackbar({
      open: true,
      message,
      autoHideDuration: 10000,
    })
  }

  const onRegenerateTeaserHeadline =
    ({ originalText, version, publication }: TitleFormValues) =>
    async () => {
      setFeedbackSent({
        ...feedbackSent,
        [FEEDBACK_ELEMENTS.teaserHeadline]: false,
      })
      setTeaserHeadlineLoading(true)

      const teaser = await generateTeaser({ body: originalText, version, publication })

      if (!teaser) {
        onError(t('textToTextErrors.teaserError'))
        return
      }

      setTeaserHeadline(teaser.teaserHeadline)
      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_TEASER_HEADLINE,
        elementLabel: 'REGENERATE',
        model: version,
      })
      setTeaserHeadlineLoading(false)
    }

  const onRegenerateTeaserTitle =
    ({ originalText, version, publication }: TitleFormValues) =>
    async () => {
      setFeedbackSent({
        ...feedbackSent,
        [FEEDBACK_ELEMENTS.teaserTitle]: false,
      })
      setTeaserTitleLoading(true)

      const teaser = await generateTeaser({ body: originalText, version, publication })

      if (!teaser) {
        onError(t('textToTextErrors.teaserError'))
        return
      }

      setTeaserTitle(teaser.teaserTitle)
      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_TEASER_TITLE,
        elementLabel: 'REGENERATE',
        model: version,
      })

      setTeaserTitleLoading(false)
    }

  const onRegenerateMetaTitle =
    ({ originalText, version, publication }: TitleFormValues) =>
    async () => {
      setFeedbackSent({ ...feedbackSent, [FEEDBACK_ELEMENTS.metaTitle]: false })
      setMetaTitleLoading(true)

      const metaTitle = await generateMetaTitle({ body: originalText, version, publication })

      if (!metaTitle) {
        onError(t('textToTextErrors.metaTitleError'))
      }

      setMetaTitle(metaTitle)

      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_META_TITLE,
        elementLabel: 'REGENERATE',
        model: version,
      })
      setMetaTitleLoading(false)
    }

  const onRegenerateOgTitle =
    ({ originalText, version, publication }: TitleFormValues) =>
    async () => {
      setFeedbackSent({ ...feedbackSent, [FEEDBACK_ELEMENTS.metaTitle]: false })
      setOgTitleLoading(true)

      const ogTitle = await generateOgTitle({ body: originalText, version, publication })

      if (!ogTitle) {
        onError(t('textToTextErrors.ogTitleError'))
      }

      setOgTitle(ogTitle)

      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_OG_TITLE,
        elementLabel: 'REGENERATE',
        model: version,
      })
      setOgTitleLoading(false)
    }

  const onRegenerateLead =
    ({ originalText, version, publication }: TitleFormValues) =>
    async () => {
      setFeedbackSent({ ...feedbackSent, [FEEDBACK_ELEMENTS.articleLead]: false })
      setLeadLoading(true)

      const lead = await generateLead({ body: originalText, version, publication })

      if (!lead) {
        onError(t('textToTextErrors.leadError'))
      }

      setLead(lead)

      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_LEAD,
        elementLabel: 'REGENERATE',
        model: version,
      })
      setLeadLoading(false)
    }

  const onRegenerateTextRewrite =
    ({ originalText, source, focus, version, sourceUrl, paragraphs, characters, publication }: ArticleFormValues) =>
    async () => {
      setFeedbackSent({ ...feedbackSent, [FEEDBACK_ELEMENTS.text]: false })
      setRewriteTextLoading(true)

      const rewrittenText = await generateTextRewrite({
        originalText,
        source,
        focus,
        version,
        sourceUrl,
        paragraphs: convertParagraphsValue(paragraphs),
        characters: Number(characters),
        publication,
      })

      if (!rewrittenText) {
        onError(t('textToTextErrors.textRewriteError'))
      }

      setRewrittenText(rewrittenText)

      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_REWRITE,
        elementLabel: 'REGENERATE',
        model: version,
      })
      setRewriteTextLoading(false)
    }

  const onRegenerateSummary =
    ({ originalText, version, publication }: TitleFormValues) =>
    async () => {
      setFeedbackSent({ ...feedbackSent, [FEEDBACK_ELEMENTS.text]: false })
      setSummaryLoading(true)

      const summary = await generateSummary({ originalText, publication, version })

      if (!summary) {
        onError(t('textToTextErrors.textRewriteError'))
      }

      setSummary(summary)
      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_ARTICLE_SUMMARY,
        elementLabel: 'REGENERATE',
        model: version,
      })
      setSummaryLoading(false)
    }

  const onRegenerateAll =
    ({ originalText, version, source, sourceUrl, focus, paragraphs, characters, publication }: any) =>
    async () => {
      setFeedbackSent({})
      setRegenerateAllLoading(true)

      if (type === 'article') {
        await generateArticleFields({
          originalText,
          version,
          source,
          sourceUrl,
          focus,
          paragraphs,
          characters,
          publication,
        })
      } else {
        await generateFields({ originalText, version, publication })
      }

      trackAiGeneration({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TEXT_TO_TEXT,
        clickAction: FrontendGoogleAnalyticsClickAction.REGENERATE_ALL,
        elementLabel: 'REGENERATE ALL',
        model: version,
      })
      setRegenerateAllLoading(false)
    }

  const reset = () => {
    setFeedbackSent({})

    setTeaserHeadline(null)
    setSelectedTeaserHeadline(0)

    setTeaserTitle(null)
    setSelectedTeaserTitle(0)

    setMetaTitle(null)
    setSelectedMetaTitle(0)

    setLead(null)
    setSelectedLead(0)

    setOgTitle(null)
    setSelectedOgTitle(0)
  }

  const generateFields = async ({ originalText, version, publication }: TitleFormValues) => {
    const [teaser, lead, metaTitle, ogTitle] = await Promise.all([
      generateTeaser({ body: originalText, version, publication }),
      generateLead({ body: originalText, version, publication }),
      generateMetaTitle({ body: originalText, version, publication }),
      generateOgTitle({ body: originalText, version, publication }),
    ])

    if ([teaser, lead, metaTitle, ogTitle].includes(null)) {
      onError(t('titleGenerator.fieldsError'))
    }

    setTeaserHeadline(teaser?.teaserHeadline)
    setTeaserTitle(teaser?.teaserTitle)
    setLead(lead)
    setMetaTitle(metaTitle)
    setOgTitle(ogTitle)
    setRewrittenText(originalText)
  }

  const generateArticleFields = async ({
    originalText,
    source,
    sourceUrl,
    focus,
    version,
    paragraphs,
    characters,
    publication,
  }: ArticleFormValues) => {
    const [teaser, lead, metaTitle, ogTitle, rewrittenText] = await Promise.all([
      generateTeaser({ body: originalText, version, publication }),
      generateLead({ body: originalText, version, publication }),
      generateMetaTitle({ body: originalText, version, publication }),
      generateOgTitle({ body: originalText, version, publication }),
      generateTextRewrite({
        originalText,
        source,
        focus,
        version,
        sourceUrl,
        paragraphs: convertParagraphsValue(paragraphs),
        characters: Number(characters),
        publication,
      }),
    ])

    const summary = rewrittenText ? await generateSummary({ originalText: rewrittenText, version, publication }) : null

    if ([teaser, lead, metaTitle, ogTitle, rewrittenText, summary].includes(null)) {
      onError(t('titleGenerator.fieldsError'))
    }

    setTeaserHeadline(teaser?.teaserHeadline)
    setTeaserTitle(teaser?.teaserTitle)
    setLead(lead)
    setMetaTitle(metaTitle)
    setOgTitle(ogTitle)
    if (rewrittenText) {
      setRewrittenText(rewrittenText)
    }
    setSummary(summary)
  }

  const onGenerateTitleSubmit = async (formData: TitleFormValues) => {
    const { originalText, version, publication, sourceUrl } = formData
    let substituteText = originalText

    setIsLoading(true)
    if (sourceUrl && !originalText) {
      substituteText = await scrapeUrl(sourceUrl)
      if (setFormValue) {
        setFormValue('originalText', substituteText, { shouldValidate: true })
      }
    }
    setTimeout(() => loaderRef.current?.scrollIntoView({ behavior: 'smooth' }), 200)

    reset()
    await generateFields({ originalText: substituteText, version, publication })
    trackAiGeneration({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.TEXT_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.GENERATE_ARTICLE_MEDIA,
      elementLabel: 'Generate Titles',
      model: version,
    })
    setIsLoading(false)
  }

  const onRateModalConfirm = (element: string) => {
    setFeedbackSent({ ...feedbackSent, [element]: true })
  }

  const onGenerateArticleSubmit = async (formData: ArticleFormValues) => {
    const { originalText, source, sourceUrl, focus, version, paragraphs, characters, publication } = formData
    let substituteText = originalText

    setIsLoading(true)
    if (sourceUrl && !originalText) {
      substituteText = await scrapeUrl(sourceUrl)
      if (setFormValue) {
        setFormValue('originalText', substituteText, { shouldValidate: true })
      }
    }

    setTimeout(() => loaderRef.current?.scrollIntoView({ behavior: 'smooth' }), 200)
    reset()
    await generateArticleFields({
      originalText: substituteText,
      source,
      focus,
      version,
      sourceUrl,
      paragraphs,
      characters,
      publication,
    })
    trackAiGeneration({
      page: pathname,
      hashedUserId: session?.hashedId,
      context: GoogleAnalyticsContext.TEXT_TO_TEXT,
      clickAction: FrontendGoogleAnalyticsClickAction.GENERATE_ARTICLE_EXTERNAL,
      elementLabel: 'Generate Article',
      model: version,
    })
    setIsLoading(false)
  }

  const onSendToCMSSubmit = async (publication: ArticlePublications) => {
    setSendToMDBLoading(true)

    const location = await sendArticleToMDB({
      metaTitle: metaTitle[selectedMetaTitle],
      teaserHeadline: teaserHeadline[selectedTeaserHeadline],
      teaserTitle: teaserTitle[selectedTeaserTitle],
      lead: lead[selectedLead],
      body: rewrittenText,
      language: publication === ArticlePublications.BLICK_ROMANDIE ? MDBArticleLanguage.FR : MDBArticleLanguage.DE,
      ogTitle: ogTitle[selectedOgTitle],
      summary: summary,
    })

    if (!location) {
      setSendToMDBLoading(false)
      openSnackbar({
        open: true,
        autoHideDuration: 10000,
        variant: 'error',
        message: t('textToTextErrors.sendToMDBError'),
      })
      return
    }

    setSendToMDBLoading(false)
    const documentId = location.split('/').reverse()[0]
    openSnackbar({
      open: true,
      message: (
        <div style={{ maxWidth: '300px' }}>
          <Trans t={t} i18nKey="articleGenerator.sentToMDBSuccess">
            text
            <a
              href={`${config.mdbUrl}${documentId}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              text
            </a>
            text
          </Trans>
        </div>
      ),
      autoHideDuration: 20000,
      variant: 'success',
    })
  }

  const onChromePluginInfoClose = () => {
    setShowChromePluginInfo(false)
    localStorageSetItem('hideChromePluginInfo', '1')
  }

  useEffect(() => {
    if (prefilledArticleText && setFormValue) {
      setFormValue('originalText', prefilledArticleText)
    }
  }, [prefilledArticleText, setFormValue])

  useEffect(() => {
    if (prefilledArticleSource && setFormValue) {
      setFormValue('source', prefilledArticleSource)
    }
  }, [prefilledArticleSource, setFormValue])

  useEffect(() => {
    if (prefilledArticleUrl && setFormValue) {
      setFormValue('sourceUrl', prefilledArticleUrl)
    }
  }, [prefilledArticleUrl, setFormValue])

  useEffect(() => {
    if (prefilledArticleText || prefilledArticleSource || prefilledArticleUrl) {
      openSnackbar({
        open: true,
        message: t('common.fieldsPreffilledFromExtension'),
        variant: 'success',
      })
    }
  }, [prefilledArticleText, prefilledArticleSource, prefilledArticleUrl, openSnackbar, t])

  return {
    isLoading,
    setIsLoading,
    loaderRef,
    allTitleDataReceived,
    allArticleDataReceived,

    metaTitle: {
      data: metaTitle,
      isLoading: isMetaTitleLoading,
      onRegenerate: onRegenerateMetaTitle,
      selected: selectedMetaTitle,
      setSelected: setSelectedMetaTitle,
    },
    ogTitle: {
      data: ogTitle,
      isLoading: isOgTitleLoading,
      onRegenerate: onRegenerateOgTitle,
      selected: selectedOgTitle,
      setSelected: setSelectedOgTitle,
    },
    teaserHeadline: {
      data: teaserHeadline,
      isLoading: isTeaserHeadlineLoading,
      onRegenerate: onRegenerateTeaserHeadline,
      selected: selectedTeaserHeadline,
      setSelected: setSelectedTeaserHeadline,
    },
    teaserTitle: {
      data: teaserTitle,
      isLoading: isTeaserTitleLoading,
      onRegenerate: onRegenerateTeaserTitle,
      selected: selectedTeaserTitle,
      setSelected: setSelectedTeaserTitle,
    },
    lead: {
      data: lead,
      isLoading: isLeadLoading,
      onRegenerate: onRegenerateLead,
      selected: selectedLead,
      setSelected: setSelectedLead,
    },
    rewrittenText: {
      data: rewrittenText,
      isLoading: isRewriteTextLoading,
      onRegenerate: onRegenerateTextRewrite,
    },
    summary: {
      data: summary,
      isLoading: isSummaryLoading,
      onRegenerate: onRegenerateSummary,
    },

    onRegenerateAll,
    isRegenerateAllLoading,

    feedbackSent,

    onGenerateTitleSubmit,
    onGenerateArticleSubmit,

    rateModalConfig,
    setRateModalConfig,
    onRateModalConfirm,

    reset,

    onSendToCMSSubmit,
    sendToMDBLoading,

    showChromePluginInfo,
    onChromePluginInfoClose,
  }
}
