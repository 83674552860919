// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewSr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Dobrodošli u našu odabranu kolekciju besplatnog obrazovnog sadržaja o veštačkoj inteligenciji (AI)! Ovde ćete
          pronaći uzbudljiv spektar videa koji pokrivaju različite aspekte veštačke inteligencije, uključujući
          Generativnu AI. Bez obzira da li tek počinjete ili već imate napredno znanje, ovde ima nešto za svakoga.
          <br />
          <br />
          Ova kolekcija je savršena za vas ako želite da proširite svoje znanje u oblasti veštačke inteligencije, bilo
          iz profesionalnih razloga ili iz ličnog interesovanja. Možete proći kroz sadržaj svojim tempom i udobno se
          obrazovati kod kuće.
          <br />
          <br />
          Zaronite u fascinantni svet veštačke inteligencije i iskoristite priliku da se upoznate sa najnovijim
          trendovima i tehnologijama. Nadam se da ćete smatrati ove resurse vrednim i obogaćujućim. Uživajte u svojem
          putovanju učenja!
        </Typography>
        <Typography variant="h3">Veštačka inteligencija uopšteno:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            Veštačka inteligencija vs. Mašinsko učenje vs. Duboko učenje vs. Neuralne mreže (IBM; Članak)
          </ExternalLink>{' '}
          - Članak koji objašnjava razlike između ovih pojmova i korisna definicija veštačke inteligencije.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Uvod u Velike jezičke modele (YouTube)
          </ExternalLink>{' '}
          - Ovo je 1-časovni uvod za opštu publiku u Velike jezičke modele: osnovne tehničke komponente iza sistema
          poput ChatGPT, Claude i Google Bard. Šta su, kuda idu, poređenja i analogije sa današnjim operativnim
          sistemima i neki od izazova u vezi sa sigurnošću ovog novog računarskog paradigme.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Uvod u Generativnu AI (Google)
          </ExternalLink>{' '}
          - Ovo je uvodni nivo mikroučenja koji ima za cilj objašnjenje šta je Generativna AI, kako se koristi i kako se
          razlikuje od tradicionalnih metoda mašinskog učenja. Takođe pokriva Google alate koji vam pomažu da razvijete
          svoje aplikacije Gen AI.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Uvod u Velike jezičke modele (LLM) (Google)
          </ExternalLink>{' '}
          - Ovo je uvodni nivo mikro-učenja koji istražuje šta su veliki jezički modeli (LLM), slučajevi upotrebe gde se
          mogu koristiti i kako možete koristiti podešavanje prompta za poboljšanje performansi LLM. Takođe pokriva
          Google alate koji vam pomažu da razvijete svoje aplikacije Gen AI.
        </Typography>
        <Typography variant="h3">Pokretanje:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Osnove inženjeringa prompta (AWS)
          </ExternalLink>{' '}
          - U ovom kursu ćete naučiti principe, tehnike i najbolje prakse za dizajniranje efikasnih promptova. Ovaj kurs
          uvodi osnove inženjeringa prompta i napreduje do naprednih tehnika prompta. Takođe ćete naučiti kako da se
          zaštitite od zloupotrebe prompta i kako da ublažite pristrasnost pri interakciji sa modelima osnova.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Inženjering prompta (Coursera)
          </ExternalLink>{' '}
          - U ovom kursu ćete naučiti principe, tehnike i najbolje prakse za dizajniranje efikasnih promptova. Ovaj kurs
          uvodi osnove inženjeringa prompta i napreduje do naprednih tehnika prompta. Takođe ćete naučiti kako da se
          zaštitite od zloupotrebe prompta i kako da ublažite pristrasnost pri interakciji sa modelima osnova.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            Inženjering prompta ChatGPT
          </ExternalLink>{' '}
          - Naučite najbolje prakse inženjeringa prompta za razvoj aplikacija. Otkrijte nove načine korišćenja LLM,
          uključujući kako da izgradite svoj vlastiti prilagođeni chatbot. Stičite praktično iskustvo u pisanju i
          iteriranju promptova sami koristeći OpenAI API.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Efikasno Pokretanje za ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - Ovaj video objašnjava kako napraviti efikasne pokrete ChatGPT 4 kako biste izvukli maksimum iz svog
          asistenta za veštačku inteligenciju. Bez obzira da li ste programer ili samo neko ko želi da poboljša
          performanse svog asistenta za veštačku inteligenciju, ovaj video je za vas.
        </Typography>
        <Typography variant="h3">Kreiranje slike:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Kurs Midjourney za Početnike (YouTube)
          </ExternalLink>{' '}
          - U ovom kursu ćete naučiti sve o generisanju AI umetnosti koristeći platformu Midjourney. Otkrićete osnove
          kreiranja i upravljanja promptovima, rad sa različitim verzijama modela i istraživanje naprednih tehnika za
          kreiranje impresivnih slika.
        </Typography>
      </MainCard>
    </Text>
  )
}
