// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewHu = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Üdvözöljük válogatott gyűjteményünkben az Mesterséges Intelligencia (MI) ingyenes tananyagait! Itt izgalmas
          videókat találhat különböző MI-aspektusokról, beleértve a Generatív MI-t is. Akár csak most kezded, akár már
          haladó is vagy, itt mindenki számára van valami.
          <br />
          <br />
          Ez a gyűjtemény tökéletes választás számodra, ha bővíteni szeretnéd az MI területén szerzett ismereteidet,
          legyen szó szakmai vagy személyes érdeklődésről. A tartalmat saját tempódban átveheted, és otthonról
          kényelmesen képezheted magad.
          <br />
          <br />
          Merülj el az Mesterséges Intelligencia lenyűgöző világában, és ragadd meg az alkalmat, hogy megismerkedj a
          legújabb trendekkel és technológiákkal. Remélem, hasznosnak és gazdagítónak találod ezeket az erőforrásokat.
          Jó tanulást kívánok neked!
        </Typography>
        <Typography variant="h3">Az MI általánosan:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            MI kontra Gépi Tanulás kontra Mély Tanulás kontra Neurális Hálózatok (IBM; Cikk)
          </ExternalLink>{' '}
          - Egy cikk, amely magyarázza a különbségeket ezek között a fogalmak között, és egy hasznos meghatározást az
          Mesterséges Intelligenciáról.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Bevezetés a Nagy Nyelvi Modellekbe (YouTube)
          </ExternalLink>{' '}
          - Ez egy általános közönségnek szóló, 1 órás bevezetés a Nagy Nyelvi Modellekbe: az alapvető technikai
          komponensek a ChatGPT, Claude és a Google Bard rendszerek mögött. Mi ezek, merre tartanak, összehasonlítások
          és analógiák a jelenkori operációs rendszerekhez, valamint néhány a biztonsággal kapcsolatos kihívás az új
          számítási paradigmában.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Bevezetés a Generatív MI-be (Google)
          </ExternalLink>{' '}
          - Ez egy bevezető szintű mikrotanfolyam, amely célja a Generatív MI magyarázata, annak használata és annak
          megkülönböztetése a hagyományos gépi tanulási módszerektől. Az is bemutatja a Google eszközöket, amelyek
          segítenek neked saját Gen MI alkalmazásaidat fejleszteni.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Bevezetés a Nagy Nyelvi Modellekbe (LLM-ek) (Google)
          </ExternalLink>{' '}
          - Ez egy bevezető szintű mikrotanfolyam, amely felfedezi, hogy mi az a nagy nyelvi modell (LLM), milyen
          felhasználási esetekben használhatók, és hogyan lehet a prompt hangolás segítségével javítani az LLM
          teljesítményét. Az is bemutatja a Google eszközöket, amelyek segítenek neked saját Gen MI alkalmazásaidat
          fejleszteni.
        </Typography>
        <Typography variant="h3">Bátorítás:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Az Ébresztés Mérnöki Alapjai (AWS)
          </ExternalLink>{' '}
          - Ebben a tanfolyamon megtanulod a hatékony promptok tervezésének elveit, technikáit és legjobb gyakorlatait.
          Ez a tanfolyam bemutatja a promptmérnöki alapokat, és halad az előrehaladott prompttechnikákig. Megtanulod azt
          is, hogyan védheted magad a promptok visszaélésétől, és hogyan csökkentheted a torzítást, amikor
          kölcsönhatásba lépsz az Alapmodelljekekkel.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Prompt Mérnöki (Coursera)
          </ExternalLink>{' '}
          - Ebben a tanfolyamon megtanulod a hatékony promptok tervezésének elveit, technikáit és legjobb gyakorlatait.
          Ez a tanfolyam bemutatja a promptmérnöki alapokat, és halad az előrehaladott prompttechnikákig. Megtanulod azt
          is, hogyan védheted magad a promptok visszaélésétől, és hogyan csökkentheted a torzítást, amikor
          kölcsönhatásba lépsz az Alapmodelljekekkel.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            ChatGPT Bátorító Mérnöki
          </ExternalLink>{' '}
          - Tanuld meg a bátorító mérnöki legjobb gyakorlatait az alkalmazásfejlesztéshez. Fedezd fel az LLM-ek új
          módjait, beleértve saját egyedi chatbotod építésének módját is. Szerezz gyakorlatot a promptok írásában és
          iterálásában, saját magad használva az OpenAI API-t.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Hatékony Bátorítás a ChatGPT4 számára (YouTube)
          </ExternalLink>{' '}
          - Ez a videó elmagyarázza, hogyan hozz létre hatékony ChatGPT 4 bátorításokat, hogy a legtöbbet hozhasd ki az
          AI segédodból. Akár fejlesztő vagy, akár csak valaki, aki javítani akarja az AI segéd teljesítményét, ez a
          videó neked szól.
        </Typography>
        <Typography variant="h3">Képkészítés:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Midjourney Tanfolyam Kezdőknek (YouTube)
          </ExternalLink>{' '}
          - Ebben a tanfolyamon meg fogod tanulni az AI művészeti generálásának minden fortélyát a Midjourney platform
          segítségével. Felfedezed a promptok létrehozásának és kezelésének alapjait, dolgozol különböző
          modellverziókkal, és felfedezed a lenyűgöző képek létrehozásának haladó technikáit.
        </Typography>
      </MainCard>
    </Text>
  )
}
