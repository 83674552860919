import { ArticlePublications, UserNeeds } from './prompts';

export interface ArticleTeaser {
  teaserHeadline: string[];
  teaserTitle: string[];
}

export interface ArticleRewrite {
  rewrite: string;
}

export interface ArticleLead {
  lead: string[];
}

export interface ArticleMetaTitle {
  metaTitle: string[];
}

export interface ArticleSummary {
  summary: string[];
}

export interface ArticleOgTitle {
  ogTitle: string[];
}

export type ArticleTeaserResponse = ArticleTeaser;

export type ArticleRewriteResponse = string;

export type ArticleLeadResponse = string[];

export type ArticleMetaTitleResponse = string[];

export type ArticleSummaryResponse = string[];

export type ArticleTeaserHeadlineResponse = string[];

export type ArticleTeaserTitleResponse = string[];

export type ArticleOgTitleResponse = string[];

export type ArticleSendToMDBResponse = {
  id: string;
  status: number;
};

export interface ArticleRequest {
  body: string;
  articleId?: string;
  version?: AiVersion;
  headline?: string;
  regenerate?: boolean;
  publication?: ArticlePublications;
}

export interface ArticleRewriteRequest {
  originalText: string;
  source: string;
  sourceUrl?: string;
  focus?: string;
  version?: AiVersion;
  characters?: number;
  paragraphs?: number;
  publication?: ArticlePublications;
  userNeed?: UserNeeds;
}

export interface ArticleSummaryRequest {
  originalText: string;
  version?: AiVersion;
  regenerate?: boolean;
  publication?: ArticlePublications;
  articleId?: string;
}

export interface ArticleSendToMDBRequest {
  metaTitle: string;
  teaserTitle: string;
  teaserHeadline: string;
  lead: string;
  body: string;
  language: MDBArticleLanguage;
  ogTitle: string;
  summary: string[];
}

export interface ArticleRequestConfig {
  origin?: string;
}

export interface SwissLanguageRule {
  description: string;
  search: string;
  replace: string;
  testInput: string;
  expectedOutput: string;
  warning?: string;
}

export enum AiVersion {
  GPT4o = 'GPT_4o',
  CLAUDE_35_SONNET = 'Claude_35_Sonnet'
}

export enum TextToTextOrigin {
  MDB_PLUGIN = 'mdb_plugin'
}

export enum MDBArticleLanguage {
  DE = 'german',
  FR = 'french'
}

export const MAX_REWRITE_LENGTH = 10_000;
