// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewEn = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Please accept and follow the following rules for the use of artificial intelligence in editorial work. The
          basis for the following points are the{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            AI guidelines of Ringier.
          </ExternalLink>
          <ol>
            <li>
              We actively promote understanding and use of AI tools among our employees to optimize work processes.
            </li>
            <li>Our use of AI is always based on responsibility and ethical principles.</li>
            <li>
              Transparency is important to us: We clearly disclose the use of AI in our reporting, whether in texts or
              image captions.
            </li>
            <li>We ensure that AI-generated content is never published without final review by a human.</li>
            <li>
              We strictly adhere to not feeding confidential information, trade secrets, or personal data into public AI
              tools.
            </li>
            <li>
              For transcriptions of interviews, we use our internal audio tool (Blisper) in the AI Forge, not
              third-party AI services.
            </li>
            <li>
              We do not use AI-based image tools such as DALL·E 3 and Midjourney for the creation of news photos. Their
              use is permitted for illustration-like images, but only in consultation with the supervisor, photo editing
              department, or the AI Lead Newsroom.
            </li>
            <li>
              For sensitive topics where AI could lead to disinformation or misinterpretation, we refrain from using it.
            </li>
            <li>We offer internal training on AI for our employees.</li>
            <li>
              Considering the rapid development in the field of AI, we always stay up to date and encourage our
              employees to report potentially useful AI tools to the responsible AI office.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          As of Feb24/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
