import { routes } from 'constants/routes'
import { IconSparkles } from './icons/IconSparkles'

export const help = {
  id: 'help',
  type: 'group',
  children: [
    {
      id: 'about',
      title: routes.about.title,
      icon: 'info',
      type: 'item',
      url: routes.about.url,
    },
    {
      id: 'ai-manifesto',
      title: routes.manifesto.title,
      icon: 'local_police',
      type: 'item',
      url: routes.manifesto.url,
    },
    {
      id: 'learn-more',
      title: routes.learnMore.title,
      icon: IconSparkles,
      type: 'collapse',
      children: [
        {
          id: 'getting-started',
          title: routes.learnMore.titleAlt,
          type: 'item',
          url: routes.learnMore.url,
        },
        {
          id: 'recommended-tools',
          title: routes.recommendedTools.title,
          type: 'item',
          url: routes.recommendedTools.url,
        },
      ],
    },
  ],
}
