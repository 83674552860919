import { ClickAwayListener, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode, useState } from 'react'
import { TooltipContent } from './TooltipContent'

type Props = {
  href: string
  children: ReactNode
  isPlus?: string
  title?: string
}

export const ReferenceLink = ({ href, children, isPlus, title }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isMobileTooltipOpen, setIsMobileTooltipOpen] = useState(false)

  return isMobile ? (
    <ClickAwayListener onClickAway={() => setIsMobileTooltipOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        title={<TooltipContent isPlus={isPlus} title={title} />}
        placement="top"
        open={isMobileTooltipOpen}
      >
        <CustomLink onClick={() => setIsMobileTooltipOpen(true)}>{children}</CustomLink>
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <Tooltip title={<TooltipContent isPlus={isPlus} title={title} />} placement="top">
      <CustomLink href={href} target="_blank">
        {children}
      </CustomLink>
    </Tooltip>
  )
}

const CustomLink = styled('a')`
  width: 16px;
  height: 16px;
  border-radius: 15px;
  background: ${({ theme }) => theme.palette.blue[500]};
  color: ${({ theme }) => theme.palette.grey[100]};
  display: inline-block;
  text-decoration: none;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
`
