import * as Sentry from '@sentry/react'
import { config } from 'config'

class SentryLogger {
  constructor() {
    Sentry.init({
      dsn: config.sentryDSN,
      integrations: [Sentry.browserTracingIntegration()],
      environment: config.env,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: config.env === 'prod' ? 0.2 : 1.0,
      beforeSend: (event /* , hint */) => {
        if (config.env === 'dev') {
          // eslint-disable-next-line
          console.log(event.exception?.values)
          return null
        }
        return event
      },
    })
  }

  info(message: string) {
    Sentry.captureMessage(message, 'info')
  }

  warning(message: string) {
    Sentry.captureMessage(message, 'warning')
  }

  error(error: Error) {
    Sentry.captureException(error)
  }
}

const logger = new SentryLogger()

export { logger, Sentry }
