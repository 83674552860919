import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  margin: auto;

  .MuiTooltip-tooltip {
    font-family: 'Source Sans Pro', sans-serif;
    background: #fff;
    font-size: 12px;
    color: #1f1f1f;
    box-shadow:
      0px 8px 16px 0px rgba(97, 108, 119, 0.15),
      0px 2px 4px 0px rgba(46, 54, 61, 0.1);
    border-radius: 1px;
    margin-bottom: 0 !important;
    padding: 12px;

    .MuiTooltip-arrow {
      color: #fff;
    }
  }
`
