// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewFr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Bienvenue dans notre sélection de contenus d'apprentissage gratuits sur l'Intelligence Artificielle (IA) !
          Vous trouverez ici une gamme excitante de vidéos couvrant divers aspects de l'IA, y compris l'IA générative.
          Que vous commenciez tout juste ou que vous ayez déjà des connaissances avancées, il y en a pour tout le monde
          ici.
          <br />
          <br />
          Cette collection est parfaite pour vous si vous souhaitez approfondir vos connaissances dans le domaine de
          l'IA, que ce soit pour des raisons professionnelles ou par intérêt personnel. Vous pouvez parcourir le contenu
          à votre rythme et vous éduquer confortablement depuis chez vous.
          <br />
          <br />
          Plongez dans le monde fascinant de l'Intelligence Artificielle et saisissez l'occasion de vous familiariser
          avec les dernières tendances et technologies. J'espère que vous trouverez ces ressources précieuses et
          enrichissantes. Profitez de votre parcours d'apprentissage !
        </Typography>
        <Typography variant="h3">IA en général :</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            IA vs. Apprentissage automatique vs. Apprentissage profond vs. Réseaux neuronaux (IBM; Article)
          </ExternalLink>{' '}
          - Article explicatif sur les différences entre ces concepts et une définition utile de l'Intelligence
          Artificielle.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Introduction aux grands modèles de langage (YouTube)
          </ExternalLink>{' '}
          - Il s'agit d'une introduction générale d'une heure aux grands modèles de langage : les composants techniques
          de base derrière des systèmes comme ChatGPT, Claude et Google Bard. Ce qu'ils sont, où ils vont, comparaisons
          et analogies avec les systèmes d'exploitation actuels et certains des défis liés à la sécurité de ce nouveau
          paradigme informatique.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Introduction à l'IA générative (Google)
          </ExternalLink>{' '}
          - Il s'agit d'un cours de micro-apprentissage de niveau introductif visant à expliquer ce qu'est l'IA
          générative, comment elle est utilisée et en quoi elle diffère des méthodes traditionnelles d'apprentissage
          automatique. Il couvre également les outils Google pour vous aider à développer vos propres applications Gen
          IA.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Introduction aux grands modèles de langage (LLMs) (Google)
          </ExternalLink>{' '}
          - Il s'agit d'un cours de micro-apprentissage de niveau introductif qui explore ce que sont les grands modèles
          de langage (LLM), les cas d'utilisation où ils peuvent être utilisés et comment vous pouvez utiliser
          l'ajustement des prompts pour améliorer les performances des LLM. Il couvre également les outils Google pour
          vous aider à développer vos propres applications Gen IA.
        </Typography>
        <Typography variant="h3">Incitation :</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Fondements de l'ingénierie des prompts (AWS)
          </ExternalLink>{' '}
          - Dans ce cours, vous apprendrez les principes, les techniques et les meilleures pratiques pour concevoir des
          prompts efficaces. Ce cours présente les bases de l'ingénierie des prompts et passe aux techniques de prompt
          avancées. Vous apprendrez également comment vous protéger contre l'utilisation abusive des prompts et comment
          atténuer les biais lors de l'interaction avec les modèles de base.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Ingénierie des prompts (Coursera)
          </ExternalLink>{' '}
          - Dans ce cours, vous apprendrez les principes, les techniques et les meilleures pratiques pour concevoir des
          prompts efficaces. Ce cours présente les bases de l'ingénierie des prompts et passe aux techniques de prompt
          avancées. Vous apprendrez également comment vous protéger contre l'utilisation abusive des prompts et comment
          atténuer les biais lors de l'interaction avec les modèles de base.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            Ingénierie des prompts ChatGPT
          </ExternalLink>{' '}
          - Apprenez les meilleures pratiques en matière d'ingénierie des prompts pour le développement d'applications.
          Découvrez de nouvelles façons d'utiliser les LLMs, y compris comment créer votre propre chatbot personnalisé.
          Acquérez une expérience pratique en écrivant et en itérant sur des prompts vous-même à l'aide de l'API OpenAI
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Incitation efficace pour ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - Cette vidéo explique comment créer des prompts efficaces pour ChatGPT 4 pour vous aider à tirer le meilleur
          parti de votre assistant IA. Que vous soyez développeur ou simplement quelqu'un qui souhaite améliorer les
          performances de son assistant IA, cette vidéo est pour vous.
        </Typography>
        <Typography variant="h3">Création d'images :</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Cours Midjourney pour débutants (YouTube)
          </ExternalLink>{' '}
          - Dans ce cours, vous apprendrez les tenants et les aboutissants de la génération d'art IA en utilisant la
          plateforme Midjourney. Vous découvrirez les principes de base de la création et de la gestion des prompts, le
          travail avec différentes versions de modèles et l'exploration de techniques avancées pour créer des images
          impressionnantes.
        </Typography>
      </MainCard>
    </Text>
  )
}
