import { Button } from '@mui/material'
import { Question } from 'types/blick-widgets'
import { useBlickWidgets } from 'views/BlickWidgets/context/useBlickWidgets'

type Props = {
  question: Question
}

export const SuggestedQuestion = ({ question }: Props) => {
  const { onQuestionClick, isAnswerLoading } = useBlickWidgets()

  return (
    <Button
      variant="outlined"
      onClick={() => onQuestionClick(question)}
      sx={{ textTransform: 'none', ...(isAnswerLoading && { pointerEvents: 'none' }) }}
    >
      {question.text}
    </Button>
  )
}
