import { SyntheticEvent } from 'react'

// material-ui
import { AlertProps, Button, Fade, Grow, Slide, SlideProps } from '@mui/material'
import MuiSnackbar, { SnackbarOrigin } from '@mui/material/Snackbar'

// types
import { KeyedObject } from 'types'

// assets
import { useApp } from 'hooks/useApp'
import useTheme from '@mui/system/useTheme'
import { SnackbarProps } from 'types/snackbar'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade,
}

const defaultOptions = {
  action: false,
  autoHideDuration: 3000,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  } as SnackbarOrigin,
  variant: 'default',
  alert: {
    color: 'primary',
    variant: 'filled',
  } as AlertProps,
  transition: 'Fade',
  close: true,
  maxStack: 3,
  dense: false,
  iconVariant: 'hide',
  actionButton: false,
  undoButton: false,
}

// ==============================|| SNACKBAR ||============================== //

export const Snackbar = () => {
  const { snackbar, closeSnackbar } = useApp()
  const theme = useTheme()

  const {
    anchorOrigin = defaultOptions.anchorOrigin,
    autoHideDuration = defaultOptions.autoHideDuration,
    close = defaultOptions.close,
    message = defaultOptions.message,
    open = defaultOptions.open,
    transition = defaultOptions.transition,
    undoButton = defaultOptions.undoButton,
    variant = defaultOptions.variant,
  } = snackbar || (defaultOptions as SnackbarProps)

  const baseStyleRoot = {
    fontWeight: '700',
    padding: '10px',
    fontSize: '14px',
    lineHeight: '20px',
    paddingRight: '22px',
    '& .MuiSnackbarContent-message': {
      padding: '0',
    },
  }

  const closeIconBaseStyle = {
    position: 'absolute',
    right: '10px',
    cursor: 'pointer',
    top: 'calc(50% - 12px)',
  }

  const variantsSx: { [key: string]: any } = {
    default: {
      '& .MuiPaper-root': {
        ...baseStyleRoot,
        background: theme.palette.purple[50],
        color: theme.palette.purple[500],
        '& .close-icon': {
          ...closeIconBaseStyle,
          color: theme.palette.purple[200],
        },
      },
    },
    info: {
      '& .MuiPaper-root': {
        ...baseStyleRoot,
        background: theme.palette.blue[50],
        color: theme.palette.blue[500],
        '& .close-icon': {
          ...closeIconBaseStyle,
          color: theme.palette.blue[200],
        },
      },
    },
    success: {
      '& .MuiPaper-root': {
        ...baseStyleRoot,
        background: theme.palette.success.light,
        color: theme.palette.success.dark,
        '& .close-icon': {
          ...closeIconBaseStyle,
          color: theme.palette.success.main,
        },
      },
    },
    warning: {
      '& .MuiPaper-root': {
        ...baseStyleRoot,
        background: theme.palette.warning.light,
        color: theme.palette.warning.main,
        '& .close-icon': {
          ...closeIconBaseStyle,
          color: theme.palette.warning.main,
        },
      },
    },
    error: {
      '& .MuiPaper-root': {
        ...baseStyleRoot,
        background: theme.palette.error.light,
        color: theme.palette.error.dark,
        '& .close-icon': {
          ...closeIconBaseStyle,
          color: theme.palette.error.main,
        },
      },
    },
  }

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    closeSnackbar()
  }

  return (
    <>
      {/* default snackbar */}
      <MuiSnackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={message}
        TransitionComponent={animation[transition]}
        action={
          <>
            {undoButton && (
              <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button>
            )}

            {close && (
              <div className="close-icon" onClick={handleClose}>
                <MaterialIcon filled fontSize="12px" name={'cancel'} />
              </div>
            )}
          </>
        }
        sx={variantsSx[variant]}
      />
    </>
  )
}
