import { useState, useEffect } from 'react'
import { isInBroswer } from 'utils/utils'

// ==============================|| LOCAL STORAGE HOOKS ||============================== //

export function useLocalStorage<ValueType>(key: string, defaultValue: ValueType) {
  const getItem = (key: string) => {
    if (isInBroswer()) {
      return localStorage.getItem(key)
    }

    return null
  }

  const setItem = (key: string, value: string) => {
    if (isInBroswer()) {
      return localStorage.setItem(key, value)
    }

    return null
  }

  const [value, setValue] = useState(() => {
    const storedValue = getItem(key)
    return storedValue === null ? defaultValue : JSON.parse(storedValue)
  })

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue)
      }
    }
    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [key, defaultValue])

  const setValueInLocalStorage = (newValue: ValueType) => {
    setValue((currentValue: any) => {
      const result = typeof newValue === 'function' ? newValue(currentValue) : newValue
      setItem(key, JSON.stringify(result))
      return result
    })
  }

  return [value, setValueInLocalStorage]
}
