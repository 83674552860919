import { axiosInstance } from 'utils/axios'
import { ApiResponse } from 'shared'
import { ToolboxDownloadOptions } from 'types/common'

export const translateText = async ({ text, sourceLanguage, targetLanguage }: any) => {
  try {
    const result = await axiosInstance.post<ApiResponse<any>>('/translate/text', {
      text,
      sourceLanguage,
      targetLanguage,
    })

    return result.data.data.translations[0]
  } catch {
    return null
  }
}

export const downloadTextTranslation = async (
  { text, sourceLanguage, targetLanguage }: any,
  type: ToolboxDownloadOptions,
) => {
  try {
    const result = await axiosInstance.post<Blob>(
      `/translate/text-download/${type}`,
      {
        text,
        sourceLanguage,
        targetLanguage,
      },
      {
        responseType: 'blob',
      },
    )

    return result.data
  } catch {
    return null
  }
}
