// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewHu = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Kérjük, fogadja el és kövesse az alábbi szabályokat az mesterséges intelligencia használatára az szerkesztői
          munkában. A következő pontok alapját a{' '}
          <ExternalLink target="_blank" href="https://drive.google.com/file/d/1vj6QJr_ZTJQGISdE9N6bwmUQaiTHC8Pl/view">
            Ringier mesterséges intelligencia irányelvei
          </ExternalLink>
          képezik.
          <ol>
            <li>
              Aktívan elősegítjük a mesterséges intelligencia eszközök megértését és használatát munkatársaink között a
              munkafolyamatok optimalizálása érdekében.
            </li>
            <li>A mesterséges intelligencia használata mindig felelősségre és etikai elvekre épül.</li>
            <li>
              A transzparencia fontos számunkra: Világosan feltárjuk a mesterséges intelligencia használatát
              jelentéseinkben, legyen szó szövegekről vagy képaláírásokról.
            </li>
            <li>
              Garantáljuk, hogy a mesterséges intelligencia által generált tartalom soha nem kerül nyilvánosságra emberi
              végleges áttekintés nélkül.
            </li>
            <li>
              Szigorúan betartjuk, hogy ne adjunk táplálékot bizalmas információknak, üzleti titkoknak vagy személyes
              adatoknak nyilvános mesterséges intelligencia eszközökbe.
            </li>
            <li>
              Az interjúk tranzkripciójához belső audio eszközünket (Blisper) használjuk az AI Forge-ban, nem harmadik
              fél által kínált mesterséges intelligencia szolgáltatásokat.
            </li>
            <li>
              Nem használunk AI alapú képes eszközöket, mint például a DALL·E 3 és a Midjourney hírfotók készítéséhez.
              Ezek használata engedélyezett illusztráció-szerű képekhez, de csak konzultáció után a felügyelővel, a fotó
              szerkesztési osztállyal vagy az AI vezető hírszobával.
            </li>
            <li>
              Az érzékeny témák esetén, ahol az AI dezinformációhoz vagy félreértéshez vezethet, tartózkodunk annak
              használatától.
            </li>
            <li>Belső képzést biztosítunk munkatársaink számára az AI terén.</li>
            <li>
              Tekintettel az AI területén történő gyors fejlődésre, mindig naprakészek vagyunk és ösztönözzük
              munkatársainkat, hogy jelentsék be a potenciálisan hasznos AI eszközöket a felelős AI irodának.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          2024. február 24-től/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
