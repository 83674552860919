import { TranslationContext } from 'contexts/TranslationContext'
import { useContext } from 'react'
import { TranslationContextType } from 'types/translation'

export const useTranslationContext = (): TranslationContextType => {
  const context = useContext(TranslationContext)

  if (context === undefined) {
    console.error('useTranslationContext must be used within a TranslationContextProvider')
  }

  return context
}
