// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewFr = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge est une boîte à outils AI spécialisée conçue pour Ringier Media, offrant une suite d'outils
          sélectionnés qui exploitent l'IA générative et d'autres technologies de l'IA. Ces outils sont conçus pour
          répondre efficacement à des cas d'utilisation éditoriaux spécifiques.
          <br />
          <br />
          Cette boîte à outils innovante fait partie de l'initiative AI Boost.
          <br />
          <br />
          Le premier outil disponible est notre outil avancé de conversion de la parole en texte, précédemment connu
          sous le nom de Blisper. Il offre un moyen transparent de transcrire des fichiers vidéo et audio dans n'importe
          quelle langue, simplifiant la création de contenu.
          <br />
          <br />
          À l'avenir, nous prévoyons d'introduire un outil qui permettra la création d'articles basés sur diverses
          sources externes telles que des rapports de police, des rapports médiatiques ou des articles de presse
          étrangers. Cela améliorera considérablement la diversité et la pertinence du contenu.
          <br />
          <br />
          Nous nous engageons à introduire régulièrement et à tester de nouvelles fonctionnalités pour améliorer et
          soutenir vos routines de travail quotidiennes.
          <br />
          <br />
          Vos commentaires nous sont précieux. Veuillez partager vos réflexions sur la qualité des outils individuels
          via cette enquête:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          Pour tout bogue ou erreur rencontré, veuillez les signaler ici:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          Pour toute autre question, suggestion ou demande de renseignements, n'hésitez pas à contacter:
          <ul>
            <li>
              Lauri Kurki, Chef de Projet AI Boost à{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, Responsable de l'Innovation AI au Blick Newsroom à{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
