import {
  BlickWidgetsAnswerContentItem,
  BlickWidgetsAnswerLink,
  Message as MessageType,
  QuestionWidgetId,
} from 'types/blick-widgets'
import { ReferenceLink } from './ReferenceLink'
import { Spacer } from 'components/ui-component/Spacer'
import { ArticleSlider } from './ArticleSlider'

type Props = {
  message: MessageType
  index: number
  widgetId: QuestionWidgetId
}

export const Message = ({ message, widgetId }: Props) => {
  const contentItemContent = (contentItem: BlickWidgetsAnswerContentItem, linkItem?: BlickWidgetsAnswerLink) => {
    return (
      <>
        <b>{contentItem.category}</b> {contentItem.text}
        {linkItem && (
          <ReferenceLink href={linkItem?.url} isPlus={linkItem?.isPlus} title={linkItem.title}>
            {contentItem.link}
          </ReferenceLink>
        )}
      </>
    )
  }

  return (
    <>
      {widgetId === 'dailyBriefing' ? (
        <>
          <ul>
            {message.content?.map((contentItem) => {
              return (
                <li key={contentItem.text}>{contentItemContent(contentItem, message?.links?.[contentItem.link])}</li>
              )
            })}
          </ul>
        </>
      ) : (
        <>
          {message.content?.map((contentItem) => {
            return <p key={contentItem.text}>{contentItemContent(contentItem, message?.links?.[contentItem.link])}</p>
          })}
        </>
      )}

      {message.links && (
        <>
          <Spacer v size={32} />
          <ArticleSlider links={message.links} />
        </>
      )}
    </>
  )
}
