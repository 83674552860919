import { Box, LinearProgress, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
import { Spacer } from 'components/ui-component/Spacer'
import { useEffect, useState } from 'react'

const INTIIAL_TIME = 1000 * 60 * 5 // 5 min
const TRANSCRIPTION_TIME_PER_SECOND = 0.3333
const UPDATE_PROGRESS_INTERVAL = 1000 * 10

type Props = {
  createdAt: string
  inputAudioFileDuration: number
}

export const JobProgress = ({ createdAt, inputAudioFileDuration }: Props) => {
  const theme = useTheme()
  const [currentTime, setSetCurrentTime] = useState(new Date().getTime())

  const estimatedTranscriptionTime = inputAudioFileDuration
    ? Math.round(inputAudioFileDuration * TRANSCRIPTION_TIME_PER_SECOND) * 1000 + INTIIAL_TIME
    : INTIIAL_TIME // in ms
  const estimatedRemainingTime = new Date(createdAt).getTime() + estimatedTranscriptionTime - currentTime // in ms
  const progress =
    estimatedTranscriptionTime > 0 ? 100 - Math.round((estimatedRemainingTime / estimatedTranscriptionTime) * 100) : 99
  const estimatedRemainingMinutes = Math.round(estimatedRemainingTime / 1000 / 60)

  useEffect(() => {
    const updateProgressInterval = setInterval(() => setSetCurrentTime(new Date().getTime()), UPDATE_PROGRESS_INTERVAL)

    return () => {
      clearInterval(updateProgressInterval)
    }
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: '100%',
        maxWidth: '100px',
      }}
    >
      <Typography variant="caption">
        {estimatedRemainingMinutes > 0 ? (
          <FormattedMessage id="audioToText.estimatedTimeLeft" values={{ value: estimatedRemainingMinutes }} />
        ) : (
          <FormattedMessage id="audioToText.takingLongerThanExpected" />
        )}
      </Typography>
      <Spacer v size={4} />
      <LinearProgress
        variant="determinate"
        value={progress > 99 ? 95 : progress}
        sx={{
          height: '8px',
          borderRadius: '8px',
          width: '100%',
          background: theme.palette.purple[200],
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.purple[500],
          },
        }}
      />
    </Box>
  )
}
