// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewDe = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge ist eine spezialisierte AI-Toolbox für Ringier Medien, die eine Auswahl an Tools bietet, die auf
          generativer KI und anderen KI-Technologien basieren. Diese Tools sind speziell entwickelt, um effektiv
          spezifische redaktionelle Anwendungsfälle zu lösen.
          <br />
          <br />
          Diese innovative Toolbox ist ein Teil der AI Boost-Initiative.
          <br />
          <br />
          Das erste verfügbare Tool ist unser fortschrittliches Sprach-zu-Text-Umwandlungstool, ehemals bekannt als
          AiForge. Es bietet eine einfache Möglichkeit, Video- und Audiodateien in jeder Sprache zu transkribieren, was
          die Inhaltsproduktion vereinfacht.
          <br />
          <br />
          In naher Zukunft planen wir, ein Tool einzuführen, das die Erstellung von Artikeln auf Basis verschiedener
          externer Eingaben wie Polizeiberichte, Medienberichte oder ausländische Nachrichtenartikel ermöglicht. Dies
          wird die Vielfalt und Relevanz der Inhalte erheblich erhöhen.
          <br />
          <br />
          Wir sind bestrebt, regelmäßig neue Funktionen einzuführen und zu testen, um Ihre täglichen Arbeitsabläufe zu
          verbessern und zu unterstützen.
          <br />
          <br />
          Ihr Feedback ist für uns sehr wertvoll. Bitte teilen Sie Ihre Meinung zur Qualität der einzelnen Tools über
          diese Umfrage mit:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          Für alle aufgetretenen Bugs oder Fehler, melden Sie diese bitte hier:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          Für alle anderen Fragen, Anregungen oder Anfragen, kontaktieren Sie bitte:
          <ul>
            <li>
              Lauri Kurki, Projektleiter AI Boost unter{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, AI Innovation Lead Blick Newsroom unter{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
