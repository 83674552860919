import { Box, ClickAwayListener, IconButton, Tooltip } from '@mui/material'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { Spacer } from 'components/ui-component/Spacer'
import { ButtonUnderline } from 'components/ui-component/buttons/ButtonUnderline'
import { useEffect, useRef, useState } from 'react'
// @ts-expect-error
import BeyondWords from '@beyondwords/player'

type Props = {
  projectId: string
  sourceId: string
  playerStyle?: string
}

export const Player = ({ projectId, sourceId, playerStyle }: Props) => {
  const playerRef = useRef(null)
  const [playerInstance, setPlayerInstance] = useState(null)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(() => {
    if (playerRef && !playerInstance) {
      const player = new BeyondWords.Player({
        target: '#player',
        projectId,
        sourceId,
        playerStyle: playerStyle ? playerStyle : 'standard',
        backgroundColor: '#f5f5f5',
        iconColor: '#d02c1e',
        textColor: '#d02c1e',
        widgetStyle: playerStyle ? playerStyle : 'standard',
        logoIconEnabled: false,
        skipButtonStyle: 'seconds-15',
      })

      setPlayerInstance(player)
    }
  }, [playerInstance, playerRef, sourceId, projectId, playerStyle])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <div id="player" ref={playerRef}></div>
      <Spacer h size={14} />
      <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
        <Tooltip // styled in the Wrapper style
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setIsTooltipOpen(false)}
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement="top"
          title={
            <>
              Die Stimme wurde mit Hilfe künstlicher Intelligenz erzeugt.{' '}
              <ButtonUnderline>So nutzt Blick KI zu deinem Vorteil.</ButtonUnderline>
            </>
          }
        >
          <IconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
            <MaterialIcon
              name={isTooltipOpen ? 'cancel' : 'info'}
              fontWeight="300"
              color="#8e8e8e"
              sx={{ cursor: 'pointer' }}
            />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  )
}
