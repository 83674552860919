// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewHu = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          Az AI Forge egy speciálisan tervezett AI eszközgyűjtemény a Ringier Media számára, amely kiválasztott
          eszközöket kínál, amelyek kihasználják a generatív AI-t és más AI technológiákat. Ezek az eszközök célzottan a
          specifikus szerkesztési felhasználási eseteket szolgálják ki hatékonyan.
          <br />
          <br />
          Ez az innovatív eszközgyűjtemény része az AI Boost kezdeményezésnek.
          <br />
          <br />
          Az első elérhető eszköz a fejlett beszédfelismerő konverziós eszközünk, amelyet korábban Blisper néven
          ismertek. Ez egy zökkenőmentes módot kínál videók és hangfájlok lejegyzésére bármely nyelven, egyszerűsítve a
          tartalom létrehozását.
          <br />
          <br />
          A jövőben tervezzük bevezetni azt az eszközt, amely lehetővé teszi cikkek létrehozását különféle külső
          források alapján, például rendőrségi jelentések, médiajelentések vagy külföldi hírek alapján. Ez jelentősen
          javítja a tartalom sokféleségét és relevanciáját.
          <br />
          <br />
          Elkötelezettek vagyunk az új funkciók rendszeres bevezetése és tesztelése mellett, hogy javítsuk és támogassuk
          a mindennapi munkarutinjait.
          <br />
          <br />A visszajelzésed nagyon fontos számunkra. Kérjük, osszd meg véleményedet az egyes eszközök minőségéről
          ezen a felmérésen keresztül:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          Ha bármilyen hibára vagy hibára bukkan, kérjük, jelezze itt:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          Ha bármilyen más kérdése, javaslata vagy érdeklődése van, ne habozzon kapcsolatba lépni:
          <ul>
            <li>
              Lauri Kurki, Projektvezető AI Boost, e-mail cím:{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, AI Innovációs vezető a Blick Newsroom-ban, e-mail cím:{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
