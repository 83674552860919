import React from 'react'

import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { SwiperEvents, SwiperOptions } from 'swiper/types'

import 'swiper/css'
import 'swiper/css/effect-fade'

type TSliderEvents = {
  onInit?: SwiperEvents['init']
  onSlideChange?: SwiperEvents['slideChange']
}

export type SliderSettings = SwiperOptions & TSliderEvents

type Props = {
  settings?: SliderSettings
  children?: React.ReactNode
} & SwiperProps

// swiper
// https://swiperjs.com/swiper-api
// types
// https://swiperjs.com/types/modules
export const Slider = ({ settings, children, ...restProps }: Props) => {
  return (
    <Swiper {...settings} followFinger {...restProps}>
      {React.Children.map(children, (child) => (
        <SwiperSlide style={{ width: 'auto' }}>{child}</SwiperSlide>
      ))}
    </Swiper>
  )
}
