import { routes } from 'constants/routes'
import { IconDashboard } from '@tabler/icons-react'
import { NavItemType } from 'types'

export const main: NavItemType = {
  id: 'main',
  type: 'group',
  breadcrumbs: true,
  children: [
    {
      id: 'dashboard',
      title: routes.dashboard.title,
      icon: IconDashboard,
      type: 'item',
      url: routes.dashboard.url,
      breadcrumbs: false,
    },
    {
      id: 'speech-to-text',
      title: routes.speechToText.title,
      icon: 'volume_up',
      type: 'item',
      url: routes.speechToText.url,
    },
    {
      id: 'title-generator',
      title: routes.titleGenerator.title,
      icon: 'notes',
      type: 'item',
      url: routes.titleGenerator.url,
    },
    {
      id: 'article-generator',
      title: routes.articleGenerator.title,
      icon: 'text_fields',
      type: 'item',
      url: routes.articleGenerator.url,
    },
    {
      id: 'article-summary-generator',
      title: routes.articleSummaryGenerator.title,
      icon: 'format_list_bulleted',
      type: 'item',
      url: routes.articleSummaryGenerator.url,
    },
    {
      id: 'translator',
      title: routes.translator.title,
      icon: 'translate',
      type: 'item',
      url: routes.translator.url,
    },
  ],
}
