export enum Prompt {
  LEAD = 'leadPrompt',
  META_TITLE = 'metaTitlePrompt',
  TEASER = 'teaserPrompt',
  SUMMARY = 'summaryPrompt',
  REWRITE_TEXT = 'rewriteTextPrompt',
  OG_TITLE = 'ogTitlePrompt'
}

export enum ArticlePublications {
  BLICK = 'blick',
  BLICK_ROMANDIE = 'blickRomandie',
  LIBERTATEA = 'ro-libertatea',
  VIVA = 'ro-viva',
  UNICA = 'ro-unica',
  LIBERTATEAPENTRUFEMEI = 'ro-libertateapentrufemei',
  ELLE = 'ro-elle',
  AVANTAJE = 'ro-avantaje',
  TVMANIA = 'ro-tvmania'
}

export enum UserNeeds {
  UPDATE_ME = 'updateMe',
  EDUCATE_ME = 'educateMe',
  GIVE_ME_PERSPECTIVE = 'giveMePerspective',
  DIVERT_ME = 'divertMe',
  INSPIRE_ME = 'inspireMe',
  HELP_ME = 'helpMe',
  CONNECT_ME = 'connectMe',
  KEEP_ME_ENGAGED = 'keepMeEngaged'
}

export const UserNeedsDescriptions: Record<UserNeeds, string> = {
  updateMe:
    'Articles should be concise and direct, focusing on delivering the facts of current events or breaking news. They should answer the who what, when, and where questions quickly, without unnecessary embellishment.',
  educateMe:
    'These articles should break down complex subjects into understandable segments, using clear language and supporting explanations. They may include definitions, background context, and visual aids like charts or infographics to aid comprehension.',
  giveMePerspective:
    'Content should delve into analysis or opinion, providing depth and varied viewpoints on a subject. Writers should incorporate expert opinions, historical context, and potential implications to offer readers a comprehensive understanding of the topic.',
  divertMe:
    'Aim for a lighter tone and entertaining content that provides a reprieve from more serious news. Creative storytelling, humor, and captivating visuals should be used to engage and amuse the reader.',
  inspireMe:
    "Stories should focus on positive outcomes, personal growth, or overcoming adversity. They should be written with empathy and inspiration, highlighting the human spirit's resilience and the possibility of positive change.",
  helpMe:
    "Provide practical, actionable advice aimed at solving readers' problems or improving their lives. The writing should be clear and structured, ideally in a step-by-step format, to guide the reader to a solution.",
  connectMe:
    'Content should emphasize community and shared experiences, aiming to build connections among readers. Articles might highlight communal efforts, shared challenges, or ways to participate in community actions, encouraging reader engagement and interaction.',
  keepMeEngaged:
    'Focus on keeping the reader up-to-date with ongoing trends, discussions, and social media buzz. Articles should curate and contextualize social media content, opinions, and reactions to keep readers informed and involved in the conversation.'
};

export interface PublicationStyle {
  length: Partial<Record<Prompt, PromptLengthConfig>>;
}

export interface PromptConfig {
  userNeedDescription?: string;
  style: string;
  length?: PromptLengthConfig;
}

export type PromptLengthConfig = number | string | string[] | number[];

export const DefaultArticleLength: Record<Prompt, PromptLengthConfig> = {
  [Prompt.LEAD]: 270,
  [Prompt.META_TITLE]: 75,
  [Prompt.OG_TITLE]: 75,
  [Prompt.SUMMARY]: 12,
  [Prompt.TEASER]: [30, 58],
  [Prompt.REWRITE_TEXT]: ''
};

export const PublicationStyles: Record<ArticlePublications, PublicationStyle> = {
  [ArticlePublications.BLICK]: {
    length: {
      [Prompt.LEAD]: 270,
      [Prompt.META_TITLE]: 65,
      [Prompt.OG_TITLE]: 60,
      [Prompt.SUMMARY]: 12,
      [Prompt.TEASER]: [30, 58],
      [Prompt.REWRITE_TEXT]: ''
    }
  },
  [ArticlePublications.BLICK_ROMANDIE]: {
    length: {
      [Prompt.LEAD]: 280,
      [Prompt.META_TITLE]: 75,
      [Prompt.OG_TITLE]: 75,
      [Prompt.SUMMARY]: 75,
      [Prompt.TEASER]: [30, 58]
    }
  },
  [ArticlePublications.LIBERTATEA]: {
    length: {
      [Prompt.LEAD]: 200,
      [Prompt.META_TITLE]: 85,
      [Prompt.OG_TITLE]: 65,
      [Prompt.SUMMARY]: 120,
      [Prompt.TEASER]: [30, 58]
    }
  },
  [ArticlePublications.UNICA]: {
    length: DefaultArticleLength
  },
  [ArticlePublications.LIBERTATEAPENTRUFEMEI]: {
    length: DefaultArticleLength
  },
  [ArticlePublications.ELLE]: {
    length: DefaultArticleLength
  },
  [ArticlePublications.AVANTAJE]: {
    length: DefaultArticleLength
  },
  [ArticlePublications.TVMANIA]: {
    length: DefaultArticleLength
  },
  [ArticlePublications.VIVA]: {
    length: DefaultArticleLength
  }
};
