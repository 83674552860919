import { createContext, ReactNode, useLayoutEffect, useState } from 'react'

// types
import { InitialBrowserExtensionContextProps } from 'types/browserExtension'
import { localStorageGetItem, localStorageRemoveItem } from 'utils/localStorage'

// initial state
const initialState: InitialBrowserExtensionContextProps = {}

// ==============================|| BROWSER Extension CONTEXT & PROVIDER ||============================== //

export const BrowserExtensionContext = createContext(initialState)

type BrowserExtensionProviderProps = {
  children: ReactNode
}

export const BrowserExtensionProvider = ({ children }: BrowserExtensionProviderProps) => {
  const [prefilledArticleText, setPrefilledArticleText] = useState<string | null>()
  const [prefilledArticleUrl, setPrefilledArticleUrl] = useState<string | null>()
  const [prefilledArticleSource, setPrefilledArticleSource] = useState<string | null>()

  const handlePrefiledData = () => {
    setPrefilledArticleText(localStorageGetItem('article-text'))
    setPrefilledArticleUrl(localStorageGetItem('article-url'))
    setPrefilledArticleSource(localStorageGetItem('article-source'))
    localStorageRemoveItem('article-text')
    localStorageRemoveItem('article-url')
    localStorageRemoveItem('article-source')
  }

  useLayoutEffect(() => {
    document.addEventListener('getPrefillData', handlePrefiledData)
  }, [])

  return (
    <BrowserExtensionContext.Provider value={{ prefilledArticleText, prefilledArticleUrl, prefilledArticleSource }}>
      {children}
    </BrowserExtensionContext.Provider>
  )
}
