// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewRo = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge este o trusă AI specializată proiectată pentru Ringier Media, oferind o suită de instrumente
          selectate care utilizează AI generativ și alte tehnologii AI. Aceste instrumente sunt adaptate pentru a aborda
          în mod eficient cazurile specifice de utilizare editorială.
          <br />
          <br />
          Această trusă inovatoare face parte din inițiativa AI Boost.
          <br />
          <br />
          Primul instrument disponibil este instrumentul nostru avansat de conversie a vorbirii în text, cunoscut
          anterior sub numele de Blisper. Oferă o modalitate fără sudură de a transcrie fișiere video și audio în orice
          limbă, simplificând crearea de conținut.
          <br />
          <br />
          Privind înainte, planificăm să introducem un instrument care va permite crearea de articole bazate pe diverse
          intrări externe, cum ar fi rapoartele de poliție, rapoartele media sau articolele de știri străine. Acest
          lucru va îmbunătăți în mod semnificativ diversitatea și relevanța conținutului.
          <br />
          <br />
          Suntem angajați să introducem și să testăm în mod regulat noi funcționalități pentru a îmbunătăți și a susține
          rutinele dvs. zilnice de lucru.
          <br />
          <br />
          Feedback-ul dumneavoastră este neprețuit pentru noi. Vă rugăm să vă împărtășiți gândurile despre calitatea
          instrumentelor individuale prin această sondaj:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />
          Pentru orice bug-uri sau erori întâlnite, vă rugăm să le raportați aici:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />
          Pentru orice alte întrebări, sugestii sau solicitări, nu ezitați să ne contactați:
          <ul>
            <li>
              Lauri Kurki, Coordonator de Proiect AI Boost la{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, Coordonator Inovație AI la Blick Newsroom la{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
