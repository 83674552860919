// ==============================|| TEXT TO SPEECH DEMO PAGE ||============================== //
import breadcrump from './images/article1/breadcrump.png'
import social from './images/article1/social.png'
import slider from './images/article1/slider.png'
import footer from './images/footer.png'
import { Spacer } from 'components/ui-component/Spacer'
import { Wrapper } from './components/Wrapper'
import { Header } from './components/Header'
import { SubTitle, Summary, TextWrapper, Title, Text, TextTitle } from './components/Typography'
import './fonts/fonts.css'
import { Player } from './components/Player'
import { Box } from '@mui/material'

export const Article1View = () => {
  return (
    <Wrapper>
      <Header />
      <Spacer v size={8} />
      <TextWrapper>
        <img alt="breadcrump" src={breadcrump} />
        <Spacer v size={16} />
        <SubTitle
          sx={{
            fontSize: {
              sm: '5vw',
              md: '45px',
            },
          }}
        >
          Bradley Cooper
        </SubTitle>
        <Spacer v size={8} />
        <Title
          sx={{
            fontSize: {
              sm: '9vw',
              md: '80px',
            },
          }}
        >
          Darum läuft er zu Hause ständig nackt herum
        </Title>
        <Spacer v size={24} />
        <Summary>
          Schauspieler Bradley Cooper behält sein Privatleben gerne für sich. Doch nun gab er einen interessanten
          Einblick, wie es bei ihm daheim abläuft. Dort hält er sich offenbar am liebsten nackt auf.
        </Summary>
        <Spacer v size={16} />
        <img alt="social" src={social} />
        <Spacer v size={16} />
        <Player projectId="39342" sourceId="36ff43e0-f635-4862-9ee5-862bece252f4" />
        <Spacer v size={32} />
        <Box sx={{ margin: '0 -16px' }}>
          <img alt="slider" src={slider} width="100%" />
        </Box>
        <Spacer v size={48} />
        <Text>
          Nackte Tatsachen: «Maestro»-Star Bradley Cooper (49) hat offenbart, dass er daheim am liebsten so herumläuft,
          wie Gott ihn schuf. Auch den Grund für diese besondere Leidenschaft verriet er in einem Podcast.
        </Text>
        <Spacer v size={24} />
        <Text>
          Im Gespräch mit seinem Kollegen Dax Shepard (49) erzählte er, dass er sich nackt «absolut» wohlfühle. Das
          Thema kam im «Armchair Expert»-Podcast auf, als Co-Moderator Shepard seine Morgenroutine erklärte und
          erwähnte, dass seine Töchter mit ihm reden, während er auf die Toilette geht. Wenn er auf dem eigentlich
          stillen Örtchen sitze, «fangen die Mädchen an, sich zu melden, sie fangen an, mit mir zu plaudern, und es ist
          erstaunlich, dass es ihnen egal ist», so Shepard, der mit Schauspielerin Kristen Bell (43) die Töchter Delta
          (9) und Lincoln (10) hat. Er fragte, ob es bei Coopers Tochter Lea (6) ähnlich sei.
        </Text>
        <Spacer v size={48} />
        <TextTitle>Nie allein auf der Toilette</TextTitle>
        <Spacer v size={16} />
        <Text>
          Lea stammt aus der früheren Beziehung zu Model Irina Shayk (38). Cooper erläuterte daraufhin, dass seine
          Wohnung in New York City speziell geschnitten sei: So befänden sich sein Schlafzimmer und das Bad in einem
          Raum: «Es ist 24/7, Alter! Es gibt keine Türen. Die Treppe geht nach oben und es ist alles eine Etage.»
          Deshalb nehme auch seine Tochter an allem teil: «Wir reden dort, wenn ich auf der Toilette bin, sie ist in der
          Badewanne, das ist sozusagen die Anlaufstelle.»
          <Spacer v size={16} />
          Das Interessante sei, dass er selbst «nicht so aufgewachsen» sei. «Überhaupt nicht. Ich glaube nicht, dass ich
          meinen Vater jemals auf der Toilette gesehen habe, bis er krank wurde. Sozusagen noch nie in meinem Leben.»
          Coopers Vater ist 2011 an Krebs gestorben. Anders sei es jedoch mit dem Thema Nacktheit. Nachdem Shepard
          gestanden hatte, seine Familie lebe «auf schwedische Art» und sei die ganze Zeit nackt, bestätigte Cooper:
          «Ich auch. Und übrigens, ich war auch so. Nicht bei meiner Mutter, sondern bei meinem Vater. Mein Vater war
          immer nackt, ich habe immer mit ihm geduscht.» Auf die Feststellung von Shepard, dass Cooper sich nackt
          anscheinend sehr wohlfühle, antwortete der: «Absolut.»
        </Text>
        <Spacer v size={16} />
        <TextTitle>Schnapp sich Cooper dieses Jahr einen Oscar?</TextTitle>
        <Spacer v size={16} />
        <Text>
          Bradley Cooper kann sich in diesem Jahr wieder einmal Oscar-Hoffnungen machen. Für «Maestro» ist er als bester
          Hauptdarsteller im Rennen. Er war schon häufiger nominiert, konnte die begehrte Trophäe bisher allerdings
          nicht ergattern. Seinen Durchbruch feierte er im Jahr 2009 mit der Las-Vegas-Komödie «Hangover». Sein
          Regiedebüt gab er 2018 mit «A Star Is Born», in dem er neben Lady Gaga (37) auch die Hauptrolle spielte. Der
          Film war 2019 bei den Oscars als bester Film nominiert, Cooper als bester Hauptdarsteller. (Spot on)
        </Text>
        <Spacer v size={48} />
      </TextWrapper>
      <img alt="footer" src={footer} width="100%" />
    </Wrapper>
  )
}
