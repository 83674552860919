// material-ui
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1301,
  width: '100%',
})

// ==============================|| LOADER ||============================== //

type Props = {
  position?: string
}

export const Loader = ({ position = 'fixed' }: Props) => (
  <LoaderWrapper sx={{ position }}>
    <LinearProgress color="primary" />
  </LoaderWrapper>
)
