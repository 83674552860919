/**
 * axios setup to use mock service
 */

import axios from 'axios'
import { config } from 'config'
import { router } from 'routes'
import { getLoginRedirectUrl } from './url'
import { logger } from 'logger'

export const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true, //TODO: Backend should check auth
})

// interceptor for http
axiosInstance.interceptors.response.use(
  (response) => {
    response.config.headers['Response-Time'] = new Date().getTime() - response.config.headers['Start-Time']
    return response
  },
  (error) => {
    const isSessionUrl = error.request.responseURL.indexOf('/auth/session') > -1 // let session request return promise error so session flag can be set

    if (error.response.status === 401 && !isSessionUrl) {
      router.navigate(getLoginRedirectUrl(window.location.pathname))
    }

    logger.error(error)
    return Promise.reject((error.response && error.response.data) || 'Wrong Services')
  },
)

axiosInstance.interceptors.request.use((request) => {
  const params = new URLSearchParams(window.location.search)
  const userId = params.get('userId')
  request.headers['Start-Time'] = new Date().getTime()

  if (userId) {
    request.headers['X-Debug-Userid'] = userId
  }

  return request
})
