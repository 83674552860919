// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const LearnMoreViewRo = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.learnMore" />}>
        <Typography variant="bodybg">
          Bine ai venit în colecția noastră selectată de conținut gratuit de învățare despre Inteligența Artificială
          (AI)! Aici vei găsi o gamă excitantă de videoclipuri care acoperă diverse aspecte ale AI, inclusiv AI
          Generativ. Indiferent dacă abia începi sau ai deja cunoștințe avansate, aici este ceva pentru toată lumea.
          <br />
          <br />
          Această colecție este perfectă pentru tine dacă vrei să-ți extinzi cunoștințele în domeniul AI, fie din motive
          profesionale, fie din interes personal. Poți parcurge conținutul în ritmul tău și să te educi confortabil de
          acasă.
          <br />
          <br />
          Pătrunde în fascinantul univers al Inteligenței Artificiale și profită de oportunitatea de a te familiariza cu
          cele mai recente tendințe și tehnologii. Sper că vei găsi aceste resurse valoroase și îmbogățitoare. Bucură-te
          de călătoria ta de învățare!
        </Typography>
        <Typography variant="h3">AI în general:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://www.ibm.com/blog/ai-vs-machine-learning-vs-deep-learning-vs-neural-networks/"
          >
            AI vs. Învățare Automată vs. Învățare Profundă vs. Rețele Neuronale (IBM; Articol)
          </ExternalLink>{' '}
          - Articol explicativ despre diferențele dintre aceste concepte și o definiție utilă a Inteligenței
          Artificiale.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/watch?si=sbP_6J9KWfavvYkQ&v=zjkBMFhNj_g&feature=youtu.be"
          >
            Introducere în Modelele de Limbaj Mari (YouTube)
          </ExternalLink>{' '}
          - Acesta este un introductiv pentru publicul general de o oră despre Modelele de Limbaj Mari: componentele
          tehnice de bază din spatele sistemelor precum ChatGPT, Claude și Google Bard. Ce sunt, încotro se îndreaptă,
          comparații și analogii cu sistemele de operare actuale și unele dintre provocările legate de securitate ale
          acestui nou paradigmă de calcul.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/536?catalog_rank=%7B%22rank%22%3A2%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Introducere în AI Generativ (Google)
          </ExternalLink>{' '}
          - Acesta este un curs de microînvățare la nivel introductiv care vizează explicarea a ceea ce este AI
          Generativ, cum este folosit și cum diferă de metodele tradiționale de învățare automată. De asemenea, acoperă
          Instrumentele Google pentru a vă ajuta să dezvoltați propriile aplicații Gen AI.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.cloudskillsboost.google/course_templates/539?catalog_rank=%7B%22rank%22%3A3%2C%22num_filters%22%3A1%2C%22has_search%22%3Afalse%7D"
          >
            Introducere în Modelele de Limbaj Mari (LLMs) (Google)
          </ExternalLink>{' '}
          - Acesta este un curs de micro-învățare la nivel introductiv care explorează ce sunt modelele de limbaj mari
          (LLM), cazurile de utilizare în care pot fi utilizate și modul în care puteți utiliza ajustarea promptului
          pentru a îmbunătăți performanța LLM. De asemenea, acoperă instrumentele Google pentru a vă ajuta să dezvoltați
          propriile aplicații Gen AI.
        </Typography>
        <Typography variant="h3">Prompting:</Typography>
        <Typography variant="bodybg">
          <ExternalLink
            target="_blank"
            href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17763/foundations-of-prompt-engineering"
          >
            Fundamentul Ingineriei de Prompt (AWS)
          </ExternalLink>{' '}
          - În acest curs, vei învăța principiile, tehnicile și cele mai bune practici pentru proiectarea prompturilor
          eficiente. Acest curs introduce elementele de bază ale ingineriei promptului și avansează către tehnici de
          prompt avansate. Veți învăța, de asemenea, cum să vă protejați împotriva abuzului de prompt și cum să reduceți
          prejudecățile în interacțiunea cu Modelele de Bază.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.coursera.org/learn/prompt-engineering">
            Inginerie de Prompt (Coursera)
          </ExternalLink>{' '}
          - În acest curs, vei învăța principiile, tehnicile și cele mai bune practici pentru proiectarea prompturilor
          eficiente. Acest curs introduce elementele de bază ale ingineriei promptului și avansează către tehnici de
          prompt avansate. Veți învăța, de asemenea, cum să vă protejați împotriva abuzului de prompt și cum să reduceți
          prejudecățile în interacțiunea cu Modelele de Bază.
          <br />
          <br />
          <ExternalLink
            target="_blank"
            href="https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/"
          >
            Ingineria de Prompt ChatGPT
          </ExternalLink>{' '}
          - Aflați cele mai bune practici de inginerie a promptului pentru dezvoltarea aplicațiilor. Descoperiți noi
          modalități de utilizare a LLM-urilor, inclusiv cum să vă construiți propriul chatbot personalizat. Obțineți
          practică practică scriind și iterând prompturile singuri folosind API-ul OpenAI
          <br />
          <br />
          <ExternalLink target="_blank" href="https://youtu.be/yT0qmiC0jOI?si=wWULAIal0i4RdsPH">
            Naklánire Eficiente pentru ChatGPT4 (YouTube)
          </ExternalLink>{' '}
          - Acest videoclip explică cum să creați prompturi eficiente ChatGPT 4 pentru a vă ajuta să obțineți cel mai
          mult din asistentul dvs. AI. Indiferent dacă sunteți un dezvoltator sau doar cineva care vrea să își
          îmbunătățească performanța asistentului AI, acest videoclip este pentru dvs.
        </Typography>
        <Typography variant="h3">Crearea de Imagini:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://youtu.be/ITInwQ6IhIw?si=iv3zkN2Uvu5tJ75G">
            Curs Midjourney pentru Începători (YouTube)
          </ExternalLink>{' '}
          - În acest curs, veți învăța toate detaliile generării de artă AI folosind platforma Midjourney. Veți
          descoperi fundamentalele de creare și gestionare a prompturilor, lucrând cu diferite versiuni ale modelelor și
          explorând tehnici avansate pentru a crea imagini impresionante.
        </Typography>
      </MainCard>
    </Text>
  )
}
