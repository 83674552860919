import { routes } from 'constants/routes'
import { AIForgeFeatures } from 'shared'

export const preserveUserId = (url: string) => {
  try {
    if (window) {
      const params = new URLSearchParams(window.location.search)
      const userId = params.get('userId')
      if (userId) {
        return `${url}?userId=${userId}`
      }
    }
  } catch (e) {
    console.error(e)
  }

  return url
}

export const preserveRedirectUrl = (url: string) => {
  try {
    if (window) {
      const params = new URLSearchParams(window.location.search)
      const redirectUrl = params.get('redirectUrl')
      if (redirectUrl) {
        return `${url}?redirectUrl=${redirectUrl}`
      }
    }
  } catch (e) {
    console.error(e)
  }

  return url
}

export const getLoginRedirectUrl = (pathname: string) => {
  const searchParams = new URLSearchParams()
  if (pathname !== '/') {
    searchParams.append('redirectUrl', encodeURIComponent(pathname))
  }
  return `/login?${searchParams.toString()}` // should come from routes but when preserveUserId is removed
}

export const isPathRestricted = (pathname: string, restriction?: AIForgeFeatures[]) => {
  if (restriction?.length === 0 || !restriction) {
    return false
  }

  const route = Object.values(routes).find((route) => route.url === pathname)

  if (!route || !route?.feature) {
    return false
  }

  //restriction is a whitelist of features, if it's empty all featuares/routes are available if not only the ones in the list are available
  return restriction.indexOf(route.feature) === -1
}
