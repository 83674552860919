import { Box, Button, Divider, Rating, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { Spacer } from 'components/ui-component/Spacer'
import Lottie from 'lottie-react'
import { useState } from 'react'
import writingPen from 'views/ArticleSummaryGenerator/lotties/writing_pen.json'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { RotatingIcon } from 'views/TextToText/components/RotatingIcon'
import { RateModal, RateModalConfig, onTrackRatingFunction } from 'components/RateModal'
import starsBluePurple from 'assets/lotties/stars-bluepurple.json'
import { FormattedMessage } from 'components/FormattedMessage'

type Props = {
  isLoading: boolean
  summary?: string[]
  onRegenerate: () => void
  onTrack: onTrackRatingFunction
}

export const ArticleSummaryPreview = ({ isLoading, summary, onRegenerate, onTrack }: Props) => {
  const theme = useTheme()
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(true)
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)
  const [rateModalConfig, setRateModalConfig] = useState<RateModalConfig | null>(null)
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const onRegenerateClick = () => {
    onRegenerate()
    setIsFeedbackSent(false)
  }

  const getLastRows = () => {
    const rows = []
    for (let i = 0; i < 2; i++) {
      rows.push(
        <span key={i}>
          <Spacer v size={20} />
          <TextPlaceholder />
        </span>,
      )
    }
    return rows
  }

  return (
    <Wrapper
      sx={{
        padding: {
          md: '20px 40px',
          sm: '20px 16px',
          xs: '20px 16px',
        },
      }}
    >
      <StyledSvg width="112" height="29" viewBox="0 0 112 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.608 28V27.316H0.874H1.292C2.47 27.316 3.572 26.898 3.572 24.58V4.174L1.9 1.932H0.722V1.248H8.55L22.876 19.678H22.952V4.098C22.952 2.654 22.04 1.932 20.368 1.932H19.646V1.248H26.828V1.932H26.296C24.586 1.932 23.864 3.034 23.864 3.984V28.456H22.268L4.56 5.352H4.484V24.77C4.484 26.936 5.32 27.316 7.448 27.316H7.79V28H0.608ZM28.2598 28V27.316H31.3378V1.932H28.2598V1.248H49.5018V8.81H48.8178V8.278C48.8178 4.82 46.2718 1.932 41.7498 1.932H37.2658V13.56H39.8878C42.3578 13.56 43.6498 12.154 43.6498 8.544V8.126H44.3338V20.134H43.6498V19.26C43.6498 16.562 42.3578 14.244 39.8878 14.244H37.2658V27.316H42.7378C48.9698 27.316 49.8818 22.148 49.8818 19.982V19.45H50.5658V28H28.2598ZM55.9995 1.932H53.4915V1.248H64.4735V1.932H62.1935L68.1215 19.792H68.1975L71.4655 9.57L68.9955 1.932H66.2595V1.248H78.0395V1.932H75.0755L80.8515 19.906H80.9275L85.2595 6.264C85.6775 4.972 85.9055 4.06 85.9055 3.528C85.9055 2.882 85.5635 1.932 83.3215 1.932H83.0555V1.248H90.0475V1.932H89.7055C88.6795 1.932 87.4255 2.692 86.4375 5.656L78.7615 28.608H77.8495L71.9595 10.824H71.8835L65.9935 28.608H65.0435L55.9995 1.932ZM93.0495 28.038V17.93H93.7335C94.6455 22.224 97.5335 27.924 102.664 27.924C105.932 27.924 108.136 25.91 108.136 22.566C108.136 14.282 92.9735 20.248 92.9735 8.81C92.9735 3.794 96.3175 0.639999 101.334 0.639999C105.172 0.639999 106.388 2.692 108.098 2.692C109.238 2.692 109.314 1.704 109.314 1.21H109.998V9.76H109.314C108.364 5.732 106.236 1.324 101.448 1.324C98.5595 1.324 96.3935 3.262 96.3935 6.188C96.3935 13.522 111.784 7.746 111.784 19.032C111.784 24.96 108.44 28.608 103.082 28.608C99.7755 28.608 96.6595 26.632 95.1395 26.632C94.1135 26.632 93.7335 27.316 93.7335 28.038H93.0495Z"
          fill="#697586"
        />
      </StyledSvg>
      <Spacer v size={16} />
      <Divider />
      <Spacer v size={matchDownMd ? 24 : 40} />
      <TextPlaceholder sx={{ width: '60%', height: '16px' }} />
      <Spacer v size={20} />
      <TextPlaceholder sx={{ width: '70%', height: '16px' }} />
      <Spacer v size={matchDownMd ? 24 : 40} />
      <SummaryBox>
        {!isLoading && !summary && (
          <Box sx={{ height: '148px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color={theme.palette.purple[500]} textAlign="center" variant={'h4'}>
              <FormattedMessage id="articleSummary.summaryWillAppearHere" />
            </Typography>
          </Box>
        )}
        {isLoading && (
          <>
            <Typography color={theme.palette.purple[500]} textAlign="center" variant={'h4'}>
              <FormattedMessage id="articleSummary.summaryCreationInProgress" />
            </Typography>
            <StyledLottie animationData={writingPen} />
          </>
        )}
        {!isLoading && summary && (
          <>
            <StyledMuiAccordion
              defaultExpanded={true}
              expanded={isSummaryExpanded}
              onChange={() => setIsSummaryExpanded(!isSummaryExpanded)}
            >
              <MuiAccordionSummary expandIcon={<MaterialIcon name="expand_more" />}>
                <FormattedMessage id="articleSummary.articleSummary" />
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <ul>
                  {summary.map((item, index) => (
                    <li key={`summary-${index}`}>{item}</li>
                  ))}
                </ul>
                <Spacer v size={16} />
                <Typography variant="bodysm" color={theme.palette.purple[500]}>
                  <FormattedMessage id="articleSummary.disclaimer" />
                </Typography>
              </MuiAccordionDetails>
            </StyledMuiAccordion>
          </>
        )}
      </SummaryBox>
      {!isLoading && summary && (
        <>
          <Spacer v size={24} />
          <ActionsContainer>
            <Button
              variant="text"
              sx={{
                textTransform: 'uppercase',
                color: theme.palette.blue[500],
                fontSize: '12px',
                lineHeight: '20px',
                position: 'relative',
                zIndex: '1',
              }}
              startIcon={
                <RotatingIcon $enabled={isLoading} className="material-symbols-outlined">
                  autorenew
                </RotatingIcon>
              }
              disabled={isLoading}
              onClick={onRegenerateClick}
            >
              <FormattedMessage id="common.regenerate" />
            </Button>
            {isFeedbackSent ? (
              <>
                <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.purple[500] }}>
                  <Stars $matchDownMd={matchDownMd} animationData={starsBluePurple} loop={true} />
                  {matchDownMd ? (
                    <FormattedMessage id="articleSummary.thanksForRating" />
                  ) : (
                    <FormattedMessage id="rating.thanksForYourValuableFeedback" />
                  )}
                </Typography>
              </>
            ) : !isLoading ? (
              <RatingContainer>
                {matchDownMd ? <FormattedMessage id="rating.rate" /> : <FormattedMessage id="rating.rateResult" />}
                <Rating
                  sx={{
                    color: theme.palette.purple[500],
                    '& .MuiRating-iconEmpty': { color: theme.palette.purple[500] },
                  }}
                  onChange={(e, value) => {
                    setRateModalConfig({
                      title: 'article summary',
                      defaultValue: `${value}`,
                    })
                  }}
                />
              </RatingContainer>
            ) : null}
          </ActionsContainer>
        </>
      )}
      {matchDownMd && (
        <>
          <Spacer v size={24} />
          <Divider />
        </>
      )}
      <Spacer v size={matchDownMd ? 16 : 40} />
      <Box sx={{ display: 'flex' }}>
        <div>
          <svg width="56" height="59" viewBox="0 0 56 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.12 2.12006V27.0001H23.6C32.8 26.5201 35.12 23.0001 34.88 13.0001C34.88 3.08006 31.76 2.12006 24.96 2.12006H19.12ZM0.16 57.0001V55.5601H6.64V2.12006H0.16V0.680057H25.28C34.08 0.680057 48.32 2.12006 48.32 13.8801C48.32 23.5601 37.6 26.7601 29.52 27.3201V27.4801C41.28 28.5201 47.04 33.0801 47.2 41.6401C47.36 54.2001 48.48 55.4001 51.68 55.4001C52.48 55.4001 53.68 54.9201 54.56 54.3601L55.12 55.5601C52.32 57.6401 48.4 58.2801 45.36 58.2801C36.8 58.2801 34.72 52.2001 34.72 43.4001C34.72 30.5201 31.6 28.6001 23.36 28.4401H19.12V55.5601H25.84V57.0001H0.16Z"
              fill="#E0E0E0"
            />
          </svg>
        </div>
        <Spacer h size={20} />
        <Box sx={{ width: '100%', marginTop: '8px' }}>
          <TextPlaceholder />
          <Spacer v size={20} />
          <TextPlaceholder />
        </Box>
      </Box>
      {getLastRows()}

      {rateModalConfig && (
        <RateModal
          isOpen={Boolean(rateModalConfig)}
          onClose={() => setRateModalConfig(null)}
          config={rateModalConfig}
          onConfirm={() => setIsFeedbackSent(true)}
          onTrack={onTrack}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')(({ theme }) => ({
  borderRadius: '12px',
  border: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.grey[50],
  display: 'flex',
  flexDirection: 'column',
}))

const StyledSvg = styled('svg')({
  margin: '9px auto',
})

const TextPlaceholder = styled('div')(({ theme }) => ({
  background: theme.palette.grey[300],
  height: '12px',
  width: '100%',
  borderRadius: '12px',
}))

const SummaryBox = styled('div')(({ theme }) => ({
  background: theme.palette.purple[50],
  borderRadius: '8px',
  padding: '16px',
  minHeight: '180px',
}))

const StyledLottie = styled(Lottie)({
  height: '180px',
  marginTop: '-20px',
  marginBottom: '-36px',
})

const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  background: 'transparent',
  '& .MuiButtonBase-root, & .MuiButtonBase-root.Mui-expanded': {
    padding: '0',
    minHeight: 'auto',
    color: theme.palette.purple[500],
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.purple[500],
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
      color: theme.palette.purple[500],
      fontWeight: '700',
    },
  },
  '& .MuiAccordionDetails-root': {
    marginTop: '16px',
    padding: '0',
    color: theme.palette.purple[500],
    fontWeight: '500',
    fontSize: '16px',
    '& ul': {
      margin: 0,
    },
  },
}))

const ActionsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RatingContainer = styled('div')`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.palette.purple[500]};
  font-size: 12px;
  line-height: 20px;
`

const Stars = styled(Lottie)<{ $matchDownMd: boolean }>`
  position: absolute;
  margin-top: ${({ $matchDownMd }) => ($matchDownMd ? '-30px' : '-50px')};
  margin-left: ${({ $matchDownMd }) => ($matchDownMd ? '0px' : '50px')};
  height: 120px;
  width: 120px;
`
