import { AxiosError } from 'axios'
import { config } from 'config'
import { QuestionWidgetId } from 'types/blick-widgets'
import {
  ChatConversationResponse,
  ChatConversationsResponse,
  ChatHistoryResponse,
  ChatPromptResponse,
  ChatQuestionsResponse,
} from 'types/chatbot'
import { axiosInstance } from 'utils/axios'

export const postPrompt = async (
  text: string,
  isSuggestedQuestion: boolean,
  context?: string,
  conversationId?: string,
) => {
  const url = `${config.apiUrl}/chatbot/chat/chat`
  try {
    const result = await axiosInstance.post<ChatPromptResponse>(
      url,
      {
        publication: 'blick',
        text,
        ...(context && { context }),
        ...(conversationId && { conversationId }),
        isSuggestedQuestion,
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getHistory = async (sessionId: string) => {
  const url = `${config.apiUrl}/chatbot/chat/session/${sessionId}`
  try {
    const result = await axiosInstance.get<ChatHistoryResponse>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const deleteHistory = async (sessionId: string) => {
  const url = `${config.apiUrl}/chatbot/chat/session/${sessionId}`
  try {
    const result = await axiosInstance.delete(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result.status === 200
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const sendFeedback = async (sessionId: string, index: number, feedback: string) => {
  const url = `${config.apiUrl}/chatbot/chat/session/${sessionId}/${index}`
  try {
    const result = await axiosInstance.post(
      url,
      {
        feedback,
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getQuestions = async (value: { context?: string; text?: string }) => {
  const url = `${config.apiUrl}/chatbot/questions/questions`
  try {
    const result = await axiosInstance.post<ChatQuestionsResponse>(
      url,
      {
        publication: 'blick',
        ...(value.context && { context: value.context }),
        ...(value.text && { text: value.text }),
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
    )

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getCachedQuestions = async (widgetId: QuestionWidgetId, articleId = 'home') => {
  const url = `${config.apiUrl}/chatbot/questions/questions?publication=blick&widgetId=${widgetId}&context=${articleId}`
  try {
    const result = await axiosInstance.get<ChatQuestionsResponse>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getCachedAnswer = async (questionId: string, widgetId: QuestionWidgetId, articleId = 'home') => {
  const url = `${config.apiUrl}/chatbot/questions/answer?questionId=${questionId}&publication=blick&widgetId=${widgetId}&context=${articleId}`
  try {
    const result = await axiosInstance.get<ChatPromptResponse>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getConversations = async () => {
  try {
    const result = await axiosInstance.get<ChatConversationsResponse>('/analytics/conversations/')

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}

export const getConversation = async (id: string) => {
  try {
    const result = await axiosInstance.get<ChatConversationResponse>(
      `/analytics/conversations/${encodeURIComponent(id)}`,
    )

    return result
  } catch (e) {
    return new AxiosError(String(e))
  }
}
