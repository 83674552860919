import { styled } from '@mui/material'

export const SubTitle = styled('div')`
  font-family: GoodOT;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: rgb(226, 0, 0);
`

export const Title = styled('div')`
  font-family: GoodOTNarrow;
  font-size: 9vw;
  font-weight: bold;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: 0px;
  text-align: left;
  color: rgb(31, 31, 31);
`

export const TextWrapper = styled('div')`
  padding: 0 12px;
`

export const Summary = styled('div')`
  font-family: GoodOT;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  line-height: 1.4;
  text-align: left;
  color: rgb(31, 31, 31);
`

export const SmallText = styled('div')`
  color: rgb(31, 31, 31);
  font-size: 15px;
  line-height: 1.5;
`

export const TextTitle = styled('div')`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 22px;
  line-height: 1.3;
  font-weight: bold;
`

export const Text = styled('div')`
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(31, 31, 31);
  font-size: 19px;
  line-height: 1.5;
`
