import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { FormattedMessage } from 'components/FormattedMessage'
import { useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ToolboxDownloadOptions } from 'types/common'

type Props = {
  text: string
  onDownload?: (type: ToolboxDownloadOptions) => void
  isLoading: boolean
  original?: boolean
}

const COPY_TO_CLIPBOARD_TIMEOUT = 2000 // 2s

export const CopyDownloadActions = ({ text, onDownload, isLoading, original }: Props) => {
  const theme = useTheme()
  const [copied, setCopied] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const onCopyClick = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), COPY_TO_CLIPBOARD_TIMEOUT)
  }
  return (
    <CopyDownloadContainer sx={{ justifyContent: matchDownMd ? 'flex-start' : 'flex-end' }}>
      <CopyToClipboard text={text}>
        <Button
          variant="text"
          disabled={!text || isLoading}
          sx={{
            color: copied ? theme.palette.success.dark : theme.palette.grey[700],
            fontSize: '14px',
            fontWeight: '400',
            textTransform: 'none',
            justifyContent: 'start',
            paddingRight: '0px',
            '&:focus': {
              border: 'none',
              padding: '12px 8px !important',
            },
          }}
          startIcon={
            <ButtonIcon className="material-symbols-outlined">{copied ? 'check_circle' : 'content_copy'}</ButtonIcon>
          }
          onClick={onCopyClick}
        >
          {copied ? 'Copied' : 'Copy text'}
        </Button>
      </CopyToClipboard>
      {onDownload && (
        <>
          <Button
            ref={anchorRef}
            variant="text"
            disabled={!text || isLoading}
            sx={{
              color: theme.palette.grey[700],
              fontSize: '14px',
              fontWeight: '400',
              textTransform: 'none',
              justifyContent: 'start',
              paddingRight: '0px',
              '&:focus': {
                border: 'none',
                padding: '12px 8px !important',
              },
            }}
            endIcon={<ButtonIcon className="material-symbols-outlined">expand_more</ButtonIcon>}
            onClick={handleToggle}
          >
            <FormattedMessage id="audioToText.downloadAs" />
            ...
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal={false}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <StyledPaper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      sx={{ padding: '0' }}
                    >
                      <StyledMenuItem onClick={() => onDownload(ToolboxDownloadOptions.TXT)}>
                        <span className="material-symbols-outlined">download</span>
                        <FormattedMessage id="audioToText.downloadAs" /> TXT
                      </StyledMenuItem>
                      <StyledMenuItem onClick={() => onDownload(ToolboxDownloadOptions.PDF)}>
                        <span className="material-symbols-outlined">download</span>
                        <FormattedMessage id="audioToText.downloadAs" /> PDF
                      </StyledMenuItem>
                      <StyledMenuItem onClick={() => onDownload(ToolboxDownloadOptions.DOCX)}>
                        <span className="material-symbols-outlined">download</span>
                        <FormattedMessage id="audioToText.downloadAs" /> DOCX
                      </StyledMenuItem>
                      <StyledMenuItem onClick={() => onDownload(ToolboxDownloadOptions.SRT)}>
                        <span className="material-symbols-outlined">download</span>
                        <FormattedMessage id="audioToText.downloadAs" /> SRT
                      </StyledMenuItem>
                      {original && (
                        <StyledMenuItem onClick={() => onDownload(ToolboxDownloadOptions.VTT)}>
                          <span className="material-symbols-outlined">download</span>
                          <FormattedMessage id="audioToText.downloadAs" /> VVT
                        </StyledMenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </StyledPaper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </CopyDownloadContainer>
  )
}

const ButtonIcon = styled('span')`
  font-size: 24px;
`

const CopyDownloadContainer = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 16px;
`

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  > span {
    margin-right: 8px;
  }
  padding-left: 24px;
  padding-top: 6px;
  padding-bottom: 6px;

  &:hover {
    background: #def3fb;
  }
`

const StyledPaper = styled(Paper)`
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.13);
  min-width: 280px;
`
