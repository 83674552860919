import { Login } from 'views/Authentication/Login'
// import { ChatbotView } from 'views/Chatbot/Chatbot'

export const AuthRoutes = {
  children: [
    {
      path: '/login',
      element: <Login />,
    },
  ],
}
