import { FormattedMessage } from 'components/FormattedMessage'
import { AIForgeFeatures } from 'shared'
import { Routes } from 'types/routes'

export const routes: Routes = {
  dashboard: {
    title: <FormattedMessage id="pages.dashboard" />,
    url: '/dashboard',
  },
  speechToText: {
    title: <FormattedMessage id="pages.speechToText" />,
    url: '/speech-to-text/transcriptions',
    feature: AIForgeFeatures.SPEECH_TO_TEXT,
  },
  titleGenerator: {
    title: <FormattedMessage id="pages.titleGenerator" />,
    url: '/title-generator',
    feature: AIForgeFeatures.TITLE_GENERATOR,
  },
  articleGenerator: {
    title: <FormattedMessage id="pages.articleGenerator" />,
    url: '/article-generator',
    feature: AIForgeFeatures.ARTICLE_GENERATOR,
  },
  articleSummaryGenerator: {
    title: <FormattedMessage id="pages.articleSummaryGenerator" />,
    url: '/article-summary-generator',
    feature: AIForgeFeatures.ARTICLE_SUMMARY_GENERATOR,
  },
  translator: {
    title: <FormattedMessage id="pages.translator" />,
    url: '/translator',
    feature: AIForgeFeatures.TRANSLATOR,
  },
  chatbot: {
    title: <FormattedMessage id="pages.chatbot" />,
    url: '/chatbot',
    feature: AIForgeFeatures.CHATBOT,
  },
  chatbotDev: {
    title: <FormattedMessage id="pages.chatbotDev" />,
    url: '/chatbot-dev',
    feature: AIForgeFeatures.CHATBOT,
  },
  chatbotAdmin: {
    title: <FormattedMessage id="pages.chatbotAdmin" />,
    url: '/chatbot-admin',
    feature: AIForgeFeatures.CHATBOT,
  },
  blickWidgets: {
    title: <FormattedMessage id="pages.blickWidgets" />,
    url: '/blick-widgets',
  },
  about: {
    title: <FormattedMessage id="pages.about" />,
    url: '/about',
  },
  manifesto: {
    title: <FormattedMessage id="pages.aiManifesto" />,
    url: '/manifesto',
  },
  learnMore: {
    title: <FormattedMessage id="pages.learnMore" />,
    titleAlt: <FormattedMessage id="pages.gettingStarted" />,
    url: '/learn-more',
  },
  recommendedTools: {
    title: <FormattedMessage id="pages.recommendedTools" />,
    url: '/recommended',
  },
  login: {
    title: <FormattedMessage id="pages.login" />,
    url: '/login',
  },
}
