import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { getMenuItems } from 'menu-items'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavItemType } from 'types'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { useTheme } from '@mui/material/styles'
import HomeIcon from '@mui/icons-material/Home'
import { Link, useMediaQuery } from '@mui/material'
import { CustomBreadcrumps } from 'types/custom-breadcrumps'

type Props = {
  custom?: CustomBreadcrumps
}

export const Breadcrumbs = ({ custom }: Props) => {
  const theme = useTheme()
  const pathname = window.location.pathname
  const [main, setMain] = useState<NavItemType | undefined>()
  const [item, setItem] = useState<NavItemType>()
  const [isMatched, setIsMatched] = useState(false)
  const matchMobile = useMediaQuery(theme.breakpoints.down('md'))
  const menuItems = useMemo(() => getMenuItems(), [])
  const iconSX = {
    width: '16px',
    height: '16px',
    color: theme.palette.blue[500],
  }

  const linkSX = {
    display: 'flex',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
    whiteSpace: 'nowrap',
  }

  const getDefaultLinks = () =>
    main?.title && (
      <Typography {...(main?.url && { component: Link, href: main?.url })} variant="bodysm" sx={linkSX}>
        {main?.title}
      </Typography>
    )

  const getDefaultItem = () =>
    item?.title && (
      <Typography variant="bodysm" sx={{ ...linkSX }}>
        {item.title}
      </Typography>
    )

  const getCustomLinks = () =>
    custom?.likns.map((link, index) => {
      const Title = () => link.title
      return (
        <Typography key={`link-${index}`} component={Link} href={link.url} variant="bodysm" sx={linkSX}>
          <Title />
        </Typography>
      )
    })

  const getCustomItem = () => (
    <Typography variant="bodysm" sx={{ ...linkSX }}>
      {custom?.item.title}
    </Typography>
  )

  // set active item state
  const getCollapse = useCallback(
    (menu: NavItemType) => {
      if (menu.children) {
        menu.children.filter((collapse: NavItemType) => {
          if (collapse.type && collapse.type === 'collapse') {
            getCollapse(collapse as { children: NavItemType[]; type?: string })
            if (collapse.url === pathname) {
              setMain(collapse)
              // setItem(collapse);
              setIsMatched(true)
            }
          } else if (collapse.type && collapse.type === 'item') {
            if (pathname === collapse.url) {
              setMain(menu)
              setItem(collapse)
              setIsMatched(true)
            }
          }
          return false
        })
      }
    },
    [pathname],
  )

  useEffect(() => {
    if (pathname) {
      setIsMatched(false)
    }
  }, [pathname])

  useEffect(() => {
    if (!isMatched) {
      setMain(undefined)
      setItem(undefined)
    }
  }, [isMatched])

  useEffect(() => {
    menuItems?.items?.map((menu: NavItemType) => {
      if (menu.type && menu.type === 'group') {
        if (menu?.url && menu.url === pathname) {
          setMain(menu)
          setItem(menu)
          setIsMatched(true)
        } else {
          getCollapse(menu as { children: NavItemType[]; type?: string })
        }
      }
      return false
    })
  }, [pathname, getCollapse, menuItems])

  if ((!item && !main && !custom) || item?.breadcrumbs === false) {
    return null
  }

  return (
    <Card sx={{ marginBottom: '24px' }}>
      <Box sx={{ padding: '4px 16px' }}>
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
          {!matchMobile && (
            <Grid item>
              <Typography variant="h4">
                {custom?.title ? custom.title : main?.title ? main.title : item?.title}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            sx={{
              maxWidth: '100%',
              overflow: 'hidden',
              '& .MuiBreadcrumbs-ol': {
                flexWrap: 'nowrap',
              },
            }}
          >
            <MuiBreadcrumbs
              aria-label="breadcrumb"
              maxItems={4}
              separator={<MaterialIcon sx={{ color: theme.palette.grey[700] }} name="chevron_right" />}
              sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 0, mr: '16px' } }}
            >
              <Typography
                sx={{ display: 'flex', alignItems: 'center' }}
                component={Link}
                href="/dashboard"
                color="textSecondary"
                variant="subtitle1"
              >
                <HomeIcon style={{ ...iconSX }} />
              </Typography>

              {custom ? getCustomLinks() : getDefaultLinks()}
              {custom ? getCustomItem() : getDefaultItem()}
            </MuiBreadcrumbs>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
