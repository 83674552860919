import { config } from 'config'
import { GoogleAnalyticsContext } from 'shared'
import {
  FrontendGoogleAnalyticsAiGenerationEventData,
  FrontendGoogleAnalyticsBaseUsageEventData,
  FrontendGoogleAnalyticsEventType,
  FrontendGoogleAnalyticsNewTranscriptionEventData,
  FrontendGoogleAnalyticsRatingEventData,
  FrontendGoogleAnalyticsTranslateEventData,
  TrackAiGenerationParams,
  TrackButtonClickParams,
  TrackChatbotActionParams,
  TrackNewTranscriptionParams,
  TrackRatingParams,
  TrackTranslateActionParams,
} from 'types/analytics'

const pushToDataLayer = (payload: FrontendGoogleAnalyticsBaseUsageEventData) => {
  window?.dataLayer?.push(payload)
}

export const trackPageView = (page: string, hashedUserId: string) => {
  const payload: FrontendGoogleAnalyticsBaseUsageEventData = {
    event: FrontendGoogleAnalyticsEventType.VIRTUAL_PAGE_VIEW,
    page,
    user_id: hashedUserId,
    env: config.env,
  }

  pushToDataLayer(payload)
}

export const trackButtonClick = ({
  page,
  hashedUserId,
  context,
  clickAction,
  elementLabel,
}: TrackButtonClickParams) => {
  if (hashedUserId) {
    const payload: FrontendGoogleAnalyticsBaseUsageEventData = {
      event: FrontendGoogleAnalyticsEventType.BUTTON_CLICK,
      page,
      user_id: hashedUserId,
      context,
      click_action: clickAction,
      element_label: elementLabel,
      env: config.env,
    }

    pushToDataLayer(payload)
  }
}

export const trackAiGeneration = ({
  page,
  hashedUserId,
  context,
  clickAction,
  elementLabel,
  model,
}: TrackAiGenerationParams) => {
  if (hashedUserId) {
    const payload: FrontendGoogleAnalyticsAiGenerationEventData = {
      event: FrontendGoogleAnalyticsEventType.BUTTON_CLICK, // maybe have different event for this
      page,
      user_id: hashedUserId,
      context,
      click_action: clickAction,
      element_label: elementLabel,
      env: config.env,
      model,
    }

    pushToDataLayer(payload)
  }
}

export const trackNewTranscription = ({
  page,
  hashedUserId,
  context,
  clickAction,
  elementLabel,
  duration,
  format,
}: TrackNewTranscriptionParams) => {
  if (hashedUserId) {
    const payload: FrontendGoogleAnalyticsNewTranscriptionEventData = {
      event: FrontendGoogleAnalyticsEventType.BUTTON_CLICK, // maybe have different event for this
      page,
      user_id: hashedUserId,
      context,
      click_action: clickAction,
      element_label: elementLabel,
      env: config.env,
      file_duration: duration,
      format_input: format,
    }

    pushToDataLayer(payload)
  }
}

export const trackChatbotAction = ({
  event,
  page,
  hashedUserId,
  context,
  value,
  sessionId,
  interactionId,
  isSuggestedQuestion,
  responseTime,
  noAnswer,
  traceId,
}: TrackChatbotActionParams) => {
  if (hashedUserId) {
    const payload: FrontendGoogleAnalyticsBaseUsageEventData = {
      event,
      page,
      user_id: hashedUserId,
      context,
      env: config.env,
      ...(value && { value }),
      ...(sessionId && { session_id: sessionId }),
      ...(interactionId && { interaction_id: interactionId }),
      ...(isSuggestedQuestion && { is_suggested_question: isSuggestedQuestion }),
      ...(responseTime && { response_time: responseTime }),
      ...(noAnswer !== undefined && { no_answer: noAnswer }),
      ...(traceId && { trace_id: traceId }),
    }

    pushToDataLayer(payload)
  }
}

export const trackTranslateAction = ({
  page,
  hashedUserId,
  context,
  sourceLanguage,
  targetLanguage,
}: TrackTranslateActionParams) => {
  if (hashedUserId) {
    const payload: FrontendGoogleAnalyticsTranslateEventData = {
      event: FrontendGoogleAnalyticsEventType.TRANSLATE,
      page,
      user_id: hashedUserId,
      context,
      env: config.env,
      source_language: sourceLanguage,
      target_language: targetLanguage,
    }

    pushToDataLayer(payload)
  }
}

export const trackElementImpression = (
  page: string,
  hashedUserId: string,
  context: GoogleAnalyticsContext,
  elementLabel: string,
) => {
  const payload: FrontendGoogleAnalyticsBaseUsageEventData = {
    event: FrontendGoogleAnalyticsEventType.ELEMENT_IMPRESSION,
    page,
    user_id: hashedUserId,
    context,
    element_label: elementLabel,
    env: config.env,
  }

  pushToDataLayer(payload)
}

export const trackRating = ({
  page,
  hashedUserId,
  elementLabel,
  rating,
  feedbackText,
  context,
  model,
  traceId,
  sessionId,
  interactionId,
  articleId,
  jobId,
}: TrackRatingParams) => {
  if (hashedUserId) {
    const payload: FrontendGoogleAnalyticsRatingEventData = {
      event: FrontendGoogleAnalyticsEventType.SEND_FEEDBACK,
      page,
      user_id: hashedUserId,
      element_label: elementLabel,
      rating,
      feedback_text: feedbackText,
      env: config.env,
      ...(context && { context }),
      ...(model && { model }),
      ...(traceId && { trace_id: traceId }),
      ...(sessionId && { session_id: sessionId }),
      ...(interactionId && { interaction_id: interactionId }),
      ...(articleId && { article_id: articleId }),
      ...(jobId && { job_id: jobId }),
    }

    pushToDataLayer(payload)
  }
}
