import { useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  ButtonBase,
  Chip,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project imports
import { useConfig } from 'hooks/useConfig'
import { LAYOUT_CONST } from 'constant'

// types
import { LinkTarget, NavItemType } from 'types'

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useApp } from 'hooks/useApp'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { Spacer } from 'components/ui-component/Spacer'
import { useLocation } from 'react-router-dom'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

interface NavItemProps {
  item: NavItemType
  level: number
  parentId?: string
  isParents?: boolean
}

export const NavItem = ({ item, level, parentId, isParents = false }: NavItemProps) => {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { pathname } = useLocation()
  const { layout, borderRadius } = useConfig()

  const { drawerOpen, toggleDrawer, selectedMenuItem, setSelectedMenuItem, setSelectedMenuID } = useApp()

  const isSelected = selectedMenuItem.findIndex((id) => id === item.id) > -1

  const Icon = item?.icon
  const itemIcon = item?.icon ? (
    <Icon
      stroke={1.5}
      size={drawerOpen ? '20px' : '24px'}
      style={{
        color: isSelected ? theme.palette.blue[500] : theme.palette.text.primary,
        ...(layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && isParents && { fontSize: 20, stroke: '1.5' }),
      }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        color: isSelected ? theme.palette.blue[500] : theme.palette.text.primary,
        width: selectedMenuItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: selectedMenuItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  let itemTarget: LinkTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  const itemHandler = (id: string) => {
    setSelectedMenuItem([id])
    if (matchesSM) {
      toggleDrawer(false)
    }
    setSelectedMenuID(parentId)
  }

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id)
    if (currentIndex > -1 && item.id) {
      setSelectedMenuItem([item.id])
    }
    // eslint-disable-next-line
  }, [pathname])

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary'

  return (
    <>
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <ListItemButton
          component={Link}
          href={item.url!}
          target={itemTarget}
          disabled={item.disabled}
          disableRipple={!drawerOpen}
          sx={{
            ...item.style,
            zIndex: 1201,
            borderRadius: `${borderRadius}px`,
            mb: 0.5,
            paddingTop: '6px',
            paddingBottom: '6px',
            ...(drawerOpen &&
              level === 1 &&
              theme.palette.mode !== 'dark' && {
                '&:hover': {
                  background: theme.palette.blue[50],
                },
                '&.Mui-selected': {
                  background: theme.palette.blue[50],
                  color: theme.palette.blue[600],
                  '&:hover': {
                    color: theme.palette.blue[600],
                    background: theme.palette.blue[50],
                  },
                },
              }),
            ...((!drawerOpen || level !== 1) && {
              py: level === 1 ? 0 : 1,
              '&:hover': {
                bgcolor: 'transparent',
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: 'transparent',
                },
                bgcolor: 'transparent',
              },
            }),
          }}
          selected={isSelected}
          onClick={() => itemHandler(item.id!)}
        >
          <ButtonBase sx={{ borderRadius: `${borderRadius}px` }} disableRipple={drawerOpen} aria-label="theme-icon">
            <ListItemIcon
              sx={{
                textAlign: 'center',
                minWidth: level === 1 ? 36 : 18,
                color: isSelected ? theme.palette.blue[600] : textColor,
                ...(!drawerOpen &&
                  level === 1 && {
                    borderRadius: `${borderRadius}px`,
                    width: 46,
                    height: 46,
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      bgcolor: theme.palette.blue[50],
                    },
                    ...(isSelected && {
                      bgcolor: theme.palette.blue[50],
                      '&:hover': {
                        bgcolor: theme.palette.blue[50],
                      },
                    }),
                  }),
              }}
            >
              {typeof item?.icon === 'string' ? (
                <>
                  <MaterialIcon name={item.icon} fontSize="20px" />
                  {drawerOpen && <Spacer h size={16} />}
                </>
              ) : (
                itemIcon
              )}
            </ListItemIcon>
          </ButtonBase>

          {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <ListItemText
              primary={
                <Typography variant="bodymd" color="inherit">
                  {item.title}
                </Typography>
              }
              secondary={
                item.caption && (
                  <Typography
                    variant="caption"
                    sx={{ ...theme.typography.subMenuCaption }}
                    display="block"
                    gutterBottom
                  >
                    {item.caption}
                  </Typography>
                )
              }
            />
          )}

          {drawerOpen && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          component={Link}
          href={item.url!}
          target={itemTarget}
          disabled={item.disabled}
          {...(isParents && {
            onClick: () => {
              setSelectedMenuID(item.id!)
            },
          })}
          sx={{
            borderRadius: isParents ? `${borderRadius}px` : 0,
            mb: isParents ? 0 : 0.5,
            alignItems: 'flex-start',
            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
            py: 1,
            pl: 2,
            mr: isParents ? 1 : 0,
          }}
          selected={isSelected}
          onClick={() => itemHandler(item.id!)}
        >
          <ListItemIcon
            sx={{
              my: 'auto',
              minWidth: !item?.icon ? 18 : 36,
            }}
          >
            {itemIcon}
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography variant="bodymd" color="inherit">
                {item.title}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )
            }
          />

          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  )
}
