// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewSk = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Vitajte na našej kurátorovanej stránke, kde odporúčame rôzne externé AI nástroje! Tu nájdete výber nástrojov,
          ktoré môžu zlepšiť vašu prácu a podnietiť inováciu. Je však dôležité si uvedomiť, že tieto nástroje by sa mali
          používať s opatrnosťou a zodpovednosťou.
          <br />
          <br />
          Pred začatím používania vás žiadame, aby ste sa oboznámili s našimi smernicami pre AI. Tieto smernice sú
          vytvorené na zabezpečenie etického, zodpovedného a efektívneho využívania technológií AI. Pomôžu vám zvládať
          potenciálne zložitosti a výzvy, ktoré prichádzajú s používaním externých AI nástrojov.
          <br />
          <br />
          Upozorňujeme, že aj keď tieto nástroje odporúčame pre ich potenciálne výhody, neposkytujeme priamu podporu. Ak
          sa stretáte s problémami alebo máte otázky, budete musieť kontaktovať príslušné podporné kanály pre každý
          nástroj.
          <br />
          <br />
          Používajte tieto nástroje múdro na preskúmanie možností AI, vždy s ohľadom na naše smernice a potrebu opatrnej
          a informovanej aplikácie. Šťastné preskúmavanie!
        </Typography>
        <Typography variant="h3">AI Asistenti:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : V súčasnosti najlepší AI asistent na trhu. ChatGPT od spoločnosti OpenAI je konverzačný model AI schopný
          generovať text podobný ľudskému. Je ideálny na vytváranie článkov, brainstorming, získavanie spätnej väzby k
          nápadom, sumarizovanie obsahu a podobne. Obrázkový tvorivý motor spoločnosti OpenAI, Dall-E, ktorý je dostupný
          prostredníctvom ChatGPT, dokáže generovať jedinečné obrázky pre články.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai je AI asistent, ktorý poskytuje multimediálne odpovede a agreguje viaceré podkladové modely AI,
          ako sú ChatGPT od spoločnosti OpenAI alebo Claude od spoločnosti Anthropic. Môže byť použitý pre rôzne
          každodenné úlohy.
        </Typography>
        <Typography variant="h3">Písanie:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : AI písací asistent, ktorý podporuje nemecký jazyk a pomáha vytvárať zaujímavý obsah rýchlo. Môže byť použitý
          na vytváranie článkov, generovanie titulkov a tvorbu kreatívnych písacích diel.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : Textový generátor AI podporujúci nemecký jazyk, ktorý sa špecializuje na tvorbu marketingového obsahu. Je
          užitočný pri písaní zaujímavých úvodov článkov, kópie reklamy a príspevkov na sociálnych médiách súvisiacich s
          článkami.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Nástroj na písanie a parafrazovanie poháňaný AI. Pomáha v prepracovaní viet pre jasnosť, zlepšenie gramatiky
          a vyhýbanie sa plagiátorstvu v článkoch. Dobrá funkcia v nemeckom jazyku.
        </Typography>
        <Typography variant="h3">Vytváranie obrázkov:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Program AI, ktorý sa špecializuje na vytváranie vysokej kvality, umeleckých obrázkov. Ideálny na vytváranie
          jedinečných vizuálov a ilustrácií pre príbehy a funkcie. Momentálne dostupný len prostredníctvom{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discordu
          </ExternalLink>
          . Nájdete tu{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            sprievodcu použitím
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Nástroj poháňaný AI, ktorý generuje realistické obrázky a umenie. Užitočný pre vizuálne vyprávanie príbehov
          a tvorbu vlastných ilustrácií pre články.
        </Typography>
        <Typography variant="h3">Úprava fotografií:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Platforma na úpravu fotografií, ktorá využíva AI pre pokročilé úpravy. Môže zlepšiť kvalitu fotografií
          použitých v článkoch, vrátane úprav v osvetlení, textúre a farbe.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Ponúka softvér na zlepšovanie fotografií poháňaný AI. Užitočný na zväčšovanie obrázkov bez straty kvality,
          odstránenie šumu a zaostrenie obrázkov pre digitálne publikácie.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Nástroj na úpravu obrázkov založený na AI, ktorý automaticky upravuje fotografie, upravuje osvetlenie a
          farby a aplikuje rôzne efekty, vhodný na zlepšovanie vizuálneho obsahu v článkoch.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Špecializuje sa na odstraňovanie pozadia z obrázkov pomocou AI. Je užitočný na rýchlu úpravu obrázkov pre
          články, najmä pri potrebe izolovať subjekty od ich pozadia pre grafické kompozície.
        </Typography>
      </MainCard>
    </Text>
  )
}
