// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewDe = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Willkommen auf unserer kuratierten Seite, auf der wir eine Vielzahl externer KI-Werkzeuge empfehlen! Hier
          finden Sie eine Auswahl an Werkzeugen, die Ihre Arbeit verbessern und Innovationen vorantreiben können. Es ist
          jedoch wichtig, sich daran zu erinnern, dass diese Werkzeuge mit Sorgfalt und Verantwortung verwendet werden
          sollten.
          <br />
          <br />
          Bevor Sie eintauchen, möchten wir Sie dringend bitten, sich mit unseren KI-Richtlinien vertraut zu machen.
          Diese Richtlinien sind so konzipiert, dass ein ethischer, verantwortungsvoller und effektiver Einsatz von
          KI-Technologien gewährleistet wird. Sie helfen Ihnen, die potenziellen Komplexitäten und Herausforderungen zu
          bewältigen, die mit der Verwendung externer KI-Werkzeuge einhergehen.
          <br />
          <br />
          Bitte beachten Sie, dass wir zwar diese Werkzeuge aufgrund ihrer potenziellen Vorteile empfehlen, jedoch keine
          direkte Unterstützung dafür anbieten. Wenn Sie auf Probleme stoßen oder Fragen haben, müssen Sie sich an die
          jeweiligen Support-Kanäle für jedes Werkzeug wenden.
          <br />
          <br />
          Verwenden Sie diese Werkzeuge klug, um die Möglichkeiten der KI zu erkunden, wobei Sie immer unsere
          Richtlinien und die Notwendigkeit für eine vorsichtige und informierte Anwendung im Hinterkopf behalten. Viel
          Spaß beim Erkunden!
        </Typography>
        <Typography variant="h3">KI-Assistenten:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : Derzeit der beste KI-Assistent auf dem Markt. ChatGPT von OpenAI ist ein konversationsfähiges KI-Modell, das
          in der Lage ist, menschenähnlichen Text zu generieren. Ideal zum Entwerfen von Artikeln, Brainstorming,
          Feedback zu Ideen, Zusammenfassen von Inhalten usw. OpenAIs Bildgenerierungsengine Dall-E, die über ChatGPT
          zugänglich ist, kann einzigartige Bilder für Artikel generieren.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai ist ein KI-Assistent, der multimediale Antworten liefert und mehrere zugrunde liegende
          KI-Modelle wie OpenAIs ChatGPT oder Anthropics Claude aggregiert. Es kann für eine Vielzahl von alltäglichen
          Aufgaben verwendet werden.
        </Typography>
        <Typography variant="h3">Schreiben:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : Ein KI-Schreibassistent, der Deutsch unterstützt und dabei hilft, schnell überzeugende Inhalte zu erstellen.
          Er kann zum Entwerfen von Artikeln, Generieren von Schlagzeilen und Produzieren von kreativen Schreibstücken
          verwendet werden.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : Ein auf Textgenerierung spezialisierter KI-Assistent, der Deutsch unterstützt und sich auf die Erstellung
          von Marketinginhalten spezialisiert hat. Er ist hilfreich beim Verfassen von ansprechenden
          Artikel-Einleitungen, Anzeigenkopien und Social-Media-Beiträgen zu Artikeln.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Ein KI-gestütztes Schreib- und Paraphrasierungswerkzeug. Hilft beim Umformulieren von Sätzen für Klarheit,
          Verbesserung der Grammatik und Vermeidung von Plagiaten in Artikeln. Funktioniert gut auf Deutsch.
        </Typography>
        <Typography variant="h3">Bildgenerierung:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Ein KI-Programm, das sich auf die Erstellung hochwertiger, künstlerischer Bilder spezialisiert hat. Ideal
          für die Erstellung einzigartiger Visuals und Illustrationen für Geschichten und Features. Derzeit nur über{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          zugänglich. Eine Anleitung zur Verwendung finden Sie{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            hier
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Ein von KI angetriebenes Werkzeug, das realistische Bilder und Kunstwerke generiert. Nützlich für visuelles
          Storytelling und das Erstellen individueller Illustrationen für Artikel.
        </Typography>
        <Typography variant="h3">Fotobearbeitung:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : Eine Fotobearbeitungsplattform, die KI für fortgeschrittene Bearbeitungsaufgaben verwendet. Sie kann die
          Qualität der in Artikeln verwendeten Fotos verbessern, einschließlich Anpassungen an Beleuchtung, Textur und
          Farbe.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Bietet KI-gestützte Fotoverbesserungssoftware. Nützlich zum Vergrößern von Bildern ohne Qualitätsverlust,
          Entfernen von Rauschen und Schärfen von Bildern für die digitale Veröffentlichung.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Ein auf KI basierendes Bildbearbeitungstool, das Fotos automatisch bearbeiten, Beleuchtung und Farben
          anpassen und verschiedene Effekte anwenden kann, geeignet zur Verbesserung des visuellen Inhalts in Artikeln.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Spezialisiert sich auf das Entfernen von Hintergründen aus Bildern mithilfe von KI. Es ist nützlich für das
          schnelle Bearbeiten von Bildern für Artikel, insbesondere wenn Objekte von ihren Hintergründen isoliert werden
          müssen, um grafische Kompositionen zu erstellen.
        </Typography>
      </MainCard>
    </Text>
  )
}
