'use client'
import React, { Ref } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Card, CardContent, CardHeader, Divider, CardProps, CardHeaderProps, CardContentProps } from '@mui/material'

// types
import { KeyedObject } from 'types'

// constant
export const mainCardHeaderSX = {
  padding: '20px',
  '& .MuiCardHeader-action': { mr: 0 },
  '&  .MuiCardHeader-title': {
    margin: '0',
  },
}

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean
  boxShadow?: boolean
  children: React.ReactNode | string
  style?: React.CSSProperties
  content?: boolean
  className?: string
  contentClass?: string
  contentSX?: CardContentProps['sx']
  darkTitle?: boolean
  noDivider?: boolean
  headerSX?: CardHeaderProps['sx']
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: React.ReactNode | string
}

export const MainCard = React.forwardRef(
  (
    {
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      noDivider = false,
      headerSX = {},
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme()
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[300] + 98,
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader sx={{ ...headerSX, ...mainCardHeaderSX }} title={title} action={secondary} />
        )}
        {darkTitle && title && (
          <CardHeader sx={{ ...headerSX, ...mainCardHeaderSX }} title={title} action={secondary} />
        )}

        {/* content & header divider */}
        {title && !noDivider && <Divider />}

        {/* card content */}
        {content && (
          <CardContent
            sx={{
              padding: {
                md: '20px !important',
                sm: '20px 16px !important',
                xs: '20px 16px !important',
              },
              ...contentSX,
            }}
            className={contentClass}
          >
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    )
  },
)
MainCard.displayName = 'MainCard'
