export const sessionStorageSetItem = (key: string, value: string) => {
  if (sessionStorage) {
    sessionStorage.setItem(key, value)
  }
}

export const sessionStorageGetItem = (key: string): string | null => {
  if (sessionStorage) {
    return sessionStorage.getItem(key)
  }
  return null
}

export const sessionStorageRemoveItem = (key: string) => {
  if (sessionStorage) {
    sessionStorage.removeItem(key)
  }
}
