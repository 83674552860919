// material-ui
import { Theme, TypographyVariantsOptions } from '@mui/material/styles'

export const Typography = (theme: Theme, borderRadius: number, fontFamily: string): TypographyVariantsOptions => ({
  fontFamily,
  h6: {
    fontSize: '14px',
    color: theme.palette.grey[700],
    fontWeight: 400,
    lineHeight: '20px',
  },
  h5: {
    fontSize: '14px',
    color: theme.palette.grey[700],
    fontWeight: 700,
    lineHeight: '24px',
  },
  h4: {
    fontSize: '16px',
    color: theme.palette.grey[700],
    fontWeight: 700,
    lineHeight: '24px',
  },
  h3: {
    fontSize: '20px',
    color: theme.palette.grey[700],
    fontWeight: 700,
    lineHeight: '28px',
  },
  h2: {
    fontSize: '24px',
    color: theme.palette.grey[700],
    fontWeight: 700,
    lineHeight: '32px',
  },
  h1: {
    fontSize: '34px',
    color: theme.palette.grey[700],
    fontWeight: 700,
    lineHeight: '42px',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.text.dark,
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  caption: {
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '700',
    lineGeight: '16px',
    color: theme.palette.purple[500],
  },
  bodybg: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.grey[700],
  },
  bodymd: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.grey[700],
  },
  bodysm: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.grey[700],
    display: 'inline-block',
  },
  button: {
    textTransform: 'capitalize',
  },
  customInput: {
    marginTop: 1,
    marginBottom: 1,
    '& > label': {
      top: 23,
      left: 0,
      color: theme.palette.grey[500],
      '&[data-shrink="false"]': {
        top: 5,
      },
    },
    '& > div > input': {
      padding: '30.5px 14px 11.5px !important',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
    },
  },
  mainContent: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.secondary.light,
    width: '100%',
    minHeight: 'calc(100vh - 126px)',
    flexGrow: 1,
    padding: '20px',
    marginTop: '88px',
    marginRight: '20px',
    borderRadius: `${borderRadius}px`,
  },
  menuCaption: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.grey[700],
    padding: '6px',
    textTransform: 'capitalize',
    marginTop: '10px',
  },
  subMenuCaption: {
    fontSize: '0.6875rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
  },
  commonAvatar: {
    cursor: 'pointer',
    borderRadius: '8px',
  },
  smallAvatar: {
    width: '22px',
    height: '22px',
    fontSize: '1rem',
  },
  mediumAvatar: {
    width: '34px',
    height: '34px',
    fontSize: '14px',
    fontWeight: '700',
  },
  largeAvatar: {
    width: '44px',
    height: '44px',
    fontSize: '1.5rem',
  },
})
