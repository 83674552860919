import { Box, Link, Tooltip, Typography, styled } from '@mui/material'
import { useState } from 'react'
import Swiper from 'swiper'
import { Spacer } from 'components/ui-component/Spacer'
import { Slider } from './Slider'
import { TooltipContent } from './TooltipContent'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { BlickWidgetsAnswerLinks } from 'types/blick-widgets'
import blickPlusLogo from 'assets/images/blick-plus-logo.svg'

type Props = {
  links: BlickWidgetsAnswerLinks
}

export const ArticleSlider = ({ links }: Props) => {
  const [sliderInstance, setSliderInstance] = useState<Swiper | undefined>()
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Quellen</Typography>
        <NavHolder>
          <NavButton disabled={isBeginning} onClick={() => sliderInstance?.slidePrev()}>
            <MaterialIcon name="arrow_back" />
          </NavButton>
          <NavButton disabled={isEnd} onClick={() => sliderInstance?.slideNext()}>
            <MaterialIcon name="arrow_forward" />
          </NavButton>
        </NavHolder>
      </Box>
      <Typography variant="h6">Antwort basiert auf folgenden Blick-Inhalten</Typography>
      <Spacer v size={8} />
      <Slider
        settings={{
          onInit: (slider: Swiper) => {
            setSliderInstance(slider)
            setIsBeginning(slider.isBeginning)
            setIsEnd(slider.isEnd)
          },
          onSlideChange: (slider: Swiper) => {
            setIsBeginning(slider.isBeginning)
            setIsEnd(slider.isEnd)
          },
          slidesPerView: 'auto',
          spaceBetween: 8,
        }}
      >
        {Object.keys(links).map((key) => {
          const link = links[key]
          return (
            <div style={{ width: '160px' }} key={key}>
              <Tooltip title={<TooltipContent isPlus={link?.isPlus} title={link.title} />} placement="top">
                <Link href={link.url} target="_blank" sx={{ textDecoration: 'none' }}>
                  <Article>
                    <Typography variant="h6">
                      {' '}
                      {link?.isPlus === 'true' && <img src={blickPlusLogo} alt="Blick plus" />} {link.title}
                    </Typography>
                    <Spacer v size={8} />
                    <div>
                      <ArticleNumber>
                        <Typography variant="h6">{key}</Typography>
                      </ArticleNumber>
                    </div>
                  </Article>
                </Link>
              </Tooltip>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

const Article = styled('div')`
  padding: 8px;
  background: ${({ theme }) => theme.palette.grey[300]};
  height: 97px;
  width: 160px;
  box-sizing: border-box;
`

const ArticleNumber = styled('div')`
  background: ${({ theme }) => theme.palette.grey[300]};
  color: ${({ theme }) => theme.palette.grey[800]};
  border-radius: 4px;
  display: inline-block;
  border-radius: 20px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 15px;
`

const NavHolder = styled('div')`
  margin-top: -5px;
  display: flex;
`

const NavButton = styled('div')<{ disabled?: boolean }>`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.blue[500]};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `   
        pointer-events: none;
        color: ${theme.palette.grey[500]};
    `}
`
