'use client'

import { SimpleModal, SimpleModalActions } from 'components/ui-component/SimpleModal'
import { Button, CircularProgress, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  isLoading?: boolean
}

export const DeleteTranscriptionModal = ({ isOpen = true, onClose, onConfirm, isLoading }: Props) => {
  const theme = useTheme()
  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography variant="h3">
          <FormattedMessage id="audioToText.deleteMessage" />
        </Typography>
      }
      headerSX={{ paddingBottom: '0 !important' }}
    >
      {/* */}
      <SimpleModalActions noDivider sx={{ padding: '0', gap: '12px' }}>
        <Button variant="outlined" onClick={onClose} sx={{ textTransform: 'uppercase' }}>
          <FormattedMessage id="common.noCancel" />
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={onConfirm}
          sx={{ textTransform: 'uppercase', minWidth: '113px' }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: theme.palette.grey[400] }} />
          ) : (
            <FormattedMessage id="common.yesDelete" />
          )}
        </Button>
      </SimpleModalActions>
    </SimpleModal>
  )
}
