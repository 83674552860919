import { css, styled } from '@mui/material'

export const RotatingIcon = styled('span')<{ $enabled: boolean }>`
  margin-right: 0px;
  ${({ $enabled }) =>
    $enabled &&
    css`
      animation: rotation infinite 3s linear;
      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    `}
`
