'use client'

import { Button, Typography } from '@mui/material'
import { RateModal } from 'components/RateModal'

import { MainCard } from 'components/ui-component/cards/MainCard'
import { useForm } from 'react-hook-form'
import { Spacer } from 'components/ui-component/Spacer'
import { TitleGeneratorForm } from './components/TitleGeneratorForm'
import { RoboticLoader } from './components/RoboticLoader'
import { Results } from './components/Results'
import { GENERATE_FOR_OPTIONS, TitleFormValues, useTextGenerator } from './useTextGenerator'
import { FormattedMessage } from 'components/FormattedMessage'
import { AiVersion, ArticlePublications, GoogleAnalyticsContext } from 'shared'
import { useLocation } from 'react-router-dom'
import { trackRating } from 'tracking/gtm'
import { useAuth } from 'hooks/useAuth'
import { ActionFooter } from './components/ActionFooter'
import { InfoBox } from 'components/ui-component/InfoBox'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { config } from 'config'

export const TitleGeneratorView = () => {
  const { pathname } = useLocation()
  const { session } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    reset: resetForm,
  } = useForm<TitleFormValues>({
    defaultValues: {
      version: AiVersion.CLAUDE_35_SONNET,
      publication: GENERATE_FOR_OPTIONS[0].value as ArticlePublications,
    },
  })

  const {
    isLoading,
    loaderRef,
    allTitleDataReceived,
    metaTitle,
    ogTitle,
    teaserHeadline,
    teaserTitle,
    lead,
    rewrittenText,
    onRegenerateAll,
    isRegenerateAllLoading,
    feedbackSent,
    onGenerateTitleSubmit,
    rateModalConfig,
    setRateModalConfig,
    onRateModalConfirm,
    allArticleDataReceived,
    reset,
    onSendToCMSSubmit,
    sendToMDBLoading,
    showChromePluginInfo,
    onChromePluginInfoClose,
  } = useTextGenerator('title', setValue)

  const originalTextValue = watch('originalText')

  const onReset = () => {
    reset()
    resetForm()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <MainCard>
        {showChromePluginInfo && (
          <>
            <InfoBox
              title={<FormattedMessage id="titleGenerator.chromePluginInfoTitle" />}
              text={<FormattedMessage id="titleGenerator.chromePluginInfoDescription" />}
              actions={
                <Button variant="text" startIcon={<MaterialIcon name="add" />} href={config.chromeExtentionUrl}>
                  <FormattedMessage id="common.addChromePlugin" />
                </Button>
              }
              onClose={() => onChromePluginInfoClose()}
            />
            <Spacer v size={40} />
          </>
        )}

        <Typography variant="h3">
          <FormattedMessage id="titleGenerator.headline" />
        </Typography>
        <Spacer v size={8} />
        <Typography variant="bodybg">
          <FormattedMessage id="titleGenerator.subHeadline" />
        </Typography>
        <Spacer v size={24} />
        <TitleGeneratorForm
          register={register}
          onSubmit={handleSubmit(onGenerateTitleSubmit)}
          isLoading={isLoading}
          control={control}
          errors={errors}
          originalTextLength={originalTextValue ? originalTextValue.length : 0}
        />
      </MainCard>
      <Spacer v size={42} />
      <RoboticLoader isLoading={isLoading} loaderRef={loaderRef} />
      {allTitleDataReceived && (
        <Results
          publication={getValues('publication')}
          metaTitle={{
            ...metaTitle,
            onRegenerate: metaTitle.onRegenerate(watch()),
          }}
          ogTitle={{
            ...ogTitle,
            onRegenerate: ogTitle.onRegenerate(watch()),
          }}
          teaserHeadline={{
            ...teaserHeadline,
            onRegenerate: teaserHeadline.onRegenerate(watch()),
          }}
          teaserTitle={{
            ...teaserTitle,
            onRegenerate: teaserTitle.onRegenerate(watch()),
          }}
          lead={{
            ...lead,
            onRegenerate: lead.onRegenerate(watch()),
          }}
          rewrittenText={{
            ...rewrittenText,
            onRegenerate: undefined,
          }}
          setRateModalConfig={setRateModalConfig}
          onRegenerateAll={onRegenerateAll(watch())}
          isRegenerateAllLoading={isRegenerateAllLoading}
          feedbackSent={feedbackSent}
        />
      )}
      {allArticleDataReceived && (
        <ActionFooter
          onReset={onReset}
          onSubmit={() => onSendToCMSSubmit(getValues('publication'))}
          isLoading={sendToMDBLoading}
        />
      )}
      {rateModalConfig && (
        <RateModal
          isOpen={Boolean(rateModalConfig)}
          onClose={() => setRateModalConfig(null)}
          config={rateModalConfig}
          onConfirm={onRateModalConfirm}
          onTrack={(rating, feedbackText) =>
            trackRating({
              page: pathname,
              hashedUserId: session?.hashedId,
              elementLabel: rateModalConfig.title,
              context: GoogleAnalyticsContext.TEXT_TO_TEXT,
              rating,
              feedbackText,
              model: getValues('version'),
            })
          }
        />
      )}
    </>
  )
}
