export type JSONValue = string | number | boolean | Json | JSONArray | null | undefined;
export interface Json {
  [x: string]: JSONValue;
}

export interface JSONArray extends Array<JSONValue> {}

export type Nullable<T> = T | null;
export type Maybe<T> = T | undefined;
export type NullOrUndef<T> = Maybe<Nullable<T>>;
export type AnyObject = Record<string | number, unknown>;

export enum ToolboxLanguageOptions {
  ENGLISH = 'english',
  GERMAN = 'german',
  FRENCH = 'french',
  POLISH = 'polish',
  HUNGARIAN = 'hungarian',
  SLOVAK = 'slovak',
  ROMANIAN = 'romanian',
  SERBIAN = 'serbian'
}

export interface TranscriptionContentSegment {
  speaker: string;
  start: number;
  end: number;
  words: WordList[];
}
export interface TranscriptionContent {
  version: SpeechRecognitionVersion;
  segments: TranscriptionContentSegment[];
}

export interface WordList {
  text: string;
  probability: number;
}

export enum SpeechRecognitionVersion {
  V1 = 'v1', // AWS Transcribe + Whisper
  V2 = 'v2' // Whisper with SpeakerRecognition
}
