import React from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'
import { App } from 'App'
import { reportWebVitals } from 'reportWebVitals'
import TagManager from 'react-gtm-module'
import { config } from 'config'
import { Sentry } from 'logger'

const tagManagerArgs = {
  gtmId: config.gtmID,
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Sentry.ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
