'use client'

import { styled } from '@mui/material'

type Props = {
  size?: number
  horizontal?: boolean
  vertical?: boolean
  h?: boolean
  v?: boolean
}

export const Spacer = styled('div')(({ vertical, v, horizontal, h, size }: Props) => ({
  height: `${vertical || v ? `${size}px` : 'auto'}`,
  width: `${horizontal || h ? `${size}px` : 'auto'}`,
}))
