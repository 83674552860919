'use client'

import { styled } from '@mui/material'
import { useTranscriptionContext } from 'hooks/useTranscriptionContext'
import React, { SyntheticEvent, useCallback } from 'react'

type Props = {
  audioSrc?: any
  handleAudioLoadedMetadata?: (duration?: number) => void
}

export const AudioPlayer = ({ audioSrc, handleAudioLoadedMetadata }: Props) => {
  const { playerRef, playerWrapperRef } = useTranscriptionContext()

  const onAudioLoadedMetadata = useCallback<React.ReactEventHandler<HTMLAudioElement>>(
    (audioEl: SyntheticEvent<HTMLAudioElement>) => {
      handleAudioLoadedMetadata && handleAudioLoadedMetadata((audioEl.target as HTMLAudioElement)?.duration)
    },
    [handleAudioLoadedMetadata],
  )

  return (
    <Wrapper>
      <AudioWrapper ref={playerWrapperRef}>
        <Audio
          src={audioSrc}
          controls
          onLoadedMetadata={onAudioLoadedMetadata}
          ref={playerRef}
          crossOrigin="anonymous"
        />
      </AudioWrapper>
    </Wrapper>
  )
}

const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '0',
  left: '0',
  width: '100%',
  zIndex: '1099',
  background: theme.palette.grey[600],
  padding: '20px',
  display: 'flex',
  boxShadow: '0px -1px 8px 0px rgba(54, 65, 82, 0.20)',
}))

const AudioWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: '1',
})

const Audio = styled('audio')(({ theme }) => ({
  width: '100%',
  maxWidth: '590px',
  height: '48px',
  '&::-webkit-media-controls-panel': {
    background: theme.palette.common.white,
  },
}))
