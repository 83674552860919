export const IconSparkles = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_1498)">
        <path
          d="M15.4709 10.2L16.4401 12.529L18.7647 13.5L16.4401 14.471L15.4709 16.8L14.5017 14.471L12.1771 13.5L14.5017 12.529L15.4709 10.2ZM15.4709 5L12.9756 11L6.98687 13.5L12.9756 16L15.4709 22L17.9662 16L23.955 13.5L17.9662 11L15.4709 5ZM4.11726 7.875L5.48968 12L6.8621 7.875L10.9794 6.5L6.8621 5.125L5.48968 1L4.11726 5.125L0 6.5L4.11726 7.875ZM6.36304 18.625L5.48968 16L4.61632 18.625L1.99625 19.5L4.61632 20.375L5.48968 23L6.36304 20.375L8.98311 19.5L6.36304 18.625Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_1498">
          <rect width="23.955" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
