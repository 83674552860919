import { ReactNode, createContext, useCallback, useState } from 'react'
import { ulid } from 'ulid'
import { AxiosError, AxiosResponse } from 'axios'
import {
  BlickWidgetsAnswerResponse,
  BlickWidgetsContextType,
  Message,
  Question,
  QuestionWidgetId,
} from 'types/blick-widgets'
import { getAnswer, getQuestions } from 'api/blick-widgets'

type BlickWidgetsProviderProps = {
  children: ReactNode
  widgetId: QuestionWidgetId
}

export const BlickWidgetsContext = createContext<BlickWidgetsContextType>({
  messages: [],
  setMessages: () => {
    return
  },
  onQuestionClick: () => {
    return
  },
  onQuestionSubmit: () => {
    return
  },
  getInitialQuestions: () => {
    return
  },
  isInitialLoading: false,
  isAnswerLoading: false,
  widgetId: 'dailyBriefing',
})

// const dummyAnswer: ChatPromptResponse = {
//   output: {
//     Google: true,
//     cost: 1,
//     intent: "",
//     response: {
//       Google: true,
//       documents: [],
//       response: "Dummy answer",
//       text: "Dummy answer",
//     },
//     span_id: "",
//     text: "Dummy answer",
//     trace_id: "",
//     usage: [],
//     no_answer: false,
//   },
// };

export const BlickWidgetsProvider = ({ children, widgetId }: BlickWidgetsProviderProps) => {
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  const [isAnswerLoading, setIsAnswerLoading] = useState(false)
  const [initialQuestions, setInitialQuestions] = useState<Question[]>()
  const [messages, setMessages] = useState<Message[]>([])

  const handleAnswer = async (
    answer: AxiosResponse<BlickWidgetsAnswerResponse>,
    interactionId: string,
    // isSuggestedQuestion: boolean,
  ) => {
    const { id, content, links } = answer.data
    // const suggestedQuestions = await getQuestions(widgetId)
    setIsAnswerLoading(false)
    setMessages((prevState) => [
      ...prevState,
      {
        type: 'answer',
        content,
        links,
        // ...(!(suggestedQuestions instanceof AxiosError) && {
        //   suggestedQuestions: suggestedQuestions.data.questions,
        // }),
        spanId: id,
        interactionId,
      },
    ])
  }

  const onQuestionClick = async (question: Question) => {
    setMessages((prevState) => [{ type: 'question', text: `${question.text} :` }])
    setIsAnswerLoading(true)

    const interactionId = ulid()

    const answerResult = await getAnswer(question.id, widgetId)

    if (answerResult instanceof AxiosError) {
      setIsAnswerLoading(false)
      return
    }

    if (answerResult) {
      await handleAnswer(answerResult, interactionId)
    }
  }

  const onQuestionSubmit = async (question: Question) => {
    setMessages([...messages, { type: 'question', text: question.text }])
    // handleAnswer(dummyAnswer);

    setIsAnswerLoading(true)

    const interactionId = ulid()

    const answerResult = await getAnswer(question.id, widgetId)

    if (answerResult instanceof AxiosError) {
      setIsAnswerLoading(false)
      return
    }

    if (answerResult) {
      await handleAnswer(answerResult, interactionId)
    }
  }

  const getInitialQuestions = useCallback(
    async (articleId?: string) => {
      setIsInitialLoading(true)
      const result = await getQuestions(widgetId, articleId)
      if (!(result instanceof AxiosError)) {
        setInitialQuestions(result.data.questions)
      }
      setIsInitialLoading(false)
    },
    [widgetId],
  )

  return (
    <BlickWidgetsContext.Provider
      value={{
        messages,
        setMessages,
        onQuestionClick,
        onQuestionSubmit,
        isInitialLoading,
        isAnswerLoading,
        initialQuestions,
        getInitialQuestions,
        widgetId,
      }}
    >
      {children}
    </BlickWidgetsContext.Provider>
  )
}
