// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'
// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const ManifestoViewDe = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.aiManifesto" />}>
        <Typography variant="bodybg">
          Bitte akzeptiere und befolge die folgenden Regeln zur Nutzung Künstlicher Intelligenz in der redaktionellen
          Arbeit. Grundlage für die folgenden Punkte sind die{' '}
          <ExternalLink
            target="_blank"
            href="https://mcusercontent.com/f6a607d4980d33aa5086638e8/files/22be4bf5-5e26-ab42-c0df-85ebc6c68b31/AI_Guidelines_Ringier_DE.pdf"
          >
            KI-Richtlinien von Ringier.
          </ExternalLink>
          <ol>
            <li>
              Wir fördern aktiv das Verständnis und die Nutzung von KI-Tools unter unseren Mitarbeitenden, um
              Arbeitsprozesse zu optimieren.
            </li>
            <li>Unser Einsatz von KI basiert stets auf Verantwortung und ethischen Grundsätzen.</li>
            <li>
              Transparenz ist uns wichtig: Wir legen den Einsatz von KI in unserer Berichterstattung deutlich offen, sei
              dies bei Texten oder Bildlegenden.
            </li>
            <li>
              Wir stellen sicher, dass KI-generierte Inhalte nie ohne finale Überprüfung durch einen Menschen
              veröffentlicht werden.
            </li>
            <li>
              Wir halten uns strikt daran, keine vertraulichen Informationen, Betriebsgeheimnisse oder personenbezogene
              Daten in öffentliche KI-Tools einzuspeisen.
            </li>
            <li>
              Für Transkriptionen von Interviews nutzen wir unser internes Audio-Tool (Blisper) im AI-Forge, keine
              KI-Dienste Dritter.
            </li>
            <li>
              KI-basierte Bildertools wie DALL·E 3 und Midjourney setzen wir nicht für die Erstellung von News-Fotos
              ein. Ihr Einsatz ist bei Illustrations-ähnlichen Bildern gestattet, aber nur in Absprache mit dem
              Vorgesetzten, der Fotoredaktion oder dem AI Lead Newsroom.
            </li>
            <li>
              Bei sensiblen Themen, bei denen KI zu Desinformation oder Fehlinterpretationen führen könnte, verzichten
              wir auf deren Nutzung.
            </li>
            <li>Wir bieten interne Schulungen zum Thema KI für unsere Mitarbeitenden an.</li>
            <li>
              In Anbetracht der schnellen Entwicklung im Bereich KI bleiben wir stets auf dem neuesten Stand und
              ermutigen unsere Mitarbeitenden, potenziell nützliche KI-Tools an die zuständige AI-Stelle zu melden.
            </li>
          </ol>
        </Typography>
        <Typography variant="bodybg" sx={{ fontStyle: 'italic' }}>
          Stand Feb24/Bö
        </Typography>
      </MainCard>
    </Text>
  )
}
