'use client'
import { useMediaQuery, useTheme } from '@mui/material'
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react'

// types
import { InitialAppContectProps } from 'types'
import { SnackbarProps } from 'types/snackbar'

// initial state
const initialState: InitialAppContectProps = {
  drawerOpen: true,
  toggleDrawer: () => {
    return
  },
  // TODO:AiForge - check if selectedMenuItem and selectedMenuID can be combined to one prop
  selectedMenuItem: ['dashboard'],
  setSelectedMenuItem: () => {
    return
  },
  selectedMenuID: null,
  setSelectedMenuID: () => {
    return
  },
  snackbar: null,
  openSnackbar: (options: SnackbarProps) => {
    return
  },
  closeSnackbar: () => {
    return
  },
}

// ==============================|| APP CONTEXT & PROVIDER ||============================== //

export const AppContext = createContext(initialState)

type AppProviderProps = {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const theme = useTheme()
  const matchMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState<string[]>([])
  const [selectedMenuID, setSelectedMenuID] = useState<string | null | undefined>(null)
  const [snackbar, setSnackbar] = useState<SnackbarProps | null>(null)

  const toggleDrawer = (isOpen: boolean) => {
    setDrawerOpen(isOpen)
  }

  const openSnackbar = useCallback((options: SnackbarProps) => {
    setSnackbar(options)
  }, [])

  const closeSnackbar = () => {
    if (snackbar) {
      setSnackbar({ ...snackbar, open: false })
    }
  }

  useEffect(() => {
    setDrawerOpen(!matchMobile)
  }, [matchMobile])

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        selectedMenuItem,
        setSelectedMenuItem,
        selectedMenuID,
        setSelectedMenuID,
        openSnackbar,
        closeSnackbar,
        snackbar,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
