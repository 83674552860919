import { Typography, styled } from '@mui/material'
import { ReactNode } from 'react'
import { MaterialIcon } from './MaterialIcon'
import { Spacer } from './Spacer'

type Props = {
  title: string | ReactNode
  text?: string | ReactNode
  actions?: ReactNode
  onClose?: () => void
}

export const InfoBox = ({ title, text, actions, onClose }: Props) => {
  return (
    <Wrapper>
      <div>
        <MaterialIcon name="info" />
      </div>
      <Spacer h size={12} />
      <div>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="bodymd">{text}</Typography>
        <div>{actions}</div>
      </div>
      {onClose && <CloseIcon name="close" fontSize="20px" onClick={() => onClose()} />}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  padding: 12px 16px 3px 16px;
  display: flex;
  background: ${({ theme }) => theme.palette.blue[50]};
  border-radius: 8px;
  position: relative;
`

const CloseIcon = styled(MaterialIcon)`
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
`
