import { Box, Button, styled } from '@mui/material'
import { generateLead, generateMetaTitle, generateOgTitle, generateTeaser } from 'api/textToText'
import { FormattedMessage } from 'components/FormattedMessage'
import { RateModal, RateModalConfig } from 'components/RateModal'
import { Spacer } from 'components/ui-component/Spacer'
import { useAuth } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  ArticleMetaTitleResponse,
  AiVersion,
  TextToTextOrigin,
  GoogleAnalyticsContext,
  ArticlePublications,
  Prompt,
} from 'shared'
import { trackRating } from 'tracking/gtm'
import { TinyMceEvent } from 'types/tinymce-plugin'
import { getLengthValueForPublication } from 'utils/helpers'
import { MultiResult } from 'views/TextToText/components/MultiResult'
import { RoboticLoader } from 'views/TextToText/components/RoboticLoader'

type Props = {
  type: 'meta-title' | 'lead' | 'teaser-headline' | 'teaser-title' | 'og-title'
}

const COMMON_ERROR_TEXT = 'An error occurred. Please close this window and try again.'

export const TitlePluginView = ({ type }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { session } = useAuth()
  const [data, setData] = useState<TinyMceEvent>()
  const [documentId, setDocumentId] = useState<string>()
  const [publication, setPublication] = useState<ArticlePublications>(ArticlePublications.BLICK)
  const [result, setResult] = useState<ArticleMetaTitleResponse>()
  const [pluginTitle, setPluginTitle] = useState<string>()
  const [maxChars, setMaxChars] = useState<number>()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [rateModalConfig, setRateModalConfig] = useState<RateModalConfig | null>(null)
  const [feedbackSent, setFeedbackSent] = useState<boolean>()
  const onInsert = () => {
    result &&
      selectedIndex !== undefined &&
      window.parent.postMessage({ type: 'data', value: result[selectedIndex] }, '*')
  }

  const handleMessage = (event: any) => {
    if (event.data.type === 'data') {
      setData(event.data as TinyMceEvent)
    }

    if (event.data.type === 'id') {
      setDocumentId(event.data.value)
    }

    if (event.data.type === 'language') {
      if (event.data.value === 'fr') {
        setPublication(ArticlePublications.BLICK_ROMANDIE)
      } else {
        setPublication(ArticlePublications.BLICK)
      }
    }
  }

  const getMetaTitle = async (text?: string) => {
    if (!text) {
      return
    }
    setIsLoading(true)

    const metaTitleResult = await generateMetaTitle(
      { body: text, version: AiVersion.CLAUDE_35_SONNET, articleId: documentId, publication },
      { origin: TextToTextOrigin.MDB_PLUGIN },
    )
    if (metaTitleResult) {
      setResult(metaTitleResult)
    } else {
      setError(COMMON_ERROR_TEXT)
    }
    setIsLoading(false)
  }

  const getLead = async (text?: string) => {
    if (!text) {
      return
    }
    setIsLoading(true)
    const leadResult = await generateLead(
      { body: text, version: AiVersion.CLAUDE_35_SONNET, articleId: documentId, publication },
      { origin: TextToTextOrigin.MDB_PLUGIN },
    )
    if (leadResult) {
      setResult(leadResult)
    } else {
      setError(COMMON_ERROR_TEXT)
    }
    setIsLoading(false)
  }

  const getTeaser = async (text?: string, headline?: boolean) => {
    if (!text) {
      return
    }
    setIsLoading(true)
    const teaserResult = await generateTeaser(
      { body: text, version: AiVersion.CLAUDE_35_SONNET, articleId: documentId, publication },
      { origin: TextToTextOrigin.MDB_PLUGIN },
    )
    if (teaserResult) {
      setResult(headline ? teaserResult.teaserHeadline : teaserResult.teaserTitle)
    } else {
      setError(COMMON_ERROR_TEXT)
    }
    setIsLoading(false)
  }

  const getOgTitle = async (text?: string) => {
    if (!text) {
      return
    }
    setIsLoading(true)
    const ogResult = await generateOgTitle(
      { body: text, version: AiVersion.CLAUDE_35_SONNET, articleId: documentId, publication },
      { origin: TextToTextOrigin.MDB_PLUGIN },
    )
    if (ogResult) {
      setResult(ogResult)
    } else {
      setError(COMMON_ERROR_TEXT)
    }
    setIsLoading(false)
  }

  const getData = useCallback(() => {
    if (data && type) {
      switch (type) {
        case 'meta-title': {
          getMetaTitle(data.value)
          setPluginTitle('Meta-Titel')
          setMaxChars(getLengthValueForPublication(ArticlePublications.BLICK, Prompt.META_TITLE) as number)
          setError(undefined)
          break
        }
        case 'lead': {
          getLead(data.value)
          setPluginTitle('Artikel-Lead')
          setMaxChars(getLengthValueForPublication(ArticlePublications.BLICK, Prompt.LEAD) as number)
          setError(undefined)
          break
        }
        case 'teaser-headline': {
          getTeaser(data.value, true)
          setPluginTitle('Teaser-Überzeile')
          setMaxChars(
            (getLengthValueForPublication(ArticlePublications.BLICK, Prompt.TEASER) as number[])?.[0] as number,
          )
          setError(undefined)
          break
        }
        case 'teaser-title': {
          getTeaser(data.value)
          setPluginTitle('Teaser-Titel')
          setMaxChars(
            (getLengthValueForPublication(ArticlePublications.BLICK, Prompt.TEASER) as number[])?.[1] as number,
          )
          setError(undefined)
          break
        }
        case 'og-title': {
          getOgTitle(data.value)
          setPluginTitle('Og-title')
          setMaxChars(getLengthValueForPublication(ArticlePublications.BLICK, Prompt.OG_TITLE) as number)
          setError(undefined)
          break
        }
        default: {
          setError('No type provided. Please provide type ("meta-title" or "lead")')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type])

  const onRegenerate = () => {
    setSelectedIndex(undefined)
    setFeedbackSent(undefined)
    getData()
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    window.parent.postMessage({ type: 'ready' }, '*')

    return () => {
      window.removeEventListener('message', handleMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => getData(), [getData])

  useEffect(() => {
    if (!data?.value) {
      setError('Bitte Artikelinhalt befüllen.')
    }
  }, [data])

  return (
    <>
      <RoboticLoader isLoading={isLoading} loaderRef={null} isTitleLoading />
      {!isLoading && result && (
        <Wrapper>
          <MultiResult
            title={
              <>
                <FormattedMessage id={'common.chooseA'} /> {pluginTitle}
              </>
            }
            content={result}
            onSelect={(index) => setSelectedIndex(index)}
            selectedIndex={selectedIndex}
            isLoading={false}
            chipLabel={t('common.maxCharacters', { value: maxChars })}
            feedbackKey={pluginTitle}
            onRatingClick={setRateModalConfig}
            feedbackSent={feedbackSent}
            onRegenerateClick={onRegenerate}
            plugin
          />
          <Spacer v size={24} />
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" disabled={selectedIndex === undefined} onClick={onInsert}>
              Insert {pluginTitle}
            </Button>
          </Box>
        </Wrapper>
      )}

      {error && <Wrapper>{error}</Wrapper>}

      {rateModalConfig && (
        <RateModal
          isOpen={Boolean(rateModalConfig)}
          onClose={() => setRateModalConfig(null)}
          config={rateModalConfig}
          onConfirm={() => setFeedbackSent(true)}
          onTrack={(rating, feedbackText) =>
            trackRating({
              page: pathname,
              hashedUserId: session?.hashedId,
              elementLabel: rateModalConfig.title,
              context: GoogleAnalyticsContext.MDB_PLUGIN,
              rating,
              feedbackText,
              model: AiVersion.CLAUDE_35_SONNET,
              articleId: documentId,
            })
          }
        />
      )}
    </>
  )
}

const Wrapper = styled('div')`
  padding: 24px;
`
