// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const RecommendedViewRo = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.recommendedTools" />}>
        <Typography variant="bodybg">
          Bine ai venit pe pagina noastră selectată cu atenție, unde recomandăm o varietate de instrumente AI externe!
          Aici, vei găsi o selecție de instrumente care pot îmbunătăți munca ta și pot stimula inovația. Cu toate
          acestea, este crucial să îți amintești că aceste instrumente ar trebui utilizate cu grijă și responsabilitate.
          <br />
          <br />
          Înainte de a te arunca înăuntru, te îndemnăm să te familiarizezi cu ghidurile noastre AI. Aceste ghiduri sunt
          în loc pentru a asigura utilizarea etică, responsabilă și eficientă a tehnologiilor AI. Ele te vor ajuta să
          navighezi prin complexitățile și provocările potențiale care vin odată cu utilizarea instrumentelor AI
          externe.
          <br />
          <br />
          Te rog să ții cont că, deși recomandăm aceste instrumente pentru beneficiile lor potențiale, nu oferim suport
          direct pentru ele. Dacă întâmpini probleme sau ai întrebări, va trebui să te adresezi canalelor de suport
          respective pentru fiecare instrument.
          <br />
          <br />
          Folosește aceste instrumente cu înțelepciune pentru a explora posibilitățile AI, având întotdeauna în minte
          ghidurile noastre și nevoia de aplicare cu prudență și informare. Explorare plăcută!
        </Typography>
        <Typography variant="h3">Asistenți AI:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://chat.openai.com/">
            ChatGPT (OpenAI)
          </ExternalLink>
          : În prezent cel mai bun asistent AI de pe piață. ChatGPT de la OpenAI este un model AI conversațional capabil
          să genereze text asemănător cu cel uman. Este ideal pentru redactarea de articole, brainstorming, obținerea de
          feedback cu privire la idei, rezumarea conținutului și altele. Motorul de creare a imaginilor al OpenAI,
          Dall-E, accesibil prin ChatGPT, poate genera imagini unice pentru articole.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.perplexity.ai/">
            Perplexity
          </ExternalLink>
          : Perlexity.ai este un asistent AI care furnizează răspunsuri multimedia și agregă mai multe modele AI
          subiacente, cum ar fi ChatGPT al OpenAI sau Claude al Anthropic. Poate fi folosit pentru o varietate de
          sarcini de zi cu zi.
        </Typography>
        <Typography variant="h3">Scriere:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://rytr.me/">
            Rytr
          </ExternalLink>
          : Un asistent AI de scriere care susține limba germană și care ajută la crearea rapidă a conținutului
          convingător. Poate fi folosit pentru redactarea de articole, generarea de titluri și crearea de texte
          creative.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://neuroflash.com/">
            Neuroflash
          </ExternalLink>
          : Un AI de generare a textului care susține limba germană și care se specializează în crearea de conținut de
          marketing. Este util în redactarea introducerilor captivante ale articolelor, a copiilor de reclamă și a
          postărilor pe rețelele sociale legate de articole.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://quillbot.com/">
            Quillbot
          </ExternalLink>
          : Un instrument de scriere și parafrazare alimentat de AI. Ajută la rescrierea propozițiilor pentru claritate,
          îmbunătățirea gramaticii și evitarea plagiatului în articole. Funcționează bine în limba germană.
        </Typography>
        <Typography variant="h3">Creare de imagini:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://www.midjourney.com/">
            Midjourney
          </ExternalLink>
          : Un program AI specializat în crearea de imagini de înaltă calitate, artistice. Ideal pentru crearea de
          vizualuri și ilustrații unice pentru povești și caracteristici. În prezent accesibil doar prin{' '}
          <ExternalLink target="_blank" href="https://discord.com/">
            Discord
          </ExternalLink>
          . Poți găsi un ghid despre cum să îl folosești{' '}
          <ExternalLink
            target="_blank"
            href="https://www.datacamp.com/tutorial/how-to-use-midjourney-a-comprehensive-guide-to-ai-generated-artwork-creation"
          >
            aici
          </ExternalLink>
          .
          <br />
          <br />
          <ExternalLink target="_blank" href="https://leonardo.ai/">
            Leonardo.ai
          </ExternalLink>
          : Un instrument alimentat de AI care generează imagini și artă realiste. Util pentru povestirea vizuală și
          crearea de ilustrații personalizate pentru articole.
        </Typography>
        <Typography variant="h3">Editare foto:</Typography>
        <Typography variant="bodybg">
          <ExternalLink target="_blank" href="https://skylum.com/luminar">
            Luminar Neo
          </ExternalLink>
          : O platformă de editare foto care utilizează AI pentru sarcini avansate de editare. Poate îmbunătăți
          calitatea fotografiilor utilizate în articole, inclusiv ajustările în iluminare, textură și culoare.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.topazlabs.com/">
            Topaz Labs
          </ExternalLink>
          : Oferă software de îmbunătățire a fotografiilor alimentat de AI. Util pentru mărirea imaginilor fără pierdere
          de calitate, reducerea zgomotului și accentuarea imaginilor pentru publicații digitale.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://imagen-ai.com/">
            Imagen
          </ExternalLink>
          : Un instrument de editare a imaginilor bazat pe AI care poate edita automat fotografiile, ajusta iluminarea
          și culorile și aplica diferite efecte, potrivit pentru îmbunătățirea conținutului vizual în articole.
          <br />
          <br />
          <ExternalLink target="_blank" href="https://www.remove.bg/">
            Remove.bg
          </ExternalLink>
          : Se specializează în înlăturarea fundalurilor din imagini folosind AI. Este benefic pentru editarea rapidă a
          imaginilor pentru articole, în special atunci când este necesar să se izoleze subiectele de fundalurile lor
          pentru compoziții grafice.
        </Typography>
      </MainCard>
    </Text>
  )
}
