// material-ui
import { Typography } from '@mui/material'
import { FormattedMessage } from 'components/FormattedMessage'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { ExternalLink } from 'components/ui-component/links/ExternalLink'
import { Text } from 'components/ui-component/Text'

// ==============================|| DASHBOARD PAGE ||============================== //

export const AboutViewPl = () => {
  return (
    <Text>
      <MainCard title={<FormattedMessage id="pages.about" />}>
        <Typography variant="bodybg">
          AI Forge to specjalistyczne narzędzie AI zaprojektowane dla Ringier Media, oferujące zestaw rozwiązań, które
          wykorzystują generatywną sztuczną inteligencję i inne technologie AI. Są one dostosowane do skutecznego
          rozwiązywania konkretnych problemów spotykanych w pracy redakcyjnej.
          <br />
          <br />
          Ten innowacyjny zestaw narzędzi jest częścią inicjatywy AI Boost.
          <br />
          <br />
          Pierwszym dostępnym rozwiązaniem jest nasze zaawansowane narzędzie do konwersji mowy na tekst, wcześniej znane
          jako Blisper. Oferuje ono bezproblemowy sposób na transkrypcję plików wideo i dźwiękowych w dowolnym języku,
          upraszczając tworzenie treści.
          <br />
          <br />
          W przyszłości planujemy wprowadzić narzędzie, które umożliwi tworzenie artykułów na podstawie różnych
          zewnętrznych źródeł, takich jak raporty policyjne, raporty medialne czy artykuły zagranicznych gazet. To
          znacznie zwiększy różnorodność i trafność treści.
          <br />
          <br />
          Jesteśmy zobowiązani do regularnego wprowadzania i testowania nowych funkcji, aby stale ulepszać i wspierać
          Twoją codzienną pracę.
          <br />
          <br />
          Twoja opinia jest dla nas bezcenna. Prosimy podziel się swoimi przemyśleniami na temat jakości poszczególnych
          narzędzi poprzez tę ankietę:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/afo3a59KeYZqvT9V7">
            https://forms.gle/afo3a59KeYZqvT9V7
          </ExternalLink>
          <br />
          <br />W przypadku napotkania jakichkolwiek błędów lub problemów, proszę zgłosić je tutaj:{' '}
          <ExternalLink target="_blank" href="https://forms.gle/uXR2LGeug8ps4NU16">
            https://forms.gle/uXR2LGeug8ps4NU16
          </ExternalLink>
          <br />
          <br />W przypadku jakichkolwiek innych pytań lub sugestii, proszę śmiało kontaktować się z:
          <ul>
            <li>
              Lauri Kurki, Kierownik Projektu AI Boost, na adres{' '}
              <ExternalLink href="mailto:lauri.kurki.extern@ringier.ch">lauri.kurki.extern@ringier.ch</ExternalLink>
            </li>
            <li>
              Thomas Benkö, Kierownik Innowacji AI w redakcji Blick, na adres{' '}
              <ExternalLink href="mailto:thomas.benkoe@ringier.ch">thomas.benkoe@ringier.ch</ExternalLink>
            </li>
          </ul>
        </Typography>
      </MainCard>
    </Text>
  )
}
